import Dumb, { Props } from 'components/GFC/GFCCancelButton/GFCCancelButton'
import React from 'react'
import { useCancelGfcRegistrationMutation } from 'types/graphql'
import useRefreshGfcTable from 'hooks/useRefreshGfcTable'

const GFCCancelButton = (
  props: Omit<Props, 'loading' | 'cancelRegistration'>
): JSX.Element => {
  const onCompleted = useRefreshGfcTable()
  const registrationId = props.attendee.registrationId as number
  const [cancelRegistration, { loading }] = useCancelGfcRegistrationMutation({
    variables: { registrationId },
    onCompleted,
  })
  return (
    <Dumb
      cancelRegistration={ cancelRegistration }
      loading={ loading }
      { ...props }
    />
  )
}

export default GFCCancelButton
