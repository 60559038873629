import Dumb from 'components/GFC/GFCDetailsDialog/GFCDetailsDialog'
import React from 'react'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import {
  CloseDialogMutationFn,
  DialogPropsQuery,
  useDialogPropsQuery,
  useGfcScheduleQuery,
} from 'types/graphql'
import ErrorMessage from 'components/ErrorMessage'

interface Props {
  fullScreen: boolean;
  closeDialog: CloseDialogMutationFn;
}

const GFCDetailsDialog = ({
  fullScreen,
  closeDialog,
}: Props): JSX.Element | null => {
  const { data: dialogData } = useDialogPropsQuery()
  const {
    dialogProps: { id },
  } = dialogData as DialogPropsQuery
  const { data, loading, error } = useGfcScheduleQuery({ variables: { id } })

  if (loading) return null

  if (!data) return <ErrorMessage error={ error } />

  return (
    <Dumb
      gfcSchedule={ data.gfcSchedule }
      fullScreen={ fullScreen }
      closeDialog={ closeDialog }
    />
  )
}

export default withMobileDialog()(GFCDetailsDialog)
