import React, { useEffect } from 'react'
import { Tab, Tabs } from 'material-ui/Tabs'
import CartIcon from 'material-ui/svg-icons/action/shopping-cart'
import CardLi from 'components/CardLi'
import CouponField from 'components/CouponField'
import UpdateCartItemQuantity from 'components/UpdateCartItemQuantity'
import CheckoutButton from 'components/CheckoutButton'
import RemoveCartItemButton from 'components/RemoveCartItemButton'
import { asPrice } from 'lib/helpers'
import Divider from 'material-ui/Divider'
import { CartItemFragment, CartItemsQuery } from 'types/graphql'
import useStyles from './styles'

const otherStyles = {
  cardLiHeader: {
    paddingRight: '20px',
  },
  removeButton: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}

export interface Props {
  data: CartItemsQuery;
  closeCart: () => void;
}

const Cart = ({ data: { viewer }, closeCart }: Props): JSX.Element => {
  const total = (viewer.cart && viewer.cart.total) || 0
  const items = (viewer.cart && viewer.cart.items) || []
  const classes = useStyles()

  useEffect((): void => {
    // close the drawer if last item removed
    if (!items.length) {
      closeCart()
    }
  }, [items, closeCart])

  const subtitle = (item: CartItemFragment): JSX.Element => {
    return item.isMembership ? (
      <span>{asPrice(item.price * item.quantity)}</span>
    ) : (
      <div>
        {item.staff ? <p>Instructor: {item.staff.name}</p> : null}
        <UpdateCartItemQuantity item={ item } /> /{' '}
        {asPrice(item.price * item.quantity)}
      </div>
    )
  }

  return (
    <Tabs className={ classes.container }>
      <Tab icon={ <CartIcon /> } aria-label='Cart'>
        <h2 className={ classes.tabHeader }>Order Summary</h2>
        {items.map(
          (item): JSX.Element => (
            <CardLi
              key={ item.id }
              title={ item.title }
              subtitle={ subtitle(item) }
              headerChildren={
                <RemoveCartItemButton
                  itemId={ item.id }
                  buttonStyle={ otherStyles.removeButton }
                />
              }
            />
          )
        )}
        <Divider />
        <CouponField />
        <div className={ classes.buttonContainer }>
          <CheckoutButton
            label={ `Checkout - ${asPrice(total)}` }
            total={ asPrice(total) }
            items={ items }
          />
        </div>
      </Tab>
    </Tabs>
  )
}

export default Cart
