import React from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TermsDialog from 'components/TermsDialog'
import RegistrationStepper from 'components/registration/RegistrationStepper'
import ImageRotator from 'components/ImageRotator'
import Hidden from '@material-ui/core/Hidden'
import PointsOnlyRegistration from 'components/registration/PointsOnlyRegistration'
import useStyles from './registrationStyles'

export interface Props {
  pointsOnly?: boolean;
}

const Registration = ({ pointsOnly }: Props): JSX.Element => {
  const classes = useStyles()
  const { invitationToken } = useParams()

  return (
    <Grid container className={ classes.container }>
      <Grid item xs={ 12 } md={ 5 } className={ classes.content }>
        <img
          src='/images/elevation-logo.png'
          alt='elevation-logo'
          className={ classes.logo }
        />
        <Hidden mdUp>
          <Link to='/login'>
            <Button variant='text' className={ classes.signInButtonSmallScreen }>
              Sign In
            </Button>
          </Link>
        </Hidden>
        <div className={ classes.form }>
          {pointsOnly && invitationToken ? (
            <PointsOnlyRegistration token={ invitationToken } />
          ) : (
            <RegistrationStepper />
          )}
        </div>
        <Hidden mdUp>
          <footer className={ classes.footer }>
            <TermsDialog />
          </footer>
        </Hidden>
      </Grid>

      <Hidden smDown>
        <Grid item md={ 7 } sm={ 5 } className={ classes.ambiance }>
          <ImageRotator />
          <div className={ classes.signInContainer }>
            <span className={ classes.gutterRight }>
              Already have an existing account?
            </span>
            <Link to='/login'>
              <Button variant='text' className={ classes.signInButton }>
                Sign In
              </Button>
            </Link>
          </div>
          <div className={ classes.termsContainer }>
            <p>
              By proceeding to create your account and use Elevation Corporate
              Health Service, you are agreeing to our Terms of Service. If you
              do not agree, do not use Elevation Corporate Health Service.
            </p>
            <TermsDialog />
          </div>
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default Registration
