import Dumb from './RedeemButton'
import React from 'react'
import {
  CloseDialogMutationFn,
  useRedeemRewardPointsMutation,
} from 'types/graphql'

export interface Props {
  productId: number;
  points: number;
  closeDialog?: CloseDialogMutationFn;
  disabled: boolean;
}
const RedeemButton = ({
  productId,
  closeDialog,
  points,
  ...props
}: Props): JSX.Element => {
  const [redeemRewardPoints, { loading }] = useRedeemRewardPointsMutation({
    variables: { productId, points },
    onCompleted: (): void => {
      closeDialog && closeDialog()
    },
  })

  return (
    <Dumb
      redeem={ redeemRewardPoints }
      loading={ loading }
      points={ points }
      { ...props }
    />
  )
}

export default RedeemButton
