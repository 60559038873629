import Dumb from './ConfirmationStep'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation, Query } from 'react-apollo'
import Spinner from 'components/Spinner'
import {
  removeWithId,
  addMissingIds,
  getPreviousQueryVars,
} from 'lib/cacheHelpers'

import { REGISTER_FOR_APPOINTMENT_MUTATION } from 'mutations'
import {
  APPOINTMENT_CONFIRMATION_QUERY,
  APPOINTMENTS_FOR_CALENDAR_QUERY,
} from 'queries'

const getCacheData = (cache, vars) =>
  cache.readQuery({
    query: APPOINTMENTS_FOR_CALENDAR_QUERY,
    variables: vars,
  })

// Extract the calendar entries froma a data object
const getEntries = data => data.viewer.location.appointmentCalendarEntries

const updateCache = (cache, vars, newData) => {
  cache.writeQuery({
    query: APPOINTMENTS_FOR_CALENDAR_QUERY,
    variables: vars,
    data: newData,
  })
}

const update = (cache, { data }, slotId) => {
  const vars = getPreviousQueryVars(cache, 'appointmentCalendarEntries')

  const cacheData = getCacheData(cache, vars)
  const cachedEntries = getEntries(cacheData)
  const responseEntries = getEntries(data.registerForAppointment)

  // Remove the entry for which the user registered. This is required in case
  // the registration caused the entry to be deleted.
  const trimmedEntries = removeWithId(cachedEntries, slotId)
  // Add the calendar entries from the response to the cache. The response
  // should have entries for this apptId during the time span of the
  // original entry.
  const combinedEntries = addMissingIds(trimmedEntries, responseEntries)

  // Duplicate cacheData to keep it immutable
  let newData = Object.assign({}, cacheData)
  newData.viewer.location.appointmentCalendarEntries = combinedEntries

  updateCache(cache, vars, newData)
}

const ConfirmationStep = props => {
  const {
    onComplete,
    back,
    event: { eventId, id: slotId, startTime: eventStart, endTime: eventEnd },
    selectedLength,
    selectedTime,
  } = props

  // Stepper may render this before length is available
  if (!selectedLength || !selectedTime) return ''

  return (
    <Query
      query={ APPOINTMENT_CONFIRMATION_QUERY }
      variables={ {
        apptId: eventId,
        length: selectedLength,
      } }
    >
      {({ data, loading }) => {
        if (loading) return <Spinner center />

        const price = data.viewer.location.appointment.totalPrice
        return (
          <Mutation
            mutation={ REGISTER_FOR_APPOINTMENT_MUTATION }
            variables={ {
              length: selectedLength,
              startTime: selectedTime,
              apptId: eventId,
              eventStart,
              eventEnd,
            } }
            onCompleted={ onComplete }
            update={ (...args) => update(...args, slotId) }
          >
            {(registerForAppt, { loading }) => (
              <Dumb
                { ...props }
                price={ price }
                registerForAppt={ registerForAppt }
                loading={ loading }
                back={ back }
              />
            )}
          </Mutation>
        )
      }}
    </Query>
  )
}

ConfirmationStep.propTypes = {
  onComplete: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  selectedLength: PropTypes.number,
  selectedTime: PropTypes.string,
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
}

export default ConfirmationStep
