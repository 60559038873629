import React, { useState } from 'react'
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Props } from './index'

const PasswordInput = ({
  label,
  error,
  name,
  ...props
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  return (
    <FormControl fullWidth key={ name } error={ Boolean(error) }>
      <InputLabel required htmlFor={ name }>
        {label}
      </InputLabel>
      <Input
        { ...props }
        id={ name }
        name={ name }
        type={ showPassword ? 'text' : 'password' }
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='Toggle password visibility'
              onClick={ handleClickShowPassword }
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

export default PasswordInput
