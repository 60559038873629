import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  card: {
    width: '100%',
    position: 'relative',
  },
  removalBtn: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  avatar: {
    height: 100,
    width: 100,
    margin: 'auto',
  },
})
