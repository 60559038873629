import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

// Style the child elements with active color
export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    container: {
      marginBottom: spacing(),
      display: 'inline-block',
    },
    unchecked: { opacity: 0.3 },
    button: {
      padding: spacing(),
    },
  })
)
