import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { TIME_FORMAT } from 'lib/formats'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  btnWrapper: {
    padding: theme.spacing(),
  },
  button: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  backBtnContainer: {
    marginTop: 10,
  },
})

const TimeStep = ({ times, onComplete, back, classes }) => (
  <Grid container>
    {times.map(time => (
      <Grid item xs={ 3 } key={ time } className={ classes.btnWrapper }>
        <Button
          variant='outlined'
          className={ classes.button }
          onClick={ () => onComplete('selectedTime', time) }
        >
          {moment(time).format(TIME_FORMAT)}
        </Button>
      </Grid>
    ))}
    <Grid container className={ classes.backBtnContainer }>
      <Button onClick={ back }>Back</Button>
    </Grid>
  </Grid>
)

TimeStep.propTypes = {
  times: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default withStyles(styles)(TimeStep)
