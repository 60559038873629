import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'
import SelectFieldInput from 'components/dialogForms/SelectFieldInput'

export interface Props {
  editUserLoading: boolean;
}

const EditEmergencyInfoDialog = ({ editUserLoading }: Props): JSX.Element => {
  return (
    <DialogForm submitLabel='Save' loading={ editUserLoading }>
      <h2 style={ { fontWeight: 100 } }>Edit Emergency Info</h2>
      <TextFieldInput name='emergencyFirstName' />
      <TextFieldInput name='emergencyMiddleName' />
      <TextFieldInput name='emergencyLastName' />
      <SelectFieldInput
        name='emergencyRelation'
        labelWidth={ 150 }
        options={ [
          { value: 1, label: 'Father' },
          { value: 2, label: 'Mother' },
          { value: 3, label: 'Friend' },
          { value: 4, label: 'Other' },
        ] }
        emptyOptionValue={ 0 }
      />
      <TextFieldInput name='emergencyPhone' phoneNumber />
      <TextFieldInput name='emergencyEmail' />
      <TextFieldInput name='emergencyAddress' />
    </DialogForm>
  )
}

export default EditEmergencyInfoDialog
