import Dumb from 'components/hfs/FitnessService/FitnessService'
import React from 'react'
import { FITNESS_SERVICE_TYPES_QUERY } from 'queries/hfs'

import { useAddItemToCartMutation, useAdvanceTourMutation } from 'types/graphql'
import { FitnessServiceIndexProps } from 'components/hfs/types'

const FitnessService = (props: FitnessServiceIndexProps): JSX.Element => {
  const [
    addItemToCartMutation,
    { loading: addItemToCartLoading },
  ] = useAddItemToCartMutation({
    refetchQueries: [{ query: FITNESS_SERVICE_TYPES_QUERY }],
  })

  const [advanceTour] = useAdvanceTourMutation()

  return (
    <Dumb
      addItemToCartMutation={ addItemToCartMutation }
      advanceTour={ advanceTour }
      addItemToCartLoading={ addItemToCartLoading }
      { ...props }
    />
  )
}

export default FitnessService
