import React from 'react'
import PropTypes from 'prop-types'
import confirm from 'lib/confirm'
import LoadingButton from 'components/LoadingButton'

const handleClick = renewSubscription => {
  const text = 'Would you like to restart your subscription?'
  confirm(text).then(() => renewSubscription(), () => {})
}

const RenewSubscriptionButton = ({ renewSubscription, loading }) => (
  <LoadingButton
    loading={ loading }
    onClick={ () => handleClick(renewSubscription) }
  >
    Renew Subscription
  </LoadingButton>
)

RenewSubscriptionButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  renewSubscription: PropTypes.func.isRequired,
}

export default RenewSubscriptionButton
