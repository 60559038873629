// This solutiuon is only for the v3 transition phase. set token sent in the url.
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { login } from 'lib/helpers'

const GetRedirectedCreds = ({ match }) => {
  if (match.params.token) login(match.params.token)
  return <Redirect to='/' />
}

GetRedirectedCreds.propTypes = {
  match: PropTypes.object.isRequired,
}

export default GetRedirectedCreds
