import React from 'react'
import Typography from '@material-ui/core/Typography'
import LoadingButton from 'components/LoadingButton'
import PasswordInput from 'components/account/PasswordInput'
import { FormikProps } from 'formik'
import useStyles from '../accountStyles'
import { ResetPasswordForm } from './index'

export interface FormField {
  name: string;
  label: string;
  error?: string | boolean;
}

const fields = ({
  errors,
  touched,
}: FormikProps<ResetPasswordForm>): FormField[] => [
  {
    name: 'currentPassword',
    label: 'Current Password',
    error: touched.currentPassword && errors.currentPassword,
  },
  {
    name: 'password',
    label: 'New Password',
    error: touched.password && errors.password,
  },
  {
    name: 'passwordConfirmation',
    label: 'Confirm Password',
    error: touched.passwordConfirmation && errors.passwordConfirmation,
  },
]

export interface Props {
  loading: boolean;
  formikProps: FormikProps<ResetPasswordForm>;
}

const ChangePasswordTab = ({ formikProps, loading }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <form className={ classes.container } onSubmit={ formikProps.handleSubmit }>
      <Typography variant='subtitle2' className={ classes.helpText }>
        Change your password here. Remember to make it unique, secure and at
        least 6 characters!
      </Typography>
      {fields(formikProps).map(
        (field: FormField): JSX.Element => (
          <PasswordInput key={ field.name } { ...field } />
        )
      )}
      <div className={ classes.actionButtonContainer }>
        <LoadingButton type='submit' loading={ loading }>
          Change Password
        </LoadingButton>
      </div>
    </form>
  )
}

export default ChangePasswordTab
