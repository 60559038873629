import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { createMarkup } from 'lib/helpers'
import LoadingButton from 'components/LoadingButton'
import LogoutButton from 'components/auth/LogoutButton'
import ErrorDialog from 'components/ErrorDialog'

export interface Props {
  open: boolean;
  skipLogout?: boolean;
  handleDisagreed: () => Promise<void>;
  handleAgreed: () => void;
  agreedLoading: boolean;
  disagreedLoading: boolean;
  title?: string | null;
  waiver?: string | null;
}

const WaiverDialog = ({
  open,
  skipLogout,
  handleDisagreed,
  handleAgreed,
  agreedLoading,
  disagreedLoading,
  title,
  waiver,
}: Props): JSX.Element | null => {
  // skip waiver if it's not set
  if (!title || !waiver)
    return (
      <ErrorDialog
        errorMessage='Waiver not found. Please contact your facility manager.'
        allowLogout
      />
    )

  return (
    <Dialog
      open={ open }
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='confirmation-dialog-title'
    >
      <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={ createMarkup(waiver) } />
      </DialogContent>
      <DialogActions>
        {skipLogout ? (
          <LoadingButton
            loading={ disagreedLoading }
            secondary
            onClick={ handleDisagreed }
          >
            Disagree
          </LoadingButton>
        ) : (
          <LogoutButton beforeLogout={ handleDisagreed }>
            <LoadingButton loading={ disagreedLoading } secondary>
              Disagree (Logout)
            </LoadingButton>
          </LogoutButton>
        )}
        <LoadingButton loading={ agreedLoading } onClick={ handleAgreed }>
          Agree
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default WaiverDialog
