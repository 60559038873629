import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EventSlot from '../EventSlot'
import Spinner from 'components/Spinner'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'

class EventSlots extends Component {
  state = { hasMore: true }

  loadFunc = () => {
    const { updateQuery, props } = this
    const { availableSlots, type } = props.data.viewer.location.event
    const before = props.variables.before
    let after
    if (availableSlots.length) {
      if (type === 'OpenAvailabilityEvent') {
        // we need to add a day to whole day events so we don't get the same
        // slot over and over
        after = moment(availableSlots[availableSlots.length - 1].endTime).add(
          1,
          'day'
        )
      } else {
        after = availableSlots[availableSlots.length - 1].endTime
      }
    } else {
      after = props.variables.after
    }

    this.props
      .fetchMore({
        variables: { after, before },
        updateQuery,
      })
      .then(() => {
        // For some reason the dialog doesn't reposition itself with data
        window.dispatchEvent(new Event('resize'))
      })
  }

  updateQuery = (prev, { fetchMoreResult }) => {
    if (!fetchMoreResult) return prev
    if (!fetchMoreResult.viewer.location.event.availableSlots.length) {
      this.setState({ hasMore: false })
    }
    const slots = Object.assign({}, prev.viewer.location.event, {
      availableSlots: [
        ...prev.viewer.location.event.availableSlots,
        ...fetchMoreResult.viewer.location.event.availableSlots,
      ],
    })
    return Object.assign({}, prev, {
      viewer: {
        ...prev.viewer,
        location: {
          ...prev.viewer.location,
          event: { ...prev.viewer.location.event, ...slots },
        },
      },
    })
  }

  render() {
    const { loading, data } = this.props

    if (loading) return <Spinner center />
    if (!data) return ''

    const event = data.viewer.location.event

    return (
      <InfiniteScroll
        loadMore={ this.loadFunc }
        hasMore={ this.state.hasMore }
        loader={ <h4 key={ 0 }>Loading ...</h4> }
        // listen to scroll on parent component instead of window
        useWindow={ false }
        style={ { width: '100%' } }
      >
        {event.availableSlots.map(slot => (
          <EventSlot
            slot={ slot }
            event={ event }
            key={ `${event.id}-${slot.startTime}` }
            after={ this.props.variables.after }
            before={ this.props.variables.before }
          />
        ))}
      </InfiniteScroll>
    )
  }
}

EventSlots.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      location: PropTypes.shape({
        event: PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
          availableSlots: PropTypes.arrayOf(
            PropTypes.shape({
              startTime: PropTypes.string.isRequired,
              endTime: PropTypes.string.isRequired,
            })
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }),
  variables: PropTypes.shape({
    after: PropTypes.string.isRequired,
    before: PropTypes.string.isRequired,
  }),
}

export default EventSlots
