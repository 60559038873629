import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'

export interface Props {
  submitLoading: boolean;
}

const RenameCreditCardDialog = ({ submitLoading }: Props) => (
  <DialogForm submitLabel='Rename' loading={ submitLoading }>
    <h2 style={ { fontWeight: 100 } }>Rename Credit Card Label</h2>
    <TextFieldInput name='label' label='New Label' />
  </DialogForm>
)

export default RenameCreditCardDialog
