import React, { Fragment } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import EventSlots from 'components/Events/EventSlots'
import { CloseDialogMutationFn, DialogPropsEvent } from 'types/graphql'
import EventDialogTitle from 'components/Events/EventDialogTitle'
import ErrorMessage from 'components/ErrorMessage'

export interface Props {
  event: DialogPropsEvent | null;
  closeDialog: CloseDialogMutationFn;
}

const EventSlotsDialog = ({ event, closeDialog }: Props): JSX.Element => {
  const handleClose = (): void => {
    closeDialog()
  }

  return (
    <Dialog open onClose={ handleClose }>
      {event ? (
        <Fragment>
          <EventDialogTitle event={ event } />
          <DialogContent>
            <EventSlots
              eventId={ event.eventId as number }
              startDate={ event.startTime }
              endDate={ event.endTime }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={ handleClose }>Close</Button>
          </DialogActions>
        </Fragment>
      ) : (
        <ErrorMessage />
      )}
    </Dialog>
  )
}

export default EventSlotsDialog
