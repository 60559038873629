import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    welcomeText: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
  })
)
