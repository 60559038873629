import React, { useContext } from 'react'
import { useLocationUpdatesQuery } from 'types/graphql-auth'
import { RegistrationContext } from 'components/registration/helpers'
import { Context } from 'components/registration/types'
import FormSelectField from 'components/registration/FormSelectField'

const SubLocationStep = (): JSX.Element | null => {
  const { formValues } = useContext(RegistrationContext) as Context

  const { data, loading, error } = useLocationUpdatesQuery({
    variables: { locationId: formValues.user.locationId as number },
  })

  if (loading) return null

  if (!data) return <p>Error: {error && error.message}</p>

  return (
    <FormSelectField
      autoFocus
      label={ `Choose ${data.location.config.subLocationsLabel}` }
      options={ data.location.subLocations }
      name='user.subLocationId'
    />
  )
}

export default SubLocationStep
