import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  container: {
    height: '100%',
  },
  tabHeader: {
    textAlign: 'center' as 'center',
    fontWeight: 100,
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '1.1rem',
  },
  profileImageContainer: {
    width: '100%',
    textAlign: 'center' as 'center',
    marginTop: '10px',
  },
  tab: {
    minWidth: 50,
  },
})
