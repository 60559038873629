import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  (theme: Theme): StyleRules => ({
    list: {
      marginTop: -theme.spacing(4),
      marginBottom: -theme.spacing(2),
    },
  })
)
