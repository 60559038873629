import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

// Style the child elements with active color
export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    itemsList: {
      width: '100%',
    },
    expansionPanel: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      width: '100%',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
    expansionPanelSummary: {
      position: 'sticky',
      top: -spacing(1),
      backgroundColor: '#EEE',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: spacing(7),
      zIndex: 2,
    },
    spinnerContainer: {
      minHeight: spacing(8),
    },
    spinner: {
      top: spacing(1),
    },
  })
)
