import { makeStyles, Theme, StyleRules } from '@material-ui/core/styles'

// Style the child elements with active color
export default makeStyles(
  (theme: Theme): StyleRules => ({
    buttonContainer: {
      marginTop: theme.spacing(),
    },
  })
)
