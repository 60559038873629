import React from 'react'
import DialogLink from 'components/DialogLink'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import { UserCardsQuery } from 'types/graphql'
import UserCreditCard from 'components/account/UserCreditCard'

export interface Props {
  data: UserCardsQuery;
}

const CreditCardsTab = ({ data: { viewer } }: Props): JSX.Element => {
  const classes = useStyles()
  const { cards } = viewer

  return (
    <div>
      {cards.map(
        (card): JSX.Element => (
          <UserCreditCard key={ card.id } card={ card } name={ viewer.fullName } />
        )
      )}
      <div className={ classes.buttonContainer }>
        <DialogLink
          dialogComponent='AddCreditCardDialog'
          trigger={
            <Button variant='contained' color='primary'>
              Add Credit Card
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default CreditCardsTab
