import Dumb from './Account'
import React from 'react'
import { AccountTabComponent, AccountTabQuery } from 'types/graphql'
import Spinner from 'components/Spinner'

const Account = (): JSX.Element => (
  <AccountTabComponent>
    {({ data, loading }): JSX.Element => {
      if (loading) return <Spinner center fullHeight />

      return <Dumb data={ data as AccountTabQuery } />
    }}
  </AccountTabComponent>
)

export default Account
