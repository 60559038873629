import Dumb from './PointsOnlyRegistration'
import React from 'react'
import { login } from 'lib/helpers'
import {
  RegisterPointsOnlyUserMutationFn,
  useRegisterPointsOnlyUserMutation,
  useValidateCredentialsMutation,
  ValidateCredentialsMutationFn,
  ValidateCredentialsPayload,
} from 'types/graphql-auth'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import {
  password,
  passwordConfirmation,
  profile,
  username,
} from 'lib/yupHelpers'

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    profile,
    username,
    password,
    passwordConfirmation,
  }),
})

const formValues = {
  user: {
    profile: {
      firstName: '',
      lastName: '',
    },
    username: '',
    password: '',
    passwordConfirmation: '',
  },
}

export interface PointsOnlyUser {
  user: {
    profile: {
      firstName: string;
      lastName: string;
    };
    username: string;
    password: string;
    passwordConfirmation: string;
  };
}

export interface Props {
  token: string;
}

const validate = async(
  values: PointsOnlyUser,
  validateCredentials: ValidateCredentialsMutationFn,
): Promise<ValidateCredentialsPayload | void> => {
  const res = await validateCredentials({
    variables: { user: values.user },
  })
  const errors = res && res.data && res.data.validateCredentials
  if (errors) return errors
}

const submit = async(
  values: PointsOnlyUser,
  register: RegisterPointsOnlyUserMutationFn,
  push: (path: string) => void,
  token: string,
): Promise<void> => {
  const res = await register({ variables: { user: values.user, token } })
  if (!res || !res.data || !res.data.registerPointsOnlyUser) return
  login(res.data.registerPointsOnlyUser.accessToken)
  push('/')
}

const PointsOnlyRegistration = ({ token }: Props): JSX.Element => {
  const [
    register,
    { loading: registerLoading },
  ] = useRegisterPointsOnlyUserMutation()

  const [
    validateCredentials,
    { loading: validationLoading },
  ] = useValidateCredentialsMutation()

  const { push } = useHistory()

  const handleValidate = (
    values: PointsOnlyUser,
  ): Promise<ValidateCredentialsPayload | void> =>
    validate(values, validateCredentials)
  const handleSubmit = (values: PointsOnlyUser): Promise<void> =>
    submit(values, register, push, token)

  return (
    <Formik
      validationSchema={ validationSchema }
      validate={ handleValidate }
      onSubmit={ handleSubmit }
      initialValues={ formValues }
      validateOnBlur={ false }
      validateOnChange={ false }
    >
      {(formikProps: FormikProps<PointsOnlyUser>): JSX.Element => (
        <Dumb
          formikProps={ formikProps }
          loading={ validationLoading || registerLoading }
        />
      )}
    </Formik>
  )
}

export default PointsOnlyRegistration
