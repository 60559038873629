import React from 'react'
import PropTypes from 'prop-types'
import LoadingButton from 'components/LoadingButton'
import confirm from 'lib/confirm'

const handleClick = cancelSubscription => {
  const text =
    'We are sad to see you go, but once you cancel your subscription ' +
    'you will no longer be charged. Your subscription will stay active ' +
    'until the end of pay cycle.'
  confirm(text).then(() => cancelSubscription(), () => {})
}

const CancelSubscriptionButton = ({ cancelSubscription, loading }) => (
  <LoadingButton
    secondary
    loading={ loading }
    onClick={ () => handleClick(cancelSubscription) }
  >
    Cancel Subscription
  </LoadingButton>
)

CancelSubscriptionButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
}

export default CancelSubscriptionButton
