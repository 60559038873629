import Dumb from './RespondToPartnershipButton'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'

import { RESPOND_TO_PARTNERSHIP_MUTATION } from 'mutations/family'
import { noop } from 'lib/helpers'

const RespondToPartnershipButton = ({ accept, onCompleted = noop }) => {
  const respond = (mutate, accept) => {
    // Because this component is unmounted after the user responds, the
    // onCompleted `Mutation` prop is never called. So have the same result,
    // we need to use the promise returned by the mutate function.
    mutate({ variables: { accept } }).then(onCompleted)
  }

  return (
    <Mutation mutation={ RESPOND_TO_PARTNERSHIP_MUTATION } variables={ { accept } }>
      {(respondToPartnership, { loading }) => (
        <Dumb
          loading={ loading }
          accept={ accept }
          respondToPartnership={ accept => respond(respondToPartnership, accept) }
        />
      )}
    </Mutation>
  )
}

RespondToPartnershipButton.propTypes = {
  accept: PropTypes.bool.isRequired,
  onCompleted: PropTypes.func,
}

export default RespondToPartnershipButton
