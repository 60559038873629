import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any,
  Upload: any,
};


/** Autogenerated return type of AcceptMembershipAgreement */
export type AcceptMembershipAgreementPayload = {
   __typename?: 'AcceptMembershipAgreementPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

export type ActiveRecordInterface = {
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of AddAllergy */
export type AddAllergyPayload = {
   __typename?: 'AddAllergyPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

/** Autogenerated return type of AddChecklistItem */
export type AddChecklistItemPayload = {
   __typename?: 'AddChecklistItemPayload',
  admin: Admin,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of AddChecklist */
export type AddChecklistPayload = {
   __typename?: 'AddChecklistPayload',
  admin: Admin,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of AddCreditCard */
export type AddCreditCardPayload = {
   __typename?: 'AddCreditCardPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of AddDependent */
export type AddDependentPayload = {
   __typename?: 'AddDependentPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of AddGuest */
export type AddGuestPayload = {
   __typename?: 'AddGuestPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of AddItemToCart */
export type AddItemToCartPayload = {
   __typename?: 'AddItemToCartPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of AddMedicalCondition */
export type AddMedicalConditionPayload = {
   __typename?: 'AddMedicalConditionPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

/** An admin */
export type Admin = ActiveRecordInterface & {
   __typename?: 'Admin',
  createdAt: Scalars['ISO8601DateTime'],
  email: Scalars['String'],
  errors: Array<Scalars['String']>,
  fullName: Scalars['String'],
  id: Scalars['Int'],
  location: Location,
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of AgreeToWaiver */
export type AgreeToWaiverPayload = {
   __typename?: 'AgreeToWaiverPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export type Appointment = ActiveRecordInterface & {
   __typename?: 'Appointment',
  availableLengths: Array<Scalars['Int']>,
  availableSlots: Array<EventSlot>,
  availableTimes: Array<Scalars['ISO8601DateTime']>,
  createdAt: Scalars['ISO8601DateTime'],
  details?: Maybe<Scalars['String']>,
  endDate: Scalars['String'],
  endTimedate: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  eventLocation?: Maybe<Scalars['String']>,
  formattedPrice?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  price?: Maybe<Scalars['String']>,
  slot: EventSlot,
  startDate: Scalars['String'],
  startTimedate: Scalars['ISO8601DateTime'],
  title: Scalars['String'],
  totalPrice?: Maybe<Scalars['String']>,
  type: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};


export type AppointmentAvailableLengthsArgs = {
  startTime: Scalars['ISO8601DateTime'],
  endTime: Scalars['ISO8601DateTime']
};


export type AppointmentAvailableSlotsArgs = {
  after?: Maybe<Scalars['ISO8601DateTime']>,
  before?: Maybe<Scalars['ISO8601DateTime']>
};


export type AppointmentAvailableTimesArgs = {
  startTime: Scalars['ISO8601DateTime'],
  endTime: Scalars['ISO8601DateTime'],
  length: Scalars['Int']
};


export type AppointmentSlotArgs = {
  slotId: Scalars['String']
};


export type AppointmentTotalPriceArgs = {
  length: Scalars['Int']
};

export type AppointmentBooking = {
   __typename?: 'AppointmentBooking',
  charge?: Maybe<StripeCharge>,
  details?: Maybe<Scalars['String']>,
  endTime: Scalars['ISO8601DateTime'],
  eventLocation?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  staff?: Maybe<Staff>,
  startTime: Scalars['ISO8601DateTime'],
  title: Scalars['String'],
};

export type Attendee = {
   __typename?: 'Attendee',
  attendeeId: Scalars['Int'],
  attendeeType: Scalars['String'],
  avatar: Scalars['String'],
  firstName: Scalars['String'],
  id: Scalars['String'],
  lastName: Scalars['String'],
  registrationId?: Maybe<Scalars['Int']>,
  status: ClassRegistrationStatusEnum,
};

export type AutomatedChecklistItem = ActiveRecordInterface & {
   __typename?: 'AutomatedChecklistItem',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  label: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type Bewell = {
   __typename?: 'Bewell',
  backgroundUrl?: Maybe<Scalars['String']>,
  categories: Array<BewellCategory>,
  cutoff: Scalars['Int'],
  dayDisplay: Scalars['String'],
  dayNumber: Scalars['Int'],
  daysUntilStart?: Maybe<Scalars['Int']>,
  endDate?: Maybe<Scalars['ISO8601DateTime']>,
  hasEnded: Scalars['Boolean'],
  hasStarted: Scalars['Boolean'],
  optedIn?: Maybe<Scalars['Boolean']>,
  points: Scalars['Int'],
  show: Scalars['Boolean'],
  showWidget: Scalars['Boolean'],
  startDate?: Maybe<Scalars['ISO8601DateTime']>,
  stoneThreshold: Scalars['Int'],
  stonesEnabled: Scalars['Boolean'],
  totalWellnessStoneColor?: Maybe<Scalars['String']>,
  totalWellnessStoneLabel?: Maybe<Scalars['String']>,
};


export type BewellDayDisplayArgs = {
  isToday: Scalars['Boolean']
};


export type BewellDayNumberArgs = {
  isToday: Scalars['Boolean']
};

export type BewellCategory = ActiveRecordInterface & {
   __typename?: 'BewellCategory',
  bewellItems: Array<BewellItem>,
  color: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  isStoneEarned: Scalars['Boolean'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type BewellItem = ActiveRecordInterface & {
   __typename?: 'BewellItem',
  bewellCategory: BewellCategory,
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  isCompletedToday: Scalars['Boolean'],
  isCompletedYesterday: Scalars['Boolean'],
  perIntervalDisplay: Scalars['String'],
  points: Scalars['Int'],
  subtitle?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  url?: Maybe<Scalars['String']>,
  urlLabel?: Maybe<Scalars['String']>,
};

export type BiometricDatum = ActiveRecordInterface & {
   __typename?: 'BiometricDatum',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  expirableUrl: Scalars['String'],
  id: Scalars['Int'],
  pdfFileName?: Maybe<Scalars['String']>,
  status: BiometricDatumStatusEnum,
  updatedAt: Scalars['ISO8601DateTime'],
};

export enum BiometricDatumStatusEnum {
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED'
}

/** Autogenerated return type of CancelGfcRegistration */
export type CancelGfcRegistrationPayload = {
   __typename?: 'CancelGfcRegistrationPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of CancelSubscription */
export type CancelSubscriptionPayload = {
   __typename?: 'CancelSubscriptionPayload',
  message?: Maybe<Scalars['String']>,
  subscription: StripeSubscription,
};

export type Cart = ActiveRecordInterface & {
   __typename?: 'Cart',
  coupon?: Maybe<Coupon>,
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  itemCount: Scalars['Int'],
  items: Array<CartItem>,
  total: Scalars['Float'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type CartItem = ActiveRecordInterface & {
   __typename?: 'CartItem',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  isMembership: Scalars['Boolean'],
  price: Scalars['Float'],
  quantity: Scalars['Int'],
  staff?: Maybe<Staff>,
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of ChangeCurrentLocation */
export type ChangeCurrentLocationPayload = {
   __typename?: 'ChangeCurrentLocationPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of ChangePassword */
export type ChangePasswordPayload = {
   __typename?: 'ChangePasswordPayload',
  message?: Maybe<Scalars['String']>,
};

export type Checklist = ActiveRecordInterface & {
   __typename?: 'Checklist',
  createdAt: Scalars['ISO8601DateTime'],
  effectiveDate: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  items: Array<ChecklistItem>,
  label: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type ChecklistItem = ActiveRecordInterface & {
   __typename?: 'ChecklistItem',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  isCompleted: Scalars['Boolean'],
  isManual: Scalars['Boolean'],
  label: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};


export type ChecklistItemIsCompletedArgs = {
  userId?: Maybe<Scalars['Int']>
};

/** Autogenerated return type of Checkout */
export type CheckoutPayload = {
   __typename?: 'CheckoutPayload',
  cart: Cart,
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of ClaimGfcSpot */
export type ClaimGfcSpotPayload = {
   __typename?: 'ClaimGfcSpotPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export type ClassRegistration = ActiveRecordInterface & {
   __typename?: 'ClassRegistration',
  attendeeId: Scalars['Int'],
  attendeeType: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  gfcSchedule: GfcSchedule,
  groupFitnessClassId: Scalars['Int'],
  id: Scalars['Int'],
  status: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  userId: Scalars['Int'],
};

export enum ClassRegistrationStatusEnum {
  /** open */
  Register = 'register',
  /** registered */
  Registered = 'registered',
  /** waitlist */
  Waitlist = 'waitlist',
  /** cancelled */
  Cancelled = 'cancelled',
  /** no_show */
  NoShow = 'no_show'
}

export type Company = ActiveRecordInterface & {
   __typename?: 'Company',
  badgeRegex?: Maybe<Scalars['String']>,
  badgeRequired: Scalars['Boolean'],
  createdAt: Scalars['ISO8601DateTime'],
  creditsForGfc: Scalars['Boolean'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  locations: Array<Location>,
  name: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type CompanyPolicy = ActiveRecordInterface & {
   __typename?: 'CompanyPolicy',
  createdAt: Scalars['ISO8601DateTime'],
  dependentWaiver?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  hipaaWaiver?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  waiver?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of ContactInstructor */
export type ContactInstructorPayload = {
   __typename?: 'ContactInstructorPayload',
  message?: Maybe<Scalars['String']>,
  success: Scalars['Boolean'],
};

export type Coupon = ActiveRecordInterface & {
   __typename?: 'Coupon',
  couponCode: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  discount: Scalars['Int'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type Dependent = ActiveRecordInterface & {
   __typename?: 'Dependent',
  age: Scalars['Int'],
  avatar: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  dateOfBirth: Scalars['ISO8601DateTime'],
  emergencyFirstName: Scalars['String'],
  emergencyLastName: Scalars['String'],
  emergencyPhone: Scalars['String'],
  errors: Array<Scalars['String']>,
  firstName: Scalars['String'],
  id: Scalars['Int'],
  isAdult: Scalars['Boolean'],
  isOfAge: Scalars['Boolean'],
  lastName: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type DependentInput = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']>,
  emergencyFirstName?: Maybe<Scalars['String']>,
  emergencyLastName?: Maybe<Scalars['String']>,
  emergencyPhone?: Maybe<Scalars['String']>,
  profileImage?: Maybe<Scalars['Upload']>,
};

export type DialogProps = {
   __typename?: 'DialogProps',
  id: Scalars['Int'],
  cardId?: Maybe<Scalars['Int']>,
  gfcScheduleId?: Maybe<Scalars['Int']>,
  sessions?: Maybe<Scalars['Int']>,
  staffId?: Maybe<Scalars['Int']>,
  itemClass?: Maybe<Scalars['String']>,
  videoUrl?: Maybe<Scalars['String']>,
  service?: Maybe<DialogPropsFitnessService>,
  event?: Maybe<DialogPropsEvent>,
};

export type DialogPropsEvent = {
   __typename?: 'DialogPropsEvent',
  id: Scalars['Int'],
  eventId?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  startTime: Scalars['String'],
  endTime: Scalars['String'],
  staff?: Maybe<Staff>,
  details?: Maybe<Scalars['String']>,
  eventLocation?: Maybe<Scalars['String']>,
  formattedPrice?: Maybe<Scalars['String']>,
};

export type DialogPropsEventInput = {
  id: Scalars['Int'],
  eventId?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  startTime: Scalars['String'],
  endTime: Scalars['String'],
  staff?: Maybe<StaffInput>,
  details?: Maybe<Scalars['String']>,
  eventLocation?: Maybe<Scalars['String']>,
};

export type DialogPropsFitnessService = {
   __typename?: 'DialogPropsFitnessService',
  id: Scalars['Int'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  validFor?: Maybe<Scalars['String']>,
  askAvailability?: Maybe<Scalars['Boolean']>,
};

export type DialogPropsFitnessServiceInput = {
  id?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  validFor?: Maybe<Scalars['String']>,
  askAvailability?: Maybe<Scalars['Boolean']>,
};

export type DialogPropsInput = {
  id: Scalars['Int'],
  cardId?: Maybe<Scalars['Int']>,
  gfcScheduleId?: Maybe<Scalars['Int']>,
  staffId?: Maybe<Scalars['Int']>,
  itemClass?: Maybe<Scalars['String']>,
  videoUrl?: Maybe<Scalars['String']>,
  service?: Maybe<DialogPropsFitnessServiceInput>,
  event?: Maybe<DialogPropsEventInput>,
};

/** Autogenerated return type of EditDependent */
export type EditDependentPayload = {
   __typename?: 'EditDependentPayload',
  dependent: Dependent,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of EditGuest */
export type EditGuestPayload = {
   __typename?: 'EditGuestPayload',
  guest: Guest,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of EditUser */
export type EditUserPayload = {
   __typename?: 'EditUserPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

export enum EmergencyRelationEnum {
  Father = 'Father',
  Mother = 'Mother',
  Friend = 'Friend',
  Other = 'Other',
  Spouse = 'Spouse'
}

export type Event = ActiveRecordInterface & {
   __typename?: 'Event',
  availableSlots: Array<EventSlot>,
  createdAt: Scalars['ISO8601DateTime'],
  details?: Maybe<Scalars['String']>,
  endDate: Scalars['String'],
  endTimedate: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  eventLocation?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  price?: Maybe<Scalars['String']>,
  slot: EventSlot,
  startDate: Scalars['String'],
  startTimedate: Scalars['ISO8601DateTime'],
  title: Scalars['String'],
  type: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};


export type EventAvailableSlotsArgs = {
  after?: Maybe<Scalars['ISO8601DateTime']>,
  before?: Maybe<Scalars['ISO8601DateTime']>
};


export type EventSlotArgs = {
  slotId: Scalars['String']
};

export type EventCalendarEntry = {
   __typename?: 'EventCalendarEntry',
  details?: Maybe<Scalars['String']>,
  endTime: Scalars['ISO8601DateTime'],
  eventId: Scalars['Int'],
  eventLocation?: Maybe<Scalars['String']>,
  formattedPrice?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  staff?: Maybe<Staff>,
  startTime: Scalars['ISO8601DateTime'],
  title: Scalars['String'],
  type: Scalars['String'],
};

export type EventRegistration = {
   __typename?: 'EventRegistration',
  charge?: Maybe<StripeCharge>,
  details?: Maybe<Scalars['String']>,
  endTime: Scalars['ISO8601DateTime'],
  eventLocation?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  staff?: Maybe<Staff>,
  startTime: Scalars['ISO8601DateTime'],
  title: Scalars['String'],
};

export type EventSlot = {
   __typename?: 'EventSlot',
  endTime: Scalars['ISO8601DateTime'],
  eventId: Scalars['Int'],
  eventRegistration?: Maybe<EventRegistration>,
  id: Scalars['String'],
  openSpotsDisplay: Scalars['String'],
  startTime: Scalars['ISO8601DateTime'],
};

export type FacilityUseOption = ActiveRecordInterface & {
   __typename?: 'FacilityUseOption',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  label: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** a fitness services offered for purchase, general/staff specific */
export type FitnessService = ActiveRecordInterface & {
   __typename?: 'FitnessService',
  askAvailability: Scalars['Boolean'],
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  price: Scalars['Float'],
  staffSpecific: Scalars['Boolean'],
  staffs: Array<Staff>,
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  validFor: Scalars['String'],
};

/** a category of fitness services */
export type FitnessServiceType = ActiveRecordInterface & {
   __typename?: 'FitnessServiceType',
  createdAt: Scalars['ISO8601DateTime'],
  description?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  externalUrl?: Maybe<Scalars['String']>,
  /** All fitness services that are available to the user */
  fitnessServices: Array<FitnessService>,
  iconColor: Scalars['String'],
  iconImage: Scalars['String'],
  id: Scalars['Int'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** a pack or subscription to gfc credits */
export type GfcCreditPlan = ActiveRecordInterface & {
   __typename?: 'GfcCreditPlan',
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  price: Scalars['Float'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  validFor?: Maybe<Scalars['String']>,
};

/** Full set of options to filter the gfc table by */
export type GfcFilterOptions = {
   __typename?: 'GfcFilterOptions',
  instructor: Array<Scalars['String']>,
  title: Array<Scalars['String']>,
};

export type GfcFiltersInput = {
  day: Array<Scalars['String']>,
  title: Array<Scalars['String']>,
  instructor: Array<Scalars['String']>,
  startDateTime?: Maybe<Scalars['ISO8601DateTime']>,
};

export type GfcSchedule = ActiveRecordInterface & {
   __typename?: 'GfcSchedule',
  /** an array representing the user and dependents registered */
  attending: Array<Attendee>,
  availableSpots: Scalars['Int'],
  classLocation?: Maybe<Scalars['String']>,
  costForUser: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  endDateTime: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  eventDate: Scalars['String'],
  formattedTime: Scalars['String'],
  freeForUser: Scalars['Boolean'],
  gfc: GroupFitnessClass,
  groupFitnessClass: GroupFitnessClass,
  id: Scalars['Int'],
  instructor: Scalars['String'],
  isForChildDependents: Scalars['Boolean'],
  lastRegistration?: Maybe<UserActivity>,
  spotsAvailable: Scalars['Boolean'],
  staffId: Scalars['Int'],
  startDateTime: Scalars['ISO8601DateTime'],
  startTime?: Maybe<Scalars['Int']>,
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of GfcTableData */
export type GfcTableDataPayload = {
   __typename?: 'GfcTableDataPayload',
  gfcSchedules: Array<GfcSchedule>,
  message?: Maybe<Scalars['String']>,
  totalCount: Scalars['Int'],
};

export type GroupFitnessClass = ActiveRecordInterface & {
   __typename?: 'GroupFitnessClass',
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  isFreeGfc: Scalars['Boolean'],
  location: Location,
  price?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type Guest = ActiveRecordInterface & {
   __typename?: 'Guest',
  createdAt: Scalars['ISO8601DateTime'],
  emergencyFirstName?: Maybe<Scalars['String']>,
  emergencyLastName?: Maybe<Scalars['String']>,
  emergencyPhone?: Maybe<Scalars['String']>,
  emergencyRelation?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  firstName: Scalars['String'],
  id: Scalars['Int'],
  lastName: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of GuestCharge */
export type GuestChargePayload = {
   __typename?: 'GuestChargePayload',
  message?: Maybe<Scalars['String']>,
  success: Scalars['Boolean'],
};

export type GuestInput = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  emergencyFirstName?: Maybe<Scalars['String']>,
  emergencyLastName?: Maybe<Scalars['String']>,
  emergencyPhone?: Maybe<Scalars['String']>,
  emergencyRelation?: Maybe<Scalars['String']>,
};

/** a pack or subscription to guest passes */
export type GuestPassPackage = ActiveRecordInterface & {
   __typename?: 'GuestPassPackage',
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  price: Scalars['Float'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  validFor?: Maybe<Scalars['String']>,
};

export type IntakeSurvey = {
   __typename?: 'IntakeSurvey',
  responses: Array<IntakeSurveyResponse>,
  show: Scalars['Boolean'],
};

export type IntakeSurveyAnswer = {
   __typename?: 'IntakeSurveyAnswer',
  answer: Scalars['String'],
  question: Scalars['String'],
};

export type IntakeSurveyResponse = {
   __typename?: 'IntakeSurveyResponse',
  answers: Array<IntakeSurveyAnswer>,
  id: Scalars['String'],
  submittedAt: Scalars['ISO8601DateTime'],
};

export type Invitation = ActiveRecordInterface & {
   __typename?: 'Invitation',
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>,
  createdAt: Scalars['ISO8601DateTime'],
  email: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of InvitePartner */
export type InvitePartnerPayload = {
   __typename?: 'InvitePartnerPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};


/** Autogenerated return type of JoinBewell */
export type JoinBewellPayload = {
   __typename?: 'JoinBewellPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export type Location = ActiveRecordInterface & {
   __typename?: 'Location',
  address?: Maybe<Scalars['String']>,
  appointment: Appointment,
  appointmentCalendarEntries: Array<EventCalendarEntry>,
  appointments: Array<Appointment>,
  availableAutomatedChecklistItems: Array<AutomatedChecklistItem>,
  bewell?: Maybe<Bewell>,
  checklist?: Maybe<Checklist>,
  checklists: Array<Checklist>,
  city: Scalars['String'],
  company: Company,
  companyPolicy: CompanyPolicy,
  config: LocationConfiguration,
  country?: Maybe<Scalars['String']>,
  createdAt: Scalars['ISO8601DateTime'],
  currentChecklist?: Maybe<Checklist>,
  customRedeemProducts: Array<RewardRedemptionOption>,
  errors: Array<Scalars['String']>,
  event: Event,
  eventCalendarEntries: Array<EventCalendarEntry>,
  events: Array<Event>,
  fitnessServiceTypes: Array<FitnessServiceType>,
  generalInformation?: Maybe<Scalars['String']>,
  gfcCreditPlans: Array<GfcCreditPlan>,
  gfcFilterOptions: GfcFilterOptions,
  gfcSchedules: Array<GfcSchedule>,
  guestPassPackages: Array<GuestPassPackage>,
  hasAppointments: Scalars['Boolean'],
  hasEvents: Scalars['Boolean'],
  hasGFCs: Scalars['Boolean'],
  hasPlans: Scalars['Boolean'],
  hasServices: Scalars['Boolean'],
  hasStaff: Scalars['Boolean'],
  hoursOfOperation?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  intakeSurveyUrl: Scalars['String'],
  limitedGfcSchedules: Array<GfcSchedule>,
  lockers: Array<Locker>,
  management?: Maybe<Scalars['String']>,
  managementContact?: Maybe<Scalars['String']>,
  memberships: Array<Membership>,
  name: Scalars['String'],
  payments: PaymentsEnum,
  phoneNumber?: Maybe<Scalars['String']>,
  previousChecklist?: Maybe<Checklist>,
  staff: Staff,
  staffs: Array<Staff>,
  state?: Maybe<Scalars['String']>,
  stripeApiKey?: Maybe<Scalars['String']>,
  tangoCards: Array<RewardRedemptionOption>,
  timezone: Scalars['String'],
  totalGfcCount: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
  user: User,
  zip?: Maybe<Scalars['String']>,
};


export type LocationAppointmentArgs = {
  apptId: Scalars['Int']
};


export type LocationAppointmentCalendarEntriesArgs = {
  after: Scalars['ISO8601DateTime'],
  before: Scalars['ISO8601DateTime'],
  apptId?: Maybe<Scalars['Int']>
};


export type LocationAppointmentsArgs = {
  startDate?: Maybe<Scalars['ISO8601DateTime']>,
  endDate?: Maybe<Scalars['ISO8601DateTime']>
};


export type LocationChecklistArgs = {
  checklistId: Scalars['Int']
};


export type LocationEventArgs = {
  eventId: Scalars['Int']
};


export type LocationEventCalendarEntriesArgs = {
  after: Scalars['ISO8601DateTime'],
  before: Scalars['ISO8601DateTime']
};


export type LocationEventsArgs = {
  startDate?: Maybe<Scalars['ISO8601DateTime']>,
  endDate?: Maybe<Scalars['ISO8601DateTime']>
};


export type LocationGfcSchedulesArgs = {
  after?: Maybe<Scalars['ISO8601DateTime']>,
  before?: Maybe<Scalars['ISO8601DateTime']>
};


export type LocationLimitedGfcSchedulesArgs = {
  offset?: Maybe<Scalars['Int']>,
  filters?: Maybe<GfcFiltersInput>
};


export type LocationStaffArgs = {
  id: Scalars['Int']
};


export type LocationTotalGfcCountArgs = {
  filters?: Maybe<GfcFiltersInput>
};


export type LocationUserArgs = {
  userId: Scalars['Int']
};

/** A location's configuration */
export type LocationConfiguration = ActiveRecordInterface & {
   __typename?: 'LocationConfiguration',
  allowExternalPartners: Scalars['Boolean'],
  biometricDataEnabled: Scalars['Boolean'],
  createdAt: Scalars['ISO8601DateTime'],
  dependentChildMinAge: Scalars['Int'],
  dependentsEnabled: Scalars['Boolean'],
  errors: Array<Scalars['String']>,
  eventsLabel: Scalars['String'],
  facilityCheckinWidgetEnabled: Scalars['Boolean'],
  freeGfcEnabled: Scalars['Boolean'],
  gfcCheckinWidgetEnabled: Scalars['Boolean'],
  groupCreditsDescription?: Maybe<Scalars['String']>,
  groupCreditsIcon: Scalars['String'],
  groupCreditsTitle?: Maybe<Scalars['String']>,
  guestPassIcon: Scalars['String'],
  guestPassInterval: Scalars['String'],
  guestsEnabled: Scalars['Boolean'],
  hideDob: Scalars['Boolean'],
  id: Scalars['Int'],
  logo: Scalars['String'],
  masonJarWidgetEnabled: Scalars['Boolean'],
  membershipAgreementCopy?: Maybe<Scalars['String']>,
  membershipsEnabled: Scalars['Boolean'],
  minRedeemPointLimit?: Maybe<Scalars['Int']>,
  onlineCoachingWidgetEnabled: Scalars['Boolean'],
  partnersEnabled: Scalars['Boolean'],
  pointsRedemptionEnabled: Scalars['Boolean'],
  qrCodeEnabled: Scalars['Boolean'],
  requireGuestEmergencyContacts: Scalars['Boolean'],
  rewardPointsEnabled: Scalars['Boolean'],
  rewardPointsRedemptionOption: RedemptionOptionsEnum,
  subscriptionsDescription?: Maybe<Scalars['String']>,
  subscriptionsIcon: Scalars['String'],
  subscriptionsTitle?: Maybe<Scalars['String']>,
  unlimitedGuestPasses: Scalars['Boolean'],
  updatedAt: Scalars['ISO8601DateTime'],
  usersCanEditAddress: Scalars['Boolean'],
  usersCanEditDependents: Scalars['Boolean'],
  usersCanEditProfileImage: Scalars['Boolean'],
  videoLibraryEnabled: Scalars['Boolean'],
};

export type Locker = ActiveRecordInterface & {
   __typename?: 'Locker',
  cancellationProration: Scalars['Int'],
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  frequency: Scalars['String'],
  id: Scalars['Int'],
  name: Scalars['String'],
  price: Scalars['String'],
  size: Scalars['String'],
  status: LockerStatusEnum,
  updatedAt: Scalars['ISO8601DateTime'],
};

export enum LockerStatusEnum {
  RentedToYou = 'RENTED_TO_YOU',
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Error = 'ERROR'
}

/** Autogenerated return type of LogoutV2User */
export type LogoutV2UserPayload = {
   __typename?: 'LogoutV2UserPayload',
  message?: Maybe<Scalars['String']>,
  success: Scalars['Boolean'],
};

/** Autogenerated return type of MarkMessageAsRead */
export type MarkMessageAsReadPayload = {
   __typename?: 'MarkMessageAsReadPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export type Membership = ActiveRecordInterface & {
   __typename?: 'Membership',
  allowUserRenewal: Scalars['Boolean'],
  autoRenew: Scalars['Boolean'],
  billingCycle: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  price: Scalars['Float'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type Mutation = {
   __typename?: 'Mutation',
  acceptMembershipAgreement?: Maybe<AcceptMembershipAgreementPayload>,
  addAllergy?: Maybe<AddAllergyPayload>,
  addChecklist?: Maybe<AddChecklistPayload>,
  addChecklistItem?: Maybe<AddChecklistItemPayload>,
  addCreditCard?: Maybe<AddCreditCardPayload>,
  addDependent?: Maybe<AddDependentPayload>,
  addGuest?: Maybe<AddGuestPayload>,
  addItemToCart?: Maybe<AddItemToCartPayload>,
  addMedicalCondition?: Maybe<AddMedicalConditionPayload>,
  agreeToWaiver?: Maybe<AgreeToWaiverPayload>,
  cancelGfcRegistration?: Maybe<CancelGfcRegistrationPayload>,
  cancelSubscription?: Maybe<CancelSubscriptionPayload>,
  changeCurrentLocation?: Maybe<ChangeCurrentLocationPayload>,
  changePassword?: Maybe<ChangePasswordPayload>,
  checkout?: Maybe<CheckoutPayload>,
  claimGfcSpot?: Maybe<ClaimGfcSpotPayload>,
  contactInstructor?: Maybe<ContactInstructorPayload>,
  editDependent?: Maybe<EditDependentPayload>,
  editGuest?: Maybe<EditGuestPayload>,
  editUser?: Maybe<EditUserPayload>,
  gfcTableData?: Maybe<GfcTableDataPayload>,
  guestCharge?: Maybe<GuestChargePayload>,
  invitePartner?: Maybe<InvitePartnerPayload>,
  joinBewell?: Maybe<JoinBewellPayload>,
  logoutV2User?: Maybe<LogoutV2UserPayload>,
  markMessageAsRead?: Maybe<MarkMessageAsReadPayload>,
  recordCompletedIntakeSurvey?: Maybe<RecordCompletedIntakeSurveyPayload>,
  redeemCoupon?: Maybe<RedeemCouponPayload>,
  redeemRewardPoints?: Maybe<RedeemRewardPointsPayload>,
  registerForAppointment?: Maybe<RegisterForAppointmentPayload>,
  registerForEvent?: Maybe<RegisterForEventPayload>,
  registerToGfc?: Maybe<RegisterToGfcPayload>,
  removeAllergy?: Maybe<RemoveAllergyPayload>,
  removeChecklist?: Maybe<RemoveChecklistPayload>,
  removeChecklistItem?: Maybe<RemoveChecklistItemPayload>,
  removeCreditCard?: Maybe<RemoveCreditCardPayload>,
  removeDependent?: Maybe<RemoveDependentPayload>,
  removeGuest?: Maybe<RemoveGuestPayload>,
  removeItemFromCart?: Maybe<RemoveItemFromCartPayload>,
  removeMedicalCondition?: Maybe<RemoveMedicalConditionPayload>,
  removePartnership?: Maybe<RemovePartnershipPayload>,
  renameCreditCard?: Maybe<RenameCreditCardPayload>,
  renewSubscription?: Maybe<RenewSubscriptionPayload>,
  rentLocker?: Maybe<RentLockerPayload>,
  respondToPartnership?: Maybe<RespondToPartnershipPayload>,
  setPrimaryCard?: Maybe<SetPrimaryCardPayload>,
  toggleBewellItemComplete?: Maybe<ToggleBewellItemCompletePayload>,
  toggleChecklistItemCompleted?: Maybe<ToggleChecklistItemCompletedPayload>,
  tourCompleted?: Maybe<TourCompletedPayload>,
  unregisterForEvent?: Maybe<UnregisterForEventPayload>,
  unrentLocker?: Maybe<UnrentLockerPayload>,
  updateChecklistLabel?: Maybe<UpdateChecklistLabelPayload>,
  updateItemQuantity?: Maybe<UpdateItemQuantityPayload>,
  uploadBiometricData?: Maybe<UploadBiometricDataPayload>,
  updateTour?: Maybe<Scalars['Boolean']>,
  advanceTour?: Maybe<Scalars['Boolean']>,
  updateTourStatus?: Maybe<Scalars['Boolean']>,
  openDialog?: Maybe<Scalars['Boolean']>,
  closeDialog?: Maybe<Scalars['Boolean']>,
  updateVideoSearch?: Maybe<Scalars['Boolean']>,
  setRefreshGfcTable?: Maybe<Scalars['Boolean']>,
};


export type MutationAddAllergyArgs = {
  allergy: Scalars['String']
};


export type MutationAddChecklistArgs = {
  effectiveDate: Scalars['ISO8601DateTime']
};


export type MutationAddChecklistItemArgs = {
  checklistId: Scalars['Int'],
  label?: Maybe<Scalars['String']>,
  automatedItemId?: Maybe<Scalars['Int']>
};


export type MutationAddCreditCardArgs = {
  tokenId: Scalars['String'],
  asPrimary: Scalars['Boolean']
};


export type MutationAddDependentArgs = {
  dependentParams: DependentInput,
  agreeToTerms?: Maybe<Scalars['Boolean']>
};


export type MutationAddGuestArgs = {
  guestParams: GuestInput
};


export type MutationAddItemToCartArgs = {
  itemId: Scalars['Int'],
  itemClass: Scalars['String'],
  quantity?: Maybe<Scalars['Int']>,
  staffId?: Maybe<Scalars['Int']>,
  userAvailability?: Maybe<Scalars['String']>,
  userComments?: Maybe<Scalars['String']>,
  autoRenew?: Maybe<Scalars['Boolean']>
};


export type MutationAddMedicalConditionArgs = {
  medicalCondition: Scalars['String']
};


export type MutationAgreeToWaiverArgs = {
  locationId?: Maybe<Scalars['Int']>,
  waiverType?: Maybe<Scalars['String']>
};


export type MutationCancelGfcRegistrationArgs = {
  registrationId: Scalars['Int']
};


export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['Int']
};


export type MutationChangeCurrentLocationArgs = {
  locationId: Scalars['Int']
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'],
  password: Scalars['String'],
  passwordConfirmation: Scalars['String']
};


export type MutationClaimGfcSpotArgs = {
  registrationId: Scalars['Int']
};


export type MutationContactInstructorArgs = {
  staffId: Scalars['Int'],
  subject: Scalars['String'],
  message: Scalars['String']
};


export type MutationEditDependentArgs = {
  dependentParams: DependentInput,
  id: Scalars['Int']
};


export type MutationEditGuestArgs = {
  id: Scalars['Int'],
  guestParams: GuestInput
};


export type MutationEditUserArgs = {
  profile?: Maybe<ProfileInput>,
  user?: Maybe<UserInput>
};


export type MutationGfcTableDataArgs = {
  page: Scalars['Int'],
  pageSize: Scalars['Int'],
  filters: GfcFiltersInput
};


export type MutationGuestChargeArgs = {
  amount: Scalars['Int'],
  chargeToken: Scalars['String'],
  email: Scalars['String']
};


export type MutationInvitePartnerArgs = {
  email: Scalars['String'],
  external: Scalars['Boolean']
};


export type MutationMarkMessageAsReadArgs = {
  messageId: Scalars['Int']
};


export type MutationRedeemCouponArgs = {
  code: Scalars['String']
};


export type MutationRedeemRewardPointsArgs = {
  productId: Scalars['Int'],
  points: Scalars['Int']
};


export type MutationRegisterForAppointmentArgs = {
  apptId: Scalars['Int'],
  startTime: Scalars['ISO8601DateTime'],
  length: Scalars['Int']
};


export type MutationRegisterForEventArgs = {
  eventId: Scalars['Int'],
  startTime: Scalars['String']
};


export type MutationRegisterToGfcArgs = {
  gfcScheduleId: Scalars['Int'],
  attendeeId: Scalars['Int'],
  attendeeType: Scalars['String']
};


export type MutationRemoveAllergyArgs = {
  allergy: Scalars['String']
};


export type MutationRemoveChecklistArgs = {
  checklistId: Scalars['Int']
};


export type MutationRemoveChecklistItemArgs = {
  checklistItemId: Scalars['Int']
};


export type MutationRemoveCreditCardArgs = {
  cardId: Scalars['Int']
};


export type MutationRemoveDependentArgs = {
  id: Scalars['Int']
};


export type MutationRemoveGuestArgs = {
  id: Scalars['Int']
};


export type MutationRemoveItemFromCartArgs = {
  itemId: Scalars['Int']
};


export type MutationRemoveMedicalConditionArgs = {
  medicalCondition: Scalars['String']
};


export type MutationRenameCreditCardArgs = {
  cardId: Scalars['Int'],
  label: Scalars['String']
};


export type MutationRenewSubscriptionArgs = {
  subscriptionId: Scalars['Int']
};


export type MutationRentLockerArgs = {
  lockerId: Scalars['Int']
};


export type MutationRespondToPartnershipArgs = {
  accept: Scalars['Boolean']
};


export type MutationSetPrimaryCardArgs = {
  cardId: Scalars['Int']
};


export type MutationToggleBewellItemCompleteArgs = {
  id: Scalars['Int'],
  isToday: Scalars['Boolean'],
  complete: Scalars['Boolean']
};


export type MutationToggleChecklistItemCompletedArgs = {
  itemId: Scalars['Int'],
  userId: Scalars['Int']
};


export type MutationUnregisterForEventArgs = {
  registrationId: Scalars['Int']
};


export type MutationUnrentLockerArgs = {
  lockerId: Scalars['Int']
};


export type MutationUpdateChecklistLabelArgs = {
  checklistId: Scalars['Int'],
  label: Scalars['String']
};


export type MutationUpdateItemQuantityArgs = {
  itemId: Scalars['Int'],
  quantity: Scalars['Int']
};


export type MutationUploadBiometricDataArgs = {
  file: Scalars['Upload']
};


export type MutationUpdateTourArgs = {
  run?: Maybe<Scalars['Boolean']>,
  stepIndex?: Maybe<Scalars['Int']>
};


export type MutationOpenDialogArgs = {
  dialogComponent: Scalars['String'],
  dialogProps?: Maybe<DialogPropsInput>
};


export type MutationUpdateVideoSearchArgs = {
  videoSearch: Scalars['String']
};


export type MutationSetRefreshGfcTableArgs = {
  refreshGfcTable: Scalars['Boolean']
};

export enum PaymentsEnum {
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
  Disabled = 'DISABLED'
}

export type Profile = ActiveRecordInterface & {
   __typename?: 'Profile',
  allergies: Array<Scalars['String']>,
  avatar?: Maybe<Scalars['String']>,
  badge?: Maybe<Scalars['String']>,
  bloodType?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  contactEmail?: Maybe<Scalars['String']>,
  createdAt: Scalars['ISO8601DateTime'],
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']>,
  emergencyAddress?: Maybe<Scalars['String']>,
  emergencyEmail?: Maybe<Scalars['String']>,
  emergencyFirstName?: Maybe<Scalars['String']>,
  emergencyLastName?: Maybe<Scalars['String']>,
  emergencyMiddleName?: Maybe<Scalars['String']>,
  emergencyPhone?: Maybe<Scalars['String']>,
  emergencyRelation?: Maybe<EmergencyRelationEnum>,
  emergencyRelationIndex?: Maybe<Scalars['Int']>,
  errors: Array<Scalars['String']>,
  firstName: Scalars['String'],
  gender: Scalars['String'],
  height?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  lastName: Scalars['String'],
  medicalConditions: Array<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  updatedAt: Scalars['ISO8601DateTime'],
  weight?: Maybe<Scalars['String']>,
  workPhone?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
};


export type ProfileAvatarArgs = {
  size?: Maybe<Scalars['Int']>
};

export type ProfileInput = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']>,
  gender?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['String']>,
  weight?: Maybe<Scalars['String']>,
  bloodType?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  workPhone?: Maybe<Scalars['String']>,
  contactEmail?: Maybe<Scalars['String']>,
  emergencyFirstName?: Maybe<Scalars['String']>,
  emergencyMiddleName?: Maybe<Scalars['String']>,
  emergencyLastName?: Maybe<Scalars['String']>,
  emergencyRelation?: Maybe<Scalars['Int']>,
  emergencyPhone?: Maybe<Scalars['String']>,
  emergencyEmail?: Maybe<Scalars['String']>,
  emergencyAddress?: Maybe<Scalars['String']>,
  profilePicture?: Maybe<Scalars['Upload']>,
  fitnessRegistrationConfirmation?: Maybe<Scalars['Boolean']>,
  fitnessPurchaseConfirmation?: Maybe<Scalars['Boolean']>,
  fitnessClassSchedule?: Maybe<Scalars['Boolean']>,
  badge?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  admin: Admin,
  /** server date, used to see if client is stale. */
  currentServerDate: Scalars['String'],
  gfcSchedule: GfcSchedule,
  guestEmergencyRelations: Array<Scalars['String']>,
  location: Location,
  videoLibrary?: Maybe<VideoLibrary>,
  viewer: User,
  dialogComponent: Scalars['String'],
  dialogProps: DialogProps,
  tour: Tour,
  profileMenuOpen: Scalars['Boolean'],
  videoSearch: Scalars['String'],
  refreshGfcTable: Scalars['Boolean'],
};


export type QueryGfcScheduleArgs = {
  id: Scalars['Int']
};


export type QueryLocationArgs = {
  locationId: Scalars['Int']
};


export type QueryVideoLibraryArgs = {
  offset?: Maybe<Scalars['Int']>,
  search?: Maybe<Scalars['String']>
};

/** Autogenerated return type of RecordCompletedIntakeSurvey */
export type RecordCompletedIntakeSurveyPayload = {
   __typename?: 'RecordCompletedIntakeSurveyPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RedeemCoupon */
export type RedeemCouponPayload = {
   __typename?: 'RedeemCouponPayload',
  cart: Cart,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RedeemRewardPoints */
export type RedeemRewardPointsPayload = {
   __typename?: 'RedeemRewardPointsPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export enum RedemptionOptionsEnum {
  Custom = 'CUSTOM',
  Tango = 'TANGO'
}

/** Autogenerated return type of RegisterForAppointment */
export type RegisterForAppointmentPayload = {
   __typename?: 'RegisterForAppointmentPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RegisterForEvent */
export type RegisterForEventPayload = {
   __typename?: 'RegisterForEventPayload',
  message?: Maybe<Scalars['String']>,
  slot: EventSlot,
};

/** Autogenerated return type of RegisterToGfc */
export type RegisterToGfcPayload = {
   __typename?: 'RegisterToGfcPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RemoveAllergy */
export type RemoveAllergyPayload = {
   __typename?: 'RemoveAllergyPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

/** Autogenerated return type of RemoveChecklistItem */
export type RemoveChecklistItemPayload = {
   __typename?: 'RemoveChecklistItemPayload',
  admin: Admin,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RemoveChecklist */
export type RemoveChecklistPayload = {
   __typename?: 'RemoveChecklistPayload',
  admin: Admin,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RemoveCreditCard */
export type RemoveCreditCardPayload = {
   __typename?: 'RemoveCreditCardPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RemoveDependent */
export type RemoveDependentPayload = {
   __typename?: 'RemoveDependentPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RemoveGuest */
export type RemoveGuestPayload = {
   __typename?: 'RemoveGuestPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RemoveItemFromCart */
export type RemoveItemFromCartPayload = {
   __typename?: 'RemoveItemFromCartPayload',
  cart: Cart,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RemoveMedicalCondition */
export type RemoveMedicalConditionPayload = {
   __typename?: 'RemoveMedicalConditionPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

/** Autogenerated return type of RemovePartnership */
export type RemovePartnershipPayload = {
   __typename?: 'RemovePartnershipPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RenameCreditCard */
export type RenameCreditCardPayload = {
   __typename?: 'RenameCreditCardPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of RenewSubscription */
export type RenewSubscriptionPayload = {
   __typename?: 'RenewSubscriptionPayload',
  message?: Maybe<Scalars['String']>,
  subscription: StripeSubscription,
};

/** Autogenerated return type of RentLocker */
export type RentLockerPayload = {
   __typename?: 'RentLockerPayload',
  location: Location,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RespondToPartnership */
export type RespondToPartnershipPayload = {
   __typename?: 'RespondToPartnershipPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export type RewardRedemptionOption = ActiveRecordInterface & {
   __typename?: 'RewardRedemptionOption',
  createdAt: Scalars['ISO8601DateTime'],
  description?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  image: Scalars['String'],
  name: Scalars['String'],
  pointsPerProduct: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of SetPrimaryCard */
export type SetPrimaryCardPayload = {
   __typename?: 'SetPrimaryCardPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

export type Staff = ActiveRecordInterface & {
   __typename?: 'Staff',
  about?: Maybe<Scalars['String']>,
  avatar: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  email?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['String']>,
  updatedAt: Scalars['ISO8601DateTime'],
};

export type StaffInput = {
  id: Scalars['Int'],
  name: Scalars['String'],
};

/** A user's credit card */
export type StripeCard = ActiveRecordInterface & {
   __typename?: 'StripeCard',
  brand: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  isPrimary: Scalars['Boolean'],
  label: Scalars['String'],
  last4: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** an user order retrieved from Stripe */
export type StripeCharge = ActiveRecordInterface & {
   __typename?: 'StripeCharge',
  createdAt: Scalars['ISO8601DateTime'],
  createdOnStripe: Scalars['ISO8601DateTime'],
  description?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  price: Scalars['String'],
  quantity?: Maybe<Scalars['Int']>,
  sessions?: Maybe<Scalars['Int']>,
  sourceLast4?: Maybe<Scalars['String']>,
  staffName?: Maybe<Scalars['String']>,
  status: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** an user subscription retrieved from Stripe */
export type StripeSubscription = ActiveRecordInterface & {
   __typename?: 'StripeSubscription',
  allowUserRenewal: Scalars['Boolean'],
  cancelAtPeriodEnd: Scalars['Boolean'],
  createdAt: Scalars['ISO8601DateTime'],
  currentPeriodEnd: Scalars['Int'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  payFrequency: Scalars['String'],
  price: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

/** Autogenerated return type of ToggleBewellItemComplete */
export type ToggleBewellItemCompletePayload = {
   __typename?: 'ToggleBewellItemCompletePayload',
  bewellItem: BewellItem,
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of ToggleChecklistItemCompleted */
export type ToggleChecklistItemCompletedPayload = {
   __typename?: 'ToggleChecklistItemCompletedPayload',
  admin: Admin,
  message?: Maybe<Scalars['String']>,
};

export type Tour = {
   __typename?: 'Tour',
  run?: Maybe<Scalars['Boolean']>,
  stepIndex?: Maybe<Scalars['Int']>,
};

/** Autogenerated return type of TourCompleted */
export type TourCompletedPayload = {
   __typename?: 'TourCompletedPayload',
  message?: Maybe<Scalars['String']>,
  profile: Profile,
  success: Scalars['Boolean'],
  viewer: User,
};

/** Autogenerated return type of UnregisterForEvent */
export type UnregisterForEventPayload = {
   __typename?: 'UnregisterForEventPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** Autogenerated return type of UnrentLocker */
export type UnrentLockerPayload = {
   __typename?: 'UnrentLockerPayload',
  location: Location,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of UpdateChecklistLabel */
export type UpdateChecklistLabelPayload = {
   __typename?: 'UpdateChecklistLabelPayload',
  admin: Admin,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of UpdateItemQuantity */
export type UpdateItemQuantityPayload = {
   __typename?: 'UpdateItemQuantityPayload',
  cart: Cart,
  message?: Maybe<Scalars['String']>,
};


/** Autogenerated return type of UploadBiometricData */
export type UploadBiometricDataPayload = {
   __typename?: 'UploadBiometricDataPayload',
  message?: Maybe<Scalars['String']>,
  viewer: User,
};

/** A user */
export type User = ActiveRecordInterface & {
   __typename?: 'User',
  agreedToWaiver?: Maybe<Scalars['Boolean']>,
  alertAboutMembershipFee: Scalars['Boolean'],
  availableCreditsDisplay?: Maybe<Scalars['String']>,
  avatar: Scalars['String'],
  bewell?: Maybe<Bewell>,
  biometricDataFile: BiometricDatum,
  biometricDataFiles: Array<BiometricDatum>,
  card: StripeCard,
  cards: Array<StripeCard>,
  cart?: Maybe<Cart>,
  classRegistration?: Maybe<ClassRegistration>,
  company: Company,
  completedTour: Scalars['Boolean'],
  country?: Maybe<Scalars['String']>,
  createdAt: Scalars['ISO8601DateTime'],
  dependent?: Maybe<Dependent>,
  dependents: Array<Dependent>,
  email: Scalars['String'],
  errors: Array<Scalars['String']>,
  /** user's facility uses in date range */
  facilityUses: Array<UserActivity>,
  firstBank: Scalars['Boolean'],
  fullName: Scalars['String'],
  gfcCheckins: Array<UserActivity>,
  guest?: Maybe<Guest>,
  guestPassCount?: Maybe<Scalars['Float']>,
  guests: Array<Guest>,
  hasActivities: Scalars['Boolean'],
  id: Scalars['Int'],
  impersonated: Scalars['Boolean'],
  intakeSurvey: IntakeSurvey,
  intercomToken: Scalars['String'],
  invitation?: Maybe<Invitation>,
  lapsedMembership: Scalars['Boolean'],
  location: Location,
  locations: Array<Location>,
  locker: Locker,
  needsToAddCard: Scalars['Boolean'],
  orderHistory: Array<StripeCharge>,
  partner?: Maybe<User>,
  pendingPartner?: Maybe<User>,
  pendingPartnerRequestFrom?: Maybe<User>,
  pendingReview: Scalars['Boolean'],
  pointsOnly: Scalars['Boolean'],
  profile: Profile,
  registeredClasses: Array<ClassRegistration>,
  stripeCustomerToken?: Maybe<Scalars['String']>,
  subscriptions: Array<StripeSubscription>,
  totalFacilityCheckinByCurrentMonth?: Maybe<Scalars['Int']>,
  totalGfcCheckinByCurrentMonth?: Maybe<Scalars['Int']>,
  totalPoints?: Maybe<Scalars['Int']>,
  unlimitedCreditsUntil?: Maybe<Scalars['String']>,
  unreadMessages: Array<UserMessage>,
  unregisteredGfcSchedules: Array<GfcSchedule>,
  upcomingAppointmentBookings: Array<AppointmentBooking>,
  upcomingEventRegistrations: Array<EventRegistration>,
  updatedAt: Scalars['ISO8601DateTime'],
  userActivities: Array<UserActivity>,
  username: Scalars['String'],
};


/** A user */
export type UserAgreedToWaiverArgs = {
  locationId?: Maybe<Scalars['Int']>,
  waiverType?: Maybe<Scalars['String']>
};


/** A user */
export type UserAvatarArgs = {
  size?: Maybe<Scalars['Int']>
};


/** A user */
export type UserBiometricDataFileArgs = {
  datumId: Scalars['Int']
};


/** A user */
export type UserCardArgs = {
  cardId: Scalars['Int']
};


/** A user */
export type UserClassRegistrationArgs = {
  classRegistrationId: Scalars['Int']
};


/** A user */
export type UserDependentArgs = {
  dependentId?: Maybe<Scalars['Int']>
};


/** A user */
export type UserFacilityUsesArgs = {
  startDateUnix: Scalars['Float'],
  endDateUnix: Scalars['Float']
};


/** A user */
export type UserGfcCheckinsArgs = {
  startDateUnix: Scalars['Float'],
  endDateUnix: Scalars['Float']
};


/** A user */
export type UserGuestArgs = {
  guestId?: Maybe<Scalars['Int']>
};


/** A user */
export type UserLockerArgs = {
  lockerId: Scalars['Int']
};

export type UserActivity = ActiveRecordInterface & {
   __typename?: 'UserActivity',
  createdAt: Scalars['ISO8601DateTime'],
  createdByAvatar: Scalars['String'],
  createdByName: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  errors: Array<Scalars['String']>,
  facilityUseOption?: Maybe<FacilityUseOption>,
  facilityUseOptionLabel?: Maybe<Scalars['String']>,
  gfcTitle?: Maybe<Scalars['String']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};


export type UserActivityCreatedByAvatarArgs = {
  size: Scalars['Int']
};

export type UserInput = {
  profile?: Maybe<ProfileInput>,
  password?: Maybe<Scalars['String']>,
  passwordConfirmation?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
};

export type UserMessage = ActiveRecordInterface & {
   __typename?: 'UserMessage',
  admin?: Maybe<Admin>,
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  message: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  updatedAt: Scalars['ISO8601DateTime'],
};

/** A video for the fitness library */
export type Video = ActiveRecordInterface & {
   __typename?: 'Video',
  createdAt: Scalars['ISO8601DateTime'],
  description: Scalars['String'],
  duration: Scalars['Int'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  imgUrl: Scalars['String'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  videoId: Scalars['String'],
  videoUrl: Scalars['String'],
};

/** A set of videos representing search results */
export type VideoLibrary = {
   __typename?: 'VideoLibrary',
  totalCount: Scalars['Int'],
  videos: Array<Video>,
};

export type BiometricDataFileFragment = (
  { __typename?: 'BiometricDatum' }
  & Pick<BiometricDatum, 'id' | 'pdfFileName' | 'status' | 'createdAt'>
);

export type FitnessServiceFragment = (
  { __typename?: 'FitnessService' }
  & Pick<FitnessService, 'id' | 'title' | 'description' | 'price' | 'staffSpecific' | 'validFor' | 'askAvailability'>
  & { staffs: Array<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'avatar' | 'name'>
  )> }
);

export type MembershipFragment = (
  { __typename?: 'Membership' }
  & Pick<Membership, 'id' | 'title' | 'description' | 'price' | 'billingCycle' | 'allowUserRenewal' | 'autoRenew'>
);

export type AppointmentBookingFragment = (
  { __typename?: 'AppointmentBooking' }
  & Pick<AppointmentBooking, 'id' | 'title' | 'eventLocation' | 'startTime' | 'endTime'>
  & { staff: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'name'>
  )>, charge: Maybe<(
    { __typename?: 'StripeCharge' }
    & Pick<StripeCharge, 'price'>
  )> }
);

export type AppointmentFragment = (
  { __typename?: 'Appointment' }
  & Pick<Appointment, 'id' | 'title' | 'details' | 'eventLocation' | 'price' | 'formattedPrice' | 'startTimedate' | 'endTimedate' | 'startDate' | 'endDate'>
);

export type EventCalendarEntryFragment = (
  { __typename?: 'EventCalendarEntry' }
  & Pick<EventCalendarEntry, 'id' | 'eventId' | 'title' | 'startTime' | 'endTime' | 'type' | 'eventLocation' | 'details' | 'formattedPrice'>
  & { staff: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'name'>
  )> }
);

export type DependentFragment = (
  { __typename?: 'Dependent' }
  & Pick<Dependent, 'id' | 'firstName' | 'lastName' | 'dateOfBirth' | 'emergencyFirstName' | 'emergencyLastName' | 'emergencyPhone' | 'avatar' | 'age' | 'isAdult' | 'isOfAge'>
);

export type GuestFragment = (
  { __typename?: 'Guest' }
  & Pick<Guest, 'id' | 'firstName' | 'lastName' | 'emergencyFirstName' | 'emergencyLastName' | 'emergencyPhone' | 'emergencyRelation'>
);

export type EventRegistrationFragment = (
  { __typename?: 'EventRegistration' }
  & Pick<EventRegistration, 'id' | 'title' | 'eventLocation' | 'startTime' | 'endTime'>
  & { charge: Maybe<(
    { __typename?: 'StripeCharge' }
    & Pick<StripeCharge, 'price'>
  )> }
);

export type AttendeeFragment = (
  { __typename?: 'Attendee' }
  & Pick<Attendee, 'id' | 'registrationId' | 'attendeeId' | 'attendeeType' | 'firstName' | 'lastName' | 'avatar' | 'status'>
);

export type GfcScheduleFragment = (
  { __typename?: 'GfcSchedule' }
  & Pick<GfcSchedule, 'id' | 'title' | 'eventDate' | 'startDateTime' | 'endDateTime' | 'formattedTime' | 'instructor' | 'staffId' | 'classLocation' | 'spotsAvailable' | 'isForChildDependents' | 'costForUser' | 'freeForUser'>
  & { attending: Array<(
    { __typename?: 'Attendee' }
    & AttendeeFragment
  )>, gfc: (
    { __typename?: 'GroupFitnessClass' }
    & Pick<GroupFitnessClass, 'id' | 'description' | 'price'>
  ) }
);

export type GfcMutationUpdateFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'availableCreditsDisplay'>
  & { unregisteredGfcSchedules: Array<(
    { __typename?: 'GfcSchedule' }
    & GfcScheduleFragment
  )>, registeredClasses: Array<(
    { __typename?: 'ClassRegistration' }
    & Pick<ClassRegistration, 'id' | 'status'>
    & { gfcSchedule: (
      { __typename?: 'GfcSchedule' }
      & GfcScheduleFragment
    ) }
  )> }
);

export type InvitationFragment = (
  { __typename?: 'Invitation' }
  & Pick<Invitation, 'id' | 'email'>
);

export type PartnerFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'fullName' | 'avatar'>
);

export type PartnersFragment = (
  { __typename?: 'User' }
  & { partner: Maybe<(
    { __typename?: 'User' }
    & PartnerFragment
  )>, pendingPartner: Maybe<(
    { __typename?: 'User' }
    & PartnerFragment
  )>, pendingPartnerRequestFrom: Maybe<(
    { __typename?: 'User' }
    & PartnerFragment
  )>, invitation: Maybe<(
    { __typename?: 'Invitation' }
    & InvitationFragment
  )> }
);

export type BewellFragment = (
  { __typename?: 'Bewell' }
  & Pick<Bewell, 'daysUntilStart' | 'showWidget' | 'startDate' | 'endDate' | 'backgroundUrl' | 'hasStarted' | 'hasEnded' | 'optedIn' | 'points'>
);

export type BewellItemFragment = (
  { __typename?: 'BewellItem' }
  & Pick<BewellItem, 'id' | 'title' | 'description' | 'points' | 'perIntervalDisplay' | 'subtitle' | 'url' | 'urlLabel' | 'isCompletedToday' | 'isCompletedYesterday'>
);

export type SubscriptionFragment = (
  { __typename?: 'StripeSubscription' }
  & Pick<StripeSubscription, 'id' | 'price' | 'name' | 'currentPeriodEnd' | 'cancelAtPeriodEnd' | 'allowUserRenewal' | 'payFrequency'>
);

export type CardFragment = (
  { __typename?: 'StripeCard' }
  & Pick<StripeCard, 'id' | 'last4' | 'brand' | 'label' | 'isPrimary'>
);

export type CartItemFragment = (
  { __typename?: 'CartItem' }
  & Pick<CartItem, 'id' | 'title' | 'price' | 'quantity' | 'isMembership'>
  & { staff: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'id' | 'name'>
  )> }
);

export type UserActivityFragment = (
  { __typename?: 'UserActivity' }
  & Pick<UserActivity, 'id' | 'name' | 'createdAt' | 'description' | 'createdByName' | 'createdByAvatar'>
);

export type ProfileBasicInfoFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'gender' | 'dateOfBirth' | 'height' | 'weight' | 'bloodType' | 'city' | 'state' | 'zip' | 'phoneNumber' | 'workPhone' | 'contactEmail' | 'emergencyFirstName' | 'emergencyMiddleName' | 'emergencyLastName' | 'emergencyRelation' | 'emergencyPhone' | 'emergencyEmail' | 'emergencyAddress'>
);

export type LockerFragment = (
  { __typename?: 'Locker' }
  & Pick<Locker, 'id' | 'name' | 'size' | 'status' | 'price' | 'frequency'>
);

export type ChecklistItemFragment = (
  { __typename?: 'ChecklistItem' }
  & Pick<ChecklistItem, 'id' | 'label' | 'isCompleted'>
);

export type ChecklistFragment = (
  { __typename?: 'Checklist' }
  & Pick<Checklist, 'id' | 'label'>
  & { items: Array<(
    { __typename?: 'ChecklistItem' }
    & ChecklistItemFragment
  )> }
);

export type RewardRedemptionOptionFragment = (
  { __typename?: 'RewardRedemptionOption' }
  & Pick<RewardRedemptionOption, 'id' | 'name' | 'description' | 'image' | 'pointsPerProduct'>
);

export type ProfileBasicDetailsFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'firstName' | 'lastName' | 'dateOfBirth' | 'gender' | 'height' | 'weight' | 'bloodType'>
);

export type CancelSubscriptionMutationVariables = {
  subscriptionId: Scalars['Int']
};


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelSubscription: Maybe<(
    { __typename?: 'CancelSubscriptionPayload' }
    & Pick<CancelSubscriptionPayload, 'message'>
    & { subscription: (
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'id' | 'cancelAtPeriodEnd'>
    ) }
  )> }
);

export type RenewSubscriptionMutationVariables = {
  subscriptionId: Scalars['Int']
};


export type RenewSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { renewSubscription: Maybe<(
    { __typename?: 'RenewSubscriptionPayload' }
    & Pick<RenewSubscriptionPayload, 'message'>
    & { subscription: (
      { __typename?: 'StripeSubscription' }
      & Pick<StripeSubscription, 'id' | 'cancelAtPeriodEnd'>
    ) }
  )> }
);

export type AddCreditCardMutationVariables = {
  tokenId: Scalars['String'],
  asPrimary: Scalars['Boolean']
};


export type AddCreditCardMutation = (
  { __typename?: 'Mutation' }
  & { addCreditCard: Maybe<(
    { __typename?: 'AddCreditCardPayload' }
    & Pick<AddCreditCardPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'needsToAddCard' | 'lapsedMembership'>
    ) }
  )> }
);

export type RemoveCreditCardMutationVariables = {
  cardId: Scalars['Int']
};


export type RemoveCreditCardMutation = (
  { __typename?: 'Mutation' }
  & { removeCreditCard: Maybe<(
    { __typename?: 'RemoveCreditCardPayload' }
    & Pick<RemoveCreditCardPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { cards: Array<(
        { __typename?: 'StripeCard' }
        & CardFragment
      )> }
    ) }
  )> }
);

export type SetPrimaryCardMutationVariables = {
  cardId: Scalars['Int']
};


export type SetPrimaryCardMutation = (
  { __typename?: 'Mutation' }
  & { setPrimaryCard: Maybe<(
    { __typename?: 'SetPrimaryCardPayload' }
    & Pick<SetPrimaryCardPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { cards: Array<(
        { __typename?: 'StripeCard' }
        & CardFragment
      )> }
    ) }
  )> }
);

export type RenameCreditCardMutationVariables = {
  cardId: Scalars['Int'],
  label: Scalars['String']
};


export type RenameCreditCardMutation = (
  { __typename?: 'Mutation' }
  & { renameCreditCard: Maybe<(
    { __typename?: 'RenameCreditCardPayload' }
    & Pick<RenameCreditCardPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { cards: Array<(
        { __typename?: 'StripeCard' }
        & CardFragment
      )> }
    ) }
  )> }
);

export type ChangePasswordMutationVariables = {
  currentPassword: Scalars['String'],
  password: Scalars['String'],
  passwordConfirmation: Scalars['String']
};


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: Maybe<(
    { __typename?: 'ChangePasswordPayload' }
    & Pick<ChangePasswordPayload, 'message'>
  )> }
);

export type JoinBewellMutationVariables = {};


export type JoinBewellMutation = (
  { __typename?: 'Mutation' }
  & { joinBewell: Maybe<(
    { __typename?: 'JoinBewellPayload' }
    & Pick<JoinBewellPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { bewell: Maybe<(
        { __typename?: 'Bewell' }
        & Pick<Bewell, 'optedIn'>
      )> }
    ) }
  )> }
);

export type ToggleBewellItemCompleteMutationVariables = {
  id: Scalars['Int'],
  isToday: Scalars['Boolean'],
  complete: Scalars['Boolean']
};


export type ToggleBewellItemCompleteMutation = (
  { __typename?: 'Mutation' }
  & { toggleBewellItemComplete: Maybe<(
    { __typename?: 'ToggleBewellItemCompletePayload' }
    & Pick<ToggleBewellItemCompletePayload, 'message'>
    & { bewellItem: (
      { __typename?: 'BewellItem' }
      & Pick<BewellItem, 'id' | 'isCompletedToday' | 'isCompletedYesterday'>
      & { bewellCategory: (
        { __typename?: 'BewellCategory' }
        & Pick<BewellCategory, 'id' | 'isStoneEarned'>
      ) }
    ), viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { bewell: Maybe<(
        { __typename?: 'Bewell' }
        & Pick<Bewell, 'points'>
      )> }
    ) }
  )> }
);

export type UploadBiometricDataMutationVariables = {
  file: Scalars['Upload']
};


export type UploadBiometricDataMutation = (
  { __typename?: 'Mutation' }
  & { uploadBiometricData: Maybe<(
    { __typename?: 'UploadBiometricDataPayload' }
    & Pick<UploadBiometricDataPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { biometricDataFiles: Array<(
        { __typename?: 'BiometricDatum' }
        & BiometricDataFileFragment
      )> }
    ) }
  )> }
);

export type AddDependentMutationVariables = {
  dependentParams: DependentInput,
  agreeToTerms?: Maybe<Scalars['Boolean']>
};


export type AddDependentMutation = (
  { __typename?: 'Mutation' }
  & { addDependent: Maybe<(
    { __typename?: 'AddDependentPayload' }
    & Pick<AddDependentPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { dependents: Array<(
        { __typename?: 'Dependent' }
        & DependentFragment
      )> }
    ) }
  )> }
);

export type EditDependentMutationVariables = {
  dependentParams: DependentInput,
  id: Scalars['Int']
};


export type EditDependentMutation = (
  { __typename?: 'Mutation' }
  & { editDependent: Maybe<(
    { __typename?: 'EditDependentPayload' }
    & Pick<EditDependentPayload, 'message'>
    & { dependent: (
      { __typename?: 'Dependent' }
      & DependentFragment
    ) }
  )> }
);

export type RemoveDependentMutationVariables = {
  id: Scalars['Int']
};


export type RemoveDependentMutation = (
  { __typename?: 'Mutation' }
  & { removeDependent: Maybe<(
    { __typename?: 'RemoveDependentPayload' }
    & Pick<RemoveDependentPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { dependents: Array<(
        { __typename?: 'Dependent' }
        & DependentFragment
      )> }
    ) }
  )> }
);

export type InvitePartnerMutationVariables = {
  email: Scalars['String'],
  external: Scalars['Boolean']
};


export type InvitePartnerMutation = (
  { __typename?: 'Mutation' }
  & { invitePartner: Maybe<(
    { __typename?: 'InvitePartnerPayload' }
    & Pick<InvitePartnerPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { pendingPartner: Maybe<(
        { __typename?: 'User' }
        & PartnerFragment
      )>, partner: Maybe<(
        { __typename?: 'User' }
        & PartnerFragment
      )>, invitation: Maybe<(
        { __typename?: 'Invitation' }
        & InvitationFragment
      )> }
    ) }
  )> }
);

export type RemovePartnershipMutationVariables = {};


export type RemovePartnershipMutation = (
  { __typename?: 'Mutation' }
  & { removePartnership: Maybe<(
    { __typename?: 'RemovePartnershipPayload' }
    & Pick<RemovePartnershipPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & PartnersFragment
    ) }
  )> }
);

export type RespondToPartnershipMutationVariables = {
  accept: Scalars['Boolean']
};


export type RespondToPartnershipMutation = (
  { __typename?: 'Mutation' }
  & { respondToPartnership: Maybe<(
    { __typename?: 'RespondToPartnershipPayload' }
    & Pick<RespondToPartnershipPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & PartnersFragment
    ) }
  )> }
);

export type AddGuestMutationVariables = {
  guestParams: GuestInput
};


export type AddGuestMutation = (
  { __typename?: 'Mutation' }
  & { addGuest: Maybe<(
    { __typename?: 'AddGuestPayload' }
    & Pick<AddGuestPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { guests: Array<(
        { __typename?: 'Guest' }
        & GuestFragment
      )> }
    ) }
  )> }
);

export type EditGuestMutationVariables = {
  guestParams: GuestInput,
  id: Scalars['Int']
};


export type EditGuestMutation = (
  { __typename?: 'Mutation' }
  & { editGuest: Maybe<(
    { __typename?: 'EditGuestPayload' }
    & Pick<EditGuestPayload, 'message'>
    & { guest: (
      { __typename?: 'Guest' }
      & GuestFragment
    ) }
  )> }
);

export type RemoveGuestMutationVariables = {
  id: Scalars['Int']
};


export type RemoveGuestMutation = (
  { __typename?: 'Mutation' }
  & { removeGuest: Maybe<(
    { __typename?: 'RemoveGuestPayload' }
    & Pick<RemoveGuestPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { guests: Array<(
        { __typename?: 'Guest' }
        & GuestFragment
      )> }
    ) }
  )> }
);

export type RegisterToGfcMutationVariables = {
  gfcScheduleId: Scalars['Int'],
  attendeeId: Scalars['Int'],
  attendeeType: Scalars['String']
};


export type RegisterToGfcMutation = (
  { __typename?: 'Mutation' }
  & { registerToGfc: Maybe<(
    { __typename?: 'RegisterToGfcPayload' }
    & Pick<RegisterToGfcPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & { userActivities: Array<(
        { __typename?: 'UserActivity' }
        & UserActivityFragment
      )> }
      & GfcMutationUpdateFragment
    ) }
  )> }
);

export type CancelGfcRegistrationMutationVariables = {
  registrationId: Scalars['Int']
};


export type CancelGfcRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { cancelGfcRegistration: Maybe<(
    { __typename?: 'CancelGfcRegistrationPayload' }
    & Pick<CancelGfcRegistrationPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & { userActivities: Array<(
        { __typename?: 'UserActivity' }
        & UserActivityFragment
      )> }
      & GfcMutationUpdateFragment
    ) }
  )> }
);

export type ClaimGfcSpotMutationVariables = {
  registrationId: Scalars['Int']
};


export type ClaimGfcSpotMutation = (
  { __typename?: 'Mutation' }
  & { claimGfcSpot: Maybe<(
    { __typename?: 'ClaimGfcSpotPayload' }
    & Pick<ClaimGfcSpotPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & GfcMutationUpdateFragment
    ) }
  )> }
);

export type GfcTableDataMutationVariables = {
  pageSize: Scalars['Int'],
  page: Scalars['Int'],
  filters: GfcFiltersInput
};


export type GfcTableDataMutation = (
  { __typename?: 'Mutation' }
  & { gfcTableData: Maybe<(
    { __typename?: 'GfcTableDataPayload' }
    & Pick<GfcTableDataPayload, 'totalCount'>
    & { gfcSchedules: Array<(
      { __typename?: 'GfcSchedule' }
      & GfcScheduleFragment
    )> }
  )> }
);

export type LogoutV2UserMutationVariables = {};


export type LogoutV2UserMutation = (
  { __typename?: 'Mutation' }
  & { logoutV2User: Maybe<(
    { __typename?: 'LogoutV2UserPayload' }
    & Pick<LogoutV2UserPayload, 'success'>
  )> }
);

export type EditUserAvatarMutationVariables = {
  profile?: Maybe<ProfileInput>
};


export type EditUserAvatarMutation = (
  { __typename?: 'Mutation' }
  & { editUser: Maybe<(
    { __typename?: 'EditUserPayload' }
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'avatar'>
      ) }
    ) }
  )> }
);

export type EditUserContactInfoMutationVariables = {
  user?: Maybe<UserInput>,
  profile?: Maybe<ProfileInput>
};


export type EditUserContactInfoMutation = (
  { __typename?: 'Mutation' }
  & { editUser: Maybe<(
    { __typename?: 'EditUserPayload' }
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'city' | 'state' | 'zip' | 'phoneNumber' | 'workPhone' | 'contactEmail'>
      ) }
    ) }
  )> }
);

export type TourCompletedMutationVariables = {};


export type TourCompletedMutation = (
  { __typename?: 'Mutation' }
  & { tourCompleted: Maybe<(
    { __typename?: 'TourCompletedPayload' }
    & Pick<TourCompletedPayload, 'success'>
  )> }
);

export type AcceptMembershipAgreementMutationVariables = {};


export type AcceptMembershipAgreementMutation = (
  { __typename?: 'Mutation' }
  & { acceptMembershipAgreement: Maybe<(
    { __typename?: 'AcceptMembershipAgreementPayload' }
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'alertAboutMembershipFee'>
    ) }
  )> }
);

export type AddItemToCartMutationVariables = {
  itemId: Scalars['Int'],
  itemClass: Scalars['String'],
  quantity?: Maybe<Scalars['Int']>,
  staffId?: Maybe<Scalars['Int']>,
  userComments?: Maybe<Scalars['String']>,
  userAvailability?: Maybe<Scalars['String']>,
  autoRenew?: Maybe<Scalars['Boolean']>
};


export type AddItemToCartMutation = (
  { __typename?: 'Mutation' }
  & { addItemToCart: Maybe<(
    { __typename?: 'AddItemToCartPayload' }
    & Pick<AddItemToCartPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { cart: Maybe<(
        { __typename?: 'Cart' }
        & Pick<Cart, 'id' | 'itemCount'>
        & { items: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id'>
        )> }
      )> }
    ) }
  )> }
);

export type CheckoutMutationVariables = {};


export type CheckoutMutation = (
  { __typename?: 'Mutation' }
  & { checkout: Maybe<(
    { __typename?: 'CheckoutPayload' }
    & Pick<CheckoutPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { cart: Maybe<(
        { __typename?: 'Cart' }
        & Pick<Cart, 'id' | 'itemCount'>
        & { items: Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'id'>
        )> }
      )>, subscriptions: Array<(
        { __typename?: 'StripeSubscription' }
        & SubscriptionFragment
      )> }
    ) }
  )> }
);

export type RedeemCouponMutationVariables = {
  code: Scalars['String']
};


export type RedeemCouponMutation = (
  { __typename?: 'Mutation' }
  & { redeemCoupon: Maybe<(
    { __typename?: 'RedeemCouponPayload' }
    & { cart: (
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'total'>
      & { coupon: Maybe<(
        { __typename?: 'Coupon' }
        & Pick<Coupon, 'id' | 'couponCode' | 'discount'>
      )> }
    ) }
  )> }
);

export type RemoveItemFromCartMutationVariables = {
  itemId: Scalars['Int']
};


export type RemoveItemFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeItemFromCart: Maybe<(
    { __typename?: 'RemoveItemFromCartPayload' }
    & Pick<RemoveItemFromCartPayload, 'message'>
    & { cart: (
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'total' | 'itemCount'>
      & { items: Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'id'>
      )> }
    ) }
  )> }
);

export type UpdateItemQuantityMutationVariables = {
  itemId: Scalars['Int'],
  quantity: Scalars['Int']
};


export type UpdateItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { updateItemQuantity: Maybe<(
    { __typename?: 'UpdateItemQuantityPayload' }
    & { cart: (
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'total'>
      & { items: Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'id' | 'quantity'>
      )> }
    ) }
  )> }
);

export type ContactInstructorMutationVariables = {
  staffId: Scalars['Int'],
  subject: Scalars['String'],
  message: Scalars['String']
};


export type ContactInstructorMutation = (
  { __typename?: 'Mutation' }
  & { contactInstructor: Maybe<(
    { __typename?: 'ContactInstructorPayload' }
    & Pick<ContactInstructorPayload, 'success' | 'message'>
  )> }
);

export type RegisterForEventMutationVariables = {
  eventId: Scalars['Int'],
  startTime: Scalars['String']
};


export type RegisterForEventMutation = (
  { __typename?: 'Mutation' }
  & { registerForEvent: Maybe<(
    { __typename?: 'RegisterForEventPayload' }
    & Pick<RegisterForEventPayload, 'message'>
    & { slot: (
      { __typename?: 'EventSlot' }
      & Pick<EventSlot, 'id' | 'openSpotsDisplay'>
      & { eventRegistration: Maybe<(
        { __typename?: 'EventRegistration' }
        & Pick<EventRegistration, 'id' | 'startTime' | 'endTime' | 'title' | 'eventLocation' | 'details'>
        & { charge: Maybe<(
          { __typename?: 'StripeCharge' }
          & Pick<StripeCharge, 'price'>
        )> }
      )> }
    ) }
  )> }
);

export type UnregisterForEventMutationVariables = {
  registrationId: Scalars['Int']
};


export type UnregisterForEventMutation = (
  { __typename?: 'Mutation' }
  & { unregisterForEvent: Maybe<(
    { __typename?: 'UnregisterForEventPayload' }
    & Pick<UnregisterForEventPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { upcomingEventRegistrations: Array<(
        { __typename?: 'EventRegistration' }
        & EventRegistrationFragment
      )>, upcomingAppointmentBookings: Array<(
        { __typename?: 'AppointmentBooking' }
        & AppointmentBookingFragment
      )> }
    ) }
  )> }
);

export type RegisterForAppointmentMutationVariables = {
  apptId: Scalars['Int'],
  startTime: Scalars['ISO8601DateTime'],
  length: Scalars['Int'],
  eventStart: Scalars['ISO8601DateTime'],
  eventEnd: Scalars['ISO8601DateTime']
};


export type RegisterForAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { registerForAppointment: Maybe<(
    { __typename?: 'RegisterForAppointmentPayload' }
    & Pick<RegisterForAppointmentPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { upcomingAppointmentBookings: Array<(
        { __typename?: 'AppointmentBooking' }
        & AppointmentBookingFragment
      )>, location: (
        { __typename?: 'Location' }
        & Pick<Location, 'id'>
        & { appointmentCalendarEntries: Array<(
          { __typename?: 'EventCalendarEntry' }
          & EventCalendarEntryFragment
        )> }
      ) }
    ) }
  )> }
);

export type RedeemRewardPointsMutationVariables = {
  productId: Scalars['Int'],
  points: Scalars['Int']
};


export type RedeemRewardPointsMutation = (
  { __typename?: 'Mutation' }
  & { redeemRewardPoints: Maybe<(
    { __typename?: 'RedeemRewardPointsPayload' }
    & Pick<RedeemRewardPointsPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'totalPoints'>
    ) }
  )> }
);

export type ChangeCurrentLocationMutationVariables = {
  locationId: Scalars['Int']
};


export type ChangeCurrentLocationMutation = (
  { __typename?: 'Mutation' }
  & { changeCurrentLocation: Maybe<(
    { __typename?: 'ChangeCurrentLocationPayload' }
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'id'>
      ) }
    ) }
  )> }
);

export type RecordCompletedIntakeSurveyMutationVariables = {};


export type RecordCompletedIntakeSurveyMutation = (
  { __typename?: 'Mutation' }
  & { recordCompletedIntakeSurvey: Maybe<(
    { __typename?: 'RecordCompletedIntakeSurveyPayload' }
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { intakeSurvey: (
        { __typename?: 'IntakeSurvey' }
        & Pick<IntakeSurvey, 'show'>
      ) }
    ) }
  )> }
);

export type RentLockerMutationVariables = {
  lockerId: Scalars['Int']
};


export type RentLockerMutation = (
  { __typename?: 'Mutation' }
  & { rentLocker: Maybe<(
    { __typename?: 'RentLockerPayload' }
    & Pick<RentLockerPayload, 'message'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { lockers: Array<(
        { __typename?: 'Locker' }
        & LockerFragment
      )> }
    ) }
  )> }
);

export type AgreeToWaiverMutationVariables = {
  locationId?: Maybe<Scalars['Int']>
};


export type AgreeToWaiverMutation = (
  { __typename?: 'Mutation' }
  & { agreeToWaiver: Maybe<(
    { __typename?: 'AgreeToWaiverPayload' }
    & Pick<AgreeToWaiverPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'agreedToWaiver'>
    ) }
  )> }
);

export type AgreeToHipaaWaiverMutationVariables = {};


export type AgreeToHipaaWaiverMutation = (
  { __typename?: 'Mutation' }
  & { agreeToWaiver: Maybe<(
    { __typename?: 'AgreeToWaiverPayload' }
    & Pick<AgreeToWaiverPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { agreedToHipaaWaiver: User['agreedToWaiver'] }
    ) }
  )> }
);

export type UnrentLockerMutationVariables = {
  lockerId: Scalars['Int']
};


export type UnrentLockerMutation = (
  { __typename?: 'Mutation' }
  & { unrentLocker: Maybe<(
    { __typename?: 'UnrentLockerPayload' }
    & Pick<UnrentLockerPayload, 'message'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { lockers: Array<(
        { __typename?: 'Locker' }
        & LockerFragment
      )> }
    ) }
  )> }
);

export type MarkMessageAsReadMutationVariables = {
  messageId: Scalars['Int']
};


export type MarkMessageAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markMessageAsRead: Maybe<(
    { __typename?: 'MarkMessageAsReadPayload' }
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { unreadMessages: Array<(
        { __typename?: 'UserMessage' }
        & Pick<UserMessage, 'id'>
      )> }
    ) }
  )> }
);

export type UpdateTourMutationVariables = {
  run?: Maybe<Scalars['Boolean']>,
  stepIndex?: Maybe<Scalars['Int']>
};


export type UpdateTourMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTour'>
);

export type AdvanceTourMutationVariables = {};


export type AdvanceTourMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'advanceTour'>
);

export type UpdateTourStatusMutationVariables = {};


export type UpdateTourStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTourStatus'>
);

export type OpenDialogMutationVariables = {
  dialogComponent: Scalars['String'],
  dialogProps?: Maybe<DialogPropsInput>
};


export type OpenDialogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'openDialog'>
);

export type CloseDialogMutationVariables = {};


export type CloseDialogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'closeDialog'>
);

export type UpdateVideoSearchMutationVariables = {
  videoSearch: Scalars['String']
};


export type UpdateVideoSearchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateVideoSearch'>
);

export type SetRefreshGfcTableMutationVariables = {
  refreshGfcTable: Scalars['Boolean']
};


export type SetRefreshGfcTableMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setRefreshGfcTable'>
);

export type AddAllergyMutationVariables = {
  allergy: Scalars['String']
};


export type AddAllergyMutation = (
  { __typename?: 'Mutation' }
  & { addAllergy: Maybe<(
    { __typename?: 'AddAllergyPayload' }
    & Pick<AddAllergyPayload, 'message'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'allergies'>
    ) }
  )> }
);

export type RemoveAllergyMutationVariables = {
  allergy: Scalars['String']
};


export type RemoveAllergyMutation = (
  { __typename?: 'Mutation' }
  & { removeAllergy: Maybe<(
    { __typename?: 'RemoveAllergyPayload' }
    & Pick<RemoveAllergyPayload, 'message'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'allergies'>
    ) }
  )> }
);

export type AddMedicalConditionMutationVariables = {
  medicalCondition: Scalars['String']
};


export type AddMedicalConditionMutation = (
  { __typename?: 'Mutation' }
  & { addMedicalCondition: Maybe<(
    { __typename?: 'AddMedicalConditionPayload' }
    & Pick<AddMedicalConditionPayload, 'message'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'medicalConditions'>
    ) }
  )> }
);

export type RemoveMedicalConditionMutationVariables = {
  medicalCondition: Scalars['String']
};


export type RemoveMedicalConditionMutation = (
  { __typename?: 'Mutation' }
  & { removeMedicalCondition: Maybe<(
    { __typename?: 'RemoveMedicalConditionPayload' }
    & Pick<RemoveMedicalConditionPayload, 'message'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'medicalConditions'>
    ) }
  )> }
);

export type EditUserBasicDetailsMutationVariables = {
  profile?: Maybe<ProfileInput>
};


export type EditUserBasicDetailsMutation = (
  { __typename?: 'Mutation' }
  & { editUser: Maybe<(
    { __typename?: 'EditUserPayload' }
    & Pick<EditUserPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'firstName' | 'lastName' | 'dateOfBirth' | 'gender' | 'height' | 'weight' | 'bloodType'>
      ) }
    ) }
  )> }
);

export type EditUserEmergencyContactMutationVariables = {
  profile?: Maybe<ProfileInput>
};


export type EditUserEmergencyContactMutation = (
  { __typename?: 'Mutation' }
  & { editUser: Maybe<(
    { __typename?: 'EditUserPayload' }
    & Pick<EditUserPayload, 'message'>
    & { viewer: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'emergencyFirstName' | 'emergencyMiddleName' | 'emergencyLastName' | 'emergencyRelation' | 'emergencyRelationIndex' | 'emergencyPhone' | 'emergencyEmail' | 'emergencyAddress'>
      ) }
    ) }
  )> }
);

export type AccountTabQueryVariables = {};


export type AccountTabQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'hasPlans' | 'payments'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'qrCodeEnabled'>
      ) }
    ) }
  ) }
);

export type UserOrdersQueryVariables = {};


export type UserOrdersQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'email'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city' | 'country'>
    ), orderHistory: Array<(
      { __typename?: 'StripeCharge' }
      & Pick<StripeCharge, 'id' | 'createdOnStripe' | 'description' | 'sourceLast4' | 'price' | 'quantity' | 'sessions' | 'staffName' | 'status'>
    )> }
  ) }
);

export type UserActivityQueryVariables = {};


export type UserActivityQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { userActivities: Array<(
      { __typename?: 'UserActivity' }
      & UserActivityFragment
    )> }
  ) }
);

export type UserSubscriptionsQueryVariables = {};


export type UserSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { subscriptions: Array<(
      { __typename?: 'StripeSubscription' }
      & SubscriptionFragment
    )> }
  ) }
);

export type UserCardsQueryVariables = {};


export type UserCardsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName'>
    & { cards: Array<(
      { __typename?: 'StripeCard' }
      & CardFragment
    )> }
  ) }
);

export type BewellLandingQueryVariables = {
  isToday: Scalars['Boolean']
};


export type BewellLandingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'currentServerDate'>
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { bewell: Maybe<(
      { __typename?: 'Bewell' }
      & Pick<Bewell, 'points' | 'dayDisplay' | 'dayNumber' | 'cutoff'>
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone'>
    ) }
  ) }
);

export type BewellBoardQueryVariables = {};


export type BewellBoardQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { bewell: Maybe<(
        { __typename?: 'Bewell' }
        & { categories: Array<(
          { __typename?: 'BewellCategory' }
          & Pick<BewellCategory, 'id' | 'title' | 'color'>
          & { bewellItems: Array<(
            { __typename?: 'BewellItem' }
            & BewellItemFragment
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type BewellStonesQueryVariables = {};


export type BewellStonesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { bewell: Maybe<(
        { __typename?: 'Bewell' }
        & Pick<Bewell, 'stonesEnabled' | 'totalWellnessStoneLabel' | 'totalWellnessStoneColor'>
        & { categories: Array<(
          { __typename?: 'BewellCategory' }
          & Pick<BewellCategory, 'id' | 'title' | 'color' | 'isStoneEarned'>
        )> }
      )> }
    ) }
  ) }
);

export type BiometricDataQueryVariables = {};


export type BiometricDataQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { biometricDataFiles: Array<(
      { __typename?: 'BiometricDatum' }
      & BiometricDataFileFragment
    )> }
  ) }
);

export type BiometricsQueryVariables = {};


export type BiometricsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { agreedToHipaaWaiver: User['agreedToWaiver'] }
  ) }
);

export type BiometricsFileUrlQueryVariables = {
  datumId: Scalars['Int']
};


export type BiometricsFileUrlQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { biometricDataFile: (
      { __typename?: 'BiometricDatum' }
      & Pick<BiometricDatum, 'id' | 'expirableUrl'>
    ) }
  ) }
);

export type FamilyTabsQueryVariables = {};


export type FamilyTabsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'dependentsEnabled' | 'guestsEnabled' | 'partnersEnabled'>
      ) }
    ) }
  ) }
);

export type DependentQueryVariables = {
  dependentId?: Maybe<Scalars['Int']>
};


export type DependentQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { dependent: Maybe<(
      { __typename?: 'Dependent' }
      & DependentFragment
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'dependentChildMinAge'>
      ) }
    ) }
  ) }
);

export type DependentWaiverQueryVariables = {};


export type DependentWaiverQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { companyPolicy: (
        { __typename?: 'CompanyPolicy' }
        & Pick<CompanyPolicy, 'id' | 'dependentWaiver'>
      ) }
    ) }
  ) }
);

export type DependentsQueryQueryVariables = {};


export type DependentsQueryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { dependents: Array<(
      { __typename?: 'Dependent' }
      & DependentFragment
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'usersCanEditDependents'>
      ) }
    ) }
  ) }
);

export type PartnerInvitationQueryVariables = {};


export type PartnerInvitationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { pendingPartnerRequestFrom: Maybe<(
      { __typename?: 'User' }
      & PartnerFragment
    )>, partner: Maybe<(
      { __typename?: 'User' }
      & PartnerFragment
    )> }
  ) }
);

export type PartnerQueryVariables = {};


export type PartnerQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & PartnersFragment
  ) }
);

export type PartnerConfigQueryVariables = {};


export type PartnerConfigQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'allowExternalPartners'>
      ) }
    ) }
  ) }
);

export type GuestsQueryQueryVariables = {};


export type GuestsQueryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { guests: Array<(
      { __typename?: 'Guest' }
      & GuestFragment
    )> }
  ) }
);

export type GuestQueryVariables = {
  guestId?: Maybe<Scalars['Int']>
};


export type GuestQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'guestEmergencyRelations'>
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'requireGuestEmergencyContacts'>
      ) }
    ), guest: Maybe<(
      { __typename?: 'Guest' }
      & GuestFragment
    )> }
  ) }
);

export type ClassRegistrationQueryVariables = {
  classRegistrationId: Scalars['Int']
};


export type ClassRegistrationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { classRegistration: Maybe<(
      { __typename?: 'ClassRegistration' }
      & Pick<ClassRegistration, 'id' | 'status' | 'attendeeId' | 'attendeeType'>
      & { gfcSchedule: (
        { __typename?: 'GfcSchedule' }
        & Pick<GfcSchedule, 'id' | 'availableSpots'>
        & { lastRegistration: Maybe<(
          { __typename?: 'UserActivity' }
          & Pick<UserActivity, 'id' | 'updatedAt'>
        )> }
        & GfcScheduleFragment
      ) }
    )> }
  ) }
);

export type ClassesRegisteredToQueryVariables = {};


export type ClassesRegisteredToQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { registeredClasses: Array<(
      { __typename?: 'ClassRegistration' }
      & Pick<ClassRegistration, 'id' | 'status'>
      & { gfcSchedule: (
        { __typename?: 'GfcSchedule' }
        & GfcScheduleFragment
      ) }
    )> }
  ) }
);

export type UnregisteredGfcSchedulesQueryVariables = {};


export type UnregisteredGfcSchedulesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { unregisteredGfcSchedules: Array<(
      { __typename?: 'GfcSchedule' }
      & GfcScheduleFragment
    )> }
  ) }
);

export type GfcSchedulesQueryVariables = {
  after?: Maybe<Scalars['ISO8601DateTime']>,
  before?: Maybe<Scalars['ISO8601DateTime']>
};


export type GfcSchedulesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone'>
      & { gfcSchedules: Array<(
        { __typename?: 'GfcSchedule' }
        & GfcScheduleFragment
      )> }
    ) }
  ) }
);

export type LimitedGfcSchedulesQueryVariables = {
  offset?: Maybe<Scalars['Int']>,
  filters?: Maybe<GfcFiltersInput>
};


export type LimitedGfcSchedulesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone'>
      & { limitedGfcSchedules: Array<(
        { __typename?: 'GfcSchedule' }
        & GfcScheduleFragment
      )> }
    ) }
  ) }
);

export type GfcScheduleQueryVariables = {
  id: Scalars['Int']
};


export type GfcScheduleQuery = (
  { __typename?: 'Query' }
  & { gfcSchedule: (
    { __typename?: 'GfcSchedule' }
    & GfcScheduleFragment
  ) }
);

export type GfcFilterOptionsQueryVariables = {};


export type GfcFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { gfcFilterOptions: (
        { __typename?: 'GfcFilterOptions' }
        & Pick<GfcFilterOptions, 'instructor' | 'title'>
      ) }
    ) }
  ) }
);

export type LocationFitnessServiceTypesQueryVariables = {};


export type LocationFitnessServiceTypesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'subscriptionsTitle' | 'subscriptionsDescription' | 'subscriptionsIcon' | 'groupCreditsTitle' | 'groupCreditsDescription' | 'groupCreditsIcon' | 'guestPassIcon'>
      ), fitnessServiceTypes: Array<(
        { __typename?: 'FitnessServiceType' }
        & Pick<FitnessServiceType, 'id' | 'title' | 'iconColor' | 'iconImage' | 'description' | 'externalUrl'>
        & { fitnessServices: Array<(
          { __typename?: 'FitnessService' }
          & FitnessServiceFragment
        )> }
      )>, memberships: Array<(
        { __typename?: 'Membership' }
        & MembershipFragment
      )>, gfcCreditPlans: Array<(
        { __typename?: 'GfcCreditPlan' }
        & Pick<GfcCreditPlan, 'id' | 'title' | 'description' | 'price' | 'validFor'>
      )>, guestPassPackages: Array<(
        { __typename?: 'GuestPassPackage' }
        & Pick<GuestPassPackage, 'id' | 'title' | 'description' | 'price' | 'validFor'>
      )> }
    ) }
  ) }
);

export type AppLayoutQueryVariables = {};


export type AppLayoutQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'impersonated' | 'email' | 'intercomToken' | 'pendingReview' | 'pointsOnly' | 'completedTour' | 'agreedToWaiver' | 'lapsedMembership' | 'needsToAddCard' | 'alertAboutMembershipFee'>
    & { bewell: Maybe<(
      { __typename?: 'Bewell' }
      & Pick<Bewell, 'show'>
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone' | 'city' | 'name' | 'hasAppointments' | 'hasGFCs' | 'hasEvents'>
      & { lockers: Array<(
        { __typename?: 'Locker' }
        & Pick<Locker, 'id'>
      )>, config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'eventsLabel' | 'logo' | 'dependentsEnabled' | 'guestsEnabled' | 'partnersEnabled' | 'biometricDataEnabled'>
      ) }
    ), intakeSurvey: (
      { __typename?: 'IntakeSurvey' }
      & Pick<IntakeSurvey, 'show'>
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city' | 'name'>
    )> }
  ) }
);

export type CompanyWaiverQueryVariables = {
  locationId: Scalars['Int']
};


export type CompanyWaiverQuery = (
  { __typename?: 'Query' }
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & { companyPolicy: (
      { __typename?: 'CompanyPolicy' }
      & Pick<CompanyPolicy, 'id' | 'title' | 'waiver'>
    ) }
  ) }
);

export type HipaaWaiverQueryVariables = {};


export type HipaaWaiverQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { companyPolicy: (
        { __typename?: 'CompanyPolicy' }
        & Pick<CompanyPolicy, 'id' | 'hipaaWaiver'>
      ) }
    ) }
  ) }
);

export type TimezoneQueryVariables = {};


export type TimezoneQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone'>
    ) }
  ) }
);

export type DashboardQueryVariables = {};


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'hasEvents' | 'hasAppointments' | 'hasGFCs'>
      & { currentChecklist: Maybe<(
        { __typename?: 'Checklist' }
        & ChecklistFragment
      )>, previousChecklist: Maybe<(
        { __typename?: 'Checklist' }
        & ChecklistFragment
      )> }
    ) }
  ) }
);

export type TopNavQueryVariables = {};


export type TopNavQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'logo'>
      ) }
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )> }
  ) }
);

export type IntakeSurveyQueryVariables = {};


export type IntakeSurveyQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'intakeSurveyUrl'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'logo'>
      ) }
    ) }
  ) }
);

export type TransactionReceiptsQueryVariables = {};


export type TransactionReceiptsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'email'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city' | 'country'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'logo'>
      ) }
    ) }
  ) }
);

export type CartItemCountQueryVariables = {};


export type CartItemCountQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { cart: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'itemCount'>
    )> }
  ) }
);

export type UserCompletedTourQueryVariables = {};


export type UserCompletedTourQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'completedTour'>
  ) }
);

export type StaffQueryVariables = {
  staffId: Scalars['Int']
};


export type StaffQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { staff: (
        { __typename?: 'Staff' }
        & Pick<Staff, 'id' | 'name' | 'about' | 'avatar' | 'position'>
      ) }
    ) }
  ) }
);

export type StaffsQueryVariables = {};


export type StaffsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { staffs: Array<(
        { __typename?: 'Staff' }
        & Pick<Staff, 'id' | 'avatar' | 'name' | 'position' | 'about'>
      )> }
    ) }
  ) }
);

export type AvailableCreditsQueryVariables = {};


export type AvailableCreditsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'availableCreditsDisplay'>
  ) }
);

export type CheckinReportQueryVariables = {
  startDateUnix: Scalars['Float'],
  endDateUnix: Scalars['Float']
};


export type CheckinReportQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { facilityUses: Array<(
      { __typename?: 'UserActivity' }
      & Pick<UserActivity, 'id' | 'createdAt' | 'facilityUseOptionLabel'>
    )>, gfcCheckins: Array<(
      { __typename?: 'UserActivity' }
      & Pick<UserActivity, 'id' | 'createdAt' | 'gfcTitle'>
    )> }
  ) }
);

export type ViewerIdQueryVariables = {};


export type ViewerIdQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type IsImpersonatedQueryVariables = {};


export type IsImpersonatedQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'impersonated'>
  ) }
);

export type IntercomUserQueryVariables = {};


export type IntercomUserQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'fullName' | 'createdAt' | 'intercomToken'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city'>
    ) }
  ) }
);

export type NavLinksQueryQueryVariables = {};


export type NavLinksQueryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'hasActivities'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'hasEvents' | 'hasAppointments' | 'hasGFCs' | 'hasServices' | 'hasStaff'>
      & { lockers: Array<(
        { __typename?: 'Locker' }
        & Pick<Locker, 'id'>
      )>, config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'eventsLabel' | 'videoLibraryEnabled' | 'biometricDataEnabled'>
      ) }
    ), bewell: Maybe<(
      { __typename?: 'Bewell' }
      & Pick<Bewell, 'show'>
    )> }
  ) }
);

export type MedicalQueryVariables = {};


export type MedicalQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'allergies' | 'medicalConditions'>
    ) }
  ) }
);

export type MembershipAgreementCopyQueryVariables = {};


export type MembershipAgreementCopyQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'membershipAgreementCopy'>
      ) }
    ) }
  ) }
);

export type VideosQueryVariables = {
  offset?: Maybe<Scalars['Int']>,
  search?: Maybe<Scalars['String']>
};


export type VideosQuery = (
  { __typename?: 'Query' }
  & { videoLibrary: Maybe<(
    { __typename?: 'VideoLibrary' }
    & Pick<VideoLibrary, 'totalCount'>
    & { videos: Array<(
      { __typename?: 'Video' }
      & Pick<Video, 'id' | 'title' | 'description' | 'duration' | 'imgUrl' | 'videoId' | 'videoUrl'>
    )> }
  )> }
);

export type StripeApiKeyQueryVariables = {};


export type StripeApiKeyQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'stripeApiKey'>
    ) }
  ) }
);

export type CartItemsQueryVariables = {};


export type CartItemsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { cart: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id' | 'total'>
      & { items: Array<(
        { __typename?: 'CartItem' }
        & CartItemFragment
      )> }
    )> }
  ) }
);

export type UserWidgetsQueryVariables = {};


export type UserWidgetsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'availableCreditsDisplay' | 'unlimitedCreditsUntil' | 'guestPassCount' | 'totalFacilityCheckinByCurrentMonth' | 'totalGfcCheckinByCurrentMonth' | 'totalPoints' | 'firstBank'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'creditsForGfc'>
    ), bewell: Maybe<(
      { __typename?: 'Bewell' }
      & BewellFragment
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'masonJarWidgetEnabled' | 'onlineCoachingWidgetEnabled' | 'gfcCheckinWidgetEnabled' | 'facilityCheckinWidgetEnabled' | 'freeGfcEnabled' | 'guestsEnabled' | 'guestPassInterval' | 'unlimitedGuestPasses' | 'rewardPointsEnabled' | 'pointsRedemptionEnabled' | 'minRedeemPointLimit'>
      ) }
    ) }
  ) }
);

export type RewardPointsQueryVariables = {};


export type RewardPointsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'totalPoints'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'rewardPointsEnabled' | 'pointsRedemptionEnabled' | 'minRedeemPointLimit'>
      ) }
    ) }
  ) }
);

export type CartCouponQueryVariables = {};


export type CartCouponQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { cart: Maybe<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & { coupon: Maybe<(
        { __typename?: 'Coupon' }
        & Pick<Coupon, 'id' | 'couponCode' | 'discount'>
      )> }
    )> }
  ) }
);

export type EventLabelQueryVariables = {};


export type EventLabelQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'eventsLabel'>
      ) }
    ) }
  ) }
);

export type EventsQueryVariables = {
  startDate?: Maybe<Scalars['ISO8601DateTime']>,
  endDate?: Maybe<Scalars['ISO8601DateTime']>
};


export type EventsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'title' | 'details' | 'eventLocation' | 'price' | 'startTimedate' | 'endTimedate' | 'startDate' | 'endDate'>
      )>, config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'eventsLabel'>
      ) }
    ) }
  ) }
);

export type AppointmentsQueryVariables = {
  startDate?: Maybe<Scalars['ISO8601DateTime']>,
  endDate?: Maybe<Scalars['ISO8601DateTime']>
};


export type AppointmentsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { appointments: Array<(
        { __typename?: 'Appointment' }
        & AppointmentFragment
      )> }
    ) }
  ) }
);

export type EventsCalendarQueryVariables = {
  after: Scalars['ISO8601DateTime'],
  before: Scalars['ISO8601DateTime']
};


export type EventsCalendarQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone'>
      & { eventCalendarEntries: Array<(
        { __typename?: 'EventCalendarEntry' }
        & EventCalendarEntryFragment
      )> }
    ) }
  ) }
);

export type EventQueryVariables = {
  eventId: Scalars['Int'],
  after?: Maybe<Scalars['ISO8601DateTime']>,
  before?: Maybe<Scalars['ISO8601DateTime']>
};


export type EventQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'price' | 'type'>
        & { availableSlots: Array<(
          { __typename?: 'EventSlot' }
          & Pick<EventSlot, 'id' | 'startTime' | 'endTime' | 'openSpotsDisplay' | 'eventId'>
          & { eventRegistration: Maybe<(
            { __typename?: 'EventRegistration' }
            & Pick<EventRegistration, 'id' | 'startTime' | 'endTime' | 'title' | 'details' | 'eventLocation'>
            & { charge: Maybe<(
              { __typename?: 'StripeCharge' }
              & Pick<StripeCharge, 'price'>
            )> }
          )> }
        )> }
      ) }
    ) }
  ) }
);

export type UpcomingEventRegistrationsQueryVariables = {};


export type UpcomingEventRegistrationsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'eventsLabel'>
      ) }
    ), upcomingEventRegistrations: Array<(
      { __typename?: 'EventRegistration' }
      & Pick<EventRegistration, 'id' | 'title' | 'eventLocation' | 'startTime' | 'endTime'>
      & { charge: Maybe<(
        { __typename?: 'StripeCharge' }
        & Pick<StripeCharge, 'price'>
      )> }
    )> }
  ) }
);

export type AvailableApptLengthsQueryVariables = {
  apptId: Scalars['Int'],
  startTime: Scalars['ISO8601DateTime'],
  endTime: Scalars['ISO8601DateTime']
};


export type AvailableApptLengthsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { appointment: (
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id' | 'availableLengths'>
      ) }
    ) }
  ) }
);

export type AvailableApptTimesQueryVariables = {
  apptId: Scalars['Int'],
  startTime: Scalars['ISO8601DateTime'],
  endTime: Scalars['ISO8601DateTime'],
  length: Scalars['Int']
};


export type AvailableApptTimesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { appointment: (
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id' | 'availableTimes'>
      ) }
    ) }
  ) }
);

export type AppointmentConfirmationQueryVariables = {
  apptId: Scalars['Int'],
  length: Scalars['Int']
};


export type AppointmentConfirmationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { appointment: (
        { __typename?: 'Appointment' }
        & Pick<Appointment, 'id' | 'totalPrice'>
      ) }
    ) }
  ) }
);

export type UpcomingAppointmentBookingsQueryVariables = {};


export type UpcomingAppointmentBookingsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { upcomingAppointmentBookings: Array<(
      { __typename?: 'AppointmentBooking' }
      & AppointmentBookingFragment
    )> }
  ) }
);

export type AppointmentCalendarEntriesQueryVariables = {
  after: Scalars['ISO8601DateTime'],
  before: Scalars['ISO8601DateTime'],
  apptId?: Maybe<Scalars['Int']>
};


export type AppointmentCalendarEntriesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { upcomingAppointmentBookings: Array<(
      { __typename?: 'AppointmentBooking' }
      & AppointmentBookingFragment
    )>, location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'timezone'>
      & { appointmentCalendarEntries: Array<(
        { __typename?: 'EventCalendarEntry' }
        & EventCalendarEntryFragment
      )> }
    ) }
  ) }
);

export type EventSlotQueryVariables = {
  eventId: Scalars['Int'],
  slotId: Scalars['String']
};


export type EventSlotQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
        & { slot: (
          { __typename?: 'EventSlot' }
          & Pick<EventSlot, 'id' | 'eventId' | 'openSpotsDisplay'>
          & { eventRegistration: Maybe<(
            { __typename?: 'EventRegistration' }
            & Pick<EventRegistration, 'id'>
          )> }
        ) }
      ) }
    ) }
  ) }
);

export type RedeemPointsQueryVariables = {};


export type RedeemPointsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'totalPoints'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'rewardPointsRedemptionOption'>
      ), customRedeemProducts: Array<(
        { __typename?: 'RewardRedemptionOption' }
        & RewardRedemptionOptionFragment
      )>, tangoCards: Array<(
        { __typename?: 'RewardRedemptionOption' }
        & RewardRedemptionOptionFragment
      )> }
    ) }
  ) }
);

export type UserLocationsQueryQueryVariables = {};


export type UserLocationsQueryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city'>
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name' | 'city'>
    )> }
  ) }
);

export type ProfileMenuQueryVariables = {};


export type ProfileMenuQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'impersonated'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'city'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'dependentsEnabled' | 'guestsEnabled' | 'partnersEnabled'>
      ) }
    ) }
  ) }
);

export type CardLabelQueryVariables = {
  cardId: Scalars['Int']
};


export type CardLabelQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { card: (
      { __typename?: 'StripeCard' }
      & Pick<StripeCard, 'id' | 'label'>
    ) }
  ) }
);

export type UserAvatarQueryVariables = {};


export type UserAvatarQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'avatar'>
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'usersCanEditProfileImage'>
      ) }
    ) }
  ) }
);

export type ContactInfoQueryVariables = {};


export type ContactInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'city' | 'state' | 'zip' | 'phoneNumber' | 'workPhone' | 'contactEmail'>
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'usersCanEditAddress'>
      ) }
    ) }
  ) }
);

export type ProfileBasicInfoQueryVariables = {};


export type ProfileBasicInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'avatar' | 'firstName' | 'lastName'>
      & ProfileBasicInfoFragment
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'hideDob'>
      ) }
    ) }
  ) }
);

export type LockersQueryVariables = {};


export type LockersQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { lockers: Array<(
        { __typename?: 'Locker' }
        & LockerFragment
      )> }
    ) }
  ) }
);

export type LockerProrationQueryVariables = {
  lockerId: Scalars['Int']
};


export type LockerProrationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { locker: (
      { __typename?: 'Locker' }
      & Pick<Locker, 'id' | 'cancellationProration'>
    ) }
  ) }
);

export type UnreadMessagesQueryVariables = {};


export type UnreadMessagesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { unreadMessages: Array<(
      { __typename?: 'UserMessage' }
      & Pick<UserMessage, 'id' | 'message' | 'title'>
      & { admin: Maybe<(
        { __typename?: 'Admin' }
        & Pick<Admin, 'id' | 'fullName'>
      )> }
    )> }
  ) }
);

export type DialogComponentQueryVariables = {};


export type DialogComponentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'dialogComponent'>
);

export type DialogPropsQueryVariables = {};


export type DialogPropsQuery = (
  { __typename?: 'Query' }
  & { dialogProps: (
    { __typename?: 'DialogProps' }
    & Pick<DialogProps, 'id' | 'itemClass' | 'sessions' | 'videoUrl'>
    & { service: Maybe<(
      { __typename?: 'DialogPropsFitnessService' }
      & Pick<DialogPropsFitnessService, 'id' | 'title' | 'description' | 'price' | 'validFor' | 'askAvailability'>
    )>, event: Maybe<(
      { __typename?: 'DialogPropsEvent' }
      & Pick<DialogPropsEvent, 'id' | 'eventId' | 'title' | 'eventLocation' | 'details' | 'formattedPrice' | 'startTime' | 'endTime'>
      & { staff: Maybe<(
        { __typename?: 'Staff' }
        & Pick<Staff, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type TourQueryVariables = {};


export type TourQuery = (
  { __typename?: 'Query' }
  & { tour: (
    { __typename?: 'Tour' }
    & Pick<Tour, 'run' | 'stepIndex'>
  ) }
);

export type ProfileMenuOpenQueryVariables = {};


export type ProfileMenuOpenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'profileMenuOpen'>
);

export type VideoSearchQueryVariables = {};


export type VideoSearchQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'videoSearch'>
);

export type RefreshGfcTableQueryVariables = {};


export type RefreshGfcTableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'refreshGfcTable'>
);

export type UserBasicDetailsQueryVariables = {};


export type UserBasicDetailsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & ProfileBasicDetailsFragment
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
      & { config: (
        { __typename?: 'LocationConfiguration' }
        & Pick<LocationConfiguration, 'id' | 'hideDob'>
      ) }
    ) }
  ) }
);

export type EmergencyContactDetailsQueryVariables = {};


export type EmergencyContactDetailsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'emergencyFirstName' | 'emergencyMiddleName' | 'emergencyLastName' | 'emergencyRelationIndex' | 'emergencyPhone' | 'emergencyEmail' | 'emergencyAddress'>
    ) }
  ) }
);

export const BiometricDataFileFragmentDoc = gql`
    fragment biometricDataFile on BiometricDatum {
  id
  pdfFileName
  status
  createdAt
}
    `;
export const FitnessServiceFragmentDoc = gql`
    fragment fitnessService on FitnessService {
  id
  title
  description
  price
  staffSpecific
  validFor
  askAvailability
  staffs {
    id
    avatar
    name
  }
}
    `;
export const MembershipFragmentDoc = gql`
    fragment membership on Membership {
  id
  title
  description
  price
  billingCycle
  allowUserRenewal
  autoRenew
}
    `;
export const AppointmentBookingFragmentDoc = gql`
    fragment appointmentBooking on AppointmentBooking {
  id
  title
  eventLocation
  startTime
  endTime
  staff {
    id
    name
  }
  charge {
    price
  }
}
    `;
export const AppointmentFragmentDoc = gql`
    fragment appointment on Appointment {
  id
  title
  details
  eventLocation
  price
  formattedPrice
  startTimedate
  endTimedate
  startDate
  endDate
}
    `;
export const EventCalendarEntryFragmentDoc = gql`
    fragment eventCalendarEntry on EventCalendarEntry {
  id
  eventId
  title
  startTime
  endTime
  type
  eventLocation
  details
  formattedPrice
  staff {
    id
    name
  }
}
    `;
export const DependentFragmentDoc = gql`
    fragment dependent on Dependent {
  id
  firstName
  lastName
  dateOfBirth
  emergencyFirstName
  emergencyLastName
  emergencyPhone
  avatar
  age
  isAdult
  isOfAge
}
    `;
export const GuestFragmentDoc = gql`
    fragment guest on Guest {
  id
  firstName
  lastName
  emergencyFirstName
  emergencyLastName
  emergencyPhone
  emergencyRelation
}
    `;
export const EventRegistrationFragmentDoc = gql`
    fragment eventRegistration on EventRegistration {
  id
  title
  eventLocation
  startTime
  endTime
  charge {
    price
  }
}
    `;
export const AttendeeFragmentDoc = gql`
    fragment attendee on Attendee {
  id
  registrationId
  attendeeId
  attendeeType
  firstName
  lastName
  avatar
  status
}
    `;
export const GfcScheduleFragmentDoc = gql`
    fragment gfcSchedule on GfcSchedule {
  id
  title
  eventDate
  startDateTime
  endDateTime
  formattedTime
  instructor
  staffId
  classLocation
  spotsAvailable
  attending {
    ...attendee
  }
  isForChildDependents
  costForUser
  freeForUser
  gfc {
    id
    description
    price
  }
}
    ${AttendeeFragmentDoc}`;
export const GfcMutationUpdateFragmentDoc = gql`
    fragment gfcMutationUpdate on User {
  id
  unregisteredGfcSchedules {
    ...gfcSchedule
  }
  registeredClasses {
    id
    status
    gfcSchedule {
      ...gfcSchedule
    }
  }
  availableCreditsDisplay
}
    ${GfcScheduleFragmentDoc}`;
export const PartnerFragmentDoc = gql`
    fragment partner on User {
  id
  fullName
  avatar
}
    `;
export const InvitationFragmentDoc = gql`
    fragment invitation on Invitation {
  id
  email
}
    `;
export const PartnersFragmentDoc = gql`
    fragment partners on User {
  partner {
    ...partner
  }
  pendingPartner {
    ...partner
  }
  pendingPartnerRequestFrom {
    ...partner
  }
  invitation {
    ...invitation
  }
}
    ${PartnerFragmentDoc}
${InvitationFragmentDoc}`;
export const BewellFragmentDoc = gql`
    fragment bewell on Bewell {
  daysUntilStart
  showWidget
  startDate
  endDate
  backgroundUrl
  hasStarted
  hasEnded
  optedIn
  points
}
    `;
export const BewellItemFragmentDoc = gql`
    fragment bewellItem on BewellItem {
  id
  title
  description
  points
  perIntervalDisplay
  subtitle
  url
  urlLabel
  isCompletedToday
  isCompletedYesterday
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment subscription on StripeSubscription {
  id
  price
  name
  currentPeriodEnd
  cancelAtPeriodEnd
  allowUserRenewal
  payFrequency
}
    `;
export const CardFragmentDoc = gql`
    fragment card on StripeCard {
  id
  last4
  brand
  label
  isPrimary
}
    `;
export const CartItemFragmentDoc = gql`
    fragment cartItem on CartItem {
  id
  title
  price
  quantity
  isMembership
  staff {
    id
    name
  }
}
    `;
export const UserActivityFragmentDoc = gql`
    fragment userActivity on UserActivity {
  id
  name
  createdAt
  description
  createdByName
  createdByAvatar(size: 30)
}
    `;
export const ProfileBasicInfoFragmentDoc = gql`
    fragment profileBasicInfo on Profile {
  gender
  dateOfBirth
  height
  weight
  bloodType
  city
  state
  zip
  phoneNumber
  workPhone
  contactEmail
  emergencyFirstName
  emergencyMiddleName
  emergencyLastName
  emergencyRelation
  emergencyPhone
  emergencyEmail
  emergencyAddress
}
    `;
export const LockerFragmentDoc = gql`
    fragment locker on Locker {
  id
  name
  size
  status
  price
  frequency
}
    `;
export const ChecklistItemFragmentDoc = gql`
    fragment checklistItem on ChecklistItem {
  id
  label
  isCompleted
}
    `;
export const ChecklistFragmentDoc = gql`
    fragment checklist on Checklist {
  id
  label
  items {
    ...checklistItem
  }
}
    ${ChecklistItemFragmentDoc}`;
export const RewardRedemptionOptionFragmentDoc = gql`
    fragment rewardRedemptionOption on RewardRedemptionOption {
  id
  name
  description
  image
  pointsPerProduct
}
    `;
export const ProfileBasicDetailsFragmentDoc = gql`
    fragment profileBasicDetails on Profile {
  id
  firstName
  lastName
  dateOfBirth
  gender
  height
  weight
  bloodType
}
    `;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($subscriptionId: Int!) {
  cancelSubscription(subscriptionId: $subscriptionId) {
    subscription {
      id
      cancelAtPeriodEnd
    }
    message
  }
}
    `;
export type CancelSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export type CancelSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>, 'mutation'>;

    export const CancelSubscriptionComponent = (props: CancelSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables> mutation={CancelSubscriptionDocument} {...props} />
    );
    

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, baseOptions);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const RenewSubscriptionDocument = gql`
    mutation renewSubscription($subscriptionId: Int!) {
  renewSubscription(subscriptionId: $subscriptionId) {
    subscription {
      id
      cancelAtPeriodEnd
    }
    message
  }
}
    `;
export type RenewSubscriptionMutationFn = ApolloReactCommon.MutationFunction<RenewSubscriptionMutation, RenewSubscriptionMutationVariables>;
export type RenewSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenewSubscriptionMutation, RenewSubscriptionMutationVariables>, 'mutation'>;

    export const RenewSubscriptionComponent = (props: RenewSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<RenewSubscriptionMutation, RenewSubscriptionMutationVariables> mutation={RenewSubscriptionDocument} {...props} />
    );
    

/**
 * __useRenewSubscriptionMutation__
 *
 * To run a mutation, you first call `useRenewSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewSubscriptionMutation, { data, loading, error }] = useRenewSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useRenewSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenewSubscriptionMutation, RenewSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RenewSubscriptionMutation, RenewSubscriptionMutationVariables>(RenewSubscriptionDocument, baseOptions);
      }
export type RenewSubscriptionMutationHookResult = ReturnType<typeof useRenewSubscriptionMutation>;
export type RenewSubscriptionMutationResult = ApolloReactCommon.MutationResult<RenewSubscriptionMutation>;
export type RenewSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RenewSubscriptionMutation, RenewSubscriptionMutationVariables>;
export const AddCreditCardDocument = gql`
    mutation addCreditCard($tokenId: String!, $asPrimary: Boolean!) {
  addCreditCard(tokenId: $tokenId, asPrimary: $asPrimary) {
    viewer {
      id
      needsToAddCard
      lapsedMembership
    }
    message
  }
}
    `;
export type AddCreditCardMutationFn = ApolloReactCommon.MutationFunction<AddCreditCardMutation, AddCreditCardMutationVariables>;
export type AddCreditCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddCreditCardMutation, AddCreditCardMutationVariables>, 'mutation'>;

    export const AddCreditCardComponent = (props: AddCreditCardComponentProps) => (
      <ApolloReactComponents.Mutation<AddCreditCardMutation, AddCreditCardMutationVariables> mutation={AddCreditCardDocument} {...props} />
    );
    

/**
 * __useAddCreditCardMutation__
 *
 * To run a mutation, you first call `useAddCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditCardMutation, { data, loading, error }] = useAddCreditCardMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      asPrimary: // value for 'asPrimary'
 *   },
 * });
 */
export function useAddCreditCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCreditCardMutation, AddCreditCardMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCreditCardMutation, AddCreditCardMutationVariables>(AddCreditCardDocument, baseOptions);
      }
export type AddCreditCardMutationHookResult = ReturnType<typeof useAddCreditCardMutation>;
export type AddCreditCardMutationResult = ApolloReactCommon.MutationResult<AddCreditCardMutation>;
export type AddCreditCardMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCreditCardMutation, AddCreditCardMutationVariables>;
export const RemoveCreditCardDocument = gql`
    mutation removeCreditCard($cardId: Int!) {
  removeCreditCard(cardId: $cardId) {
    viewer {
      id
      cards {
        ...card
      }
    }
    message
  }
}
    ${CardFragmentDoc}`;
export type RemoveCreditCardMutationFn = ApolloReactCommon.MutationFunction<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>;
export type RemoveCreditCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>, 'mutation'>;

    export const RemoveCreditCardComponent = (props: RemoveCreditCardComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveCreditCardMutation, RemoveCreditCardMutationVariables> mutation={RemoveCreditCardDocument} {...props} />
    );
    

/**
 * __useRemoveCreditCardMutation__
 *
 * To run a mutation, you first call `useRemoveCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCreditCardMutation, { data, loading, error }] = useRemoveCreditCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useRemoveCreditCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>(RemoveCreditCardDocument, baseOptions);
      }
export type RemoveCreditCardMutationHookResult = ReturnType<typeof useRemoveCreditCardMutation>;
export type RemoveCreditCardMutationResult = ApolloReactCommon.MutationResult<RemoveCreditCardMutation>;
export type RemoveCreditCardMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCreditCardMutation, RemoveCreditCardMutationVariables>;
export const SetPrimaryCardDocument = gql`
    mutation setPrimaryCard($cardId: Int!) {
  setPrimaryCard(cardId: $cardId) {
    viewer {
      id
      cards {
        ...card
      }
    }
    message
  }
}
    ${CardFragmentDoc}`;
export type SetPrimaryCardMutationFn = ApolloReactCommon.MutationFunction<SetPrimaryCardMutation, SetPrimaryCardMutationVariables>;
export type SetPrimaryCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetPrimaryCardMutation, SetPrimaryCardMutationVariables>, 'mutation'>;

    export const SetPrimaryCardComponent = (props: SetPrimaryCardComponentProps) => (
      <ApolloReactComponents.Mutation<SetPrimaryCardMutation, SetPrimaryCardMutationVariables> mutation={SetPrimaryCardDocument} {...props} />
    );
    

/**
 * __useSetPrimaryCardMutation__
 *
 * To run a mutation, you first call `useSetPrimaryCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryCardMutation, { data, loading, error }] = useSetPrimaryCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useSetPrimaryCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPrimaryCardMutation, SetPrimaryCardMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPrimaryCardMutation, SetPrimaryCardMutationVariables>(SetPrimaryCardDocument, baseOptions);
      }
export type SetPrimaryCardMutationHookResult = ReturnType<typeof useSetPrimaryCardMutation>;
export type SetPrimaryCardMutationResult = ApolloReactCommon.MutationResult<SetPrimaryCardMutation>;
export type SetPrimaryCardMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPrimaryCardMutation, SetPrimaryCardMutationVariables>;
export const RenameCreditCardDocument = gql`
    mutation renameCreditCard($cardId: Int!, $label: String!) {
  renameCreditCard(cardId: $cardId, label: $label) {
    viewer {
      id
      cards {
        ...card
      }
    }
    message
  }
}
    ${CardFragmentDoc}`;
export type RenameCreditCardMutationFn = ApolloReactCommon.MutationFunction<RenameCreditCardMutation, RenameCreditCardMutationVariables>;
export type RenameCreditCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameCreditCardMutation, RenameCreditCardMutationVariables>, 'mutation'>;

    export const RenameCreditCardComponent = (props: RenameCreditCardComponentProps) => (
      <ApolloReactComponents.Mutation<RenameCreditCardMutation, RenameCreditCardMutationVariables> mutation={RenameCreditCardDocument} {...props} />
    );
    

/**
 * __useRenameCreditCardMutation__
 *
 * To run a mutation, you first call `useRenameCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameCreditCardMutation, { data, loading, error }] = useRenameCreditCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useRenameCreditCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameCreditCardMutation, RenameCreditCardMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameCreditCardMutation, RenameCreditCardMutationVariables>(RenameCreditCardDocument, baseOptions);
      }
export type RenameCreditCardMutationHookResult = ReturnType<typeof useRenameCreditCardMutation>;
export type RenameCreditCardMutationResult = ApolloReactCommon.MutationResult<RenameCreditCardMutation>;
export type RenameCreditCardMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameCreditCardMutation, RenameCreditCardMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  changePassword(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
    message
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const JoinBewellDocument = gql`
    mutation joinBewell {
  joinBewell {
    viewer {
      id
      bewell {
        optedIn
      }
    }
    message
  }
}
    `;
export type JoinBewellMutationFn = ApolloReactCommon.MutationFunction<JoinBewellMutation, JoinBewellMutationVariables>;
export type JoinBewellComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<JoinBewellMutation, JoinBewellMutationVariables>, 'mutation'>;

    export const JoinBewellComponent = (props: JoinBewellComponentProps) => (
      <ApolloReactComponents.Mutation<JoinBewellMutation, JoinBewellMutationVariables> mutation={JoinBewellDocument} {...props} />
    );
    

/**
 * __useJoinBewellMutation__
 *
 * To run a mutation, you first call `useJoinBewellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinBewellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinBewellMutation, { data, loading, error }] = useJoinBewellMutation({
 *   variables: {
 *   },
 * });
 */
export function useJoinBewellMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinBewellMutation, JoinBewellMutationVariables>) {
        return ApolloReactHooks.useMutation<JoinBewellMutation, JoinBewellMutationVariables>(JoinBewellDocument, baseOptions);
      }
export type JoinBewellMutationHookResult = ReturnType<typeof useJoinBewellMutation>;
export type JoinBewellMutationResult = ApolloReactCommon.MutationResult<JoinBewellMutation>;
export type JoinBewellMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinBewellMutation, JoinBewellMutationVariables>;
export const ToggleBewellItemCompleteDocument = gql`
    mutation toggleBewellItemComplete($id: Int!, $isToday: Boolean!, $complete: Boolean!) {
  toggleBewellItemComplete(id: $id, isToday: $isToday, complete: $complete) {
    bewellItem {
      id
      isCompletedToday
      isCompletedYesterday
      bewellCategory {
        id
        isStoneEarned
      }
    }
    viewer {
      id
      bewell {
        points
      }
    }
    message
  }
}
    `;
export type ToggleBewellItemCompleteMutationFn = ApolloReactCommon.MutationFunction<ToggleBewellItemCompleteMutation, ToggleBewellItemCompleteMutationVariables>;
export type ToggleBewellItemCompleteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ToggleBewellItemCompleteMutation, ToggleBewellItemCompleteMutationVariables>, 'mutation'>;

    export const ToggleBewellItemCompleteComponent = (props: ToggleBewellItemCompleteComponentProps) => (
      <ApolloReactComponents.Mutation<ToggleBewellItemCompleteMutation, ToggleBewellItemCompleteMutationVariables> mutation={ToggleBewellItemCompleteDocument} {...props} />
    );
    

/**
 * __useToggleBewellItemCompleteMutation__
 *
 * To run a mutation, you first call `useToggleBewellItemCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBewellItemCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBewellItemCompleteMutation, { data, loading, error }] = useToggleBewellItemCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isToday: // value for 'isToday'
 *      complete: // value for 'complete'
 *   },
 * });
 */
export function useToggleBewellItemCompleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleBewellItemCompleteMutation, ToggleBewellItemCompleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleBewellItemCompleteMutation, ToggleBewellItemCompleteMutationVariables>(ToggleBewellItemCompleteDocument, baseOptions);
      }
export type ToggleBewellItemCompleteMutationHookResult = ReturnType<typeof useToggleBewellItemCompleteMutation>;
export type ToggleBewellItemCompleteMutationResult = ApolloReactCommon.MutationResult<ToggleBewellItemCompleteMutation>;
export type ToggleBewellItemCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleBewellItemCompleteMutation, ToggleBewellItemCompleteMutationVariables>;
export const UploadBiometricDataDocument = gql`
    mutation uploadBiometricData($file: Upload!) {
  uploadBiometricData(file: $file) {
    viewer {
      id
      biometricDataFiles {
        ...biometricDataFile
      }
    }
    message
  }
}
    ${BiometricDataFileFragmentDoc}`;
export type UploadBiometricDataMutationFn = ApolloReactCommon.MutationFunction<UploadBiometricDataMutation, UploadBiometricDataMutationVariables>;
export type UploadBiometricDataComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadBiometricDataMutation, UploadBiometricDataMutationVariables>, 'mutation'>;

    export const UploadBiometricDataComponent = (props: UploadBiometricDataComponentProps) => (
      <ApolloReactComponents.Mutation<UploadBiometricDataMutation, UploadBiometricDataMutationVariables> mutation={UploadBiometricDataDocument} {...props} />
    );
    

/**
 * __useUploadBiometricDataMutation__
 *
 * To run a mutation, you first call `useUploadBiometricDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBiometricDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBiometricDataMutation, { data, loading, error }] = useUploadBiometricDataMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadBiometricDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadBiometricDataMutation, UploadBiometricDataMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadBiometricDataMutation, UploadBiometricDataMutationVariables>(UploadBiometricDataDocument, baseOptions);
      }
export type UploadBiometricDataMutationHookResult = ReturnType<typeof useUploadBiometricDataMutation>;
export type UploadBiometricDataMutationResult = ApolloReactCommon.MutationResult<UploadBiometricDataMutation>;
export type UploadBiometricDataMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadBiometricDataMutation, UploadBiometricDataMutationVariables>;
export const AddDependentDocument = gql`
    mutation addDependent($dependentParams: DependentInput!, $agreeToTerms: Boolean) {
  addDependent(dependentParams: $dependentParams, agreeToTerms: $agreeToTerms) {
    viewer {
      id
      dependents {
        ...dependent
      }
    }
    message
  }
}
    ${DependentFragmentDoc}`;
export type AddDependentMutationFn = ApolloReactCommon.MutationFunction<AddDependentMutation, AddDependentMutationVariables>;
export type AddDependentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddDependentMutation, AddDependentMutationVariables>, 'mutation'>;

    export const AddDependentComponent = (props: AddDependentComponentProps) => (
      <ApolloReactComponents.Mutation<AddDependentMutation, AddDependentMutationVariables> mutation={AddDependentDocument} {...props} />
    );
    

/**
 * __useAddDependentMutation__
 *
 * To run a mutation, you first call `useAddDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDependentMutation, { data, loading, error }] = useAddDependentMutation({
 *   variables: {
 *      dependentParams: // value for 'dependentParams'
 *      agreeToTerms: // value for 'agreeToTerms'
 *   },
 * });
 */
export function useAddDependentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDependentMutation, AddDependentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDependentMutation, AddDependentMutationVariables>(AddDependentDocument, baseOptions);
      }
export type AddDependentMutationHookResult = ReturnType<typeof useAddDependentMutation>;
export type AddDependentMutationResult = ApolloReactCommon.MutationResult<AddDependentMutation>;
export type AddDependentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDependentMutation, AddDependentMutationVariables>;
export const EditDependentDocument = gql`
    mutation editDependent($dependentParams: DependentInput!, $id: Int!) {
  editDependent(dependentParams: $dependentParams, id: $id) {
    dependent {
      ...dependent
    }
    message
  }
}
    ${DependentFragmentDoc}`;
export type EditDependentMutationFn = ApolloReactCommon.MutationFunction<EditDependentMutation, EditDependentMutationVariables>;
export type EditDependentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditDependentMutation, EditDependentMutationVariables>, 'mutation'>;

    export const EditDependentComponent = (props: EditDependentComponentProps) => (
      <ApolloReactComponents.Mutation<EditDependentMutation, EditDependentMutationVariables> mutation={EditDependentDocument} {...props} />
    );
    

/**
 * __useEditDependentMutation__
 *
 * To run a mutation, you first call `useEditDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDependentMutation, { data, loading, error }] = useEditDependentMutation({
 *   variables: {
 *      dependentParams: // value for 'dependentParams'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditDependentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDependentMutation, EditDependentMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDependentMutation, EditDependentMutationVariables>(EditDependentDocument, baseOptions);
      }
export type EditDependentMutationHookResult = ReturnType<typeof useEditDependentMutation>;
export type EditDependentMutationResult = ApolloReactCommon.MutationResult<EditDependentMutation>;
export type EditDependentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDependentMutation, EditDependentMutationVariables>;
export const RemoveDependentDocument = gql`
    mutation removeDependent($id: Int!) {
  removeDependent(id: $id) {
    viewer {
      id
      dependents {
        ...dependent
      }
    }
    message
  }
}
    ${DependentFragmentDoc}`;
export type RemoveDependentMutationFn = ApolloReactCommon.MutationFunction<RemoveDependentMutation, RemoveDependentMutationVariables>;
export type RemoveDependentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveDependentMutation, RemoveDependentMutationVariables>, 'mutation'>;

    export const RemoveDependentComponent = (props: RemoveDependentComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveDependentMutation, RemoveDependentMutationVariables> mutation={RemoveDependentDocument} {...props} />
    );
    

/**
 * __useRemoveDependentMutation__
 *
 * To run a mutation, you first call `useRemoveDependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDependentMutation, { data, loading, error }] = useRemoveDependentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDependentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDependentMutation, RemoveDependentMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveDependentMutation, RemoveDependentMutationVariables>(RemoveDependentDocument, baseOptions);
      }
export type RemoveDependentMutationHookResult = ReturnType<typeof useRemoveDependentMutation>;
export type RemoveDependentMutationResult = ApolloReactCommon.MutationResult<RemoveDependentMutation>;
export type RemoveDependentMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDependentMutation, RemoveDependentMutationVariables>;
export const InvitePartnerDocument = gql`
    mutation invitePartner($email: String!, $external: Boolean!) {
  invitePartner(email: $email, external: $external) {
    viewer {
      id
      pendingPartner {
        ...partner
      }
      partner {
        ...partner
      }
      invitation {
        ...invitation
      }
    }
    message
  }
}
    ${PartnerFragmentDoc}
${InvitationFragmentDoc}`;
export type InvitePartnerMutationFn = ApolloReactCommon.MutationFunction<InvitePartnerMutation, InvitePartnerMutationVariables>;
export type InvitePartnerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InvitePartnerMutation, InvitePartnerMutationVariables>, 'mutation'>;

    export const InvitePartnerComponent = (props: InvitePartnerComponentProps) => (
      <ApolloReactComponents.Mutation<InvitePartnerMutation, InvitePartnerMutationVariables> mutation={InvitePartnerDocument} {...props} />
    );
    

/**
 * __useInvitePartnerMutation__
 *
 * To run a mutation, you first call `useInvitePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitePartnerMutation, { data, loading, error }] = useInvitePartnerMutation({
 *   variables: {
 *      email: // value for 'email'
 *      external: // value for 'external'
 *   },
 * });
 */
export function useInvitePartnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvitePartnerMutation, InvitePartnerMutationVariables>) {
        return ApolloReactHooks.useMutation<InvitePartnerMutation, InvitePartnerMutationVariables>(InvitePartnerDocument, baseOptions);
      }
export type InvitePartnerMutationHookResult = ReturnType<typeof useInvitePartnerMutation>;
export type InvitePartnerMutationResult = ApolloReactCommon.MutationResult<InvitePartnerMutation>;
export type InvitePartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<InvitePartnerMutation, InvitePartnerMutationVariables>;
export const RemovePartnershipDocument = gql`
    mutation removePartnership {
  removePartnership {
    viewer {
      id
      ...partners
    }
    message
  }
}
    ${PartnersFragmentDoc}`;
export type RemovePartnershipMutationFn = ApolloReactCommon.MutationFunction<RemovePartnershipMutation, RemovePartnershipMutationVariables>;
export type RemovePartnershipComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemovePartnershipMutation, RemovePartnershipMutationVariables>, 'mutation'>;

    export const RemovePartnershipComponent = (props: RemovePartnershipComponentProps) => (
      <ApolloReactComponents.Mutation<RemovePartnershipMutation, RemovePartnershipMutationVariables> mutation={RemovePartnershipDocument} {...props} />
    );
    

/**
 * __useRemovePartnershipMutation__
 *
 * To run a mutation, you first call `useRemovePartnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnershipMutation, { data, loading, error }] = useRemovePartnershipMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemovePartnershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePartnershipMutation, RemovePartnershipMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePartnershipMutation, RemovePartnershipMutationVariables>(RemovePartnershipDocument, baseOptions);
      }
export type RemovePartnershipMutationHookResult = ReturnType<typeof useRemovePartnershipMutation>;
export type RemovePartnershipMutationResult = ApolloReactCommon.MutationResult<RemovePartnershipMutation>;
export type RemovePartnershipMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePartnershipMutation, RemovePartnershipMutationVariables>;
export const RespondToPartnershipDocument = gql`
    mutation respondToPartnership($accept: Boolean!) {
  respondToPartnership(accept: $accept) {
    viewer {
      id
      ...partners
    }
    message
  }
}
    ${PartnersFragmentDoc}`;
export type RespondToPartnershipMutationFn = ApolloReactCommon.MutationFunction<RespondToPartnershipMutation, RespondToPartnershipMutationVariables>;
export type RespondToPartnershipComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RespondToPartnershipMutation, RespondToPartnershipMutationVariables>, 'mutation'>;

    export const RespondToPartnershipComponent = (props: RespondToPartnershipComponentProps) => (
      <ApolloReactComponents.Mutation<RespondToPartnershipMutation, RespondToPartnershipMutationVariables> mutation={RespondToPartnershipDocument} {...props} />
    );
    

/**
 * __useRespondToPartnershipMutation__
 *
 * To run a mutation, you first call `useRespondToPartnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToPartnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToPartnershipMutation, { data, loading, error }] = useRespondToPartnershipMutation({
 *   variables: {
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useRespondToPartnershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondToPartnershipMutation, RespondToPartnershipMutationVariables>) {
        return ApolloReactHooks.useMutation<RespondToPartnershipMutation, RespondToPartnershipMutationVariables>(RespondToPartnershipDocument, baseOptions);
      }
export type RespondToPartnershipMutationHookResult = ReturnType<typeof useRespondToPartnershipMutation>;
export type RespondToPartnershipMutationResult = ApolloReactCommon.MutationResult<RespondToPartnershipMutation>;
export type RespondToPartnershipMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondToPartnershipMutation, RespondToPartnershipMutationVariables>;
export const AddGuestDocument = gql`
    mutation addGuest($guestParams: GuestInput!) {
  addGuest(guestParams: $guestParams) {
    viewer {
      id
      guests {
        ...guest
      }
    }
    message
  }
}
    ${GuestFragmentDoc}`;
export type AddGuestMutationFn = ApolloReactCommon.MutationFunction<AddGuestMutation, AddGuestMutationVariables>;
export type AddGuestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddGuestMutation, AddGuestMutationVariables>, 'mutation'>;

    export const AddGuestComponent = (props: AddGuestComponentProps) => (
      <ApolloReactComponents.Mutation<AddGuestMutation, AddGuestMutationVariables> mutation={AddGuestDocument} {...props} />
    );
    

/**
 * __useAddGuestMutation__
 *
 * To run a mutation, you first call `useAddGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGuestMutation, { data, loading, error }] = useAddGuestMutation({
 *   variables: {
 *      guestParams: // value for 'guestParams'
 *   },
 * });
 */
export function useAddGuestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddGuestMutation, AddGuestMutationVariables>) {
        return ApolloReactHooks.useMutation<AddGuestMutation, AddGuestMutationVariables>(AddGuestDocument, baseOptions);
      }
export type AddGuestMutationHookResult = ReturnType<typeof useAddGuestMutation>;
export type AddGuestMutationResult = ApolloReactCommon.MutationResult<AddGuestMutation>;
export type AddGuestMutationOptions = ApolloReactCommon.BaseMutationOptions<AddGuestMutation, AddGuestMutationVariables>;
export const EditGuestDocument = gql`
    mutation editGuest($guestParams: GuestInput!, $id: Int!) {
  editGuest(guestParams: $guestParams, id: $id) {
    guest {
      ...guest
    }
    message
  }
}
    ${GuestFragmentDoc}`;
export type EditGuestMutationFn = ApolloReactCommon.MutationFunction<EditGuestMutation, EditGuestMutationVariables>;
export type EditGuestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditGuestMutation, EditGuestMutationVariables>, 'mutation'>;

    export const EditGuestComponent = (props: EditGuestComponentProps) => (
      <ApolloReactComponents.Mutation<EditGuestMutation, EditGuestMutationVariables> mutation={EditGuestDocument} {...props} />
    );
    

/**
 * __useEditGuestMutation__
 *
 * To run a mutation, you first call `useEditGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGuestMutation, { data, loading, error }] = useEditGuestMutation({
 *   variables: {
 *      guestParams: // value for 'guestParams'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditGuestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGuestMutation, EditGuestMutationVariables>) {
        return ApolloReactHooks.useMutation<EditGuestMutation, EditGuestMutationVariables>(EditGuestDocument, baseOptions);
      }
export type EditGuestMutationHookResult = ReturnType<typeof useEditGuestMutation>;
export type EditGuestMutationResult = ApolloReactCommon.MutationResult<EditGuestMutation>;
export type EditGuestMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGuestMutation, EditGuestMutationVariables>;
export const RemoveGuestDocument = gql`
    mutation removeGuest($id: Int!) {
  removeGuest(id: $id) {
    viewer {
      id
      guests {
        ...guest
      }
    }
    message
  }
}
    ${GuestFragmentDoc}`;
export type RemoveGuestMutationFn = ApolloReactCommon.MutationFunction<RemoveGuestMutation, RemoveGuestMutationVariables>;
export type RemoveGuestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveGuestMutation, RemoveGuestMutationVariables>, 'mutation'>;

    export const RemoveGuestComponent = (props: RemoveGuestComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveGuestMutation, RemoveGuestMutationVariables> mutation={RemoveGuestDocument} {...props} />
    );
    

/**
 * __useRemoveGuestMutation__
 *
 * To run a mutation, you first call `useRemoveGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGuestMutation, { data, loading, error }] = useRemoveGuestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGuestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveGuestMutation, RemoveGuestMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveGuestMutation, RemoveGuestMutationVariables>(RemoveGuestDocument, baseOptions);
      }
export type RemoveGuestMutationHookResult = ReturnType<typeof useRemoveGuestMutation>;
export type RemoveGuestMutationResult = ApolloReactCommon.MutationResult<RemoveGuestMutation>;
export type RemoveGuestMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveGuestMutation, RemoveGuestMutationVariables>;
export const RegisterToGfcDocument = gql`
    mutation registerToGfc($gfcScheduleId: Int!, $attendeeId: Int!, $attendeeType: String!) {
  registerToGfc(gfcScheduleId: $gfcScheduleId, attendeeId: $attendeeId, attendeeType: $attendeeType) {
    viewer {
      ...gfcMutationUpdate
      userActivities {
        ...userActivity
      }
    }
    message
  }
}
    ${GfcMutationUpdateFragmentDoc}
${UserActivityFragmentDoc}`;
export type RegisterToGfcMutationFn = ApolloReactCommon.MutationFunction<RegisterToGfcMutation, RegisterToGfcMutationVariables>;
export type RegisterToGfcComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterToGfcMutation, RegisterToGfcMutationVariables>, 'mutation'>;

    export const RegisterToGfcComponent = (props: RegisterToGfcComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterToGfcMutation, RegisterToGfcMutationVariables> mutation={RegisterToGfcDocument} {...props} />
    );
    

/**
 * __useRegisterToGfcMutation__
 *
 * To run a mutation, you first call `useRegisterToGfcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterToGfcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerToGfcMutation, { data, loading, error }] = useRegisterToGfcMutation({
 *   variables: {
 *      gfcScheduleId: // value for 'gfcScheduleId'
 *      attendeeId: // value for 'attendeeId'
 *      attendeeType: // value for 'attendeeType'
 *   },
 * });
 */
export function useRegisterToGfcMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterToGfcMutation, RegisterToGfcMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterToGfcMutation, RegisterToGfcMutationVariables>(RegisterToGfcDocument, baseOptions);
      }
export type RegisterToGfcMutationHookResult = ReturnType<typeof useRegisterToGfcMutation>;
export type RegisterToGfcMutationResult = ApolloReactCommon.MutationResult<RegisterToGfcMutation>;
export type RegisterToGfcMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterToGfcMutation, RegisterToGfcMutationVariables>;
export const CancelGfcRegistrationDocument = gql`
    mutation cancelGfcRegistration($registrationId: Int!) {
  cancelGfcRegistration(registrationId: $registrationId) {
    viewer {
      ...gfcMutationUpdate
      userActivities {
        ...userActivity
      }
    }
    message
  }
}
    ${GfcMutationUpdateFragmentDoc}
${UserActivityFragmentDoc}`;
export type CancelGfcRegistrationMutationFn = ApolloReactCommon.MutationFunction<CancelGfcRegistrationMutation, CancelGfcRegistrationMutationVariables>;
export type CancelGfcRegistrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelGfcRegistrationMutation, CancelGfcRegistrationMutationVariables>, 'mutation'>;

    export const CancelGfcRegistrationComponent = (props: CancelGfcRegistrationComponentProps) => (
      <ApolloReactComponents.Mutation<CancelGfcRegistrationMutation, CancelGfcRegistrationMutationVariables> mutation={CancelGfcRegistrationDocument} {...props} />
    );
    

/**
 * __useCancelGfcRegistrationMutation__
 *
 * To run a mutation, you first call `useCancelGfcRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGfcRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGfcRegistrationMutation, { data, loading, error }] = useCancelGfcRegistrationMutation({
 *   variables: {
 *      registrationId: // value for 'registrationId'
 *   },
 * });
 */
export function useCancelGfcRegistrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelGfcRegistrationMutation, CancelGfcRegistrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelGfcRegistrationMutation, CancelGfcRegistrationMutationVariables>(CancelGfcRegistrationDocument, baseOptions);
      }
export type CancelGfcRegistrationMutationHookResult = ReturnType<typeof useCancelGfcRegistrationMutation>;
export type CancelGfcRegistrationMutationResult = ApolloReactCommon.MutationResult<CancelGfcRegistrationMutation>;
export type CancelGfcRegistrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelGfcRegistrationMutation, CancelGfcRegistrationMutationVariables>;
export const ClaimGfcSpotDocument = gql`
    mutation claimGfcSpot($registrationId: Int!) {
  claimGfcSpot(registrationId: $registrationId) {
    viewer {
      ...gfcMutationUpdate
    }
    message
  }
}
    ${GfcMutationUpdateFragmentDoc}`;
export type ClaimGfcSpotMutationFn = ApolloReactCommon.MutationFunction<ClaimGfcSpotMutation, ClaimGfcSpotMutationVariables>;
export type ClaimGfcSpotComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ClaimGfcSpotMutation, ClaimGfcSpotMutationVariables>, 'mutation'>;

    export const ClaimGfcSpotComponent = (props: ClaimGfcSpotComponentProps) => (
      <ApolloReactComponents.Mutation<ClaimGfcSpotMutation, ClaimGfcSpotMutationVariables> mutation={ClaimGfcSpotDocument} {...props} />
    );
    

/**
 * __useClaimGfcSpotMutation__
 *
 * To run a mutation, you first call `useClaimGfcSpotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimGfcSpotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimGfcSpotMutation, { data, loading, error }] = useClaimGfcSpotMutation({
 *   variables: {
 *      registrationId: // value for 'registrationId'
 *   },
 * });
 */
export function useClaimGfcSpotMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimGfcSpotMutation, ClaimGfcSpotMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimGfcSpotMutation, ClaimGfcSpotMutationVariables>(ClaimGfcSpotDocument, baseOptions);
      }
export type ClaimGfcSpotMutationHookResult = ReturnType<typeof useClaimGfcSpotMutation>;
export type ClaimGfcSpotMutationResult = ApolloReactCommon.MutationResult<ClaimGfcSpotMutation>;
export type ClaimGfcSpotMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimGfcSpotMutation, ClaimGfcSpotMutationVariables>;
export const GfcTableDataDocument = gql`
    mutation gfcTableData($pageSize: Int!, $page: Int!, $filters: GfcFiltersInput!) {
  gfcTableData(page: $page, pageSize: $pageSize, filters: $filters) {
    gfcSchedules {
      ...gfcSchedule
    }
    totalCount
  }
}
    ${GfcScheduleFragmentDoc}`;
export type GfcTableDataMutationFn = ApolloReactCommon.MutationFunction<GfcTableDataMutation, GfcTableDataMutationVariables>;
export type GfcTableDataComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GfcTableDataMutation, GfcTableDataMutationVariables>, 'mutation'>;

    export const GfcTableDataComponent = (props: GfcTableDataComponentProps) => (
      <ApolloReactComponents.Mutation<GfcTableDataMutation, GfcTableDataMutationVariables> mutation={GfcTableDataDocument} {...props} />
    );
    

/**
 * __useGfcTableDataMutation__
 *
 * To run a mutation, you first call `useGfcTableDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGfcTableDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gfcTableDataMutation, { data, loading, error }] = useGfcTableDataMutation({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGfcTableDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GfcTableDataMutation, GfcTableDataMutationVariables>) {
        return ApolloReactHooks.useMutation<GfcTableDataMutation, GfcTableDataMutationVariables>(GfcTableDataDocument, baseOptions);
      }
export type GfcTableDataMutationHookResult = ReturnType<typeof useGfcTableDataMutation>;
export type GfcTableDataMutationResult = ApolloReactCommon.MutationResult<GfcTableDataMutation>;
export type GfcTableDataMutationOptions = ApolloReactCommon.BaseMutationOptions<GfcTableDataMutation, GfcTableDataMutationVariables>;
export const LogoutV2UserDocument = gql`
    mutation logoutV2User {
  logoutV2User {
    success
  }
}
    `;
export type LogoutV2UserMutationFn = ApolloReactCommon.MutationFunction<LogoutV2UserMutation, LogoutV2UserMutationVariables>;
export type LogoutV2UserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LogoutV2UserMutation, LogoutV2UserMutationVariables>, 'mutation'>;

    export const LogoutV2UserComponent = (props: LogoutV2UserComponentProps) => (
      <ApolloReactComponents.Mutation<LogoutV2UserMutation, LogoutV2UserMutationVariables> mutation={LogoutV2UserDocument} {...props} />
    );
    

/**
 * __useLogoutV2UserMutation__
 *
 * To run a mutation, you first call `useLogoutV2UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutV2UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutV2UserMutation, { data, loading, error }] = useLogoutV2UserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutV2UserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutV2UserMutation, LogoutV2UserMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutV2UserMutation, LogoutV2UserMutationVariables>(LogoutV2UserDocument, baseOptions);
      }
export type LogoutV2UserMutationHookResult = ReturnType<typeof useLogoutV2UserMutation>;
export type LogoutV2UserMutationResult = ApolloReactCommon.MutationResult<LogoutV2UserMutation>;
export type LogoutV2UserMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutV2UserMutation, LogoutV2UserMutationVariables>;
export const EditUserAvatarDocument = gql`
    mutation editUserAvatar($profile: ProfileInput) {
  editUser(profile: $profile) {
    viewer {
      id
      profile {
        id
        avatar(size: 100)
      }
    }
  }
}
    `;
export type EditUserAvatarMutationFn = ApolloReactCommon.MutationFunction<EditUserAvatarMutation, EditUserAvatarMutationVariables>;
export type EditUserAvatarComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserAvatarMutation, EditUserAvatarMutationVariables>, 'mutation'>;

    export const EditUserAvatarComponent = (props: EditUserAvatarComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserAvatarMutation, EditUserAvatarMutationVariables> mutation={EditUserAvatarDocument} {...props} />
    );
    

/**
 * __useEditUserAvatarMutation__
 *
 * To run a mutation, you first call `useEditUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserAvatarMutation, { data, loading, error }] = useEditUserAvatarMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useEditUserAvatarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserAvatarMutation, EditUserAvatarMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserAvatarMutation, EditUserAvatarMutationVariables>(EditUserAvatarDocument, baseOptions);
      }
export type EditUserAvatarMutationHookResult = ReturnType<typeof useEditUserAvatarMutation>;
export type EditUserAvatarMutationResult = ApolloReactCommon.MutationResult<EditUserAvatarMutation>;
export type EditUserAvatarMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserAvatarMutation, EditUserAvatarMutationVariables>;
export const EditUserContactInfoDocument = gql`
    mutation editUserContactInfo($user: UserInput, $profile: ProfileInput) {
  editUser(user: $user, profile: $profile) {
    viewer {
      id
      profile {
        id
        city
        state
        zip
        phoneNumber
        workPhone
        contactEmail
      }
    }
  }
}
    `;
export type EditUserContactInfoMutationFn = ApolloReactCommon.MutationFunction<EditUserContactInfoMutation, EditUserContactInfoMutationVariables>;
export type EditUserContactInfoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserContactInfoMutation, EditUserContactInfoMutationVariables>, 'mutation'>;

    export const EditUserContactInfoComponent = (props: EditUserContactInfoComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserContactInfoMutation, EditUserContactInfoMutationVariables> mutation={EditUserContactInfoDocument} {...props} />
    );
    

/**
 * __useEditUserContactInfoMutation__
 *
 * To run a mutation, you first call `useEditUserContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserContactInfoMutation, { data, loading, error }] = useEditUserContactInfoMutation({
 *   variables: {
 *      user: // value for 'user'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useEditUserContactInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserContactInfoMutation, EditUserContactInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserContactInfoMutation, EditUserContactInfoMutationVariables>(EditUserContactInfoDocument, baseOptions);
      }
export type EditUserContactInfoMutationHookResult = ReturnType<typeof useEditUserContactInfoMutation>;
export type EditUserContactInfoMutationResult = ApolloReactCommon.MutationResult<EditUserContactInfoMutation>;
export type EditUserContactInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserContactInfoMutation, EditUserContactInfoMutationVariables>;
export const TourCompletedDocument = gql`
    mutation tourCompleted {
  tourCompleted {
    success
  }
}
    `;
export type TourCompletedMutationFn = ApolloReactCommon.MutationFunction<TourCompletedMutation, TourCompletedMutationVariables>;
export type TourCompletedComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TourCompletedMutation, TourCompletedMutationVariables>, 'mutation'>;

    export const TourCompletedComponent = (props: TourCompletedComponentProps) => (
      <ApolloReactComponents.Mutation<TourCompletedMutation, TourCompletedMutationVariables> mutation={TourCompletedDocument} {...props} />
    );
    

/**
 * __useTourCompletedMutation__
 *
 * To run a mutation, you first call `useTourCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTourCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tourCompletedMutation, { data, loading, error }] = useTourCompletedMutation({
 *   variables: {
 *   },
 * });
 */
export function useTourCompletedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TourCompletedMutation, TourCompletedMutationVariables>) {
        return ApolloReactHooks.useMutation<TourCompletedMutation, TourCompletedMutationVariables>(TourCompletedDocument, baseOptions);
      }
export type TourCompletedMutationHookResult = ReturnType<typeof useTourCompletedMutation>;
export type TourCompletedMutationResult = ApolloReactCommon.MutationResult<TourCompletedMutation>;
export type TourCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<TourCompletedMutation, TourCompletedMutationVariables>;
export const AcceptMembershipAgreementDocument = gql`
    mutation acceptMembershipAgreement {
  acceptMembershipAgreement {
    viewer {
      id
      alertAboutMembershipFee
    }
  }
}
    `;
export type AcceptMembershipAgreementMutationFn = ApolloReactCommon.MutationFunction<AcceptMembershipAgreementMutation, AcceptMembershipAgreementMutationVariables>;
export type AcceptMembershipAgreementComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptMembershipAgreementMutation, AcceptMembershipAgreementMutationVariables>, 'mutation'>;

    export const AcceptMembershipAgreementComponent = (props: AcceptMembershipAgreementComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptMembershipAgreementMutation, AcceptMembershipAgreementMutationVariables> mutation={AcceptMembershipAgreementDocument} {...props} />
    );
    

/**
 * __useAcceptMembershipAgreementMutation__
 *
 * To run a mutation, you first call `useAcceptMembershipAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMembershipAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMembershipAgreementMutation, { data, loading, error }] = useAcceptMembershipAgreementMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptMembershipAgreementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptMembershipAgreementMutation, AcceptMembershipAgreementMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptMembershipAgreementMutation, AcceptMembershipAgreementMutationVariables>(AcceptMembershipAgreementDocument, baseOptions);
      }
export type AcceptMembershipAgreementMutationHookResult = ReturnType<typeof useAcceptMembershipAgreementMutation>;
export type AcceptMembershipAgreementMutationResult = ApolloReactCommon.MutationResult<AcceptMembershipAgreementMutation>;
export type AcceptMembershipAgreementMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptMembershipAgreementMutation, AcceptMembershipAgreementMutationVariables>;
export const AddItemToCartDocument = gql`
    mutation addItemToCart($itemId: Int!, $itemClass: String!, $quantity: Int, $staffId: Int, $userComments: String, $userAvailability: String, $autoRenew: Boolean) {
  addItemToCart(itemId: $itemId, itemClass: $itemClass, quantity: $quantity, staffId: $staffId, userComments: $userComments, userAvailability: $userAvailability, autoRenew: $autoRenew) {
    viewer {
      id
      cart {
        id
        itemCount
        items {
          id
        }
      }
    }
    message
  }
}
    `;
export type AddItemToCartMutationFn = ApolloReactCommon.MutationFunction<AddItemToCartMutation, AddItemToCartMutationVariables>;
export type AddItemToCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddItemToCartMutation, AddItemToCartMutationVariables>, 'mutation'>;

    export const AddItemToCartComponent = (props: AddItemToCartComponentProps) => (
      <ApolloReactComponents.Mutation<AddItemToCartMutation, AddItemToCartMutationVariables> mutation={AddItemToCartDocument} {...props} />
    );
    

/**
 * __useAddItemToCartMutation__
 *
 * To run a mutation, you first call `useAddItemToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToCartMutation, { data, loading, error }] = useAddItemToCartMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      itemClass: // value for 'itemClass'
 *      quantity: // value for 'quantity'
 *      staffId: // value for 'staffId'
 *      userComments: // value for 'userComments'
 *      userAvailability: // value for 'userAvailability'
 *      autoRenew: // value for 'autoRenew'
 *   },
 * });
 */
export function useAddItemToCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddItemToCartMutation, AddItemToCartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddItemToCartMutation, AddItemToCartMutationVariables>(AddItemToCartDocument, baseOptions);
      }
export type AddItemToCartMutationHookResult = ReturnType<typeof useAddItemToCartMutation>;
export type AddItemToCartMutationResult = ApolloReactCommon.MutationResult<AddItemToCartMutation>;
export type AddItemToCartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddItemToCartMutation, AddItemToCartMutationVariables>;
export const CheckoutDocument = gql`
    mutation checkout {
  checkout {
    viewer {
      id
      cart {
        id
        itemCount
        items {
          id
        }
      }
      subscriptions {
        ...subscription
      }
    }
    message
  }
}
    ${SubscriptionFragmentDoc}`;
export type CheckoutMutationFn = ApolloReactCommon.MutationFunction<CheckoutMutation, CheckoutMutationVariables>;
export type CheckoutComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CheckoutMutation, CheckoutMutationVariables>, 'mutation'>;

    export const CheckoutComponent = (props: CheckoutComponentProps) => (
      <ApolloReactComponents.Mutation<CheckoutMutation, CheckoutMutationVariables> mutation={CheckoutDocument} {...props} />
    );
    

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, baseOptions);
      }
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>;
export type CheckoutMutationResult = ApolloReactCommon.MutationResult<CheckoutMutation>;
export type CheckoutMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckoutMutation, CheckoutMutationVariables>;
export const RedeemCouponDocument = gql`
    mutation redeemCoupon($code: String!) {
  redeemCoupon(code: $code) {
    cart {
      id
      total
      coupon {
        id
        couponCode
        discount
      }
    }
  }
}
    `;
export type RedeemCouponMutationFn = ApolloReactCommon.MutationFunction<RedeemCouponMutation, RedeemCouponMutationVariables>;
export type RedeemCouponComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RedeemCouponMutation, RedeemCouponMutationVariables>, 'mutation'>;

    export const RedeemCouponComponent = (props: RedeemCouponComponentProps) => (
      <ApolloReactComponents.Mutation<RedeemCouponMutation, RedeemCouponMutationVariables> mutation={RedeemCouponDocument} {...props} />
    );
    

/**
 * __useRedeemCouponMutation__
 *
 * To run a mutation, you first call `useRedeemCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemCouponMutation, { data, loading, error }] = useRedeemCouponMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeemCouponMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RedeemCouponMutation, RedeemCouponMutationVariables>) {
        return ApolloReactHooks.useMutation<RedeemCouponMutation, RedeemCouponMutationVariables>(RedeemCouponDocument, baseOptions);
      }
export type RedeemCouponMutationHookResult = ReturnType<typeof useRedeemCouponMutation>;
export type RedeemCouponMutationResult = ApolloReactCommon.MutationResult<RedeemCouponMutation>;
export type RedeemCouponMutationOptions = ApolloReactCommon.BaseMutationOptions<RedeemCouponMutation, RedeemCouponMutationVariables>;
export const RemoveItemFromCartDocument = gql`
    mutation removeItemFromCart($itemId: Int!) {
  removeItemFromCart(itemId: $itemId) {
    cart {
      id
      total
      itemCount
      items {
        id
      }
    }
    message
  }
}
    `;
export type RemoveItemFromCartMutationFn = ApolloReactCommon.MutationFunction<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>;
export type RemoveItemFromCartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>, 'mutation'>;

    export const RemoveItemFromCartComponent = (props: RemoveItemFromCartComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables> mutation={RemoveItemFromCartDocument} {...props} />
    );
    

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(RemoveItemFromCartDocument, baseOptions);
      }
export type RemoveItemFromCartMutationHookResult = ReturnType<typeof useRemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationResult = ApolloReactCommon.MutationResult<RemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>;
export const UpdateItemQuantityDocument = gql`
    mutation updateItemQuantity($itemId: Int!, $quantity: Int!) {
  updateItemQuantity(itemId: $itemId, quantity: $quantity) {
    cart {
      id
      total
      items {
        id
        quantity
      }
    }
  }
}
    `;
export type UpdateItemQuantityMutationFn = ApolloReactCommon.MutationFunction<UpdateItemQuantityMutation, UpdateItemQuantityMutationVariables>;
export type UpdateItemQuantityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateItemQuantityMutation, UpdateItemQuantityMutationVariables>, 'mutation'>;

    export const UpdateItemQuantityComponent = (props: UpdateItemQuantityComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateItemQuantityMutation, UpdateItemQuantityMutationVariables> mutation={UpdateItemQuantityDocument} {...props} />
    );
    

/**
 * __useUpdateItemQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateItemQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemQuantityMutation, { data, loading, error }] = useUpdateItemQuantityMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateItemQuantityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemQuantityMutation, UpdateItemQuantityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemQuantityMutation, UpdateItemQuantityMutationVariables>(UpdateItemQuantityDocument, baseOptions);
      }
export type UpdateItemQuantityMutationHookResult = ReturnType<typeof useUpdateItemQuantityMutation>;
export type UpdateItemQuantityMutationResult = ApolloReactCommon.MutationResult<UpdateItemQuantityMutation>;
export type UpdateItemQuantityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemQuantityMutation, UpdateItemQuantityMutationVariables>;
export const ContactInstructorDocument = gql`
    mutation contactInstructor($staffId: Int!, $subject: String!, $message: String!) {
  contactInstructor(staffId: $staffId, subject: $subject, message: $message) {
    success
    message
  }
}
    `;
export type ContactInstructorMutationFn = ApolloReactCommon.MutationFunction<ContactInstructorMutation, ContactInstructorMutationVariables>;
export type ContactInstructorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ContactInstructorMutation, ContactInstructorMutationVariables>, 'mutation'>;

    export const ContactInstructorComponent = (props: ContactInstructorComponentProps) => (
      <ApolloReactComponents.Mutation<ContactInstructorMutation, ContactInstructorMutationVariables> mutation={ContactInstructorDocument} {...props} />
    );
    

/**
 * __useContactInstructorMutation__
 *
 * To run a mutation, you first call `useContactInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactInstructorMutation, { data, loading, error }] = useContactInstructorMutation({
 *   variables: {
 *      staffId: // value for 'staffId'
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactInstructorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactInstructorMutation, ContactInstructorMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactInstructorMutation, ContactInstructorMutationVariables>(ContactInstructorDocument, baseOptions);
      }
export type ContactInstructorMutationHookResult = ReturnType<typeof useContactInstructorMutation>;
export type ContactInstructorMutationResult = ApolloReactCommon.MutationResult<ContactInstructorMutation>;
export type ContactInstructorMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactInstructorMutation, ContactInstructorMutationVariables>;
export const RegisterForEventDocument = gql`
    mutation registerForEvent($eventId: Int!, $startTime: String!) {
  registerForEvent(eventId: $eventId, startTime: $startTime) {
    slot {
      id
      openSpotsDisplay
      eventRegistration {
        id
        startTime
        endTime
        title
        eventLocation
        details
        charge {
          price
        }
      }
    }
    message
  }
}
    `;
export type RegisterForEventMutationFn = ApolloReactCommon.MutationFunction<RegisterForEventMutation, RegisterForEventMutationVariables>;
export type RegisterForEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterForEventMutation, RegisterForEventMutationVariables>, 'mutation'>;

    export const RegisterForEventComponent = (props: RegisterForEventComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterForEventMutation, RegisterForEventMutationVariables> mutation={RegisterForEventDocument} {...props} />
    );
    

/**
 * __useRegisterForEventMutation__
 *
 * To run a mutation, you first call `useRegisterForEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForEventMutation, { data, loading, error }] = useRegisterForEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useRegisterForEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterForEventMutation, RegisterForEventMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterForEventMutation, RegisterForEventMutationVariables>(RegisterForEventDocument, baseOptions);
      }
export type RegisterForEventMutationHookResult = ReturnType<typeof useRegisterForEventMutation>;
export type RegisterForEventMutationResult = ApolloReactCommon.MutationResult<RegisterForEventMutation>;
export type RegisterForEventMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterForEventMutation, RegisterForEventMutationVariables>;
export const UnregisterForEventDocument = gql`
    mutation unregisterForEvent($registrationId: Int!) {
  unregisterForEvent(registrationId: $registrationId) {
    viewer {
      id
      upcomingEventRegistrations {
        ...eventRegistration
      }
      upcomingAppointmentBookings {
        ...appointmentBooking
      }
    }
    message
  }
}
    ${EventRegistrationFragmentDoc}
${AppointmentBookingFragmentDoc}`;
export type UnregisterForEventMutationFn = ApolloReactCommon.MutationFunction<UnregisterForEventMutation, UnregisterForEventMutationVariables>;
export type UnregisterForEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnregisterForEventMutation, UnregisterForEventMutationVariables>, 'mutation'>;

    export const UnregisterForEventComponent = (props: UnregisterForEventComponentProps) => (
      <ApolloReactComponents.Mutation<UnregisterForEventMutation, UnregisterForEventMutationVariables> mutation={UnregisterForEventDocument} {...props} />
    );
    

/**
 * __useUnregisterForEventMutation__
 *
 * To run a mutation, you first call `useUnregisterForEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterForEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterForEventMutation, { data, loading, error }] = useUnregisterForEventMutation({
 *   variables: {
 *      registrationId: // value for 'registrationId'
 *   },
 * });
 */
export function useUnregisterForEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnregisterForEventMutation, UnregisterForEventMutationVariables>) {
        return ApolloReactHooks.useMutation<UnregisterForEventMutation, UnregisterForEventMutationVariables>(UnregisterForEventDocument, baseOptions);
      }
export type UnregisterForEventMutationHookResult = ReturnType<typeof useUnregisterForEventMutation>;
export type UnregisterForEventMutationResult = ApolloReactCommon.MutationResult<UnregisterForEventMutation>;
export type UnregisterForEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UnregisterForEventMutation, UnregisterForEventMutationVariables>;
export const RegisterForAppointmentDocument = gql`
    mutation registerForAppointment($apptId: Int!, $startTime: ISO8601DateTime!, $length: Int!, $eventStart: ISO8601DateTime!, $eventEnd: ISO8601DateTime!) {
  registerForAppointment(apptId: $apptId, startTime: $startTime, length: $length) {
    viewer {
      id
      upcomingAppointmentBookings {
        ...appointmentBooking
      }
      location {
        id
        appointmentCalendarEntries(after: $eventStart, before: $eventEnd, apptId: $apptId) {
          ...eventCalendarEntry
        }
      }
    }
    message
  }
}
    ${AppointmentBookingFragmentDoc}
${EventCalendarEntryFragmentDoc}`;
export type RegisterForAppointmentMutationFn = ApolloReactCommon.MutationFunction<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>;
export type RegisterForAppointmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>, 'mutation'>;

    export const RegisterForAppointmentComponent = (props: RegisterForAppointmentComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables> mutation={RegisterForAppointmentDocument} {...props} />
    );
    

/**
 * __useRegisterForAppointmentMutation__
 *
 * To run a mutation, you first call `useRegisterForAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForAppointmentMutation, { data, loading, error }] = useRegisterForAppointmentMutation({
 *   variables: {
 *      apptId: // value for 'apptId'
 *      startTime: // value for 'startTime'
 *      length: // value for 'length'
 *      eventStart: // value for 'eventStart'
 *      eventEnd: // value for 'eventEnd'
 *   },
 * });
 */
export function useRegisterForAppointmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>(RegisterForAppointmentDocument, baseOptions);
      }
export type RegisterForAppointmentMutationHookResult = ReturnType<typeof useRegisterForAppointmentMutation>;
export type RegisterForAppointmentMutationResult = ApolloReactCommon.MutationResult<RegisterForAppointmentMutation>;
export type RegisterForAppointmentMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>;
export const RedeemRewardPointsDocument = gql`
    mutation redeemRewardPoints($productId: Int!, $points: Int!) {
  redeemRewardPoints(productId: $productId, points: $points) {
    viewer {
      id
      totalPoints
    }
    message
  }
}
    `;
export type RedeemRewardPointsMutationFn = ApolloReactCommon.MutationFunction<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>;
export type RedeemRewardPointsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>, 'mutation'>;

    export const RedeemRewardPointsComponent = (props: RedeemRewardPointsComponentProps) => (
      <ApolloReactComponents.Mutation<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables> mutation={RedeemRewardPointsDocument} {...props} />
    );
    

/**
 * __useRedeemRewardPointsMutation__
 *
 * To run a mutation, you first call `useRedeemRewardPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemRewardPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemRewardPointsMutation, { data, loading, error }] = useRedeemRewardPointsMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      points: // value for 'points'
 *   },
 * });
 */
export function useRedeemRewardPointsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>) {
        return ApolloReactHooks.useMutation<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>(RedeemRewardPointsDocument, baseOptions);
      }
export type RedeemRewardPointsMutationHookResult = ReturnType<typeof useRedeemRewardPointsMutation>;
export type RedeemRewardPointsMutationResult = ApolloReactCommon.MutationResult<RedeemRewardPointsMutation>;
export type RedeemRewardPointsMutationOptions = ApolloReactCommon.BaseMutationOptions<RedeemRewardPointsMutation, RedeemRewardPointsMutationVariables>;
export const ChangeCurrentLocationDocument = gql`
    mutation changeCurrentLocation($locationId: Int!) {
  changeCurrentLocation(locationId: $locationId) {
    viewer {
      id
      location {
        id
      }
    }
  }
}
    `;
export type ChangeCurrentLocationMutationFn = ApolloReactCommon.MutationFunction<ChangeCurrentLocationMutation, ChangeCurrentLocationMutationVariables>;
export type ChangeCurrentLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeCurrentLocationMutation, ChangeCurrentLocationMutationVariables>, 'mutation'>;

    export const ChangeCurrentLocationComponent = (props: ChangeCurrentLocationComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeCurrentLocationMutation, ChangeCurrentLocationMutationVariables> mutation={ChangeCurrentLocationDocument} {...props} />
    );
    

/**
 * __useChangeCurrentLocationMutation__
 *
 * To run a mutation, you first call `useChangeCurrentLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCurrentLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCurrentLocationMutation, { data, loading, error }] = useChangeCurrentLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useChangeCurrentLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeCurrentLocationMutation, ChangeCurrentLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeCurrentLocationMutation, ChangeCurrentLocationMutationVariables>(ChangeCurrentLocationDocument, baseOptions);
      }
export type ChangeCurrentLocationMutationHookResult = ReturnType<typeof useChangeCurrentLocationMutation>;
export type ChangeCurrentLocationMutationResult = ApolloReactCommon.MutationResult<ChangeCurrentLocationMutation>;
export type ChangeCurrentLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeCurrentLocationMutation, ChangeCurrentLocationMutationVariables>;
export const RecordCompletedIntakeSurveyDocument = gql`
    mutation recordCompletedIntakeSurvey {
  recordCompletedIntakeSurvey {
    viewer {
      id
      intakeSurvey {
        show
      }
    }
  }
}
    `;
export type RecordCompletedIntakeSurveyMutationFn = ApolloReactCommon.MutationFunction<RecordCompletedIntakeSurveyMutation, RecordCompletedIntakeSurveyMutationVariables>;
export type RecordCompletedIntakeSurveyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RecordCompletedIntakeSurveyMutation, RecordCompletedIntakeSurveyMutationVariables>, 'mutation'>;

    export const RecordCompletedIntakeSurveyComponent = (props: RecordCompletedIntakeSurveyComponentProps) => (
      <ApolloReactComponents.Mutation<RecordCompletedIntakeSurveyMutation, RecordCompletedIntakeSurveyMutationVariables> mutation={RecordCompletedIntakeSurveyDocument} {...props} />
    );
    

/**
 * __useRecordCompletedIntakeSurveyMutation__
 *
 * To run a mutation, you first call `useRecordCompletedIntakeSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordCompletedIntakeSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordCompletedIntakeSurveyMutation, { data, loading, error }] = useRecordCompletedIntakeSurveyMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecordCompletedIntakeSurveyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecordCompletedIntakeSurveyMutation, RecordCompletedIntakeSurveyMutationVariables>) {
        return ApolloReactHooks.useMutation<RecordCompletedIntakeSurveyMutation, RecordCompletedIntakeSurveyMutationVariables>(RecordCompletedIntakeSurveyDocument, baseOptions);
      }
export type RecordCompletedIntakeSurveyMutationHookResult = ReturnType<typeof useRecordCompletedIntakeSurveyMutation>;
export type RecordCompletedIntakeSurveyMutationResult = ApolloReactCommon.MutationResult<RecordCompletedIntakeSurveyMutation>;
export type RecordCompletedIntakeSurveyMutationOptions = ApolloReactCommon.BaseMutationOptions<RecordCompletedIntakeSurveyMutation, RecordCompletedIntakeSurveyMutationVariables>;
export const RentLockerDocument = gql`
    mutation rentLocker($lockerId: Int!) {
  rentLocker(lockerId: $lockerId) {
    location {
      id
      lockers {
        ...locker
      }
    }
    message
  }
}
    ${LockerFragmentDoc}`;
export type RentLockerMutationFn = ApolloReactCommon.MutationFunction<RentLockerMutation, RentLockerMutationVariables>;
export type RentLockerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RentLockerMutation, RentLockerMutationVariables>, 'mutation'>;

    export const RentLockerComponent = (props: RentLockerComponentProps) => (
      <ApolloReactComponents.Mutation<RentLockerMutation, RentLockerMutationVariables> mutation={RentLockerDocument} {...props} />
    );
    

/**
 * __useRentLockerMutation__
 *
 * To run a mutation, you first call `useRentLockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRentLockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rentLockerMutation, { data, loading, error }] = useRentLockerMutation({
 *   variables: {
 *      lockerId: // value for 'lockerId'
 *   },
 * });
 */
export function useRentLockerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RentLockerMutation, RentLockerMutationVariables>) {
        return ApolloReactHooks.useMutation<RentLockerMutation, RentLockerMutationVariables>(RentLockerDocument, baseOptions);
      }
export type RentLockerMutationHookResult = ReturnType<typeof useRentLockerMutation>;
export type RentLockerMutationResult = ApolloReactCommon.MutationResult<RentLockerMutation>;
export type RentLockerMutationOptions = ApolloReactCommon.BaseMutationOptions<RentLockerMutation, RentLockerMutationVariables>;
export const AgreeToWaiverDocument = gql`
    mutation agreeToWaiver($locationId: Int) {
  agreeToWaiver(locationId: $locationId) {
    viewer {
      id
      agreedToWaiver
    }
    message
  }
}
    `;
export type AgreeToWaiverMutationFn = ApolloReactCommon.MutationFunction<AgreeToWaiverMutation, AgreeToWaiverMutationVariables>;
export type AgreeToWaiverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AgreeToWaiverMutation, AgreeToWaiverMutationVariables>, 'mutation'>;

    export const AgreeToWaiverComponent = (props: AgreeToWaiverComponentProps) => (
      <ApolloReactComponents.Mutation<AgreeToWaiverMutation, AgreeToWaiverMutationVariables> mutation={AgreeToWaiverDocument} {...props} />
    );
    

/**
 * __useAgreeToWaiverMutation__
 *
 * To run a mutation, you first call `useAgreeToWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeToWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeToWaiverMutation, { data, loading, error }] = useAgreeToWaiverMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useAgreeToWaiverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AgreeToWaiverMutation, AgreeToWaiverMutationVariables>) {
        return ApolloReactHooks.useMutation<AgreeToWaiverMutation, AgreeToWaiverMutationVariables>(AgreeToWaiverDocument, baseOptions);
      }
export type AgreeToWaiverMutationHookResult = ReturnType<typeof useAgreeToWaiverMutation>;
export type AgreeToWaiverMutationResult = ApolloReactCommon.MutationResult<AgreeToWaiverMutation>;
export type AgreeToWaiverMutationOptions = ApolloReactCommon.BaseMutationOptions<AgreeToWaiverMutation, AgreeToWaiverMutationVariables>;
export const AgreeToHipaaWaiverDocument = gql`
    mutation agreeToHipaaWaiver {
  agreeToWaiver(waiverType: "hipaa_waiver") {
    viewer {
      id
      agreedToHipaaWaiver: agreedToWaiver(waiverType: "hipaa_waiver")
    }
    message
  }
}
    `;
export type AgreeToHipaaWaiverMutationFn = ApolloReactCommon.MutationFunction<AgreeToHipaaWaiverMutation, AgreeToHipaaWaiverMutationVariables>;
export type AgreeToHipaaWaiverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AgreeToHipaaWaiverMutation, AgreeToHipaaWaiverMutationVariables>, 'mutation'>;

    export const AgreeToHipaaWaiverComponent = (props: AgreeToHipaaWaiverComponentProps) => (
      <ApolloReactComponents.Mutation<AgreeToHipaaWaiverMutation, AgreeToHipaaWaiverMutationVariables> mutation={AgreeToHipaaWaiverDocument} {...props} />
    );
    

/**
 * __useAgreeToHipaaWaiverMutation__
 *
 * To run a mutation, you first call `useAgreeToHipaaWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeToHipaaWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeToHipaaWaiverMutation, { data, loading, error }] = useAgreeToHipaaWaiverMutation({
 *   variables: {
 *   },
 * });
 */
export function useAgreeToHipaaWaiverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AgreeToHipaaWaiverMutation, AgreeToHipaaWaiverMutationVariables>) {
        return ApolloReactHooks.useMutation<AgreeToHipaaWaiverMutation, AgreeToHipaaWaiverMutationVariables>(AgreeToHipaaWaiverDocument, baseOptions);
      }
export type AgreeToHipaaWaiverMutationHookResult = ReturnType<typeof useAgreeToHipaaWaiverMutation>;
export type AgreeToHipaaWaiverMutationResult = ApolloReactCommon.MutationResult<AgreeToHipaaWaiverMutation>;
export type AgreeToHipaaWaiverMutationOptions = ApolloReactCommon.BaseMutationOptions<AgreeToHipaaWaiverMutation, AgreeToHipaaWaiverMutationVariables>;
export const UnrentLockerDocument = gql`
    mutation unrentLocker($lockerId: Int!) {
  unrentLocker(lockerId: $lockerId) {
    location {
      id
      lockers {
        ...locker
      }
    }
    message
  }
}
    ${LockerFragmentDoc}`;
export type UnrentLockerMutationFn = ApolloReactCommon.MutationFunction<UnrentLockerMutation, UnrentLockerMutationVariables>;
export type UnrentLockerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnrentLockerMutation, UnrentLockerMutationVariables>, 'mutation'>;

    export const UnrentLockerComponent = (props: UnrentLockerComponentProps) => (
      <ApolloReactComponents.Mutation<UnrentLockerMutation, UnrentLockerMutationVariables> mutation={UnrentLockerDocument} {...props} />
    );
    

/**
 * __useUnrentLockerMutation__
 *
 * To run a mutation, you first call `useUnrentLockerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnrentLockerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unrentLockerMutation, { data, loading, error }] = useUnrentLockerMutation({
 *   variables: {
 *      lockerId: // value for 'lockerId'
 *   },
 * });
 */
export function useUnrentLockerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnrentLockerMutation, UnrentLockerMutationVariables>) {
        return ApolloReactHooks.useMutation<UnrentLockerMutation, UnrentLockerMutationVariables>(UnrentLockerDocument, baseOptions);
      }
export type UnrentLockerMutationHookResult = ReturnType<typeof useUnrentLockerMutation>;
export type UnrentLockerMutationResult = ApolloReactCommon.MutationResult<UnrentLockerMutation>;
export type UnrentLockerMutationOptions = ApolloReactCommon.BaseMutationOptions<UnrentLockerMutation, UnrentLockerMutationVariables>;
export const MarkMessageAsReadDocument = gql`
    mutation markMessageAsRead($messageId: Int!) {
  markMessageAsRead(messageId: $messageId) {
    viewer {
      id
      unreadMessages {
        id
      }
    }
  }
}
    `;
export type MarkMessageAsReadMutationFn = ApolloReactCommon.MutationFunction<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;
export type MarkMessageAsReadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>, 'mutation'>;

    export const MarkMessageAsReadComponent = (props: MarkMessageAsReadComponentProps) => (
      <ApolloReactComponents.Mutation<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables> mutation={MarkMessageAsReadDocument} {...props} />
    );
    

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>(MarkMessageAsReadDocument, baseOptions);
      }
export type MarkMessageAsReadMutationHookResult = ReturnType<typeof useMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationResult = ApolloReactCommon.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkMessageAsReadMutation, MarkMessageAsReadMutationVariables>;
export const UpdateTourDocument = gql`
    mutation UpdateTour($run: Boolean, $stepIndex: Int) {
  updateTour(run: $run, stepIndex: $stepIndex) @client
}
    `;
export type UpdateTourMutationFn = ApolloReactCommon.MutationFunction<UpdateTourMutation, UpdateTourMutationVariables>;
export type UpdateTourComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTourMutation, UpdateTourMutationVariables>, 'mutation'>;

    export const UpdateTourComponent = (props: UpdateTourComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTourMutation, UpdateTourMutationVariables> mutation={UpdateTourDocument} {...props} />
    );
    

/**
 * __useUpdateTourMutation__
 *
 * To run a mutation, you first call `useUpdateTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTourMutation, { data, loading, error }] = useUpdateTourMutation({
 *   variables: {
 *      run: // value for 'run'
 *      stepIndex: // value for 'stepIndex'
 *   },
 * });
 */
export function useUpdateTourMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTourMutation, UpdateTourMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTourMutation, UpdateTourMutationVariables>(UpdateTourDocument, baseOptions);
      }
export type UpdateTourMutationHookResult = ReturnType<typeof useUpdateTourMutation>;
export type UpdateTourMutationResult = ApolloReactCommon.MutationResult<UpdateTourMutation>;
export type UpdateTourMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTourMutation, UpdateTourMutationVariables>;
export const AdvanceTourDocument = gql`
    mutation AdvanceTour {
  advanceTour @client
}
    `;
export type AdvanceTourMutationFn = ApolloReactCommon.MutationFunction<AdvanceTourMutation, AdvanceTourMutationVariables>;
export type AdvanceTourComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AdvanceTourMutation, AdvanceTourMutationVariables>, 'mutation'>;

    export const AdvanceTourComponent = (props: AdvanceTourComponentProps) => (
      <ApolloReactComponents.Mutation<AdvanceTourMutation, AdvanceTourMutationVariables> mutation={AdvanceTourDocument} {...props} />
    );
    

/**
 * __useAdvanceTourMutation__
 *
 * To run a mutation, you first call `useAdvanceTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceTourMutation, { data, loading, error }] = useAdvanceTourMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdvanceTourMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvanceTourMutation, AdvanceTourMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvanceTourMutation, AdvanceTourMutationVariables>(AdvanceTourDocument, baseOptions);
      }
export type AdvanceTourMutationHookResult = ReturnType<typeof useAdvanceTourMutation>;
export type AdvanceTourMutationResult = ApolloReactCommon.MutationResult<AdvanceTourMutation>;
export type AdvanceTourMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvanceTourMutation, AdvanceTourMutationVariables>;
export const UpdateTourStatusDocument = gql`
    mutation UpdateTourStatus {
  updateTourStatus @client
}
    `;
export type UpdateTourStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateTourStatusMutation, UpdateTourStatusMutationVariables>;
export type UpdateTourStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTourStatusMutation, UpdateTourStatusMutationVariables>, 'mutation'>;

    export const UpdateTourStatusComponent = (props: UpdateTourStatusComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTourStatusMutation, UpdateTourStatusMutationVariables> mutation={UpdateTourStatusDocument} {...props} />
    );
    

/**
 * __useUpdateTourStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTourStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTourStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTourStatusMutation, { data, loading, error }] = useUpdateTourStatusMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateTourStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTourStatusMutation, UpdateTourStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTourStatusMutation, UpdateTourStatusMutationVariables>(UpdateTourStatusDocument, baseOptions);
      }
export type UpdateTourStatusMutationHookResult = ReturnType<typeof useUpdateTourStatusMutation>;
export type UpdateTourStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTourStatusMutation>;
export type UpdateTourStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTourStatusMutation, UpdateTourStatusMutationVariables>;
export const OpenDialogDocument = gql`
    mutation OpenDialog($dialogComponent: String!, $dialogProps: DialogPropsInput) {
  openDialog(dialogComponent: $dialogComponent, dialogProps: $dialogProps) @client
}
    `;
export type OpenDialogMutationFn = ApolloReactCommon.MutationFunction<OpenDialogMutation, OpenDialogMutationVariables>;
export type OpenDialogComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<OpenDialogMutation, OpenDialogMutationVariables>, 'mutation'>;

    export const OpenDialogComponent = (props: OpenDialogComponentProps) => (
      <ApolloReactComponents.Mutation<OpenDialogMutation, OpenDialogMutationVariables> mutation={OpenDialogDocument} {...props} />
    );
    

/**
 * __useOpenDialogMutation__
 *
 * To run a mutation, you first call `useOpenDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openDialogMutation, { data, loading, error }] = useOpenDialogMutation({
 *   variables: {
 *      dialogComponent: // value for 'dialogComponent'
 *      dialogProps: // value for 'dialogProps'
 *   },
 * });
 */
export function useOpenDialogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OpenDialogMutation, OpenDialogMutationVariables>) {
        return ApolloReactHooks.useMutation<OpenDialogMutation, OpenDialogMutationVariables>(OpenDialogDocument, baseOptions);
      }
export type OpenDialogMutationHookResult = ReturnType<typeof useOpenDialogMutation>;
export type OpenDialogMutationResult = ApolloReactCommon.MutationResult<OpenDialogMutation>;
export type OpenDialogMutationOptions = ApolloReactCommon.BaseMutationOptions<OpenDialogMutation, OpenDialogMutationVariables>;
export const CloseDialogDocument = gql`
    mutation CloseDialog {
  closeDialog @client
}
    `;
export type CloseDialogMutationFn = ApolloReactCommon.MutationFunction<CloseDialogMutation, CloseDialogMutationVariables>;
export type CloseDialogComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CloseDialogMutation, CloseDialogMutationVariables>, 'mutation'>;

    export const CloseDialogComponent = (props: CloseDialogComponentProps) => (
      <ApolloReactComponents.Mutation<CloseDialogMutation, CloseDialogMutationVariables> mutation={CloseDialogDocument} {...props} />
    );
    

/**
 * __useCloseDialogMutation__
 *
 * To run a mutation, you first call `useCloseDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeDialogMutation, { data, loading, error }] = useCloseDialogMutation({
 *   variables: {
 *   },
 * });
 */
export function useCloseDialogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloseDialogMutation, CloseDialogMutationVariables>) {
        return ApolloReactHooks.useMutation<CloseDialogMutation, CloseDialogMutationVariables>(CloseDialogDocument, baseOptions);
      }
export type CloseDialogMutationHookResult = ReturnType<typeof useCloseDialogMutation>;
export type CloseDialogMutationResult = ApolloReactCommon.MutationResult<CloseDialogMutation>;
export type CloseDialogMutationOptions = ApolloReactCommon.BaseMutationOptions<CloseDialogMutation, CloseDialogMutationVariables>;
export const UpdateVideoSearchDocument = gql`
    mutation updateVideoSearch($videoSearch: String!) {
  updateVideoSearch(videoSearch: $videoSearch) @client
}
    `;
export type UpdateVideoSearchMutationFn = ApolloReactCommon.MutationFunction<UpdateVideoSearchMutation, UpdateVideoSearchMutationVariables>;
export type UpdateVideoSearchComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateVideoSearchMutation, UpdateVideoSearchMutationVariables>, 'mutation'>;

    export const UpdateVideoSearchComponent = (props: UpdateVideoSearchComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateVideoSearchMutation, UpdateVideoSearchMutationVariables> mutation={UpdateVideoSearchDocument} {...props} />
    );
    

/**
 * __useUpdateVideoSearchMutation__
 *
 * To run a mutation, you first call `useUpdateVideoSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoSearchMutation, { data, loading, error }] = useUpdateVideoSearchMutation({
 *   variables: {
 *      videoSearch: // value for 'videoSearch'
 *   },
 * });
 */
export function useUpdateVideoSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVideoSearchMutation, UpdateVideoSearchMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVideoSearchMutation, UpdateVideoSearchMutationVariables>(UpdateVideoSearchDocument, baseOptions);
      }
export type UpdateVideoSearchMutationHookResult = ReturnType<typeof useUpdateVideoSearchMutation>;
export type UpdateVideoSearchMutationResult = ApolloReactCommon.MutationResult<UpdateVideoSearchMutation>;
export type UpdateVideoSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVideoSearchMutation, UpdateVideoSearchMutationVariables>;
export const SetRefreshGfcTableDocument = gql`
    mutation setRefreshGfcTable($refreshGfcTable: Boolean!) {
  setRefreshGfcTable(refreshGfcTable: $refreshGfcTable) @client
}
    `;
export type SetRefreshGfcTableMutationFn = ApolloReactCommon.MutationFunction<SetRefreshGfcTableMutation, SetRefreshGfcTableMutationVariables>;
export type SetRefreshGfcTableComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetRefreshGfcTableMutation, SetRefreshGfcTableMutationVariables>, 'mutation'>;

    export const SetRefreshGfcTableComponent = (props: SetRefreshGfcTableComponentProps) => (
      <ApolloReactComponents.Mutation<SetRefreshGfcTableMutation, SetRefreshGfcTableMutationVariables> mutation={SetRefreshGfcTableDocument} {...props} />
    );
    

/**
 * __useSetRefreshGfcTableMutation__
 *
 * To run a mutation, you first call `useSetRefreshGfcTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRefreshGfcTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRefreshGfcTableMutation, { data, loading, error }] = useSetRefreshGfcTableMutation({
 *   variables: {
 *      refreshGfcTable: // value for 'refreshGfcTable'
 *   },
 * });
 */
export function useSetRefreshGfcTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetRefreshGfcTableMutation, SetRefreshGfcTableMutationVariables>) {
        return ApolloReactHooks.useMutation<SetRefreshGfcTableMutation, SetRefreshGfcTableMutationVariables>(SetRefreshGfcTableDocument, baseOptions);
      }
export type SetRefreshGfcTableMutationHookResult = ReturnType<typeof useSetRefreshGfcTableMutation>;
export type SetRefreshGfcTableMutationResult = ApolloReactCommon.MutationResult<SetRefreshGfcTableMutation>;
export type SetRefreshGfcTableMutationOptions = ApolloReactCommon.BaseMutationOptions<SetRefreshGfcTableMutation, SetRefreshGfcTableMutationVariables>;
export const AddAllergyDocument = gql`
    mutation addAllergy($allergy: String!) {
  addAllergy(allergy: $allergy) {
    profile {
      id
      allergies
    }
    message
  }
}
    `;
export type AddAllergyMutationFn = ApolloReactCommon.MutationFunction<AddAllergyMutation, AddAllergyMutationVariables>;
export type AddAllergyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAllergyMutation, AddAllergyMutationVariables>, 'mutation'>;

    export const AddAllergyComponent = (props: AddAllergyComponentProps) => (
      <ApolloReactComponents.Mutation<AddAllergyMutation, AddAllergyMutationVariables> mutation={AddAllergyDocument} {...props} />
    );
    

/**
 * __useAddAllergyMutation__
 *
 * To run a mutation, you first call `useAddAllergyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllergyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllergyMutation, { data, loading, error }] = useAddAllergyMutation({
 *   variables: {
 *      allergy: // value for 'allergy'
 *   },
 * });
 */
export function useAddAllergyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAllergyMutation, AddAllergyMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAllergyMutation, AddAllergyMutationVariables>(AddAllergyDocument, baseOptions);
      }
export type AddAllergyMutationHookResult = ReturnType<typeof useAddAllergyMutation>;
export type AddAllergyMutationResult = ApolloReactCommon.MutationResult<AddAllergyMutation>;
export type AddAllergyMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAllergyMutation, AddAllergyMutationVariables>;
export const RemoveAllergyDocument = gql`
    mutation removeAllergy($allergy: String!) {
  removeAllergy(allergy: $allergy) {
    profile {
      id
      allergies
    }
    message
  }
}
    `;
export type RemoveAllergyMutationFn = ApolloReactCommon.MutationFunction<RemoveAllergyMutation, RemoveAllergyMutationVariables>;
export type RemoveAllergyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveAllergyMutation, RemoveAllergyMutationVariables>, 'mutation'>;

    export const RemoveAllergyComponent = (props: RemoveAllergyComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveAllergyMutation, RemoveAllergyMutationVariables> mutation={RemoveAllergyDocument} {...props} />
    );
    

/**
 * __useRemoveAllergyMutation__
 *
 * To run a mutation, you first call `useRemoveAllergyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllergyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllergyMutation, { data, loading, error }] = useRemoveAllergyMutation({
 *   variables: {
 *      allergy: // value for 'allergy'
 *   },
 * });
 */
export function useRemoveAllergyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAllergyMutation, RemoveAllergyMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAllergyMutation, RemoveAllergyMutationVariables>(RemoveAllergyDocument, baseOptions);
      }
export type RemoveAllergyMutationHookResult = ReturnType<typeof useRemoveAllergyMutation>;
export type RemoveAllergyMutationResult = ApolloReactCommon.MutationResult<RemoveAllergyMutation>;
export type RemoveAllergyMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAllergyMutation, RemoveAllergyMutationVariables>;
export const AddMedicalConditionDocument = gql`
    mutation addMedicalCondition($medicalCondition: String!) {
  addMedicalCondition(medicalCondition: $medicalCondition) {
    profile {
      id
      medicalConditions
    }
    message
  }
}
    `;
export type AddMedicalConditionMutationFn = ApolloReactCommon.MutationFunction<AddMedicalConditionMutation, AddMedicalConditionMutationVariables>;
export type AddMedicalConditionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddMedicalConditionMutation, AddMedicalConditionMutationVariables>, 'mutation'>;

    export const AddMedicalConditionComponent = (props: AddMedicalConditionComponentProps) => (
      <ApolloReactComponents.Mutation<AddMedicalConditionMutation, AddMedicalConditionMutationVariables> mutation={AddMedicalConditionDocument} {...props} />
    );
    

/**
 * __useAddMedicalConditionMutation__
 *
 * To run a mutation, you first call `useAddMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMedicalConditionMutation, { data, loading, error }] = useAddMedicalConditionMutation({
 *   variables: {
 *      medicalCondition: // value for 'medicalCondition'
 *   },
 * });
 */
export function useAddMedicalConditionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMedicalConditionMutation, AddMedicalConditionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMedicalConditionMutation, AddMedicalConditionMutationVariables>(AddMedicalConditionDocument, baseOptions);
      }
export type AddMedicalConditionMutationHookResult = ReturnType<typeof useAddMedicalConditionMutation>;
export type AddMedicalConditionMutationResult = ApolloReactCommon.MutationResult<AddMedicalConditionMutation>;
export type AddMedicalConditionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMedicalConditionMutation, AddMedicalConditionMutationVariables>;
export const RemoveMedicalConditionDocument = gql`
    mutation removeMedicalCondition($medicalCondition: String!) {
  removeMedicalCondition(medicalCondition: $medicalCondition) {
    profile {
      id
      medicalConditions
    }
    message
  }
}
    `;
export type RemoveMedicalConditionMutationFn = ApolloReactCommon.MutationFunction<RemoveMedicalConditionMutation, RemoveMedicalConditionMutationVariables>;
export type RemoveMedicalConditionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveMedicalConditionMutation, RemoveMedicalConditionMutationVariables>, 'mutation'>;

    export const RemoveMedicalConditionComponent = (props: RemoveMedicalConditionComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveMedicalConditionMutation, RemoveMedicalConditionMutationVariables> mutation={RemoveMedicalConditionDocument} {...props} />
    );
    

/**
 * __useRemoveMedicalConditionMutation__
 *
 * To run a mutation, you first call `useRemoveMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMedicalConditionMutation, { data, loading, error }] = useRemoveMedicalConditionMutation({
 *   variables: {
 *      medicalCondition: // value for 'medicalCondition'
 *   },
 * });
 */
export function useRemoveMedicalConditionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMedicalConditionMutation, RemoveMedicalConditionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMedicalConditionMutation, RemoveMedicalConditionMutationVariables>(RemoveMedicalConditionDocument, baseOptions);
      }
export type RemoveMedicalConditionMutationHookResult = ReturnType<typeof useRemoveMedicalConditionMutation>;
export type RemoveMedicalConditionMutationResult = ApolloReactCommon.MutationResult<RemoveMedicalConditionMutation>;
export type RemoveMedicalConditionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMedicalConditionMutation, RemoveMedicalConditionMutationVariables>;
export const EditUserBasicDetailsDocument = gql`
    mutation editUserBasicDetails($profile: ProfileInput) {
  editUser(profile: $profile, user: null) {
    viewer {
      id
      profile {
        id
        firstName
        lastName
        dateOfBirth
        gender
        height
        weight
        bloodType
      }
    }
    message
  }
}
    `;
export type EditUserBasicDetailsMutationFn = ApolloReactCommon.MutationFunction<EditUserBasicDetailsMutation, EditUserBasicDetailsMutationVariables>;
export type EditUserBasicDetailsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserBasicDetailsMutation, EditUserBasicDetailsMutationVariables>, 'mutation'>;

    export const EditUserBasicDetailsComponent = (props: EditUserBasicDetailsComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserBasicDetailsMutation, EditUserBasicDetailsMutationVariables> mutation={EditUserBasicDetailsDocument} {...props} />
    );
    

/**
 * __useEditUserBasicDetailsMutation__
 *
 * To run a mutation, you first call `useEditUserBasicDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserBasicDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserBasicDetailsMutation, { data, loading, error }] = useEditUserBasicDetailsMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useEditUserBasicDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserBasicDetailsMutation, EditUserBasicDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserBasicDetailsMutation, EditUserBasicDetailsMutationVariables>(EditUserBasicDetailsDocument, baseOptions);
      }
export type EditUserBasicDetailsMutationHookResult = ReturnType<typeof useEditUserBasicDetailsMutation>;
export type EditUserBasicDetailsMutationResult = ApolloReactCommon.MutationResult<EditUserBasicDetailsMutation>;
export type EditUserBasicDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserBasicDetailsMutation, EditUserBasicDetailsMutationVariables>;
export const EditUserEmergencyContactDocument = gql`
    mutation editUserEmergencyContact($profile: ProfileInput) {
  editUser(profile: $profile, user: null) {
    viewer {
      id
      profile {
        id
        emergencyFirstName
        emergencyMiddleName
        emergencyLastName
        emergencyRelation
        emergencyRelationIndex
        emergencyPhone
        emergencyEmail
        emergencyAddress
      }
    }
    message
  }
}
    `;
export type EditUserEmergencyContactMutationFn = ApolloReactCommon.MutationFunction<EditUserEmergencyContactMutation, EditUserEmergencyContactMutationVariables>;
export type EditUserEmergencyContactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserEmergencyContactMutation, EditUserEmergencyContactMutationVariables>, 'mutation'>;

    export const EditUserEmergencyContactComponent = (props: EditUserEmergencyContactComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserEmergencyContactMutation, EditUserEmergencyContactMutationVariables> mutation={EditUserEmergencyContactDocument} {...props} />
    );
    

/**
 * __useEditUserEmergencyContactMutation__
 *
 * To run a mutation, you first call `useEditUserEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserEmergencyContactMutation, { data, loading, error }] = useEditUserEmergencyContactMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useEditUserEmergencyContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserEmergencyContactMutation, EditUserEmergencyContactMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserEmergencyContactMutation, EditUserEmergencyContactMutationVariables>(EditUserEmergencyContactDocument, baseOptions);
      }
export type EditUserEmergencyContactMutationHookResult = ReturnType<typeof useEditUserEmergencyContactMutation>;
export type EditUserEmergencyContactMutationResult = ApolloReactCommon.MutationResult<EditUserEmergencyContactMutation>;
export type EditUserEmergencyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserEmergencyContactMutation, EditUserEmergencyContactMutationVariables>;
export const AccountTabDocument = gql`
    query accountTab {
  viewer {
    id
    location {
      id
      hasPlans
      payments
      config {
        id
        qrCodeEnabled
      }
    }
  }
}
    `;
export type AccountTabComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountTabQuery, AccountTabQueryVariables>, 'query'>;

    export const AccountTabComponent = (props: AccountTabComponentProps) => (
      <ApolloReactComponents.Query<AccountTabQuery, AccountTabQueryVariables> query={AccountTabDocument} {...props} />
    );
    

/**
 * __useAccountTabQuery__
 *
 * To run a query within a React component, call `useAccountTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTabQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTabQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountTabQuery, AccountTabQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountTabQuery, AccountTabQueryVariables>(AccountTabDocument, baseOptions);
      }
export function useAccountTabLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountTabQuery, AccountTabQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountTabQuery, AccountTabQueryVariables>(AccountTabDocument, baseOptions);
        }
export type AccountTabQueryHookResult = ReturnType<typeof useAccountTabQuery>;
export type AccountTabLazyQueryHookResult = ReturnType<typeof useAccountTabLazyQuery>;
export type AccountTabQueryResult = ApolloReactCommon.QueryResult<AccountTabQuery, AccountTabQueryVariables>;
export const UserOrdersDocument = gql`
    query userOrders {
  viewer {
    id
    fullName
    email
    location {
      id
      city
      country
    }
    orderHistory {
      id
      createdOnStripe
      description
      sourceLast4
      price
      quantity
      sessions
      staffName
      status
    }
  }
}
    `;
export type UserOrdersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserOrdersQuery, UserOrdersQueryVariables>, 'query'>;

    export const UserOrdersComponent = (props: UserOrdersComponentProps) => (
      <ApolloReactComponents.Query<UserOrdersQuery, UserOrdersQueryVariables> query={UserOrdersDocument} {...props} />
    );
    

/**
 * __useUserOrdersQuery__
 *
 * To run a query within a React component, call `useUserOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersDocument, baseOptions);
      }
export function useUserOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersDocument, baseOptions);
        }
export type UserOrdersQueryHookResult = ReturnType<typeof useUserOrdersQuery>;
export type UserOrdersLazyQueryHookResult = ReturnType<typeof useUserOrdersLazyQuery>;
export type UserOrdersQueryResult = ApolloReactCommon.QueryResult<UserOrdersQuery, UserOrdersQueryVariables>;
export const UserActivityDocument = gql`
    query userActivity {
  viewer {
    id
    userActivities {
      ...userActivity
    }
  }
}
    ${UserActivityFragmentDoc}`;
export type UserActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserActivityQuery, UserActivityQueryVariables>, 'query'>;

    export const UserActivityComponent = (props: UserActivityComponentProps) => (
      <ApolloReactComponents.Query<UserActivityQuery, UserActivityQueryVariables> query={UserActivityDocument} {...props} />
    );
    

/**
 * __useUserActivityQuery__
 *
 * To run a query within a React component, call `useUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserActivityQuery, UserActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<UserActivityQuery, UserActivityQueryVariables>(UserActivityDocument, baseOptions);
      }
export function useUserActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserActivityQuery, UserActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserActivityQuery, UserActivityQueryVariables>(UserActivityDocument, baseOptions);
        }
export type UserActivityQueryHookResult = ReturnType<typeof useUserActivityQuery>;
export type UserActivityLazyQueryHookResult = ReturnType<typeof useUserActivityLazyQuery>;
export type UserActivityQueryResult = ApolloReactCommon.QueryResult<UserActivityQuery, UserActivityQueryVariables>;
export const UserSubscriptionsDocument = gql`
    query userSubscriptions {
  viewer {
    id
    subscriptions {
      ...subscription
    }
  }
}
    ${SubscriptionFragmentDoc}`;
export type UserSubscriptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>, 'query'>;

    export const UserSubscriptionsComponent = (props: UserSubscriptionsComponentProps) => (
      <ApolloReactComponents.Query<UserSubscriptionsQuery, UserSubscriptionsQueryVariables> query={UserSubscriptionsDocument} {...props} />
    );
    

/**
 * __useUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(UserSubscriptionsDocument, baseOptions);
      }
export function useUserSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(UserSubscriptionsDocument, baseOptions);
        }
export type UserSubscriptionsQueryHookResult = ReturnType<typeof useUserSubscriptionsQuery>;
export type UserSubscriptionsLazyQueryHookResult = ReturnType<typeof useUserSubscriptionsLazyQuery>;
export type UserSubscriptionsQueryResult = ApolloReactCommon.QueryResult<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>;
export const UserCardsDocument = gql`
    query userCards {
  viewer {
    id
    fullName
    cards {
      ...card
    }
  }
}
    ${CardFragmentDoc}`;
export type UserCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserCardsQuery, UserCardsQueryVariables>, 'query'>;

    export const UserCardsComponent = (props: UserCardsComponentProps) => (
      <ApolloReactComponents.Query<UserCardsQuery, UserCardsQueryVariables> query={UserCardsDocument} {...props} />
    );
    

/**
 * __useUserCardsQuery__
 *
 * To run a query within a React component, call `useUserCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCardsQuery, UserCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserCardsQuery, UserCardsQueryVariables>(UserCardsDocument, baseOptions);
      }
export function useUserCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCardsQuery, UserCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserCardsQuery, UserCardsQueryVariables>(UserCardsDocument, baseOptions);
        }
export type UserCardsQueryHookResult = ReturnType<typeof useUserCardsQuery>;
export type UserCardsLazyQueryHookResult = ReturnType<typeof useUserCardsLazyQuery>;
export type UserCardsQueryResult = ApolloReactCommon.QueryResult<UserCardsQuery, UserCardsQueryVariables>;
export const BewellLandingDocument = gql`
    query bewellLanding($isToday: Boolean!) {
  currentServerDate
  viewer {
    id
    bewell {
      points
      dayDisplay(isToday: $isToday)
      dayNumber(isToday: $isToday)
      cutoff
    }
    location {
      id
      timezone
    }
  }
}
    `;
export type BewellLandingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BewellLandingQuery, BewellLandingQueryVariables>, 'query'> & ({ variables: BewellLandingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BewellLandingComponent = (props: BewellLandingComponentProps) => (
      <ApolloReactComponents.Query<BewellLandingQuery, BewellLandingQueryVariables> query={BewellLandingDocument} {...props} />
    );
    

/**
 * __useBewellLandingQuery__
 *
 * To run a query within a React component, call `useBewellLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBewellLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBewellLandingQuery({
 *   variables: {
 *      isToday: // value for 'isToday'
 *   },
 * });
 */
export function useBewellLandingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BewellLandingQuery, BewellLandingQueryVariables>) {
        return ApolloReactHooks.useQuery<BewellLandingQuery, BewellLandingQueryVariables>(BewellLandingDocument, baseOptions);
      }
export function useBewellLandingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BewellLandingQuery, BewellLandingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BewellLandingQuery, BewellLandingQueryVariables>(BewellLandingDocument, baseOptions);
        }
export type BewellLandingQueryHookResult = ReturnType<typeof useBewellLandingQuery>;
export type BewellLandingLazyQueryHookResult = ReturnType<typeof useBewellLandingLazyQuery>;
export type BewellLandingQueryResult = ApolloReactCommon.QueryResult<BewellLandingQuery, BewellLandingQueryVariables>;
export const BewellBoardDocument = gql`
    query bewellBoard {
  viewer {
    id
    location {
      id
      bewell {
        categories {
          id
          title
          color
          bewellItems {
            ...bewellItem
          }
        }
      }
    }
  }
}
    ${BewellItemFragmentDoc}`;
export type BewellBoardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BewellBoardQuery, BewellBoardQueryVariables>, 'query'>;

    export const BewellBoardComponent = (props: BewellBoardComponentProps) => (
      <ApolloReactComponents.Query<BewellBoardQuery, BewellBoardQueryVariables> query={BewellBoardDocument} {...props} />
    );
    

/**
 * __useBewellBoardQuery__
 *
 * To run a query within a React component, call `useBewellBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBewellBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBewellBoardQuery({
 *   variables: {
 *   },
 * });
 */
export function useBewellBoardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BewellBoardQuery, BewellBoardQueryVariables>) {
        return ApolloReactHooks.useQuery<BewellBoardQuery, BewellBoardQueryVariables>(BewellBoardDocument, baseOptions);
      }
export function useBewellBoardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BewellBoardQuery, BewellBoardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BewellBoardQuery, BewellBoardQueryVariables>(BewellBoardDocument, baseOptions);
        }
export type BewellBoardQueryHookResult = ReturnType<typeof useBewellBoardQuery>;
export type BewellBoardLazyQueryHookResult = ReturnType<typeof useBewellBoardLazyQuery>;
export type BewellBoardQueryResult = ApolloReactCommon.QueryResult<BewellBoardQuery, BewellBoardQueryVariables>;
export const BewellStonesDocument = gql`
    query bewellStones {
  viewer {
    id
    location {
      id
      bewell {
        categories {
          id
          title
          color
          isStoneEarned
        }
        stonesEnabled
        totalWellnessStoneLabel
        totalWellnessStoneColor
      }
    }
  }
}
    `;
export type BewellStonesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BewellStonesQuery, BewellStonesQueryVariables>, 'query'>;

    export const BewellStonesComponent = (props: BewellStonesComponentProps) => (
      <ApolloReactComponents.Query<BewellStonesQuery, BewellStonesQueryVariables> query={BewellStonesDocument} {...props} />
    );
    

/**
 * __useBewellStonesQuery__
 *
 * To run a query within a React component, call `useBewellStonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBewellStonesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBewellStonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBewellStonesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BewellStonesQuery, BewellStonesQueryVariables>) {
        return ApolloReactHooks.useQuery<BewellStonesQuery, BewellStonesQueryVariables>(BewellStonesDocument, baseOptions);
      }
export function useBewellStonesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BewellStonesQuery, BewellStonesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BewellStonesQuery, BewellStonesQueryVariables>(BewellStonesDocument, baseOptions);
        }
export type BewellStonesQueryHookResult = ReturnType<typeof useBewellStonesQuery>;
export type BewellStonesLazyQueryHookResult = ReturnType<typeof useBewellStonesLazyQuery>;
export type BewellStonesQueryResult = ApolloReactCommon.QueryResult<BewellStonesQuery, BewellStonesQueryVariables>;
export const BiometricDataDocument = gql`
    query biometricData {
  viewer {
    id
    biometricDataFiles {
      ...biometricDataFile
    }
  }
}
    ${BiometricDataFileFragmentDoc}`;
export type BiometricDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BiometricDataQuery, BiometricDataQueryVariables>, 'query'>;

    export const BiometricDataComponent = (props: BiometricDataComponentProps) => (
      <ApolloReactComponents.Query<BiometricDataQuery, BiometricDataQueryVariables> query={BiometricDataDocument} {...props} />
    );
    

/**
 * __useBiometricDataQuery__
 *
 * To run a query within a React component, call `useBiometricDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiometricDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiometricDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBiometricDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiometricDataQuery, BiometricDataQueryVariables>) {
        return ApolloReactHooks.useQuery<BiometricDataQuery, BiometricDataQueryVariables>(BiometricDataDocument, baseOptions);
      }
export function useBiometricDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiometricDataQuery, BiometricDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiometricDataQuery, BiometricDataQueryVariables>(BiometricDataDocument, baseOptions);
        }
export type BiometricDataQueryHookResult = ReturnType<typeof useBiometricDataQuery>;
export type BiometricDataLazyQueryHookResult = ReturnType<typeof useBiometricDataLazyQuery>;
export type BiometricDataQueryResult = ApolloReactCommon.QueryResult<BiometricDataQuery, BiometricDataQueryVariables>;
export const BiometricsDocument = gql`
    query biometrics {
  viewer {
    id
    agreedToHipaaWaiver: agreedToWaiver(waiverType: "hipaa_waiver")
  }
}
    `;
export type BiometricsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BiometricsQuery, BiometricsQueryVariables>, 'query'>;

    export const BiometricsComponent = (props: BiometricsComponentProps) => (
      <ApolloReactComponents.Query<BiometricsQuery, BiometricsQueryVariables> query={BiometricsDocument} {...props} />
    );
    

/**
 * __useBiometricsQuery__
 *
 * To run a query within a React component, call `useBiometricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiometricsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiometricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBiometricsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiometricsQuery, BiometricsQueryVariables>) {
        return ApolloReactHooks.useQuery<BiometricsQuery, BiometricsQueryVariables>(BiometricsDocument, baseOptions);
      }
export function useBiometricsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiometricsQuery, BiometricsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiometricsQuery, BiometricsQueryVariables>(BiometricsDocument, baseOptions);
        }
export type BiometricsQueryHookResult = ReturnType<typeof useBiometricsQuery>;
export type BiometricsLazyQueryHookResult = ReturnType<typeof useBiometricsLazyQuery>;
export type BiometricsQueryResult = ApolloReactCommon.QueryResult<BiometricsQuery, BiometricsQueryVariables>;
export const BiometricsFileUrlDocument = gql`
    query biometricsFileUrl($datumId: Int!) {
  viewer {
    id
    biometricDataFile(datumId: $datumId) {
      id
      expirableUrl
    }
  }
}
    `;
export type BiometricsFileUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables>, 'query'> & ({ variables: BiometricsFileUrlQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BiometricsFileUrlComponent = (props: BiometricsFileUrlComponentProps) => (
      <ApolloReactComponents.Query<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables> query={BiometricsFileUrlDocument} {...props} />
    );
    

/**
 * __useBiometricsFileUrlQuery__
 *
 * To run a query within a React component, call `useBiometricsFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiometricsFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiometricsFileUrlQuery({
 *   variables: {
 *      datumId: // value for 'datumId'
 *   },
 * });
 */
export function useBiometricsFileUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables>(BiometricsFileUrlDocument, baseOptions);
      }
export function useBiometricsFileUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables>(BiometricsFileUrlDocument, baseOptions);
        }
export type BiometricsFileUrlQueryHookResult = ReturnType<typeof useBiometricsFileUrlQuery>;
export type BiometricsFileUrlLazyQueryHookResult = ReturnType<typeof useBiometricsFileUrlLazyQuery>;
export type BiometricsFileUrlQueryResult = ApolloReactCommon.QueryResult<BiometricsFileUrlQuery, BiometricsFileUrlQueryVariables>;
export const FamilyTabsDocument = gql`
    query familyTabs {
  viewer {
    id
    location {
      id
      config {
        id
        dependentsEnabled
        guestsEnabled
        partnersEnabled
      }
    }
  }
}
    `;
export type FamilyTabsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FamilyTabsQuery, FamilyTabsQueryVariables>, 'query'>;

    export const FamilyTabsComponent = (props: FamilyTabsComponentProps) => (
      <ApolloReactComponents.Query<FamilyTabsQuery, FamilyTabsQueryVariables> query={FamilyTabsDocument} {...props} />
    );
    

/**
 * __useFamilyTabsQuery__
 *
 * To run a query within a React component, call `useFamilyTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyTabsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFamilyTabsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FamilyTabsQuery, FamilyTabsQueryVariables>) {
        return ApolloReactHooks.useQuery<FamilyTabsQuery, FamilyTabsQueryVariables>(FamilyTabsDocument, baseOptions);
      }
export function useFamilyTabsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FamilyTabsQuery, FamilyTabsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FamilyTabsQuery, FamilyTabsQueryVariables>(FamilyTabsDocument, baseOptions);
        }
export type FamilyTabsQueryHookResult = ReturnType<typeof useFamilyTabsQuery>;
export type FamilyTabsLazyQueryHookResult = ReturnType<typeof useFamilyTabsLazyQuery>;
export type FamilyTabsQueryResult = ApolloReactCommon.QueryResult<FamilyTabsQuery, FamilyTabsQueryVariables>;
export const DependentDocument = gql`
    query dependent($dependentId: Int) {
  viewer {
    id
    dependent(dependentId: $dependentId) {
      ...dependent
    }
    location {
      id
      config {
        id
        dependentChildMinAge
      }
    }
  }
}
    ${DependentFragmentDoc}`;
export type DependentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DependentQuery, DependentQueryVariables>, 'query'>;

    export const DependentComponent = (props: DependentComponentProps) => (
      <ApolloReactComponents.Query<DependentQuery, DependentQueryVariables> query={DependentDocument} {...props} />
    );
    

/**
 * __useDependentQuery__
 *
 * To run a query within a React component, call `useDependentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDependentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDependentQuery({
 *   variables: {
 *      dependentId: // value for 'dependentId'
 *   },
 * });
 */
export function useDependentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DependentQuery, DependentQueryVariables>) {
        return ApolloReactHooks.useQuery<DependentQuery, DependentQueryVariables>(DependentDocument, baseOptions);
      }
export function useDependentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DependentQuery, DependentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DependentQuery, DependentQueryVariables>(DependentDocument, baseOptions);
        }
export type DependentQueryHookResult = ReturnType<typeof useDependentQuery>;
export type DependentLazyQueryHookResult = ReturnType<typeof useDependentLazyQuery>;
export type DependentQueryResult = ApolloReactCommon.QueryResult<DependentQuery, DependentQueryVariables>;
export const DependentWaiverDocument = gql`
    query dependentWaiver {
  viewer {
    id
    location {
      id
      companyPolicy {
        id
        dependentWaiver
      }
    }
  }
}
    `;
export type DependentWaiverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DependentWaiverQuery, DependentWaiverQueryVariables>, 'query'>;

    export const DependentWaiverComponent = (props: DependentWaiverComponentProps) => (
      <ApolloReactComponents.Query<DependentWaiverQuery, DependentWaiverQueryVariables> query={DependentWaiverDocument} {...props} />
    );
    

/**
 * __useDependentWaiverQuery__
 *
 * To run a query within a React component, call `useDependentWaiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useDependentWaiverQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDependentWaiverQuery({
 *   variables: {
 *   },
 * });
 */
export function useDependentWaiverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DependentWaiverQuery, DependentWaiverQueryVariables>) {
        return ApolloReactHooks.useQuery<DependentWaiverQuery, DependentWaiverQueryVariables>(DependentWaiverDocument, baseOptions);
      }
export function useDependentWaiverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DependentWaiverQuery, DependentWaiverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DependentWaiverQuery, DependentWaiverQueryVariables>(DependentWaiverDocument, baseOptions);
        }
export type DependentWaiverQueryHookResult = ReturnType<typeof useDependentWaiverQuery>;
export type DependentWaiverLazyQueryHookResult = ReturnType<typeof useDependentWaiverLazyQuery>;
export type DependentWaiverQueryResult = ApolloReactCommon.QueryResult<DependentWaiverQuery, DependentWaiverQueryVariables>;
export const DependentsQueryDocument = gql`
    query dependentsQuery {
  viewer {
    id
    dependents {
      ...dependent
    }
    location {
      id
      config {
        id
        usersCanEditDependents
      }
    }
  }
}
    ${DependentFragmentDoc}`;
export type DependentsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DependentsQueryQuery, DependentsQueryQueryVariables>, 'query'>;

    export const DependentsQueryComponent = (props: DependentsQueryComponentProps) => (
      <ApolloReactComponents.Query<DependentsQueryQuery, DependentsQueryQueryVariables> query={DependentsQueryDocument} {...props} />
    );
    

/**
 * __useDependentsQueryQuery__
 *
 * To run a query within a React component, call `useDependentsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDependentsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDependentsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDependentsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DependentsQueryQuery, DependentsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<DependentsQueryQuery, DependentsQueryQueryVariables>(DependentsQueryDocument, baseOptions);
      }
export function useDependentsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DependentsQueryQuery, DependentsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DependentsQueryQuery, DependentsQueryQueryVariables>(DependentsQueryDocument, baseOptions);
        }
export type DependentsQueryQueryHookResult = ReturnType<typeof useDependentsQueryQuery>;
export type DependentsQueryLazyQueryHookResult = ReturnType<typeof useDependentsQueryLazyQuery>;
export type DependentsQueryQueryResult = ApolloReactCommon.QueryResult<DependentsQueryQuery, DependentsQueryQueryVariables>;
export const PartnerInvitationDocument = gql`
    query partnerInvitation {
  viewer {
    id
    pendingPartnerRequestFrom {
      ...partner
    }
    partner {
      ...partner
    }
  }
}
    ${PartnerFragmentDoc}`;
export type PartnerInvitationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PartnerInvitationQuery, PartnerInvitationQueryVariables>, 'query'>;

    export const PartnerInvitationComponent = (props: PartnerInvitationComponentProps) => (
      <ApolloReactComponents.Query<PartnerInvitationQuery, PartnerInvitationQueryVariables> query={PartnerInvitationDocument} {...props} />
    );
    

/**
 * __usePartnerInvitationQuery__
 *
 * To run a query within a React component, call `usePartnerInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerInvitationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerInvitationQuery, PartnerInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<PartnerInvitationQuery, PartnerInvitationQueryVariables>(PartnerInvitationDocument, baseOptions);
      }
export function usePartnerInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerInvitationQuery, PartnerInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PartnerInvitationQuery, PartnerInvitationQueryVariables>(PartnerInvitationDocument, baseOptions);
        }
export type PartnerInvitationQueryHookResult = ReturnType<typeof usePartnerInvitationQuery>;
export type PartnerInvitationLazyQueryHookResult = ReturnType<typeof usePartnerInvitationLazyQuery>;
export type PartnerInvitationQueryResult = ApolloReactCommon.QueryResult<PartnerInvitationQuery, PartnerInvitationQueryVariables>;
export const PartnerDocument = gql`
    query partner {
  viewer {
    id
    ...partners
  }
}
    ${PartnersFragmentDoc}`;
export type PartnerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PartnerQuery, PartnerQueryVariables>, 'query'>;

    export const PartnerComponent = (props: PartnerComponentProps) => (
      <ApolloReactComponents.Query<PartnerQuery, PartnerQueryVariables> query={PartnerDocument} {...props} />
    );
    

/**
 * __usePartnerQuery__
 *
 * To run a query within a React component, call `usePartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
        return ApolloReactHooks.useQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, baseOptions);
      }
export function usePartnerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, baseOptions);
        }
export type PartnerQueryHookResult = ReturnType<typeof usePartnerQuery>;
export type PartnerLazyQueryHookResult = ReturnType<typeof usePartnerLazyQuery>;
export type PartnerQueryResult = ApolloReactCommon.QueryResult<PartnerQuery, PartnerQueryVariables>;
export const PartnerConfigDocument = gql`
    query partnerConfig {
  viewer {
    id
    location {
      id
      config {
        id
        allowExternalPartners
      }
    }
  }
}
    `;
export type PartnerConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PartnerConfigQuery, PartnerConfigQueryVariables>, 'query'>;

    export const PartnerConfigComponent = (props: PartnerConfigComponentProps) => (
      <ApolloReactComponents.Query<PartnerConfigQuery, PartnerConfigQueryVariables> query={PartnerConfigDocument} {...props} />
    );
    

/**
 * __usePartnerConfigQuery__
 *
 * To run a query within a React component, call `usePartnerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerConfigQuery, PartnerConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<PartnerConfigQuery, PartnerConfigQueryVariables>(PartnerConfigDocument, baseOptions);
      }
export function usePartnerConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerConfigQuery, PartnerConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PartnerConfigQuery, PartnerConfigQueryVariables>(PartnerConfigDocument, baseOptions);
        }
export type PartnerConfigQueryHookResult = ReturnType<typeof usePartnerConfigQuery>;
export type PartnerConfigLazyQueryHookResult = ReturnType<typeof usePartnerConfigLazyQuery>;
export type PartnerConfigQueryResult = ApolloReactCommon.QueryResult<PartnerConfigQuery, PartnerConfigQueryVariables>;
export const GuestsQueryDocument = gql`
    query guestsQuery {
  viewer {
    id
    guests {
      ...guest
    }
  }
}
    ${GuestFragmentDoc}`;
export type GuestsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GuestsQueryQuery, GuestsQueryQueryVariables>, 'query'>;

    export const GuestsQueryComponent = (props: GuestsQueryComponentProps) => (
      <ApolloReactComponents.Query<GuestsQueryQuery, GuestsQueryQueryVariables> query={GuestsQueryDocument} {...props} />
    );
    

/**
 * __useGuestsQueryQuery__
 *
 * To run a query within a React component, call `useGuestsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGuestsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestsQueryQuery, GuestsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestsQueryQuery, GuestsQueryQueryVariables>(GuestsQueryDocument, baseOptions);
      }
export function useGuestsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestsQueryQuery, GuestsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestsQueryQuery, GuestsQueryQueryVariables>(GuestsQueryDocument, baseOptions);
        }
export type GuestsQueryQueryHookResult = ReturnType<typeof useGuestsQueryQuery>;
export type GuestsQueryLazyQueryHookResult = ReturnType<typeof useGuestsQueryLazyQuery>;
export type GuestsQueryQueryResult = ApolloReactCommon.QueryResult<GuestsQueryQuery, GuestsQueryQueryVariables>;
export const GuestDocument = gql`
    query guest($guestId: Int) {
  guestEmergencyRelations
  viewer {
    id
    location {
      id
      config {
        id
        requireGuestEmergencyContacts
      }
    }
    guest(guestId: $guestId) {
      ...guest
    }
  }
}
    ${GuestFragmentDoc}`;
export type GuestComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GuestQuery, GuestQueryVariables>, 'query'>;

    export const GuestComponent = (props: GuestComponentProps) => (
      <ApolloReactComponents.Query<GuestQuery, GuestQueryVariables> query={GuestDocument} {...props} />
    );
    

/**
 * __useGuestQuery__
 *
 * To run a query within a React component, call `useGuestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestQuery({
 *   variables: {
 *      guestId: // value for 'guestId'
 *   },
 * });
 */
export function useGuestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestQuery, GuestQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestQuery, GuestQueryVariables>(GuestDocument, baseOptions);
      }
export function useGuestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestQuery, GuestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestQuery, GuestQueryVariables>(GuestDocument, baseOptions);
        }
export type GuestQueryHookResult = ReturnType<typeof useGuestQuery>;
export type GuestLazyQueryHookResult = ReturnType<typeof useGuestLazyQuery>;
export type GuestQueryResult = ApolloReactCommon.QueryResult<GuestQuery, GuestQueryVariables>;
export const ClassRegistrationDocument = gql`
    query classRegistration($classRegistrationId: Int!) {
  viewer {
    id
    classRegistration(classRegistrationId: $classRegistrationId) {
      id
      status
      attendeeId
      attendeeType
      gfcSchedule {
        id
        availableSpots
        lastRegistration {
          id
          updatedAt
        }
        ...gfcSchedule
      }
    }
  }
}
    ${GfcScheduleFragmentDoc}`;
export type ClassRegistrationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ClassRegistrationQuery, ClassRegistrationQueryVariables>, 'query'> & ({ variables: ClassRegistrationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ClassRegistrationComponent = (props: ClassRegistrationComponentProps) => (
      <ApolloReactComponents.Query<ClassRegistrationQuery, ClassRegistrationQueryVariables> query={ClassRegistrationDocument} {...props} />
    );
    

/**
 * __useClassRegistrationQuery__
 *
 * To run a query within a React component, call `useClassRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassRegistrationQuery({
 *   variables: {
 *      classRegistrationId: // value for 'classRegistrationId'
 *   },
 * });
 */
export function useClassRegistrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClassRegistrationQuery, ClassRegistrationQueryVariables>) {
        return ApolloReactHooks.useQuery<ClassRegistrationQuery, ClassRegistrationQueryVariables>(ClassRegistrationDocument, baseOptions);
      }
export function useClassRegistrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassRegistrationQuery, ClassRegistrationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClassRegistrationQuery, ClassRegistrationQueryVariables>(ClassRegistrationDocument, baseOptions);
        }
export type ClassRegistrationQueryHookResult = ReturnType<typeof useClassRegistrationQuery>;
export type ClassRegistrationLazyQueryHookResult = ReturnType<typeof useClassRegistrationLazyQuery>;
export type ClassRegistrationQueryResult = ApolloReactCommon.QueryResult<ClassRegistrationQuery, ClassRegistrationQueryVariables>;
export const ClassesRegisteredToDocument = gql`
    query classesRegisteredTo {
  viewer {
    id
    registeredClasses {
      id
      status
      gfcSchedule {
        ...gfcSchedule
      }
    }
  }
}
    ${GfcScheduleFragmentDoc}`;
export type ClassesRegisteredToComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables>, 'query'>;

    export const ClassesRegisteredToComponent = (props: ClassesRegisteredToComponentProps) => (
      <ApolloReactComponents.Query<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables> query={ClassesRegisteredToDocument} {...props} />
    );
    

/**
 * __useClassesRegisteredToQuery__
 *
 * To run a query within a React component, call `useClassesRegisteredToQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesRegisteredToQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesRegisteredToQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassesRegisteredToQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables>) {
        return ApolloReactHooks.useQuery<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables>(ClassesRegisteredToDocument, baseOptions);
      }
export function useClassesRegisteredToLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables>(ClassesRegisteredToDocument, baseOptions);
        }
export type ClassesRegisteredToQueryHookResult = ReturnType<typeof useClassesRegisteredToQuery>;
export type ClassesRegisteredToLazyQueryHookResult = ReturnType<typeof useClassesRegisteredToLazyQuery>;
export type ClassesRegisteredToQueryResult = ApolloReactCommon.QueryResult<ClassesRegisteredToQuery, ClassesRegisteredToQueryVariables>;
export const UnregisteredGfcSchedulesDocument = gql`
    query unregisteredGfcSchedules {
  viewer {
    id
    unregisteredGfcSchedules {
      ...gfcSchedule
    }
  }
}
    ${GfcScheduleFragmentDoc}`;
export type UnregisteredGfcSchedulesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables>, 'query'>;

    export const UnregisteredGfcSchedulesComponent = (props: UnregisteredGfcSchedulesComponentProps) => (
      <ApolloReactComponents.Query<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables> query={UnregisteredGfcSchedulesDocument} {...props} />
    );
    

/**
 * __useUnregisteredGfcSchedulesQuery__
 *
 * To run a query within a React component, call `useUnregisteredGfcSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnregisteredGfcSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnregisteredGfcSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnregisteredGfcSchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables>) {
        return ApolloReactHooks.useQuery<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables>(UnregisteredGfcSchedulesDocument, baseOptions);
      }
export function useUnregisteredGfcSchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables>(UnregisteredGfcSchedulesDocument, baseOptions);
        }
export type UnregisteredGfcSchedulesQueryHookResult = ReturnType<typeof useUnregisteredGfcSchedulesQuery>;
export type UnregisteredGfcSchedulesLazyQueryHookResult = ReturnType<typeof useUnregisteredGfcSchedulesLazyQuery>;
export type UnregisteredGfcSchedulesQueryResult = ApolloReactCommon.QueryResult<UnregisteredGfcSchedulesQuery, UnregisteredGfcSchedulesQueryVariables>;
export const GfcSchedulesDocument = gql`
    query gfcSchedules($after: ISO8601DateTime, $before: ISO8601DateTime) {
  viewer {
    id
    location {
      id
      timezone
      gfcSchedules(after: $after, before: $before) {
        ...gfcSchedule
      }
    }
  }
}
    ${GfcScheduleFragmentDoc}`;
export type GfcSchedulesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfcSchedulesQuery, GfcSchedulesQueryVariables>, 'query'>;

    export const GfcSchedulesComponent = (props: GfcSchedulesComponentProps) => (
      <ApolloReactComponents.Query<GfcSchedulesQuery, GfcSchedulesQueryVariables> query={GfcSchedulesDocument} {...props} />
    );
    

/**
 * __useGfcSchedulesQuery__
 *
 * To run a query within a React component, call `useGfcSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGfcSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGfcSchedulesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGfcSchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfcSchedulesQuery, GfcSchedulesQueryVariables>) {
        return ApolloReactHooks.useQuery<GfcSchedulesQuery, GfcSchedulesQueryVariables>(GfcSchedulesDocument, baseOptions);
      }
export function useGfcSchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfcSchedulesQuery, GfcSchedulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfcSchedulesQuery, GfcSchedulesQueryVariables>(GfcSchedulesDocument, baseOptions);
        }
export type GfcSchedulesQueryHookResult = ReturnType<typeof useGfcSchedulesQuery>;
export type GfcSchedulesLazyQueryHookResult = ReturnType<typeof useGfcSchedulesLazyQuery>;
export type GfcSchedulesQueryResult = ApolloReactCommon.QueryResult<GfcSchedulesQuery, GfcSchedulesQueryVariables>;
export const LimitedGfcSchedulesDocument = gql`
    query limitedGfcSchedules($offset: Int, $filters: GfcFiltersInput) {
  viewer {
    id
    location {
      id
      timezone
      limitedGfcSchedules(offset: $offset, filters: $filters) {
        ...gfcSchedule
      }
    }
  }
}
    ${GfcScheduleFragmentDoc}`;
export type LimitedGfcSchedulesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables>, 'query'>;

    export const LimitedGfcSchedulesComponent = (props: LimitedGfcSchedulesComponentProps) => (
      <ApolloReactComponents.Query<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables> query={LimitedGfcSchedulesDocument} {...props} />
    );
    

/**
 * __useLimitedGfcSchedulesQuery__
 *
 * To run a query within a React component, call `useLimitedGfcSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLimitedGfcSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLimitedGfcSchedulesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useLimitedGfcSchedulesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables>) {
        return ApolloReactHooks.useQuery<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables>(LimitedGfcSchedulesDocument, baseOptions);
      }
export function useLimitedGfcSchedulesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables>(LimitedGfcSchedulesDocument, baseOptions);
        }
export type LimitedGfcSchedulesQueryHookResult = ReturnType<typeof useLimitedGfcSchedulesQuery>;
export type LimitedGfcSchedulesLazyQueryHookResult = ReturnType<typeof useLimitedGfcSchedulesLazyQuery>;
export type LimitedGfcSchedulesQueryResult = ApolloReactCommon.QueryResult<LimitedGfcSchedulesQuery, LimitedGfcSchedulesQueryVariables>;
export const GfcScheduleDocument = gql`
    query gfcSchedule($id: Int!) {
  gfcSchedule(id: $id) {
    ...gfcSchedule
  }
}
    ${GfcScheduleFragmentDoc}`;
export type GfcScheduleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfcScheduleQuery, GfcScheduleQueryVariables>, 'query'> & ({ variables: GfcScheduleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GfcScheduleComponent = (props: GfcScheduleComponentProps) => (
      <ApolloReactComponents.Query<GfcScheduleQuery, GfcScheduleQueryVariables> query={GfcScheduleDocument} {...props} />
    );
    

/**
 * __useGfcScheduleQuery__
 *
 * To run a query within a React component, call `useGfcScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGfcScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGfcScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGfcScheduleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfcScheduleQuery, GfcScheduleQueryVariables>) {
        return ApolloReactHooks.useQuery<GfcScheduleQuery, GfcScheduleQueryVariables>(GfcScheduleDocument, baseOptions);
      }
export function useGfcScheduleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfcScheduleQuery, GfcScheduleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfcScheduleQuery, GfcScheduleQueryVariables>(GfcScheduleDocument, baseOptions);
        }
export type GfcScheduleQueryHookResult = ReturnType<typeof useGfcScheduleQuery>;
export type GfcScheduleLazyQueryHookResult = ReturnType<typeof useGfcScheduleLazyQuery>;
export type GfcScheduleQueryResult = ApolloReactCommon.QueryResult<GfcScheduleQuery, GfcScheduleQueryVariables>;
export const GfcFilterOptionsDocument = gql`
    query gfcFilterOptions {
  viewer {
    id
    location {
      id
      gfcFilterOptions {
        instructor
        title
      }
    }
  }
}
    `;
export type GfcFilterOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables>, 'query'>;

    export const GfcFilterOptionsComponent = (props: GfcFilterOptionsComponentProps) => (
      <ApolloReactComponents.Query<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables> query={GfcFilterOptionsDocument} {...props} />
    );
    

/**
 * __useGfcFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGfcFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGfcFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGfcFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGfcFilterOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables>(GfcFilterOptionsDocument, baseOptions);
      }
export function useGfcFilterOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables>(GfcFilterOptionsDocument, baseOptions);
        }
export type GfcFilterOptionsQueryHookResult = ReturnType<typeof useGfcFilterOptionsQuery>;
export type GfcFilterOptionsLazyQueryHookResult = ReturnType<typeof useGfcFilterOptionsLazyQuery>;
export type GfcFilterOptionsQueryResult = ApolloReactCommon.QueryResult<GfcFilterOptionsQuery, GfcFilterOptionsQueryVariables>;
export const LocationFitnessServiceTypesDocument = gql`
    query locationFitnessServiceTypes {
  viewer {
    id
    location {
      id
      config {
        id
        subscriptionsTitle
        subscriptionsDescription
        subscriptionsIcon
        groupCreditsTitle
        groupCreditsDescription
        groupCreditsIcon
        guestPassIcon
      }
      fitnessServiceTypes {
        id
        title
        iconColor
        iconImage
        description
        externalUrl
        fitnessServices {
          ...fitnessService
        }
      }
      memberships {
        ...membership
      }
      gfcCreditPlans {
        id
        title
        description
        price
        validFor
      }
      guestPassPackages {
        id
        title
        description
        price
        validFor
      }
    }
  }
}
    ${FitnessServiceFragmentDoc}
${MembershipFragmentDoc}`;
export type LocationFitnessServiceTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables>, 'query'>;

    export const LocationFitnessServiceTypesComponent = (props: LocationFitnessServiceTypesComponentProps) => (
      <ApolloReactComponents.Query<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables> query={LocationFitnessServiceTypesDocument} {...props} />
    );
    

/**
 * __useLocationFitnessServiceTypesQuery__
 *
 * To run a query within a React component, call `useLocationFitnessServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationFitnessServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationFitnessServiceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationFitnessServiceTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables>(LocationFitnessServiceTypesDocument, baseOptions);
      }
export function useLocationFitnessServiceTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables>(LocationFitnessServiceTypesDocument, baseOptions);
        }
export type LocationFitnessServiceTypesQueryHookResult = ReturnType<typeof useLocationFitnessServiceTypesQuery>;
export type LocationFitnessServiceTypesLazyQueryHookResult = ReturnType<typeof useLocationFitnessServiceTypesLazyQuery>;
export type LocationFitnessServiceTypesQueryResult = ApolloReactCommon.QueryResult<LocationFitnessServiceTypesQuery, LocationFitnessServiceTypesQueryVariables>;
export const AppLayoutDocument = gql`
    query appLayout {
  viewer {
    id
    impersonated
    email
    intercomToken
    pendingReview
    pointsOnly
    completedTour
    agreedToWaiver
    lapsedMembership
    needsToAddCard
    alertAboutMembershipFee
    bewell {
      show
    }
    location {
      id
      timezone
      city
      name
      hasAppointments
      hasGFCs
      hasEvents
      lockers {
        id
      }
      config {
        id
        eventsLabel
        logo
        dependentsEnabled
        guestsEnabled
        partnersEnabled
        biometricDataEnabled
      }
    }
    intakeSurvey {
      show
    }
    locations {
      id
      city
      name
    }
  }
}
    `;
export type AppLayoutComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AppLayoutQuery, AppLayoutQueryVariables>, 'query'>;

    export const AppLayoutComponent = (props: AppLayoutComponentProps) => (
      <ApolloReactComponents.Query<AppLayoutQuery, AppLayoutQueryVariables> query={AppLayoutDocument} {...props} />
    );
    

/**
 * __useAppLayoutQuery__
 *
 * To run a query within a React component, call `useAppLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppLayoutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppLayoutQuery, AppLayoutQueryVariables>) {
        return ApolloReactHooks.useQuery<AppLayoutQuery, AppLayoutQueryVariables>(AppLayoutDocument, baseOptions);
      }
export function useAppLayoutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppLayoutQuery, AppLayoutQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppLayoutQuery, AppLayoutQueryVariables>(AppLayoutDocument, baseOptions);
        }
export type AppLayoutQueryHookResult = ReturnType<typeof useAppLayoutQuery>;
export type AppLayoutLazyQueryHookResult = ReturnType<typeof useAppLayoutLazyQuery>;
export type AppLayoutQueryResult = ApolloReactCommon.QueryResult<AppLayoutQuery, AppLayoutQueryVariables>;
export const CompanyWaiverDocument = gql`
    query companyWaiver($locationId: Int!) {
  location(locationId: $locationId) {
    id
    companyPolicy {
      id
      title
      waiver
    }
  }
}
    `;
export type CompanyWaiverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyWaiverQuery, CompanyWaiverQueryVariables>, 'query'> & ({ variables: CompanyWaiverQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompanyWaiverComponent = (props: CompanyWaiverComponentProps) => (
      <ApolloReactComponents.Query<CompanyWaiverQuery, CompanyWaiverQueryVariables> query={CompanyWaiverDocument} {...props} />
    );
    

/**
 * __useCompanyWaiverQuery__
 *
 * To run a query within a React component, call `useCompanyWaiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWaiverQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWaiverQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCompanyWaiverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyWaiverQuery, CompanyWaiverQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyWaiverQuery, CompanyWaiverQueryVariables>(CompanyWaiverDocument, baseOptions);
      }
export function useCompanyWaiverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyWaiverQuery, CompanyWaiverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyWaiverQuery, CompanyWaiverQueryVariables>(CompanyWaiverDocument, baseOptions);
        }
export type CompanyWaiverQueryHookResult = ReturnType<typeof useCompanyWaiverQuery>;
export type CompanyWaiverLazyQueryHookResult = ReturnType<typeof useCompanyWaiverLazyQuery>;
export type CompanyWaiverQueryResult = ApolloReactCommon.QueryResult<CompanyWaiverQuery, CompanyWaiverQueryVariables>;
export const HipaaWaiverDocument = gql`
    query hipaaWaiver {
  viewer {
    id
    location {
      id
      companyPolicy {
        id
        hipaaWaiver
      }
    }
  }
}
    `;
export type HipaaWaiverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HipaaWaiverQuery, HipaaWaiverQueryVariables>, 'query'>;

    export const HipaaWaiverComponent = (props: HipaaWaiverComponentProps) => (
      <ApolloReactComponents.Query<HipaaWaiverQuery, HipaaWaiverQueryVariables> query={HipaaWaiverDocument} {...props} />
    );
    

/**
 * __useHipaaWaiverQuery__
 *
 * To run a query within a React component, call `useHipaaWaiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useHipaaWaiverQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHipaaWaiverQuery({
 *   variables: {
 *   },
 * });
 */
export function useHipaaWaiverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HipaaWaiverQuery, HipaaWaiverQueryVariables>) {
        return ApolloReactHooks.useQuery<HipaaWaiverQuery, HipaaWaiverQueryVariables>(HipaaWaiverDocument, baseOptions);
      }
export function useHipaaWaiverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HipaaWaiverQuery, HipaaWaiverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HipaaWaiverQuery, HipaaWaiverQueryVariables>(HipaaWaiverDocument, baseOptions);
        }
export type HipaaWaiverQueryHookResult = ReturnType<typeof useHipaaWaiverQuery>;
export type HipaaWaiverLazyQueryHookResult = ReturnType<typeof useHipaaWaiverLazyQuery>;
export type HipaaWaiverQueryResult = ApolloReactCommon.QueryResult<HipaaWaiverQuery, HipaaWaiverQueryVariables>;
export const TimezoneDocument = gql`
    query timezone {
  viewer {
    id
    location {
      id
      timezone
    }
  }
}
    `;
export type TimezoneComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TimezoneQuery, TimezoneQueryVariables>, 'query'>;

    export const TimezoneComponent = (props: TimezoneComponentProps) => (
      <ApolloReactComponents.Query<TimezoneQuery, TimezoneQueryVariables> query={TimezoneDocument} {...props} />
    );
    

/**
 * __useTimezoneQuery__
 *
 * To run a query within a React component, call `useTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezoneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimezoneQuery, TimezoneQueryVariables>) {
        return ApolloReactHooks.useQuery<TimezoneQuery, TimezoneQueryVariables>(TimezoneDocument, baseOptions);
      }
export function useTimezoneLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimezoneQuery, TimezoneQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimezoneQuery, TimezoneQueryVariables>(TimezoneDocument, baseOptions);
        }
export type TimezoneQueryHookResult = ReturnType<typeof useTimezoneQuery>;
export type TimezoneLazyQueryHookResult = ReturnType<typeof useTimezoneLazyQuery>;
export type TimezoneQueryResult = ApolloReactCommon.QueryResult<TimezoneQuery, TimezoneQueryVariables>;
export const DashboardDocument = gql`
    query dashboard {
  viewer {
    id
    location {
      id
      hasEvents
      hasAppointments
      hasGFCs
      currentChecklist {
        ...checklist
      }
      previousChecklist {
        ...checklist
      }
    }
  }
}
    ${ChecklistFragmentDoc}`;
export type DashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DashboardQuery, DashboardQueryVariables>, 'query'>;

    export const DashboardComponent = (props: DashboardComponentProps) => (
      <ApolloReactComponents.Query<DashboardQuery, DashboardQueryVariables> query={DashboardDocument} {...props} />
    );
    

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = ApolloReactCommon.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const TopNavDocument = gql`
    query topNav {
  viewer {
    id
    location {
      id
      name
      config {
        id
        logo
      }
    }
    locations {
      id
      name
    }
  }
}
    `;
export type TopNavComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TopNavQuery, TopNavQueryVariables>, 'query'>;

    export const TopNavComponent = (props: TopNavComponentProps) => (
      <ApolloReactComponents.Query<TopNavQuery, TopNavQueryVariables> query={TopNavDocument} {...props} />
    );
    

/**
 * __useTopNavQuery__
 *
 * To run a query within a React component, call `useTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNavQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopNavQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TopNavQuery, TopNavQueryVariables>) {
        return ApolloReactHooks.useQuery<TopNavQuery, TopNavQueryVariables>(TopNavDocument, baseOptions);
      }
export function useTopNavLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopNavQuery, TopNavQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TopNavQuery, TopNavQueryVariables>(TopNavDocument, baseOptions);
        }
export type TopNavQueryHookResult = ReturnType<typeof useTopNavQuery>;
export type TopNavLazyQueryHookResult = ReturnType<typeof useTopNavLazyQuery>;
export type TopNavQueryResult = ApolloReactCommon.QueryResult<TopNavQuery, TopNavQueryVariables>;
export const IntakeSurveyDocument = gql`
    query intakeSurvey {
  viewer {
    id
    location {
      id
      intakeSurveyUrl
      config {
        id
        logo
      }
    }
  }
}
    `;
export type IntakeSurveyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IntakeSurveyQuery, IntakeSurveyQueryVariables>, 'query'>;

    export const IntakeSurveyComponent = (props: IntakeSurveyComponentProps) => (
      <ApolloReactComponents.Query<IntakeSurveyQuery, IntakeSurveyQueryVariables> query={IntakeSurveyDocument} {...props} />
    );
    

/**
 * __useIntakeSurveyQuery__
 *
 * To run a query within a React component, call `useIntakeSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntakeSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntakeSurveyQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntakeSurveyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IntakeSurveyQuery, IntakeSurveyQueryVariables>) {
        return ApolloReactHooks.useQuery<IntakeSurveyQuery, IntakeSurveyQueryVariables>(IntakeSurveyDocument, baseOptions);
      }
export function useIntakeSurveyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntakeSurveyQuery, IntakeSurveyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IntakeSurveyQuery, IntakeSurveyQueryVariables>(IntakeSurveyDocument, baseOptions);
        }
export type IntakeSurveyQueryHookResult = ReturnType<typeof useIntakeSurveyQuery>;
export type IntakeSurveyLazyQueryHookResult = ReturnType<typeof useIntakeSurveyLazyQuery>;
export type IntakeSurveyQueryResult = ApolloReactCommon.QueryResult<IntakeSurveyQuery, IntakeSurveyQueryVariables>;
export const TransactionReceiptsDocument = gql`
    query transactionReceipts {
  viewer {
    id
    fullName
    email
    location {
      id
      city
      country
      config {
        id
        logo
      }
    }
  }
}
    `;
export type TransactionReceiptsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TransactionReceiptsQuery, TransactionReceiptsQueryVariables>, 'query'>;

    export const TransactionReceiptsComponent = (props: TransactionReceiptsComponentProps) => (
      <ApolloReactComponents.Query<TransactionReceiptsQuery, TransactionReceiptsQueryVariables> query={TransactionReceiptsDocument} {...props} />
    );
    

/**
 * __useTransactionReceiptsQuery__
 *
 * To run a query within a React component, call `useTransactionReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionReceiptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionReceiptsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionReceiptsQuery, TransactionReceiptsQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionReceiptsQuery, TransactionReceiptsQueryVariables>(TransactionReceiptsDocument, baseOptions);
      }
export function useTransactionReceiptsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionReceiptsQuery, TransactionReceiptsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionReceiptsQuery, TransactionReceiptsQueryVariables>(TransactionReceiptsDocument, baseOptions);
        }
export type TransactionReceiptsQueryHookResult = ReturnType<typeof useTransactionReceiptsQuery>;
export type TransactionReceiptsLazyQueryHookResult = ReturnType<typeof useTransactionReceiptsLazyQuery>;
export type TransactionReceiptsQueryResult = ApolloReactCommon.QueryResult<TransactionReceiptsQuery, TransactionReceiptsQueryVariables>;
export const CartItemCountDocument = gql`
    query cartItemCount {
  viewer {
    id
    cart {
      id
      itemCount
    }
  }
}
    `;
export type CartItemCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CartItemCountQuery, CartItemCountQueryVariables>, 'query'>;

    export const CartItemCountComponent = (props: CartItemCountComponentProps) => (
      <ApolloReactComponents.Query<CartItemCountQuery, CartItemCountQueryVariables> query={CartItemCountDocument} {...props} />
    );
    

/**
 * __useCartItemCountQuery__
 *
 * To run a query within a React component, call `useCartItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartItemCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartItemCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CartItemCountQuery, CartItemCountQueryVariables>) {
        return ApolloReactHooks.useQuery<CartItemCountQuery, CartItemCountQueryVariables>(CartItemCountDocument, baseOptions);
      }
export function useCartItemCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CartItemCountQuery, CartItemCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CartItemCountQuery, CartItemCountQueryVariables>(CartItemCountDocument, baseOptions);
        }
export type CartItemCountQueryHookResult = ReturnType<typeof useCartItemCountQuery>;
export type CartItemCountLazyQueryHookResult = ReturnType<typeof useCartItemCountLazyQuery>;
export type CartItemCountQueryResult = ApolloReactCommon.QueryResult<CartItemCountQuery, CartItemCountQueryVariables>;
export const UserCompletedTourDocument = gql`
    query userCompletedTour {
  viewer {
    id
    completedTour
  }
}
    `;
export type UserCompletedTourComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserCompletedTourQuery, UserCompletedTourQueryVariables>, 'query'>;

    export const UserCompletedTourComponent = (props: UserCompletedTourComponentProps) => (
      <ApolloReactComponents.Query<UserCompletedTourQuery, UserCompletedTourQueryVariables> query={UserCompletedTourDocument} {...props} />
    );
    

/**
 * __useUserCompletedTourQuery__
 *
 * To run a query within a React component, call `useUserCompletedTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompletedTourQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompletedTourQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCompletedTourQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCompletedTourQuery, UserCompletedTourQueryVariables>) {
        return ApolloReactHooks.useQuery<UserCompletedTourQuery, UserCompletedTourQueryVariables>(UserCompletedTourDocument, baseOptions);
      }
export function useUserCompletedTourLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCompletedTourQuery, UserCompletedTourQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserCompletedTourQuery, UserCompletedTourQueryVariables>(UserCompletedTourDocument, baseOptions);
        }
export type UserCompletedTourQueryHookResult = ReturnType<typeof useUserCompletedTourQuery>;
export type UserCompletedTourLazyQueryHookResult = ReturnType<typeof useUserCompletedTourLazyQuery>;
export type UserCompletedTourQueryResult = ApolloReactCommon.QueryResult<UserCompletedTourQuery, UserCompletedTourQueryVariables>;
export const StaffDocument = gql`
    query staff($staffId: Int!) {
  viewer {
    id
    location {
      id
      staff(id: $staffId) {
        id
        name
        about
        avatar
        position
      }
    }
  }
}
    `;
export type StaffComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffQuery, StaffQueryVariables>, 'query'> & ({ variables: StaffQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StaffComponent = (props: StaffComponentProps) => (
      <ApolloReactComponents.Query<StaffQuery, StaffQueryVariables> query={StaffDocument} {...props} />
    );
    

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useStaffQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffQuery, StaffQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, baseOptions);
      }
export function useStaffLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, baseOptions);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = ApolloReactCommon.QueryResult<StaffQuery, StaffQueryVariables>;
export const StaffsDocument = gql`
    query staffs {
  viewer {
    id
    location {
      id
      staffs {
        id
        avatar
        name
        position
        about
      }
    }
  }
}
    `;
export type StaffsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StaffsQuery, StaffsQueryVariables>, 'query'>;

    export const StaffsComponent = (props: StaffsComponentProps) => (
      <ApolloReactComponents.Query<StaffsQuery, StaffsQueryVariables> query={StaffsDocument} {...props} />
    );
    

/**
 * __useStaffsQuery__
 *
 * To run a query within a React component, call `useStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffsQuery, StaffsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffsQuery, StaffsQueryVariables>(StaffsDocument, baseOptions);
      }
export function useStaffsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffsQuery, StaffsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffsQuery, StaffsQueryVariables>(StaffsDocument, baseOptions);
        }
export type StaffsQueryHookResult = ReturnType<typeof useStaffsQuery>;
export type StaffsLazyQueryHookResult = ReturnType<typeof useStaffsLazyQuery>;
export type StaffsQueryResult = ApolloReactCommon.QueryResult<StaffsQuery, StaffsQueryVariables>;
export const AvailableCreditsDocument = gql`
    query availableCredits {
  viewer {
    id
    availableCreditsDisplay
  }
}
    `;
export type AvailableCreditsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableCreditsQuery, AvailableCreditsQueryVariables>, 'query'>;

    export const AvailableCreditsComponent = (props: AvailableCreditsComponentProps) => (
      <ApolloReactComponents.Query<AvailableCreditsQuery, AvailableCreditsQueryVariables> query={AvailableCreditsDocument} {...props} />
    );
    

/**
 * __useAvailableCreditsQuery__
 *
 * To run a query within a React component, call `useAvailableCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCreditsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableCreditsQuery, AvailableCreditsQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableCreditsQuery, AvailableCreditsQueryVariables>(AvailableCreditsDocument, baseOptions);
      }
export function useAvailableCreditsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableCreditsQuery, AvailableCreditsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableCreditsQuery, AvailableCreditsQueryVariables>(AvailableCreditsDocument, baseOptions);
        }
export type AvailableCreditsQueryHookResult = ReturnType<typeof useAvailableCreditsQuery>;
export type AvailableCreditsLazyQueryHookResult = ReturnType<typeof useAvailableCreditsLazyQuery>;
export type AvailableCreditsQueryResult = ApolloReactCommon.QueryResult<AvailableCreditsQuery, AvailableCreditsQueryVariables>;
export const CheckinReportDocument = gql`
    query checkinReport($startDateUnix: Float!, $endDateUnix: Float!) {
  viewer {
    id
    facilityUses(startDateUnix: $startDateUnix, endDateUnix: $endDateUnix) {
      id
      createdAt
      facilityUseOptionLabel
    }
    gfcCheckins(startDateUnix: $startDateUnix, endDateUnix: $endDateUnix) {
      id
      createdAt
      gfcTitle
    }
  }
}
    `;
export type CheckinReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckinReportQuery, CheckinReportQueryVariables>, 'query'> & ({ variables: CheckinReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckinReportComponent = (props: CheckinReportComponentProps) => (
      <ApolloReactComponents.Query<CheckinReportQuery, CheckinReportQueryVariables> query={CheckinReportDocument} {...props} />
    );
    

/**
 * __useCheckinReportQuery__
 *
 * To run a query within a React component, call `useCheckinReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinReportQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinReportQuery({
 *   variables: {
 *      startDateUnix: // value for 'startDateUnix'
 *      endDateUnix: // value for 'endDateUnix'
 *   },
 * });
 */
export function useCheckinReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckinReportQuery, CheckinReportQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckinReportQuery, CheckinReportQueryVariables>(CheckinReportDocument, baseOptions);
      }
export function useCheckinReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckinReportQuery, CheckinReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckinReportQuery, CheckinReportQueryVariables>(CheckinReportDocument, baseOptions);
        }
export type CheckinReportQueryHookResult = ReturnType<typeof useCheckinReportQuery>;
export type CheckinReportLazyQueryHookResult = ReturnType<typeof useCheckinReportLazyQuery>;
export type CheckinReportQueryResult = ApolloReactCommon.QueryResult<CheckinReportQuery, CheckinReportQueryVariables>;
export const ViewerIdDocument = gql`
    query viewerId {
  viewer {
    id
  }
}
    `;
export type ViewerIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerIdQuery, ViewerIdQueryVariables>, 'query'>;

    export const ViewerIdComponent = (props: ViewerIdComponentProps) => (
      <ApolloReactComponents.Query<ViewerIdQuery, ViewerIdQueryVariables> query={ViewerIdDocument} {...props} />
    );
    

/**
 * __useViewerIdQuery__
 *
 * To run a query within a React component, call `useViewerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerIdQuery, ViewerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerIdQuery, ViewerIdQueryVariables>(ViewerIdDocument, baseOptions);
      }
export function useViewerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerIdQuery, ViewerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerIdQuery, ViewerIdQueryVariables>(ViewerIdDocument, baseOptions);
        }
export type ViewerIdQueryHookResult = ReturnType<typeof useViewerIdQuery>;
export type ViewerIdLazyQueryHookResult = ReturnType<typeof useViewerIdLazyQuery>;
export type ViewerIdQueryResult = ApolloReactCommon.QueryResult<ViewerIdQuery, ViewerIdQueryVariables>;
export const IsImpersonatedDocument = gql`
    query isImpersonated {
  viewer {
    id
    impersonated
  }
}
    `;
export type IsImpersonatedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsImpersonatedQuery, IsImpersonatedQueryVariables>, 'query'>;

    export const IsImpersonatedComponent = (props: IsImpersonatedComponentProps) => (
      <ApolloReactComponents.Query<IsImpersonatedQuery, IsImpersonatedQueryVariables> query={IsImpersonatedDocument} {...props} />
    );
    

/**
 * __useIsImpersonatedQuery__
 *
 * To run a query within a React component, call `useIsImpersonatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsImpersonatedQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsImpersonatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsImpersonatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsImpersonatedQuery, IsImpersonatedQueryVariables>) {
        return ApolloReactHooks.useQuery<IsImpersonatedQuery, IsImpersonatedQueryVariables>(IsImpersonatedDocument, baseOptions);
      }
export function useIsImpersonatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsImpersonatedQuery, IsImpersonatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsImpersonatedQuery, IsImpersonatedQueryVariables>(IsImpersonatedDocument, baseOptions);
        }
export type IsImpersonatedQueryHookResult = ReturnType<typeof useIsImpersonatedQuery>;
export type IsImpersonatedLazyQueryHookResult = ReturnType<typeof useIsImpersonatedLazyQuery>;
export type IsImpersonatedQueryResult = ApolloReactCommon.QueryResult<IsImpersonatedQuery, IsImpersonatedQueryVariables>;
export const IntercomUserDocument = gql`
    query intercomUser {
  viewer {
    id
    email
    fullName
    company {
      id
      name
    }
    location {
      id
      city
    }
    createdAt
    intercomToken
  }
}
    `;
export type IntercomUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IntercomUserQuery, IntercomUserQueryVariables>, 'query'>;

    export const IntercomUserComponent = (props: IntercomUserComponentProps) => (
      <ApolloReactComponents.Query<IntercomUserQuery, IntercomUserQueryVariables> query={IntercomUserDocument} {...props} />
    );
    

/**
 * __useIntercomUserQuery__
 *
 * To run a query within a React component, call `useIntercomUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntercomUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntercomUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntercomUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IntercomUserQuery, IntercomUserQueryVariables>) {
        return ApolloReactHooks.useQuery<IntercomUserQuery, IntercomUserQueryVariables>(IntercomUserDocument, baseOptions);
      }
export function useIntercomUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntercomUserQuery, IntercomUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IntercomUserQuery, IntercomUserQueryVariables>(IntercomUserDocument, baseOptions);
        }
export type IntercomUserQueryHookResult = ReturnType<typeof useIntercomUserQuery>;
export type IntercomUserLazyQueryHookResult = ReturnType<typeof useIntercomUserLazyQuery>;
export type IntercomUserQueryResult = ApolloReactCommon.QueryResult<IntercomUserQuery, IntercomUserQueryVariables>;
export const NavLinksQueryDocument = gql`
    query navLinksQuery {
  viewer {
    id
    location {
      id
      hasEvents
      hasAppointments
      hasGFCs
      hasServices
      hasStaff
      lockers {
        id
      }
      config {
        id
        eventsLabel
        videoLibraryEnabled
        biometricDataEnabled
      }
    }
    bewell {
      show
    }
    hasActivities
  }
}
    `;
export type NavLinksQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NavLinksQueryQuery, NavLinksQueryQueryVariables>, 'query'>;

    export const NavLinksQueryComponent = (props: NavLinksQueryComponentProps) => (
      <ApolloReactComponents.Query<NavLinksQueryQuery, NavLinksQueryQueryVariables> query={NavLinksQueryDocument} {...props} />
    );
    

/**
 * __useNavLinksQueryQuery__
 *
 * To run a query within a React component, call `useNavLinksQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavLinksQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavLinksQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavLinksQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NavLinksQueryQuery, NavLinksQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<NavLinksQueryQuery, NavLinksQueryQueryVariables>(NavLinksQueryDocument, baseOptions);
      }
export function useNavLinksQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavLinksQueryQuery, NavLinksQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NavLinksQueryQuery, NavLinksQueryQueryVariables>(NavLinksQueryDocument, baseOptions);
        }
export type NavLinksQueryQueryHookResult = ReturnType<typeof useNavLinksQueryQuery>;
export type NavLinksQueryLazyQueryHookResult = ReturnType<typeof useNavLinksQueryLazyQuery>;
export type NavLinksQueryQueryResult = ApolloReactCommon.QueryResult<NavLinksQueryQuery, NavLinksQueryQueryVariables>;
export const MedicalDocument = gql`
    query medical {
  viewer {
    id
    email
    profile {
      id
      allergies
      medicalConditions
    }
  }
}
    `;
export type MedicalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MedicalQuery, MedicalQueryVariables>, 'query'>;

    export const MedicalComponent = (props: MedicalComponentProps) => (
      <ApolloReactComponents.Query<MedicalQuery, MedicalQueryVariables> query={MedicalDocument} {...props} />
    );
    

/**
 * __useMedicalQuery__
 *
 * To run a query within a React component, call `useMedicalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalQuery({
 *   variables: {
 *   },
 * });
 */
export function useMedicalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MedicalQuery, MedicalQueryVariables>) {
        return ApolloReactHooks.useQuery<MedicalQuery, MedicalQueryVariables>(MedicalDocument, baseOptions);
      }
export function useMedicalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MedicalQuery, MedicalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MedicalQuery, MedicalQueryVariables>(MedicalDocument, baseOptions);
        }
export type MedicalQueryHookResult = ReturnType<typeof useMedicalQuery>;
export type MedicalLazyQueryHookResult = ReturnType<typeof useMedicalLazyQuery>;
export type MedicalQueryResult = ApolloReactCommon.QueryResult<MedicalQuery, MedicalQueryVariables>;
export const MembershipAgreementCopyDocument = gql`
    query membershipAgreementCopy {
  viewer {
    id
    location {
      id
      config {
        id
        membershipAgreementCopy
      }
    }
  }
}
    `;
export type MembershipAgreementCopyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables>, 'query'>;

    export const MembershipAgreementCopyComponent = (props: MembershipAgreementCopyComponentProps) => (
      <ApolloReactComponents.Query<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables> query={MembershipAgreementCopyDocument} {...props} />
    );
    

/**
 * __useMembershipAgreementCopyQuery__
 *
 * To run a query within a React component, call `useMembershipAgreementCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipAgreementCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipAgreementCopyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipAgreementCopyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables>) {
        return ApolloReactHooks.useQuery<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables>(MembershipAgreementCopyDocument, baseOptions);
      }
export function useMembershipAgreementCopyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables>(MembershipAgreementCopyDocument, baseOptions);
        }
export type MembershipAgreementCopyQueryHookResult = ReturnType<typeof useMembershipAgreementCopyQuery>;
export type MembershipAgreementCopyLazyQueryHookResult = ReturnType<typeof useMembershipAgreementCopyLazyQuery>;
export type MembershipAgreementCopyQueryResult = ApolloReactCommon.QueryResult<MembershipAgreementCopyQuery, MembershipAgreementCopyQueryVariables>;
export const VideosDocument = gql`
    query videos($offset: Int, $search: String) {
  videoLibrary(offset: $offset, search: $search) {
    videos {
      id
      title
      description
      duration
      imgUrl
      videoId
      videoUrl
    }
    totalCount
  }
}
    `;
export type VideosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VideosQuery, VideosQueryVariables>, 'query'>;

    export const VideosComponent = (props: VideosComponentProps) => (
      <ApolloReactComponents.Query<VideosQuery, VideosQueryVariables> query={VideosDocument} {...props} />
    );
    

/**
 * __useVideosQuery__
 *
 * To run a query within a React component, call `useVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useVideosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VideosQuery, VideosQueryVariables>) {
        return ApolloReactHooks.useQuery<VideosQuery, VideosQueryVariables>(VideosDocument, baseOptions);
      }
export function useVideosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VideosQuery, VideosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VideosQuery, VideosQueryVariables>(VideosDocument, baseOptions);
        }
export type VideosQueryHookResult = ReturnType<typeof useVideosQuery>;
export type VideosLazyQueryHookResult = ReturnType<typeof useVideosLazyQuery>;
export type VideosQueryResult = ApolloReactCommon.QueryResult<VideosQuery, VideosQueryVariables>;
export const StripeApiKeyDocument = gql`
    query stripeApiKey {
  viewer {
    id
    location {
      id
      stripeApiKey
    }
  }
}
    `;
export type StripeApiKeyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StripeApiKeyQuery, StripeApiKeyQueryVariables>, 'query'>;

    export const StripeApiKeyComponent = (props: StripeApiKeyComponentProps) => (
      <ApolloReactComponents.Query<StripeApiKeyQuery, StripeApiKeyQueryVariables> query={StripeApiKeyDocument} {...props} />
    );
    

/**
 * __useStripeApiKeyQuery__
 *
 * To run a query within a React component, call `useStripeApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StripeApiKeyQuery, StripeApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<StripeApiKeyQuery, StripeApiKeyQueryVariables>(StripeApiKeyDocument, baseOptions);
      }
export function useStripeApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StripeApiKeyQuery, StripeApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StripeApiKeyQuery, StripeApiKeyQueryVariables>(StripeApiKeyDocument, baseOptions);
        }
export type StripeApiKeyQueryHookResult = ReturnType<typeof useStripeApiKeyQuery>;
export type StripeApiKeyLazyQueryHookResult = ReturnType<typeof useStripeApiKeyLazyQuery>;
export type StripeApiKeyQueryResult = ApolloReactCommon.QueryResult<StripeApiKeyQuery, StripeApiKeyQueryVariables>;
export const CartItemsDocument = gql`
    query cartItems {
  viewer {
    id
    cart {
      id
      total
      items {
        ...cartItem
      }
    }
  }
}
    ${CartItemFragmentDoc}`;
export type CartItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CartItemsQuery, CartItemsQueryVariables>, 'query'>;

    export const CartItemsComponent = (props: CartItemsComponentProps) => (
      <ApolloReactComponents.Query<CartItemsQuery, CartItemsQueryVariables> query={CartItemsDocument} {...props} />
    );
    

/**
 * __useCartItemsQuery__
 *
 * To run a query within a React component, call `useCartItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CartItemsQuery, CartItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<CartItemsQuery, CartItemsQueryVariables>(CartItemsDocument, baseOptions);
      }
export function useCartItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CartItemsQuery, CartItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CartItemsQuery, CartItemsQueryVariables>(CartItemsDocument, baseOptions);
        }
export type CartItemsQueryHookResult = ReturnType<typeof useCartItemsQuery>;
export type CartItemsLazyQueryHookResult = ReturnType<typeof useCartItemsLazyQuery>;
export type CartItemsQueryResult = ApolloReactCommon.QueryResult<CartItemsQuery, CartItemsQueryVariables>;
export const UserWidgetsDocument = gql`
    query userWidgets {
  viewer {
    id
    availableCreditsDisplay
    unlimitedCreditsUntil
    guestPassCount
    totalFacilityCheckinByCurrentMonth
    totalGfcCheckinByCurrentMonth
    totalPoints
    firstBank
    company {
      id
      creditsForGfc
    }
    bewell {
      ...bewell
    }
    location {
      id
      config {
        id
        masonJarWidgetEnabled
        onlineCoachingWidgetEnabled
        gfcCheckinWidgetEnabled
        facilityCheckinWidgetEnabled
        freeGfcEnabled
        guestsEnabled
        guestPassInterval
        unlimitedGuestPasses
        rewardPointsEnabled
        pointsRedemptionEnabled
        minRedeemPointLimit
      }
    }
  }
}
    ${BewellFragmentDoc}`;
export type UserWidgetsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserWidgetsQuery, UserWidgetsQueryVariables>, 'query'>;

    export const UserWidgetsComponent = (props: UserWidgetsComponentProps) => (
      <ApolloReactComponents.Query<UserWidgetsQuery, UserWidgetsQueryVariables> query={UserWidgetsDocument} {...props} />
    );
    

/**
 * __useUserWidgetsQuery__
 *
 * To run a query within a React component, call `useUserWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWidgetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWidgetsQuery, UserWidgetsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserWidgetsQuery, UserWidgetsQueryVariables>(UserWidgetsDocument, baseOptions);
      }
export function useUserWidgetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWidgetsQuery, UserWidgetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserWidgetsQuery, UserWidgetsQueryVariables>(UserWidgetsDocument, baseOptions);
        }
export type UserWidgetsQueryHookResult = ReturnType<typeof useUserWidgetsQuery>;
export type UserWidgetsLazyQueryHookResult = ReturnType<typeof useUserWidgetsLazyQuery>;
export type UserWidgetsQueryResult = ApolloReactCommon.QueryResult<UserWidgetsQuery, UserWidgetsQueryVariables>;
export const RewardPointsDocument = gql`
    query rewardPoints {
  viewer {
    id
    totalPoints
    location {
      id
      config {
        id
        rewardPointsEnabled
        pointsRedemptionEnabled
        minRedeemPointLimit
      }
    }
  }
}
    `;
export type RewardPointsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RewardPointsQuery, RewardPointsQueryVariables>, 'query'>;

    export const RewardPointsComponent = (props: RewardPointsComponentProps) => (
      <ApolloReactComponents.Query<RewardPointsQuery, RewardPointsQueryVariables> query={RewardPointsDocument} {...props} />
    );
    

/**
 * __useRewardPointsQuery__
 *
 * To run a query within a React component, call `useRewardPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardPointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardPointsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RewardPointsQuery, RewardPointsQueryVariables>) {
        return ApolloReactHooks.useQuery<RewardPointsQuery, RewardPointsQueryVariables>(RewardPointsDocument, baseOptions);
      }
export function useRewardPointsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RewardPointsQuery, RewardPointsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RewardPointsQuery, RewardPointsQueryVariables>(RewardPointsDocument, baseOptions);
        }
export type RewardPointsQueryHookResult = ReturnType<typeof useRewardPointsQuery>;
export type RewardPointsLazyQueryHookResult = ReturnType<typeof useRewardPointsLazyQuery>;
export type RewardPointsQueryResult = ApolloReactCommon.QueryResult<RewardPointsQuery, RewardPointsQueryVariables>;
export const CartCouponDocument = gql`
    query cartCoupon {
  viewer {
    id
    cart {
      id
      coupon {
        id
        couponCode
        discount
      }
    }
  }
}
    `;
export type CartCouponComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CartCouponQuery, CartCouponQueryVariables>, 'query'>;

    export const CartCouponComponent = (props: CartCouponComponentProps) => (
      <ApolloReactComponents.Query<CartCouponQuery, CartCouponQueryVariables> query={CartCouponDocument} {...props} />
    );
    

/**
 * __useCartCouponQuery__
 *
 * To run a query within a React component, call `useCartCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartCouponQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartCouponQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CartCouponQuery, CartCouponQueryVariables>) {
        return ApolloReactHooks.useQuery<CartCouponQuery, CartCouponQueryVariables>(CartCouponDocument, baseOptions);
      }
export function useCartCouponLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CartCouponQuery, CartCouponQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CartCouponQuery, CartCouponQueryVariables>(CartCouponDocument, baseOptions);
        }
export type CartCouponQueryHookResult = ReturnType<typeof useCartCouponQuery>;
export type CartCouponLazyQueryHookResult = ReturnType<typeof useCartCouponLazyQuery>;
export type CartCouponQueryResult = ApolloReactCommon.QueryResult<CartCouponQuery, CartCouponQueryVariables>;
export const EventLabelDocument = gql`
    query eventLabel {
  viewer {
    id
    location {
      id
      config {
        id
        eventsLabel
      }
    }
  }
}
    `;
export type EventLabelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventLabelQuery, EventLabelQueryVariables>, 'query'>;

    export const EventLabelComponent = (props: EventLabelComponentProps) => (
      <ApolloReactComponents.Query<EventLabelQuery, EventLabelQueryVariables> query={EventLabelDocument} {...props} />
    );
    

/**
 * __useEventLabelQuery__
 *
 * To run a query within a React component, call `useEventLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventLabelQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventLabelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventLabelQuery, EventLabelQueryVariables>) {
        return ApolloReactHooks.useQuery<EventLabelQuery, EventLabelQueryVariables>(EventLabelDocument, baseOptions);
      }
export function useEventLabelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventLabelQuery, EventLabelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventLabelQuery, EventLabelQueryVariables>(EventLabelDocument, baseOptions);
        }
export type EventLabelQueryHookResult = ReturnType<typeof useEventLabelQuery>;
export type EventLabelLazyQueryHookResult = ReturnType<typeof useEventLabelLazyQuery>;
export type EventLabelQueryResult = ApolloReactCommon.QueryResult<EventLabelQuery, EventLabelQueryVariables>;
export const EventsDocument = gql`
    query events($startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
  viewer {
    id
    location {
      id
      events(startDate: $startDate, endDate: $endDate) {
        id
        title
        details
        eventLocation
        price
        startTimedate
        endTimedate
        startDate
        endDate
      }
      config {
        id
        eventsLabel
      }
    }
  }
}
    `;
export type EventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventsQuery, EventsQueryVariables>, 'query'>;

    export const EventsComponent = (props: EventsComponentProps) => (
      <ApolloReactComponents.Query<EventsQuery, EventsQueryVariables> query={EventsDocument} {...props} />
    );
    

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
      }
export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const AppointmentsDocument = gql`
    query appointments($startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
  viewer {
    id
    location {
      id
      appointments(startDate: $startDate, endDate: $endDate) {
        ...appointment
      }
    }
  }
}
    ${AppointmentFragmentDoc}`;
export type AppointmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AppointmentsQuery, AppointmentsQueryVariables>, 'query'>;

    export const AppointmentsComponent = (props: AppointmentsComponentProps) => (
      <ApolloReactComponents.Query<AppointmentsQuery, AppointmentsQueryVariables> query={AppointmentsDocument} {...props} />
    );
    

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, baseOptions);
      }
export function useAppointmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, baseOptions);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = ApolloReactCommon.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
export const EventsCalendarDocument = gql`
    query eventsCalendar($after: ISO8601DateTime!, $before: ISO8601DateTime!) {
  viewer {
    id
    location {
      id
      timezone
      eventCalendarEntries(after: $after, before: $before) {
        ...eventCalendarEntry
      }
    }
  }
}
    ${EventCalendarEntryFragmentDoc}`;
export type EventsCalendarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventsCalendarQuery, EventsCalendarQueryVariables>, 'query'> & ({ variables: EventsCalendarQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EventsCalendarComponent = (props: EventsCalendarComponentProps) => (
      <ApolloReactComponents.Query<EventsCalendarQuery, EventsCalendarQueryVariables> query={EventsCalendarDocument} {...props} />
    );
    

/**
 * __useEventsCalendarQuery__
 *
 * To run a query within a React component, call `useEventsCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsCalendarQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useEventsCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsCalendarQuery, EventsCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsCalendarQuery, EventsCalendarQueryVariables>(EventsCalendarDocument, baseOptions);
      }
export function useEventsCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsCalendarQuery, EventsCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsCalendarQuery, EventsCalendarQueryVariables>(EventsCalendarDocument, baseOptions);
        }
export type EventsCalendarQueryHookResult = ReturnType<typeof useEventsCalendarQuery>;
export type EventsCalendarLazyQueryHookResult = ReturnType<typeof useEventsCalendarLazyQuery>;
export type EventsCalendarQueryResult = ApolloReactCommon.QueryResult<EventsCalendarQuery, EventsCalendarQueryVariables>;
export const EventDocument = gql`
    query event($eventId: Int!, $after: ISO8601DateTime, $before: ISO8601DateTime) {
  viewer {
    id
    location {
      id
      event(eventId: $eventId) {
        id
        price
        type
        availableSlots(after: $after, before: $before) {
          id
          startTime
          endTime
          openSpotsDisplay
          eventId
          eventRegistration {
            id
            startTime
            endTime
            title
            details
            eventLocation
            charge {
              price
            }
          }
        }
      }
    }
  }
}
    `;
export type EventComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventQuery, EventQueryVariables>, 'query'> & ({ variables: EventQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EventComponent = (props: EventComponentProps) => (
      <ApolloReactComponents.Query<EventQuery, EventQueryVariables> query={EventDocument} {...props} />
    );
    

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useEventQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventQuery, EventQueryVariables>) {
        return ApolloReactHooks.useQuery<EventQuery, EventQueryVariables>(EventDocument, baseOptions);
      }
export function useEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, baseOptions);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = ApolloReactCommon.QueryResult<EventQuery, EventQueryVariables>;
export const UpcomingEventRegistrationsDocument = gql`
    query upcomingEventRegistrations {
  viewer {
    id
    location {
      id
      config {
        id
        eventsLabel
      }
    }
    upcomingEventRegistrations {
      id
      title
      eventLocation
      startTime
      endTime
      charge {
        price
      }
    }
  }
}
    `;
export type UpcomingEventRegistrationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables>, 'query'>;

    export const UpcomingEventRegistrationsComponent = (props: UpcomingEventRegistrationsComponentProps) => (
      <ApolloReactComponents.Query<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables> query={UpcomingEventRegistrationsDocument} {...props} />
    );
    

/**
 * __useUpcomingEventRegistrationsQuery__
 *
 * To run a query within a React component, call `useUpcomingEventRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEventRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEventRegistrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingEventRegistrationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables>) {
        return ApolloReactHooks.useQuery<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables>(UpcomingEventRegistrationsDocument, baseOptions);
      }
export function useUpcomingEventRegistrationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables>(UpcomingEventRegistrationsDocument, baseOptions);
        }
export type UpcomingEventRegistrationsQueryHookResult = ReturnType<typeof useUpcomingEventRegistrationsQuery>;
export type UpcomingEventRegistrationsLazyQueryHookResult = ReturnType<typeof useUpcomingEventRegistrationsLazyQuery>;
export type UpcomingEventRegistrationsQueryResult = ApolloReactCommon.QueryResult<UpcomingEventRegistrationsQuery, UpcomingEventRegistrationsQueryVariables>;
export const AvailableApptLengthsDocument = gql`
    query availableApptLengths($apptId: Int!, $startTime: ISO8601DateTime!, $endTime: ISO8601DateTime!) {
  viewer {
    id
    location {
      id
      appointment(apptId: $apptId) {
        id
        availableLengths(startTime: $startTime, endTime: $endTime)
      }
    }
  }
}
    `;
export type AvailableApptLengthsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables>, 'query'> & ({ variables: AvailableApptLengthsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableApptLengthsComponent = (props: AvailableApptLengthsComponentProps) => (
      <ApolloReactComponents.Query<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables> query={AvailableApptLengthsDocument} {...props} />
    );
    

/**
 * __useAvailableApptLengthsQuery__
 *
 * To run a query within a React component, call `useAvailableApptLengthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableApptLengthsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableApptLengthsQuery({
 *   variables: {
 *      apptId: // value for 'apptId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useAvailableApptLengthsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables>(AvailableApptLengthsDocument, baseOptions);
      }
export function useAvailableApptLengthsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables>(AvailableApptLengthsDocument, baseOptions);
        }
export type AvailableApptLengthsQueryHookResult = ReturnType<typeof useAvailableApptLengthsQuery>;
export type AvailableApptLengthsLazyQueryHookResult = ReturnType<typeof useAvailableApptLengthsLazyQuery>;
export type AvailableApptLengthsQueryResult = ApolloReactCommon.QueryResult<AvailableApptLengthsQuery, AvailableApptLengthsQueryVariables>;
export const AvailableApptTimesDocument = gql`
    query availableApptTimes($apptId: Int!, $startTime: ISO8601DateTime!, $endTime: ISO8601DateTime!, $length: Int!) {
  viewer {
    id
    location {
      id
      appointment(apptId: $apptId) {
        id
        availableTimes(startTime: $startTime, endTime: $endTime, length: $length)
      }
    }
  }
}
    `;
export type AvailableApptTimesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableApptTimesQuery, AvailableApptTimesQueryVariables>, 'query'> & ({ variables: AvailableApptTimesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableApptTimesComponent = (props: AvailableApptTimesComponentProps) => (
      <ApolloReactComponents.Query<AvailableApptTimesQuery, AvailableApptTimesQueryVariables> query={AvailableApptTimesDocument} {...props} />
    );
    

/**
 * __useAvailableApptTimesQuery__
 *
 * To run a query within a React component, call `useAvailableApptTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableApptTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableApptTimesQuery({
 *   variables: {
 *      apptId: // value for 'apptId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useAvailableApptTimesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableApptTimesQuery, AvailableApptTimesQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableApptTimesQuery, AvailableApptTimesQueryVariables>(AvailableApptTimesDocument, baseOptions);
      }
export function useAvailableApptTimesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableApptTimesQuery, AvailableApptTimesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableApptTimesQuery, AvailableApptTimesQueryVariables>(AvailableApptTimesDocument, baseOptions);
        }
export type AvailableApptTimesQueryHookResult = ReturnType<typeof useAvailableApptTimesQuery>;
export type AvailableApptTimesLazyQueryHookResult = ReturnType<typeof useAvailableApptTimesLazyQuery>;
export type AvailableApptTimesQueryResult = ApolloReactCommon.QueryResult<AvailableApptTimesQuery, AvailableApptTimesQueryVariables>;
export const AppointmentConfirmationDocument = gql`
    query appointmentConfirmation($apptId: Int!, $length: Int!) {
  viewer {
    id
    location {
      id
      appointment(apptId: $apptId) {
        id
        totalPrice(length: $length)
      }
    }
  }
}
    `;
export type AppointmentConfirmationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables>, 'query'> & ({ variables: AppointmentConfirmationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AppointmentConfirmationComponent = (props: AppointmentConfirmationComponentProps) => (
      <ApolloReactComponents.Query<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables> query={AppointmentConfirmationDocument} {...props} />
    );
    

/**
 * __useAppointmentConfirmationQuery__
 *
 * To run a query within a React component, call `useAppointmentConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentConfirmationQuery({
 *   variables: {
 *      apptId: // value for 'apptId'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useAppointmentConfirmationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables>) {
        return ApolloReactHooks.useQuery<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables>(AppointmentConfirmationDocument, baseOptions);
      }
export function useAppointmentConfirmationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables>(AppointmentConfirmationDocument, baseOptions);
        }
export type AppointmentConfirmationQueryHookResult = ReturnType<typeof useAppointmentConfirmationQuery>;
export type AppointmentConfirmationLazyQueryHookResult = ReturnType<typeof useAppointmentConfirmationLazyQuery>;
export type AppointmentConfirmationQueryResult = ApolloReactCommon.QueryResult<AppointmentConfirmationQuery, AppointmentConfirmationQueryVariables>;
export const UpcomingAppointmentBookingsDocument = gql`
    query upcomingAppointmentBookings {
  viewer {
    id
    upcomingAppointmentBookings {
      ...appointmentBooking
    }
  }
}
    ${AppointmentBookingFragmentDoc}`;
export type UpcomingAppointmentBookingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables>, 'query'>;

    export const UpcomingAppointmentBookingsComponent = (props: UpcomingAppointmentBookingsComponentProps) => (
      <ApolloReactComponents.Query<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables> query={UpcomingAppointmentBookingsDocument} {...props} />
    );
    

/**
 * __useUpcomingAppointmentBookingsQuery__
 *
 * To run a query within a React component, call `useUpcomingAppointmentBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingAppointmentBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingAppointmentBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingAppointmentBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables>(UpcomingAppointmentBookingsDocument, baseOptions);
      }
export function useUpcomingAppointmentBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables>(UpcomingAppointmentBookingsDocument, baseOptions);
        }
export type UpcomingAppointmentBookingsQueryHookResult = ReturnType<typeof useUpcomingAppointmentBookingsQuery>;
export type UpcomingAppointmentBookingsLazyQueryHookResult = ReturnType<typeof useUpcomingAppointmentBookingsLazyQuery>;
export type UpcomingAppointmentBookingsQueryResult = ApolloReactCommon.QueryResult<UpcomingAppointmentBookingsQuery, UpcomingAppointmentBookingsQueryVariables>;
export const AppointmentCalendarEntriesDocument = gql`
    query appointmentCalendarEntries($after: ISO8601DateTime!, $before: ISO8601DateTime!, $apptId: Int) {
  viewer {
    id
    upcomingAppointmentBookings {
      ...appointmentBooking
    }
    location {
      id
      timezone
      appointmentCalendarEntries(after: $after, before: $before, apptId: $apptId) {
        ...eventCalendarEntry
      }
    }
  }
}
    ${AppointmentBookingFragmentDoc}
${EventCalendarEntryFragmentDoc}`;
export type AppointmentCalendarEntriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables>, 'query'> & ({ variables: AppointmentCalendarEntriesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AppointmentCalendarEntriesComponent = (props: AppointmentCalendarEntriesComponentProps) => (
      <ApolloReactComponents.Query<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables> query={AppointmentCalendarEntriesDocument} {...props} />
    );
    

/**
 * __useAppointmentCalendarEntriesQuery__
 *
 * To run a query within a React component, call `useAppointmentCalendarEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCalendarEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCalendarEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      apptId: // value for 'apptId'
 *   },
 * });
 */
export function useAppointmentCalendarEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables>(AppointmentCalendarEntriesDocument, baseOptions);
      }
export function useAppointmentCalendarEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables>(AppointmentCalendarEntriesDocument, baseOptions);
        }
export type AppointmentCalendarEntriesQueryHookResult = ReturnType<typeof useAppointmentCalendarEntriesQuery>;
export type AppointmentCalendarEntriesLazyQueryHookResult = ReturnType<typeof useAppointmentCalendarEntriesLazyQuery>;
export type AppointmentCalendarEntriesQueryResult = ApolloReactCommon.QueryResult<AppointmentCalendarEntriesQuery, AppointmentCalendarEntriesQueryVariables>;
export const EventSlotDocument = gql`
    query eventSlot($eventId: Int!, $slotId: String!) {
  viewer {
    id
    location {
      id
      event(eventId: $eventId) {
        id
        slot(slotId: $slotId) {
          id
          eventId
          openSpotsDisplay
          eventRegistration {
            id
          }
        }
      }
    }
  }
}
    `;
export type EventSlotComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventSlotQuery, EventSlotQueryVariables>, 'query'> & ({ variables: EventSlotQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EventSlotComponent = (props: EventSlotComponentProps) => (
      <ApolloReactComponents.Query<EventSlotQuery, EventSlotQueryVariables> query={EventSlotDocument} {...props} />
    );
    

/**
 * __useEventSlotQuery__
 *
 * To run a query within a React component, call `useEventSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSlotQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      slotId: // value for 'slotId'
 *   },
 * });
 */
export function useEventSlotQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventSlotQuery, EventSlotQueryVariables>) {
        return ApolloReactHooks.useQuery<EventSlotQuery, EventSlotQueryVariables>(EventSlotDocument, baseOptions);
      }
export function useEventSlotLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventSlotQuery, EventSlotQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventSlotQuery, EventSlotQueryVariables>(EventSlotDocument, baseOptions);
        }
export type EventSlotQueryHookResult = ReturnType<typeof useEventSlotQuery>;
export type EventSlotLazyQueryHookResult = ReturnType<typeof useEventSlotLazyQuery>;
export type EventSlotQueryResult = ApolloReactCommon.QueryResult<EventSlotQuery, EventSlotQueryVariables>;
export const RedeemPointsDocument = gql`
    query redeemPoints {
  viewer {
    id
    totalPoints
    location {
      id
      config {
        id
        rewardPointsRedemptionOption
      }
      customRedeemProducts {
        ...rewardRedemptionOption
      }
      tangoCards {
        ...rewardRedemptionOption
      }
    }
  }
}
    ${RewardRedemptionOptionFragmentDoc}`;
export type RedeemPointsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RedeemPointsQuery, RedeemPointsQueryVariables>, 'query'>;

    export const RedeemPointsComponent = (props: RedeemPointsComponentProps) => (
      <ApolloReactComponents.Query<RedeemPointsQuery, RedeemPointsQueryVariables> query={RedeemPointsDocument} {...props} />
    );
    

/**
 * __useRedeemPointsQuery__
 *
 * To run a query within a React component, call `useRedeemPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedeemPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedeemPointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRedeemPointsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RedeemPointsQuery, RedeemPointsQueryVariables>) {
        return ApolloReactHooks.useQuery<RedeemPointsQuery, RedeemPointsQueryVariables>(RedeemPointsDocument, baseOptions);
      }
export function useRedeemPointsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RedeemPointsQuery, RedeemPointsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RedeemPointsQuery, RedeemPointsQueryVariables>(RedeemPointsDocument, baseOptions);
        }
export type RedeemPointsQueryHookResult = ReturnType<typeof useRedeemPointsQuery>;
export type RedeemPointsLazyQueryHookResult = ReturnType<typeof useRedeemPointsLazyQuery>;
export type RedeemPointsQueryResult = ApolloReactCommon.QueryResult<RedeemPointsQuery, RedeemPointsQueryVariables>;
export const UserLocationsQueryDocument = gql`
    query userLocationsQuery {
  viewer {
    id
    location {
      id
      name
      city
    }
    locations {
      id
      name
      city
    }
  }
}
    `;
export type UserLocationsQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserLocationsQueryQuery, UserLocationsQueryQueryVariables>, 'query'>;

    export const UserLocationsQueryComponent = (props: UserLocationsQueryComponentProps) => (
      <ApolloReactComponents.Query<UserLocationsQueryQuery, UserLocationsQueryQueryVariables> query={UserLocationsQueryDocument} {...props} />
    );
    

/**
 * __useUserLocationsQueryQuery__
 *
 * To run a query within a React component, call `useUserLocationsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLocationsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLocationsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLocationsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserLocationsQueryQuery, UserLocationsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<UserLocationsQueryQuery, UserLocationsQueryQueryVariables>(UserLocationsQueryDocument, baseOptions);
      }
export function useUserLocationsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLocationsQueryQuery, UserLocationsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserLocationsQueryQuery, UserLocationsQueryQueryVariables>(UserLocationsQueryDocument, baseOptions);
        }
export type UserLocationsQueryQueryHookResult = ReturnType<typeof useUserLocationsQueryQuery>;
export type UserLocationsQueryLazyQueryHookResult = ReturnType<typeof useUserLocationsQueryLazyQuery>;
export type UserLocationsQueryQueryResult = ApolloReactCommon.QueryResult<UserLocationsQueryQuery, UserLocationsQueryQueryVariables>;
export const ProfileMenuDocument = gql`
    query profileMenu {
  viewer {
    id
    impersonated
    location {
      id
      city
      config {
        id
        dependentsEnabled
        guestsEnabled
        partnersEnabled
      }
    }
  }
}
    `;
export type ProfileMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileMenuQuery, ProfileMenuQueryVariables>, 'query'>;

    export const ProfileMenuComponent = (props: ProfileMenuComponentProps) => (
      <ApolloReactComponents.Query<ProfileMenuQuery, ProfileMenuQueryVariables> query={ProfileMenuDocument} {...props} />
    );
    

/**
 * __useProfileMenuQuery__
 *
 * To run a query within a React component, call `useProfileMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileMenuQuery, ProfileMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileMenuQuery, ProfileMenuQueryVariables>(ProfileMenuDocument, baseOptions);
      }
export function useProfileMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileMenuQuery, ProfileMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileMenuQuery, ProfileMenuQueryVariables>(ProfileMenuDocument, baseOptions);
        }
export type ProfileMenuQueryHookResult = ReturnType<typeof useProfileMenuQuery>;
export type ProfileMenuLazyQueryHookResult = ReturnType<typeof useProfileMenuLazyQuery>;
export type ProfileMenuQueryResult = ApolloReactCommon.QueryResult<ProfileMenuQuery, ProfileMenuQueryVariables>;
export const CardLabelDocument = gql`
    query cardLabel($cardId: Int!) {
  viewer {
    id
    card(cardId: $cardId) {
      id
      label
    }
  }
}
    `;
export type CardLabelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CardLabelQuery, CardLabelQueryVariables>, 'query'> & ({ variables: CardLabelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CardLabelComponent = (props: CardLabelComponentProps) => (
      <ApolloReactComponents.Query<CardLabelQuery, CardLabelQueryVariables> query={CardLabelDocument} {...props} />
    );
    

/**
 * __useCardLabelQuery__
 *
 * To run a query within a React component, call `useCardLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardLabelQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardLabelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CardLabelQuery, CardLabelQueryVariables>) {
        return ApolloReactHooks.useQuery<CardLabelQuery, CardLabelQueryVariables>(CardLabelDocument, baseOptions);
      }
export function useCardLabelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CardLabelQuery, CardLabelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CardLabelQuery, CardLabelQueryVariables>(CardLabelDocument, baseOptions);
        }
export type CardLabelQueryHookResult = ReturnType<typeof useCardLabelQuery>;
export type CardLabelLazyQueryHookResult = ReturnType<typeof useCardLabelLazyQuery>;
export type CardLabelQueryResult = ApolloReactCommon.QueryResult<CardLabelQuery, CardLabelQueryVariables>;
export const UserAvatarDocument = gql`
    query userAvatar {
  viewer {
    id
    profile {
      id
      avatar(size: 100)
    }
    location {
      id
      config {
        id
        usersCanEditProfileImage
      }
    }
  }
}
    `;
export type UserAvatarComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserAvatarQuery, UserAvatarQueryVariables>, 'query'>;

    export const UserAvatarComponent = (props: UserAvatarComponentProps) => (
      <ApolloReactComponents.Query<UserAvatarQuery, UserAvatarQueryVariables> query={UserAvatarDocument} {...props} />
    );
    

/**
 * __useUserAvatarQuery__
 *
 * To run a query within a React component, call `useUserAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvatarQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAvatarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAvatarQuery, UserAvatarQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAvatarQuery, UserAvatarQueryVariables>(UserAvatarDocument, baseOptions);
      }
export function useUserAvatarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAvatarQuery, UserAvatarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAvatarQuery, UserAvatarQueryVariables>(UserAvatarDocument, baseOptions);
        }
export type UserAvatarQueryHookResult = ReturnType<typeof useUserAvatarQuery>;
export type UserAvatarLazyQueryHookResult = ReturnType<typeof useUserAvatarLazyQuery>;
export type UserAvatarQueryResult = ApolloReactCommon.QueryResult<UserAvatarQuery, UserAvatarQueryVariables>;
export const ContactInfoDocument = gql`
    query contactInfo {
  viewer {
    id
    email
    profile {
      id
      city
      state
      zip
      phoneNumber
      workPhone
      contactEmail
    }
    location {
      id
      config {
        id
        usersCanEditAddress
      }
    }
  }
}
    `;
export type ContactInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ContactInfoQuery, ContactInfoQueryVariables>, 'query'>;

    export const ContactInfoComponent = (props: ContactInfoComponentProps) => (
      <ApolloReactComponents.Query<ContactInfoQuery, ContactInfoQueryVariables> query={ContactInfoDocument} {...props} />
    );
    

/**
 * __useContactInfoQuery__
 *
 * To run a query within a React component, call `useContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, baseOptions);
      }
export function useContactInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, baseOptions);
        }
export type ContactInfoQueryHookResult = ReturnType<typeof useContactInfoQuery>;
export type ContactInfoLazyQueryHookResult = ReturnType<typeof useContactInfoLazyQuery>;
export type ContactInfoQueryResult = ApolloReactCommon.QueryResult<ContactInfoQuery, ContactInfoQueryVariables>;
export const ProfileBasicInfoDocument = gql`
    query profileBasicInfo {
  viewer {
    id
    email
    profile {
      id
      avatar(size: 100)
      firstName
      lastName
      ...profileBasicInfo
    }
    location {
      id
      config {
        id
        hideDob
      }
    }
  }
}
    ${ProfileBasicInfoFragmentDoc}`;
export type ProfileBasicInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables>, 'query'>;

    export const ProfileBasicInfoComponent = (props: ProfileBasicInfoComponentProps) => (
      <ApolloReactComponents.Query<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables> query={ProfileBasicInfoDocument} {...props} />
    );
    

/**
 * __useProfileBasicInfoQuery__
 *
 * To run a query within a React component, call `useProfileBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileBasicInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables>(ProfileBasicInfoDocument, baseOptions);
      }
export function useProfileBasicInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables>(ProfileBasicInfoDocument, baseOptions);
        }
export type ProfileBasicInfoQueryHookResult = ReturnType<typeof useProfileBasicInfoQuery>;
export type ProfileBasicInfoLazyQueryHookResult = ReturnType<typeof useProfileBasicInfoLazyQuery>;
export type ProfileBasicInfoQueryResult = ApolloReactCommon.QueryResult<ProfileBasicInfoQuery, ProfileBasicInfoQueryVariables>;
export const LockersDocument = gql`
    query lockers {
  viewer {
    id
    location {
      id
      lockers {
        ...locker
      }
    }
  }
}
    ${LockerFragmentDoc}`;
export type LockersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LockersQuery, LockersQueryVariables>, 'query'>;

    export const LockersComponent = (props: LockersComponentProps) => (
      <ApolloReactComponents.Query<LockersQuery, LockersQueryVariables> query={LockersDocument} {...props} />
    );
    

/**
 * __useLockersQuery__
 *
 * To run a query within a React component, call `useLockersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLockersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLockersQuery({
 *   variables: {
 *   },
 * });
 */
export function useLockersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LockersQuery, LockersQueryVariables>) {
        return ApolloReactHooks.useQuery<LockersQuery, LockersQueryVariables>(LockersDocument, baseOptions);
      }
export function useLockersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LockersQuery, LockersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LockersQuery, LockersQueryVariables>(LockersDocument, baseOptions);
        }
export type LockersQueryHookResult = ReturnType<typeof useLockersQuery>;
export type LockersLazyQueryHookResult = ReturnType<typeof useLockersLazyQuery>;
export type LockersQueryResult = ApolloReactCommon.QueryResult<LockersQuery, LockersQueryVariables>;
export const LockerProrationDocument = gql`
    query lockerProration($lockerId: Int!) {
  viewer {
    id
    locker(lockerId: $lockerId) {
      id
      cancellationProration
    }
  }
}
    `;
export type LockerProrationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LockerProrationQuery, LockerProrationQueryVariables>, 'query'> & ({ variables: LockerProrationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LockerProrationComponent = (props: LockerProrationComponentProps) => (
      <ApolloReactComponents.Query<LockerProrationQuery, LockerProrationQueryVariables> query={LockerProrationDocument} {...props} />
    );
    

/**
 * __useLockerProrationQuery__
 *
 * To run a query within a React component, call `useLockerProrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLockerProrationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLockerProrationQuery({
 *   variables: {
 *      lockerId: // value for 'lockerId'
 *   },
 * });
 */
export function useLockerProrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LockerProrationQuery, LockerProrationQueryVariables>) {
        return ApolloReactHooks.useQuery<LockerProrationQuery, LockerProrationQueryVariables>(LockerProrationDocument, baseOptions);
      }
export function useLockerProrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LockerProrationQuery, LockerProrationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LockerProrationQuery, LockerProrationQueryVariables>(LockerProrationDocument, baseOptions);
        }
export type LockerProrationQueryHookResult = ReturnType<typeof useLockerProrationQuery>;
export type LockerProrationLazyQueryHookResult = ReturnType<typeof useLockerProrationLazyQuery>;
export type LockerProrationQueryResult = ApolloReactCommon.QueryResult<LockerProrationQuery, LockerProrationQueryVariables>;
export const UnreadMessagesDocument = gql`
    query unreadMessages {
  viewer {
    id
    unreadMessages {
      id
      message
      title
      admin {
        id
        fullName
      }
    }
  }
}
    `;
export type UnreadMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>, 'query'>;

    export const UnreadMessagesComponent = (props: UnreadMessagesComponentProps) => (
      <ApolloReactComponents.Query<UnreadMessagesQuery, UnreadMessagesQueryVariables> query={UnreadMessagesDocument} {...props} />
    );
    

/**
 * __useUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<UnreadMessagesQuery, UnreadMessagesQueryVariables>(UnreadMessagesDocument, baseOptions);
      }
export function useUnreadMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnreadMessagesQuery, UnreadMessagesQueryVariables>(UnreadMessagesDocument, baseOptions);
        }
export type UnreadMessagesQueryHookResult = ReturnType<typeof useUnreadMessagesQuery>;
export type UnreadMessagesLazyQueryHookResult = ReturnType<typeof useUnreadMessagesLazyQuery>;
export type UnreadMessagesQueryResult = ApolloReactCommon.QueryResult<UnreadMessagesQuery, UnreadMessagesQueryVariables>;
export const DialogComponentDocument = gql`
    query dialogComponent {
  dialogComponent @client
}
    `;
export type DialogComponentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DialogComponentQuery, DialogComponentQueryVariables>, 'query'>;

    export const DialogComponentComponent = (props: DialogComponentComponentProps) => (
      <ApolloReactComponents.Query<DialogComponentQuery, DialogComponentQueryVariables> query={DialogComponentDocument} {...props} />
    );
    

/**
 * __useDialogComponentQuery__
 *
 * To run a query within a React component, call `useDialogComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDialogComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDialogComponentQuery({
 *   variables: {
 *   },
 * });
 */
export function useDialogComponentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DialogComponentQuery, DialogComponentQueryVariables>) {
        return ApolloReactHooks.useQuery<DialogComponentQuery, DialogComponentQueryVariables>(DialogComponentDocument, baseOptions);
      }
export function useDialogComponentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DialogComponentQuery, DialogComponentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DialogComponentQuery, DialogComponentQueryVariables>(DialogComponentDocument, baseOptions);
        }
export type DialogComponentQueryHookResult = ReturnType<typeof useDialogComponentQuery>;
export type DialogComponentLazyQueryHookResult = ReturnType<typeof useDialogComponentLazyQuery>;
export type DialogComponentQueryResult = ApolloReactCommon.QueryResult<DialogComponentQuery, DialogComponentQueryVariables>;
export const DialogPropsDocument = gql`
    query dialogProps {
  dialogProps @client {
    id
    itemClass
    sessions
    videoUrl
    service {
      id
      title
      description
      price
      validFor
      askAvailability
    }
    event {
      id
      eventId
      title
      eventLocation
      details
      formattedPrice
      startTime
      endTime
      staff {
        id
        name
      }
    }
  }
}
    `;
export type DialogPropsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DialogPropsQuery, DialogPropsQueryVariables>, 'query'>;

    export const DialogPropsComponent = (props: DialogPropsComponentProps) => (
      <ApolloReactComponents.Query<DialogPropsQuery, DialogPropsQueryVariables> query={DialogPropsDocument} {...props} />
    );
    

/**
 * __useDialogPropsQuery__
 *
 * To run a query within a React component, call `useDialogPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDialogPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDialogPropsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDialogPropsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DialogPropsQuery, DialogPropsQueryVariables>) {
        return ApolloReactHooks.useQuery<DialogPropsQuery, DialogPropsQueryVariables>(DialogPropsDocument, baseOptions);
      }
export function useDialogPropsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DialogPropsQuery, DialogPropsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DialogPropsQuery, DialogPropsQueryVariables>(DialogPropsDocument, baseOptions);
        }
export type DialogPropsQueryHookResult = ReturnType<typeof useDialogPropsQuery>;
export type DialogPropsLazyQueryHookResult = ReturnType<typeof useDialogPropsLazyQuery>;
export type DialogPropsQueryResult = ApolloReactCommon.QueryResult<DialogPropsQuery, DialogPropsQueryVariables>;
export const TourDocument = gql`
    query tour {
  tour @client {
    run
    stepIndex
  }
}
    `;
export type TourComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TourQuery, TourQueryVariables>, 'query'>;

    export const TourComponent = (props: TourComponentProps) => (
      <ApolloReactComponents.Query<TourQuery, TourQueryVariables> query={TourDocument} {...props} />
    );
    

/**
 * __useTourQuery__
 *
 * To run a query within a React component, call `useTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourQuery({
 *   variables: {
 *   },
 * });
 */
export function useTourQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TourQuery, TourQueryVariables>) {
        return ApolloReactHooks.useQuery<TourQuery, TourQueryVariables>(TourDocument, baseOptions);
      }
export function useTourLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TourQuery, TourQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TourQuery, TourQueryVariables>(TourDocument, baseOptions);
        }
export type TourQueryHookResult = ReturnType<typeof useTourQuery>;
export type TourLazyQueryHookResult = ReturnType<typeof useTourLazyQuery>;
export type TourQueryResult = ApolloReactCommon.QueryResult<TourQuery, TourQueryVariables>;
export const ProfileMenuOpenDocument = gql`
    query profileMenuOpen {
  profileMenuOpen @client
}
    `;
export type ProfileMenuOpenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables>, 'query'>;

    export const ProfileMenuOpenComponent = (props: ProfileMenuOpenComponentProps) => (
      <ApolloReactComponents.Query<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables> query={ProfileMenuOpenDocument} {...props} />
    );
    

/**
 * __useProfileMenuOpenQuery__
 *
 * To run a query within a React component, call `useProfileMenuOpenQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMenuOpenQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMenuOpenQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileMenuOpenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables>(ProfileMenuOpenDocument, baseOptions);
      }
export function useProfileMenuOpenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables>(ProfileMenuOpenDocument, baseOptions);
        }
export type ProfileMenuOpenQueryHookResult = ReturnType<typeof useProfileMenuOpenQuery>;
export type ProfileMenuOpenLazyQueryHookResult = ReturnType<typeof useProfileMenuOpenLazyQuery>;
export type ProfileMenuOpenQueryResult = ApolloReactCommon.QueryResult<ProfileMenuOpenQuery, ProfileMenuOpenQueryVariables>;
export const VideoSearchDocument = gql`
    query videoSearch {
  videoSearch @client
}
    `;
export type VideoSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VideoSearchQuery, VideoSearchQueryVariables>, 'query'>;

    export const VideoSearchComponent = (props: VideoSearchComponentProps) => (
      <ApolloReactComponents.Query<VideoSearchQuery, VideoSearchQueryVariables> query={VideoSearchDocument} {...props} />
    );
    

/**
 * __useVideoSearchQuery__
 *
 * To run a query within a React component, call `useVideoSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useVideoSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VideoSearchQuery, VideoSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<VideoSearchQuery, VideoSearchQueryVariables>(VideoSearchDocument, baseOptions);
      }
export function useVideoSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VideoSearchQuery, VideoSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VideoSearchQuery, VideoSearchQueryVariables>(VideoSearchDocument, baseOptions);
        }
export type VideoSearchQueryHookResult = ReturnType<typeof useVideoSearchQuery>;
export type VideoSearchLazyQueryHookResult = ReturnType<typeof useVideoSearchLazyQuery>;
export type VideoSearchQueryResult = ApolloReactCommon.QueryResult<VideoSearchQuery, VideoSearchQueryVariables>;
export const RefreshGfcTableDocument = gql`
    query refreshGfcTable {
  refreshGfcTable @client
}
    `;
export type RefreshGfcTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RefreshGfcTableQuery, RefreshGfcTableQueryVariables>, 'query'>;

    export const RefreshGfcTableComponent = (props: RefreshGfcTableComponentProps) => (
      <ApolloReactComponents.Query<RefreshGfcTableQuery, RefreshGfcTableQueryVariables> query={RefreshGfcTableDocument} {...props} />
    );
    

/**
 * __useRefreshGfcTableQuery__
 *
 * To run a query within a React component, call `useRefreshGfcTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshGfcTableQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshGfcTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshGfcTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefreshGfcTableQuery, RefreshGfcTableQueryVariables>) {
        return ApolloReactHooks.useQuery<RefreshGfcTableQuery, RefreshGfcTableQueryVariables>(RefreshGfcTableDocument, baseOptions);
      }
export function useRefreshGfcTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefreshGfcTableQuery, RefreshGfcTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefreshGfcTableQuery, RefreshGfcTableQueryVariables>(RefreshGfcTableDocument, baseOptions);
        }
export type RefreshGfcTableQueryHookResult = ReturnType<typeof useRefreshGfcTableQuery>;
export type RefreshGfcTableLazyQueryHookResult = ReturnType<typeof useRefreshGfcTableLazyQuery>;
export type RefreshGfcTableQueryResult = ApolloReactCommon.QueryResult<RefreshGfcTableQuery, RefreshGfcTableQueryVariables>;
export const UserBasicDetailsDocument = gql`
    query userBasicDetails {
  viewer {
    id
    profile {
      ...profileBasicDetails
    }
    location {
      id
      config {
        id
        hideDob
      }
    }
  }
}
    ${ProfileBasicDetailsFragmentDoc}`;
export type UserBasicDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserBasicDetailsQuery, UserBasicDetailsQueryVariables>, 'query'>;

    export const UserBasicDetailsComponent = (props: UserBasicDetailsComponentProps) => (
      <ApolloReactComponents.Query<UserBasicDetailsQuery, UserBasicDetailsQueryVariables> query={UserBasicDetailsDocument} {...props} />
    );
    

/**
 * __useUserBasicDetailsQuery__
 *
 * To run a query within a React component, call `useUserBasicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBasicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBasicDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBasicDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserBasicDetailsQuery, UserBasicDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserBasicDetailsQuery, UserBasicDetailsQueryVariables>(UserBasicDetailsDocument, baseOptions);
      }
export function useUserBasicDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserBasicDetailsQuery, UserBasicDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserBasicDetailsQuery, UserBasicDetailsQueryVariables>(UserBasicDetailsDocument, baseOptions);
        }
export type UserBasicDetailsQueryHookResult = ReturnType<typeof useUserBasicDetailsQuery>;
export type UserBasicDetailsLazyQueryHookResult = ReturnType<typeof useUserBasicDetailsLazyQuery>;
export type UserBasicDetailsQueryResult = ApolloReactCommon.QueryResult<UserBasicDetailsQuery, UserBasicDetailsQueryVariables>;
export const EmergencyContactDetailsDocument = gql`
    query emergencyContactDetails {
  viewer {
    id
    profile {
      id
      emergencyFirstName
      emergencyMiddleName
      emergencyLastName
      emergencyRelationIndex
      emergencyPhone
      emergencyEmail
      emergencyAddress
    }
  }
}
    `;
export type EmergencyContactDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables>, 'query'>;

    export const EmergencyContactDetailsComponent = (props: EmergencyContactDetailsComponentProps) => (
      <ApolloReactComponents.Query<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables> query={EmergencyContactDetailsDocument} {...props} />
    );
    

/**
 * __useEmergencyContactDetailsQuery__
 *
 * To run a query within a React component, call `useEmergencyContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmergencyContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmergencyContactDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmergencyContactDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables>(EmergencyContactDetailsDocument, baseOptions);
      }
export function useEmergencyContactDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables>(EmergencyContactDetailsDocument, baseOptions);
        }
export type EmergencyContactDetailsQueryHookResult = ReturnType<typeof useEmergencyContactDetailsQuery>;
export type EmergencyContactDetailsLazyQueryHookResult = ReturnType<typeof useEmergencyContactDetailsLazyQuery>;
export type EmergencyContactDetailsQueryResult = ApolloReactCommon.QueryResult<EmergencyContactDetailsQuery, EmergencyContactDetailsQueryVariables>;