import PropTypes from 'prop-types'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import DashboardNav from 'components/DashboardNav'
import TopNav from 'components/TopNav'
import Hidden from '@material-ui/core/Hidden'
import TopBanner from 'components/TopBanner'
import moment from 'moment'

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
})

const bannerShowing = moment().isBetween('2019-08-19', '2019-10-01')

export const Landing = props => (
  <Grid container className={ props.classes.container }>
    <Grid item xs={ 12 }>
      <TopNav bannerShowing={ bannerShowing } />
    </Grid>
    {bannerShowing && <TopBanner />}
    <Grid container>
      <Hidden smDown>
        <Grid item md={ 3 } lg={ 2 }>
          <DashboardNav />
        </Grid>
      </Hidden>
      <Grid item xs={ 12 } md={ 9 } lg={ 10 } role='main'>
        {props.children}
      </Grid>
    </Grid>
  </Grid>
)

Landing.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element,
}

export default withStyles(styles)(Landing)
