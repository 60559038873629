import Dumb from './RemovePartnershipButton'
import React from 'react'
import { Mutation } from 'react-apollo'

import { REMOVE_PARTNERSHIP } from 'mutations/family'

const RemovePartnershipButton = () => (
  <Mutation mutation={ REMOVE_PARTNERSHIP }>
    {(removePartnership, { loading }) => {
      return <Dumb removePartnership={ removePartnership } loading={ loading } />
    }}
  </Mutation>
)

export default RemovePartnershipButton
