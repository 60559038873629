import React, { Fragment, useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './styles'
import {
  ChangeCurrentLocationMutationFn,
  UserLocationsQueryQuery,
} from 'types/graphql'

export interface Props {
  data: UserLocationsQueryQuery;
  changeLocation: ChangeCurrentLocationMutationFn;
  currentPath: string;
}

const ChangeLocationButton = ({
  changeLocation,
  data: {
    viewer: {
      location: { id },
      locations,
    },
  },
  currentPath,
}: Props): JSX.Element => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget as HTMLElement)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleChange = (e: React.MouseEvent): void => {
    const locationId = (e.target as HTMLLIElement).value

    handleClose()

    // Don't do anything if user clicked on location he's already at
    if (locationId === id) return

    changeLocation({
      variables: { locationId },
    }).then((): void => window.location.replace(currentPath))
  }

  return (
    <Fragment>
      <Button
        size='small'
        variant='outlined'
        aria-controls='select-location-menu'
        aria-haspopup='true'
        onClick={ handleClick }
        className={ classes.button }
      >
        Change Location
      </Button>
      <Menu
        id='select-location-menu'
        anchorEl={ anchorEl }
        keepMounted
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
      >
        {locations.map(
          (loc): JSX.Element => (
            <MenuItem key={ loc.id } onClick={ handleChange } value={ loc.id }>
              {loc.name}
            </MenuItem>
          )
        )}
      </Menu>
    </Fragment>
  )
}

export default ChangeLocationButton
