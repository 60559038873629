import React, { FormEvent, SyntheticEvent } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  bg: {
    background: 'url(/images/login-bg.png)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  loginContainer: {
    width: 400,
    margin: 'auto',
    padding: '1.5rem 3rem',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formHeader: {
    color: '#495765',
    margin: '0 0 1.5rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 1.1,
  },
})

export interface Props {
  handleSubmit:
  | ((event: SyntheticEvent<Element, Event>) => void)
  | ((e?: FormEvent<HTMLFormElement> | undefined) => void);
  header: string | JSX.Element;
  children: any;
}

const AuthScreen = (props: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={ classes.bg }>
      <form className={ classes.loginContainer } onSubmit={ props.handleSubmit }>
        <h4 className={ classes.formHeader }>{props.header}</h4>
        {props.children}
      </form>
    </div>
  )
}

export default AuthScreen
