import Dumb from './AddCreditCardForm'
import React, { useState } from 'react'
import { injectStripe, ReactStripeElements } from 'react-stripe-elements'
import { OptionsObject, useSnackbar } from 'notistack'
import {
  AddCreditCardMutationFn,
  CloseDialogMutationFn,
  useAddCreditCardMutation,
  useCloseDialogMutation,
  UserCardsDocument,
  useUserCardsQuery,
} from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'
import { VoidPromiseFn } from 'types'

interface Props {
  stripe?: ReactStripeElements.StripeProps;
}

type EnqueueSnackbar = (
  message: string | React.ReactNode,
  options?: OptionsObject
) => OptionsObject['key'] | null

const createSubmitHandler = (
  stripe: ReactStripeElements.StripeProps,
  enqueueSnackbar: EnqueueSnackbar,
  addCreditCard: AddCreditCardMutationFn,
  asPrimary: boolean,
  closeDialog: CloseDialogMutationFn
): VoidPromiseFn => {
  return async(): Promise<void> => {
    const res = await stripe.createToken()
    if (res.error) {
      enqueueSnackbar(res.error.message, { variant: 'error' })
      return
    }
    if (res.token) {
      await addCreditCard({
        variables: {
          tokenId: res.token.id,
          asPrimary,
        },
      })
      await closeDialog()
    }
  }
}

const AddCreditCardForm = ({ stripe }: Props): JSX.Element | null => {
  const [addCreditCard, { loading: addLoading }] = useAddCreditCardMutation({
    refetchQueries: [{ query: UserCardsDocument }],
  })
  const { data, loading, error } = useUserCardsQuery()
  const [asPrimary, setAsPrimary] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [closeDialog] = useCloseDialogMutation()

  if (loading) return <Spinner />

  if (!data || !stripe) return <ErrorMessage error={ error } />

  const handleSubmit = createSubmitHandler(
    stripe,
    enqueueSnackbar,
    addCreditCard,
    asPrimary,
    closeDialog
  )

  const updateAsPrimary = (): void => {
    setAsPrimary(!asPrimary)
  }

  return (
    <Dumb
      addCreditCardLoading={ addLoading }
      updateAsPrimary={ updateAsPrimary }
      asPrimary={ asPrimary }
      handleSubmit={ handleSubmit }
      closeDialog={ closeDialog }
      showPrimaryCheckbox={ Boolean(data.viewer.cards.length) }
    />
  )
}

export default injectStripe(AddCreditCardForm)
