import React from 'react'
import { ButtonProps } from '@material-ui/core/Button'
import LoadingButton from 'components/LoadingButton'
import { ClaimGfcSpotMutationFn } from 'types/graphql'
import { MapStatusToActionsProps } from 'types'

export interface Props extends ButtonProps, MapStatusToActionsProps {
  loading: boolean;
  claimGfcSpot: ClaimGfcSpotMutationFn;
}

const GFCClaimSpotButton = ({
  loading,
  style,
  claimGfcSpot,
  children,
  onSuccess,
}: Props): JSX.Element => {
  return (
    <LoadingButton
      loading={ loading }
      style={ style }
      onClick={ (): void => {
        claimGfcSpot().then((): void => {
          onSuccess && onSuccess()
        })
      } }
    >
      {children}
    </LoadingButton>
  )
}

export default GFCClaimSpotButton
