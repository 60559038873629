import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    paper: {
      maxWidth: spacing(80),
      marginTop: spacing(2),
      overflowWrap: 'break-word',
    },
    date: {
      fontStyle: 'italic',
    },
    divider: {
      marginRight: spacing(1),
    },
  })
)
