import React from 'react'
import PropTypes from 'prop-types'
import Dumb from './CheckinReport'
import { Query } from 'react-apollo'
import { CHECKIN_REPORT_QUERY } from 'queries'

const CheckinReport = ({ startDateUnix, endDateUnix }) => (
  <Query
    query={ CHECKIN_REPORT_QUERY }
    variables={ { startDateUnix, endDateUnix } }
  >
    {({ loading, data }) => (
      <Dumb
        loading={ loading }
        data={ data }
        startDateUnix={ startDateUnix }
        endDateUnix={ endDateUnix }
      />
    )}
  </Query>
)

CheckinReport.propTypes = {
  startDateUnix: PropTypes.number.isRequired,
  endDateUnix: PropTypes.number.isRequired,
}

export default CheckinReport
