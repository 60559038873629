import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import LogoutButton from 'components/auth/LogoutButton'

export interface Props {
  title?: string;
  errorMessage?: string;
  allowLogout?: boolean;
}

const ErrorDialog = ({
  title = 'Error',
  errorMessage = 'Error loading data. Please contact support.',
  allowLogout,
}: Props): JSX.Element => {
  return (
    <Dialog
      open
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='confirmation-dialog-title'
    >
      <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <p>{errorMessage}</p>
      </DialogContent>
      <DialogActions>
        {Boolean(allowLogout) && (
          <LogoutButton>
            <Button color='secondary'>Log out</Button>
          </LogoutButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
