import React, { useState } from 'react'
import withStyles, {
  StyleRules,
  WithStyles,
} from '@material-ui/core/styles/withStyles'
import PasswordIcon from '@material-ui/icons/VpnKey'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import OrderHistoryIcon from '@material-ui/icons/ShoppingCart'
import SubscriptionsIcon from '@material-ui/icons/Schedule'
import CreditCardsTab from 'components/account/CreditCardsTab'
import OrderHistoryTab from 'components/account/OrderHistoryTab'
import SubscriptionPlansTab from 'components/account/SubscriptionPlansTab'
import ChangePasswordTab from 'components/account/ChangePasswordTab'
import { AccountTabQuery } from 'types/graphql'
import { TabObject } from 'types'
import TabPanel from 'components/TabPanel'
import TabBar from 'components/TabBar'
import Qrcode from 'mdi-material-ui/Qrcode'
import QRCodeDownload from 'components/account/QRCodeDownload'

const styles: StyleRules = {
  tabHeader: {
    textAlign: 'center',
    marginBottom: '-10px',
    fontWeight: 100,
    textTransform: 'uppercase',
    fontSize: '1.1rem',
  },
  tab: {
    minWidth: 50,
  },
}

export interface Props extends WithStyles<typeof styles> {
  data: AccountTabQuery;
}

const Account = ({ classes, data }: Props): JSX.Element => {
  const {
    viewer: {
      location: {
        hasPlans,
        payments,
        config: { qrCodeEnabled },
      },
    },
  } = data

  const paymentsEnabled = payments !== 'DISABLED'

  const tabs: TabObject[] = [
    {
      icon: <CreditCardIcon />,
      value: 'credit cards',
      title: 'Credit Cards',
      component: <CreditCardsTab />,
      show: paymentsEnabled,
    },
    {
      icon: <OrderHistoryIcon />,
      value: 'order history',
      title: 'Order History',
      component: <OrderHistoryTab />,
      show: paymentsEnabled,
    },
    {
      icon: <SubscriptionsIcon />,
      value: 'subscriptions',
      title: 'Subscriptions',
      component: <SubscriptionPlansTab />,
      show: paymentsEnabled && hasPlans,
    },
    {
      icon: <PasswordIcon />,
      value: 'password',
      title: 'Password',
      component: <ChangePasswordTab />,
      show: true,
    },
    {
      icon: <Qrcode />,
      value: 'qrcode',
      title: 'QR Code',
      component: <QRCodeDownload />,
      show: qrCodeEnabled,
    },
  ].filter((tab): boolean => tab.show)

  const [value, setValue] = useState(tabs[0].value)

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ): void => {
    setValue(newValue)
  }

  const tabPanels = tabs.map(
    (tab): JSX.Element => (
      <TabPanel key={ tab.value } index={ tab.value } value={ value }>
        <h2 className={ classes.tabHeader }>{tab.title}</h2>
        {tab.component}
      </TabPanel>
    )
  )

  return (
    <div id='account-tab'>
      <TabBar tabs={ tabs } value={ value } onChange={ handleChange } />
      {tabPanels}
    </div>
  )
}

export default withStyles(styles)(Account)
