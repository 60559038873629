import React, { ReactNode } from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import ChevronIcon from '@material-ui/icons/ChevronRight'
import DialogLink from 'components/DialogLink'
import { DialogProps } from 'types/graphql'
import useStyles from './styles'

export interface Props {
  dialogProps: DialogProps;
  dialogComponent: string;
  cardStyle?: object;
  children: ReactNode;
}

const DetailsLinkCard = ({
  dialogProps,
  dialogComponent,
  cardStyle,
  children,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Card className={ classes.card } style={ cardStyle }>
      <DialogLink
        dialogComponent={ dialogComponent }
        dialogProps={ dialogProps }
        trigger={
          <CardActionArea>
            <CardContent>
              {children}
              <ChevronIcon className={ classes.chevron } />
            </CardContent>
          </CardActionArea>
        }
      />
    </Card>
  )
}

export default DetailsLinkCard
