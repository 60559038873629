import React from 'react'
import useStyles from './styles'
import { Link } from 'react-router-dom'

export interface Props {
  hideSignUp: string;
}

const RegisterLink = ({ hideSignUp }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <p className={ classes.noAccount }>
      Don't have an account?
      {hideSignUp !== '1' ? (
        <Link to='/register' className={ classes.registerLink }>
          Sign up
        </Link>
      ) : (
        <>
          <br />
          See your Fitness Manager to register.
        </>
      )}
    </p>
  )
}

export default RegisterLink
