import React from 'react'
import AccessDenied from 'layouts/AccessDenied'

export const UserUnapproved = (): JSX.Element => (
  <AccessDenied title='Account Was Not Approved'>
    <p>Sorry, your account has been unapproved</p>
  </AccessDenied>
)

export default UserUnapproved
