import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    container: {
      textAlign: 'center',
      margin: spacing(3),
    },
    actionButtonContainer: {
      textAlign: 'center',
      position: 'relative',
      top: '30px',
    },
    helpText: {
      marginBottom: spacing(2),
    },
  })
)
