import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    spinner: {
      marginLeft: spacing(1),
      position: 'relative',
      top: 2,
    },
    containerStyle: {
      float: 'right',
    },
    overlay: {
      paddingRight: spacing(1),
    },
  })
)
