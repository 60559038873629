import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import ChangeLocationButton from 'components/ChangeLocationButton'
import ProfileMenu from 'components/ProfileMenu'
import ShoppingCartButton from 'components/ShoppingCartButton'
import StartTourButton from 'components/StartTourButton'
import MobileMenuButton from 'components/navigation/MobileMenuButton'
import { Location, LocationConfiguration, TopNavQuery } from 'types/graphql'
import useStyles from './styles'

interface LocationProp extends Pick<Location, 'name'> {
  config: Pick<LocationConfiguration, 'logo'>;
}

export interface Props {
  data: TopNavQuery;
  bannerShowing: boolean;
}

const TopNav = ({
  data: {
    viewer: {
      location: {
        name,
        config: { logo },
      },
      locations,
    },
  },
  bannerShowing,
}: Props): JSX.Element => {
  const classes = useStyles({ bannerShowing })

  return (
    <Grid container alignItems='center' className={ classes.container }>
      <Grid item xs={ 12 }>
        <AppBar position='fixed' color='default'>
          <Toolbar className={ classes.toolbar }>
            <img src={ logo } alt='elevation-logo' className={ classes.logo } />
            <span className={ classes.flexGrow }>
              <Typography
                variant='h5'
                component='h1'
                className={ classes.headline }
              >
                {name}
              </Typography>
              {locations.length > 1 ? <ChangeLocationButton /> : ''}
            </span>
            <Hidden smDown>
              <StartTourButton />
              <ShoppingCartButton />
              <ProfileMenu />
            </Hidden>

            <Hidden mdUp>
              <MobileMenuButton />
            </Hidden>
          </Toolbar>
        </AppBar>
      </Grid>
    </Grid>
  )
}

export default TopNav
