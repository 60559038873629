import React from 'react'
import List from '@material-ui/core/List'
import Subheader from 'material-ui/Subheader'
import DashIcon from '@material-ui/icons/Home'
import GFCIcon from '@material-ui/icons/DirectionsBike'
import CheckinIcon from '@material-ui/icons/Beenhere'
import StaffIcon from '@material-ui/icons/Mood'
import HFCIcon from '@material-ui/icons/FitnessCenter'
import EventsIcon from '@material-ui/icons/Event'
import ScheduleIcon from '@material-ui/icons/Schedule'
import BewellIcon from '@material-ui/icons/Favorite'
import LockerIcon from '@material-ui/icons/Https'
import AnalyticsIcon from '@material-ui/icons/ShowChart'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import MenuNavLink from 'components/navigation/MenuNavLink'
import { eventsLink } from 'lib/helpers'
import { Maybe, NavLinksQueryQuery } from 'types/graphql'

export interface Props {
  data: NavLinksQueryQuery;
}

interface MenuItem {
  to: string;
  icon: JSX.Element;
  condition?: Maybe<boolean>;
}

const MenuNavLinks = ({ data }: Props): JSX.Element | null => {
  if (!data.viewer) return null

  const {
    viewer: {
      hasActivities,
      bewell,
      location: {
        config: { biometricDataEnabled, eventsLabel, videoLibraryEnabled },
        hasEvents,
        hasAppointments,
        hasGFCs,
        hasServices,
        hasStaff,
        lockers,
      },
    },
  } = data

  const items: MenuItem[] = [
    { to: '/', icon: <DashIcon /> },
    {
      to: '/group-fitness-classes',
      icon: <GFCIcon />,
      condition: hasGFCs,
    },
    {
      to: '/health-fitness-services',
      icon: <HFCIcon />,
      condition: hasServices,
    },
    {
      to: `/${eventsLink(eventsLabel)}/calendar`,
      icon: <EventsIcon />,
      condition: hasEvents,
    },
    {
      to: '/be-well-challenge',
      icon: <BewellIcon />,
      condition: bewell && bewell.show,
    },
    {
      to: '/appointments',
      icon: <ScheduleIcon />,
      condition: hasAppointments,
    },
    {
      to: '/staff',
      icon: <StaffIcon />,
      condition: hasStaff,
    },
    {
      to: '/lockers',
      icon: <LockerIcon />,
      condition: Boolean(lockers.length),
    },
    {
      to: '/biometrics',
      icon: <FingerprintIcon />,
      condition: biometricDataEnabled,
    },
    {
      to: '/video-library',
      icon: <VideoLibraryIcon />,
      condition: videoLibraryEnabled,
    },
    {
      to: '/analytics',
      icon: <AnalyticsIcon />,
      condition: hasActivities,
    },
    { to: '/facility-info', icon: <CheckinIcon /> },
  ].filter(
    (item): Maybe<boolean> => item.condition === undefined || item.condition
  )

  return (
    <List component='nav' role='navigation'>
      <Subheader>Menu</Subheader>
      {items.map(
        (item): JSX.Element => (
          <MenuNavLink item={ item } key={ item.to } />
        )
      )}
    </List>
  )
}

export default MenuNavLinks
