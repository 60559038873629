import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    subscriptionCard: {
      marginTop: spacing(3),
      marginBottom: spacing(3),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    text: {
      paddingTop: 0,
      paddingBottom: spacing(1),
    },
    errorMessage: {
      padding: spacing(3),
      textAlign: 'center',
    },
    actions: {
      textAlign: 'center',
    },
  })
)
