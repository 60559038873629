import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  (theme: Theme): StyleRules => ({
    container: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    fullAbsolute: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    image: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      opacity: 0,
      transition: 'opacity 1s',
      animationName: 'image-rotate',
      animationDuration: '30s',
      animationIterationCount: 'infinite',
    },
    overlay: {
      backgroundColor: theme.palette.registration.imageOverlay,
    },
  })
)
