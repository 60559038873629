import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CompletedIcon from '@material-ui/icons/CheckCircleOutline'
import tinycolor from 'tinycolor2'

import ToggleItemCompleteButton from 'components/bewell/ToggleItemCompleteButton'

const styles = {
  card: {
    minWidth: 275,
    paddingBottom: '30px',
    position: 'relative',
    borderRadius: 0,
    marginRight: 5,
  },
  points: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  actions: {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
  },
  action: {
    margin: 'auto',
  },
  interval: {
    background: '#ddd',
    lineHeight: '26px',
    margin: '-16px -16px 10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '.7rem',
    boxShadow: '0 3px 2px -2px #BBB',
  },
  completedIcon: {
    position: 'absolute',
    top: '10px',
    left: '0',
    width: '100%',
    height: 'calc(100% - 10px)',
    opacity: '0.2',
    color: 'green',
    pointerEvents: 'none',
  },
}

const BewellItem = ({
  classes,
  isToday,
  color,
  item: {
    id,
    title,
    description,
    points,
    perIntervalDisplay,
    subtitle,
    url,
    urlLabel,
    isCompletedToday,
    isCompletedYesterday,
  },
}) => {
  const isCompleted = isToday ? isCompletedToday : isCompletedYesterday
  return (
    <Card className={ classes.card }>
      <CardContent>
        <Typography
          className={ classes.interval }
          color='textSecondary'
          gutterBottom
          style={ { backgroundColor: tinycolor(color).setAlpha(0.65) } }
        >
          {perIntervalDisplay}
        </Typography>
        <Typography
          className={ classes.points }
          color='textSecondary'
          gutterBottom
        >
          {points} points!
        </Typography>
        <Typography variant='h5' component='h2'>
          {title}
        </Typography>
        <Typography className={ classes.pos } color='textSecondary'>
          {description}
        </Typography>
        {url && urlLabel && (
          // https://mathiasbynens.github.io/rel-noopener
          <a href={ url } target='_blank' rel='noopener noreferrer'>
            {urlLabel}
          </a>
        )}
        <Typography component='p'>{subtitle}</Typography>
        {isCompleted && <CompletedIcon className={ classes.completedIcon } />}
      </CardContent>
      <CardActions className={ classes.actions }>
        <span className={ classes.action }>
          <ToggleItemCompleteButton
            id={ id }
            isToday={ isToday }
            complete={ !isCompleted }
          />
        </span>
      </CardActions>
    </Card>
  )
}

BewellItem.propTypes = {
  classes: PropTypes.object.isRequired,
  isToday: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    perIntervalDisplay: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    url: PropTypes.string,
    urlLabel: PropTypes.string,
    isCompletedToday: PropTypes.bool.isRequired,
    isCompletedYesterday: PropTypes.bool.isRequired,
  }).isRequired,
}

export default withStyles(styles)(BewellItem)
