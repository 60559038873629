import React, { BaseSyntheticEvent, useRef } from 'react'
import { FormikHandlers, useField } from 'formik'
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import useStyles from '../styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { SelectOption } from 'components/registration/types'
import { useFocusOnError } from 'components/registration/helpers'
import { Typography } from '@material-ui/core'

export interface Props {
  options: SelectOption[];
  name: string;
  label: string;
  onChangeProp?: FormikHandlers['handleChange'];
  labelWidth?: number;
  autoFocus?: boolean;
}

export default ({
  options,
  name,
  label,
  onChangeProp,
  labelWidth = 140,
  autoFocus,
}: Props): JSX.Element => {
  const classes = useStyles()
  const fieldRef = useRef<HTMLInputElement>(null)
  const [field, { touched, error }] = useField(name)
  useFocusOnError({ fieldRef, name })

  const handleChange = (
    e: BaseSyntheticEvent,
    onChange: FormikHandlers['handleChange'],
  ): void => {
    onChangeProp && onChangeProp(e)
    onChange(e)
  }

  return (
    <FormControl
      className={ classes.field }
      required
      error={ Boolean(touched && error) }
      variant='outlined'
    >
      {labelWidth ? (
        <InputLabel id={ `${name}-select` }>{label}</InputLabel>
      ) : (
        <Typography
          id={ `${name}-select` }
          variant='body2'
          className={ classes.customFieldLabel }
        >
          {label}
        </Typography>
      )}
      <Select
        { ...field }
        value={ field.value || '' }
        labelId={ `${name}-select` }
        onChange={
          onChangeProp ?
            (e): void => {
              handleChange(e, field.onChange)
            } :
            field.onChange
        }
        SelectDisplayProps={ {
          'aria-describedby': `${name}-select-error`,
          'aria-invalid': Boolean(touched && error),
        } }
        input={
          <OutlinedInput
            labelWidth={ labelWidth }
            name={ name }
            id={ `${name}-select` }
            data-testid={ name }
            inputProps={ {
              ref: fieldRef,
              autoFocus,
            } }
          />
        }
        fullWidth
      >
        {options.map(
          ({ id, name }): JSX.Element => (
            <MenuItem value={ id } key={ id }>
              {name}
            </MenuItem>
          ),
        )}
      </Select>
      <FormHelperText id={ `${name}-select-error` }>
        {touched && error}
      </FormHelperText>
    </FormControl>
  )
}
