import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, Route, Redirect } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'

import ClaimOpenSpot from 'components/classRegistrations/ClaimOpenSpot'

const useStyles = makeStyles({
  paper: {
    padding: 25,
  },
})

const ClassRegistrations = ({ match }) => {
  const classes = useStyles()
  return (
    <Paper className={ classes.paper }>
      <Switch>
        <Route
          exact
          path={ `${match.path}/:registrationId/claim-open-spot` }
          component={ ClaimOpenSpot }
        />
        <Redirect to='/' />
      </Switch>
    </Paper>
  )
}

ClassRegistrations.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
}

export default ClassRegistrations
