import Dumb from './PasswordInput'
import React from 'react'
import { Field, FieldProps } from 'formik'

export interface Props {
  label: string;
  name: string;
  error?: string | boolean;
}

const PasswordInput = (props: Props): JSX.Element => {
  return (
    <Field key={ props.name } name={ props.name }>
      {({ field }: FieldProps<unknown>): JSX.Element => (
        <Dumb { ...field } { ...props } />
      )}
    </Field>
  )
}

export default PasswordInput
