import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  button: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
