import React from 'react'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'
import moment from 'moment'
import RenewSubscriptionButton from 'components/account/RenewSubscriptionButton'
import CancelSubscriptionButton from 'components/account/CancelSubscriptionButton'
import { UserSubscriptionsQuery } from 'types/graphql'
import useStyles from './styles'

export interface Props {
  data: UserSubscriptionsQuery;
}

const dateFormat = 'MM/DD/YYYY'
const formatDate = (num: number): string => moment.unix(num).format(dateFormat)

const SubscriptionPlansTab = ({
  data: {
    viewer: { subscriptions },
  },
}: Props): JSX.Element => {
  const classes = useStyles()

  if (!subscriptions || !subscriptions.length) {
    return <p className={ classes.errorMessage }>You have no subscriptions.</p>
  }

  return (
    <div>
      {subscriptions.map(
        (sub): JSX.Element => (
          <Card className={ classes.subscriptionCard } key={ sub.id }>
            <CardHeader
              title={ sub.name }
              subtitle={ `${sub.price} / ${sub.payFrequency}` }
              textStyle={ { paddingRight: 0 } }
            />
            <CardText className={ classes.text }>
              {sub.cancelAtPeriodEnd ? 'Cancels On: ' : 'Next Payment Due: '}
              {formatDate(sub.currentPeriodEnd)}
            </CardText>
            {sub.allowUserRenewal && (
              <CardActions className={ classes.actions }>
                {sub.cancelAtPeriodEnd ? (
                  <RenewSubscriptionButton subscriptionId={ sub.id } />
                ) : (
                  <CancelSubscriptionButton subscriptionId={ sub.id } />
                )}
              </CardActions>
            )}
          </Card>
        )
      )}
    </div>
  )
}

export default SubscriptionPlansTab
