import Dumb from './StaffList'
import { graphql } from 'react-apollo'
import { STAFFS_QUERY } from 'queries'

const StaffList = graphql(STAFFS_QUERY, {
  // ownProps are the props that are passed into the `GFCDigest`
  // when it is used by a parent component
  props: ({ data: { loading, viewer } }) => ({
    loading,
    staffs: viewer && viewer.location.staffs,
  }),
})(Dumb)

export default StaffList
