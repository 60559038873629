import Dumb from './RedeemPointsCatalog'
import React from 'react'
import {
  CloseDialogMutationFn,
  RedeemPointsQuery,
  RedemptionOptionsEnum,
} from 'types/graphql'

export interface Props {
  data: RedeemPointsQuery;
  closeDialog?: CloseDialogMutationFn;
}

const RedeemPointsCatalog = ({ data, ...props }: Props): JSX.Element => {
  const {
    viewer: {
      totalPoints,
      location: {
        config: { rewardPointsRedemptionOption },
        customRedeemProducts,
        tangoCards,
      },
    },
  } = data

  const items =
    rewardPointsRedemptionOption === RedemptionOptionsEnum.Custom ?
      customRedeemProducts :
      tangoCards

  return <Dumb items={ items } totalPoints={ totalPoints } { ...props } />
}

export default RedeemPointsCatalog
