import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LogoutButton from 'components/auth/LogoutButton'
import { CloseDialogMutationFn, RedeemPointsQuery } from 'types/graphql'
import useStyles from './styles'
import RedeemPointsCatalog from 'components/RewardPoints/RedeemPointsCatalog'
import RedeemPointsCatalogLoading from 'components/RewardPoints/RedeemPointsCatalog/RedeemPointsCatalogLoading'

export interface Props {
  data?: RedeemPointsQuery;
  closeDialog?: CloseDialogMutationFn;
  isForced?: boolean;
}

const RedeemPointsDialog = ({
  closeDialog,
  data,
  isForced,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Dialog maxWidth='md' open onClose={ closeDialog }>
      <DialogTitle disableTypography className={ classes.title }>
        <Typography variant='h6'>
          Choose a Product to Redeem!
          <br />
          {data && `You have ${data.viewer.totalPoints} points`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {data ? (
          <RedeemPointsCatalog closeDialog={ closeDialog } data={ data } />
        ) : (
          <RedeemPointsCatalogLoading />
        )}
      </DialogContent>
      <DialogActions>
        {isForced ? (
          <LogoutButton>
            <Button color='secondary'>Close</Button>
          </LogoutButton>
        ) : null}
        {closeDialog ? (
          <Button
            onClick={ (): void => {
              closeDialog()
            } }
          >
            Close
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

export default RedeemPointsDialog
