import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { confirmable, ReactConfirmProps } from 'react-confirm'
import Theme from 'lib/muiTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

export interface Props extends ReactConfirmProps {
  okLabel: string;
  cancelLabel: string;
  title: string;
  showCancel: boolean;
}

// export for testing
export const Confirmation = ({
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  title = 'Are you sure?',
  confirmation,
  show,
  dismiss,
  proceed,
  cancel,
  showCancel = true,
}: Props): JSX.Element => {
  const [result, setResult] = useState()

  return (
    <Theme>
      <Dialog open={ show } onClose={ dismiss } maxWidth='xs' fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {typeof confirmation === 'string' ? (
            <DialogContentText>{confirmation}</DialogContentText>
          ) : (
            React.cloneElement(confirmation, { result, setResult })
          )}
        </DialogContent>
        <DialogActions>
          {showCancel && (
            <Button variant='contained' onClick={ (): void => cancel() }>
              {cancelLabel}
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={ (): void => proceed(result) }
          >
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </Theme>
  )
}

export default confirmable(Confirmation)
