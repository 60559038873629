import Dumb from './FitnessServiceActions'
import React, { SyntheticEvent } from 'react'
import AvailabilityDialog from 'components/hfs/AvailabilityDialog'
import AutoRenewDialog from 'components/hfs/AutoRenewDialog'
import confirm from 'lib/confirm'
import { trackEvent } from 'lib/analytics'
import { FitnessServiceProps } from 'components/hfs/types'

type AddItemToCartFn = (
  id: number,
  staffId?: number,
  autoRenew?: boolean,
  extraInfo?: { availability?: string; comments?: string }
) => void

export type AddItemFn = (
  event: SyntheticEvent,
  id: number,
  staffId?: number,
  askAvailability?: boolean | null,
  askAutoRenew?: boolean | null
) => void

const askAvailabilityConfirm = (
  id: number,
  addItemToCart: AddItemToCartFn,
  staffId?: number
): void => {
  confirm(<AvailabilityDialog />, {
    title: 'Availability and Comments',
  }).then(
    (extraInfo): void => {
      addItemToCart(id, staffId, undefined, extraInfo)
    },
    (): void => {}
  )
}

const askAutoRenewConfirm = (
  id: number,
  addItemToCart: AddItemToCartFn
): void => {
  confirm(<AutoRenewDialog />, {
    title: 'Auto Renew?',
    okLabel: 'Yes',
    cancelLabel: 'No',
  }).then(
    // proceed callback
    (): void => addItemToCart(id, undefined, true),
    // cancel callback
    (): void => addItemToCart(id, undefined, false)
  )
}

// askAutoRenew and askAvailability are mutually exclusive
const addItem = (addItemToCart: AddItemToCartFn): AddItemFn => (
  event: SyntheticEvent,
  id: number,
  staffId?: number,
  askAvailability?: boolean | null,
  askAutoRenew?: boolean | null
): void => {
  event.stopPropagation()
  if (askAvailability) {
    askAvailabilityConfirm(id, addItemToCart, staffId)
  } else if (askAutoRenew) {
    askAutoRenewConfirm(id, addItemToCart)
  } else {
    addItemToCart(id, staffId)
  }
}

type Props = Omit<FitnessServiceProps, 'expiryText' | 'advanceTour'>

const reportToGA = (): void => {
  trackEvent({
    category: 'Cart',
    action: 'Item Added',
    label: 'Fitness Service',
  })
}

const FitnessServiceActions = (props: Props): JSX.Element => {
  const { addItemToCartMutation, itemClass, sessions, service } = props

  const addItemToCart = (
    id: number,
    staffId?: number,
    autoRenew?: boolean,
    extraInfo: { availability?: string; comments?: string } = {
      availability: undefined,
      comments: undefined,
    }
  ): void => {
    addItemToCartMutation({
      variables: {
        itemId: id,
        itemClass: itemClass,
        quantity: sessions,
        staffId: staffId,
        userAvailability: extraInfo.availability,
        userComments: extraInfo.comments,
        // if autoRenew is passed, it's from user selection so take that.
        // Otherwise, force autoRenew or default to false. Memberships only.
        autoRenew:
          autoRenew === undefined ? service.autoRenew || false : autoRenew,
      },
    }).then(reportToGA)
  }

  return <Dumb addItem={ addItem(addItemToCart) } { ...props } />
}

export default FitnessServiceActions
