import Dumb from './CancelSubscriptionButton'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { CANCEL_SUBSCRIPTION_MUTATION } from 'mutations/account'

const CancelSubscriptionButton = ({ subscriptionId }) => (
  <Mutation
    mutation={ CANCEL_SUBSCRIPTION_MUTATION }
    variables={ { subscriptionId } }
  >
    {(cancelSubscription, { loading }) => (
      <Dumb cancelSubscription={ cancelSubscription } loading={ loading } />
    )}
  </Mutation>
)

CancelSubscriptionButton.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
}

export default CancelSubscriptionButton
