import React from 'react'
import Dumb from './ChangeLocationButton'
import {
  useChangeCurrentLocationMutation,
  useUserLocationsQueryQuery,
} from 'types/graphql'
import { RouteComponentProps, withRouter } from 'react-router'

const ChangeLocationButton = ({
  location: { pathname: currentPath },
}: RouteComponentProps): JSX.Element | null => {
  const { data, loading } = useUserLocationsQueryQuery()
  const [
    changeLocation,
    { loading: mutationLoading },
  ] = useChangeCurrentLocationMutation()

  if (loading || mutationLoading || !data) return null

  return (
    <Dumb
      changeLocation={ changeLocation }
      data={ data }
      currentPath={ currentPath }
    />
  )
}

export default withRouter(ChangeLocationButton)
