// give all dialogs isOpen and closeDialog
import Dumb from './ElevationDialog'
import React from 'react'
import { Mutation, Query } from 'react-apollo'
import { CLOSE_DIALOG } from 'mutations/local'
import { DIALOG_COMPONENT_QUERY } from 'queries/local'

const ElevationDialog = props => (
  <Query query={ DIALOG_COMPONENT_QUERY }>
    {({ data: { dialogComponent } }) => {
      if (!dialogComponent) return ''
      return (
        <Mutation mutation={ CLOSE_DIALOG }>
          {closeDialog => {
            return (
              <Dumb
                dialogComponent={ dialogComponent }
                closeDialog={ closeDialog }
                { ...props }
              />
            )
          }}
        </Mutation>
      )
    }}
  </Query>
)

export default ElevationDialog
