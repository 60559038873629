import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import useStyles from './styles'
import { Form, Formik, FormikFormProps } from 'formik'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'

export interface Props extends FormikFormProps {
  handleSubmit: (value: string) => Promise<any>;
  name: string;
  formName: string;
  hintText: string;
  style?: {};
  inputProps?: {};
  shouldResetForm?: boolean;
}

const InlineForm = (props: Props): JSX.Element => {
  const classes = useStyles()

  const initialValues = { [props.name]: '' }
  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ (values, { resetForm }): void => {
        props.handleSubmit(values[props.name]).then((): void => {
          props.shouldResetForm && resetForm(initialValues)
        })
      } }
    >
      {({ values, handleChange }): JSX.Element => {
        return (
          <Form style={ props.style }>
            <TextField
              name={ props.name }
              value={ values[props.name] }
              placeholder={ props.hintText }
              className={ classNames(classes.field) }
              inputProps={ {
                ...props.inputProps,
                'aria-label': props.formName,
              } }
              fullWidth
              onChange={ handleChange }
            />
            <IconButton type='submit' aria-label={ `Submit ${props.formName}` }>
              <AddIcon />
            </IconButton>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InlineForm
