import { makeStyles, Theme, StyleRules } from '@material-ui/core/styles'

// Style the child elements with active color
export default makeStyles(
  (theme: Theme): StyleRules => ({
    active: {
      '& [class*="nav-menu-content"] *': {
        color: theme.palette.primary.main,
      },
    },
  })
)
