import React, { Dispatch, SetStateAction } from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import withStyles, {
  StyleRules,
  WithStyles,
} from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import moment from 'moment'
import { DATE_FORMAT_OFFICIAL, TIME_FORMAT } from 'lib/formats'
import BewellBoard from 'components/bewell/BewellBoard'
import ToggleDayButton from 'components/bewell/ToggleDayButton'
import BewellStoneDisplay from 'components/bewell/BewellStoneDisplay'
import ErrorMessage from 'components/ErrorMessage'
import { BewellLandingQuery } from 'types/graphql'

const styles = (theme: Theme): StyleRules => ({
  header: {
    position: 'relative',
    textAlign: 'center',
    background: 'url(/images/backgrounds/bewell.png)',
    padding: 20,
    boxShadow: theme.shadows[5],
    borderRadius: 10,
  },
  board: { marginTop: '20px' },
  pointsCircle: {
    borderRadius: '50%',
    width: 150,
    height: 150,
    margin: 'auto',
  },
  pointsGrid: {
    height: '100%',
  },
})

export interface Props extends WithStyles<typeof styles> {
  setIsToday: Dispatch<SetStateAction<boolean>>;
  isToday: boolean;
  data: BewellLandingQuery;
}

const Bewell = ({
  classes,
  setIsToday,
  isToday,
  data: {
    viewer: { bewell },
  },
}: Props): JSX.Element => {
  // REVIEW: Make bewell non-null stop using the presence of `user.bewell` to
  // determine if Be Well is configured
  if (!bewell) return <ErrorMessage />

  const { dayDisplay, dayNumber, cutoff, points } = bewell
  const currentDateMoment = isToday ? moment() : moment().subtract(1, 'day')

  const cutoffMoment = moment()
    .local()
    .startOf('day')
    .add(cutoff, 'seconds')
  const nextCutoffMoment = isToday ?
    cutoffMoment.clone().add(1, 'day') :
    cutoffMoment.clone()

  return (
    <Grid container className={ classes.header }>
      <Grid item md={ 3 } xs={ 12 }>
        <Paper className={ classes.pointsCircle }>
          <Grid
            container
            alignItems='center'
            justify='center'
            className={ classes.pointsGrid }
          >
            <Grid item xs={ 12 }>
              <Typography variant='h6'>You have</Typography>
              <Typography variant='h4' className={ classes.points }>
                {points}
              </Typography>
              <Typography variant='h6'>points</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={ 6 } xs={ 12 }>
        <Typography variant='h4'>
          Submit your points for day {dayDisplay}
        </Typography>
        <Typography variant='subtitle2' gutterBottom>
          <strong>Note:</strong> You have until{' '}
          {nextCutoffMoment.format(TIME_FORMAT)} on{' '}
          {nextCutoffMoment.format(DATE_FORMAT_OFFICIAL)} to submit points for
          Day {dayNumber}.
        </Typography>
        <Grid item xs={ 12 }>
          <BewellStoneDisplay />
        </Grid>
      </Grid>
      <Grid item md={ 3 } xs={ 12 }>
        <Typography variant='h6'>
          {currentDateMoment.format(DATE_FORMAT_OFFICIAL)}
        </Typography>
        <ToggleDayButton
          isToday={ isToday }
          setIsToday={ setIsToday }
          cutoffMoment={ cutoffMoment }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <BewellBoard className={ classes.board } isToday={ isToday } />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Bewell)
