import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'

const ResetPasswordDialog = (): JSX.Element => (
  <DialogForm loading={ false } submitLabel='Submit'>
    <h2 style={ { fontWeight: 100 } }>Don't Fret!</h2>
    <TextFieldInput name='email' />
    <p style={ { textAlign: 'center' } }>
      If we find your email in our user base, we'll send you an email with reset
      instructions.
    </p>
  </DialogForm>
)

export default ResetPasswordDialog
