import React, { useContext } from 'react'
import Dumb from './VerifyUserStep'
import { Context } from '../types'
import { RegistrationContext } from 'components/registration/helpers'
import {
  RequiredUserVerificationFieldFragment,
  useLocationUpdatesQuery,
  useVerifyUserMutation,
} from 'types/graphql-auth'
import { Form, Formik, FormikProps } from 'formik'
import map from 'lodash/map'
import moment from 'moment'

let initialValues = {}

const setupInitialValues = (
  verificationFields: RequiredUserVerificationFieldFragment[]
): void => {
  verificationFields.forEach((field): void => {
    if (field.fieldType === 'date') {
      Object.assign(initialValues, {
        [field.name]: moment().format('MM/DD/YYYY'),
      })
    } else {
      Object.assign(initialValues, { [field.name]: '' })
    }
  })
}

const renderForm = (
  formikProps: FormikProps<{ [s: string]: string }>,
  verificationLoading: boolean,
  verificationFields: RequiredUserVerificationFieldFragment[]
): JSX.Element => {
  const filteredFields = verificationFields.filter(
    (field): boolean => field.name !== 'email'
  )
  return (
    <Form>
      <Dumb
        formikProps={ formikProps }
        verificationLoading={ verificationLoading }
        verificationFields={ filteredFields }
      />
    </Form>
  )
}

const VerifyUserStep = (): JSX.Element | null => {
  const {
    formValues,
    setFormValues,
    setActiveStep,
    activeStep,
    stepAmount,
    setFormComplete,
  } = useContext(RegistrationContext) as Context

  const [verifyUser, { loading: verificationLoading }] = useVerifyUserMutation()

  // Don't really need the whole query, but it's in the cache anyway...
  const { data, loading, error } = useLocationUpdatesQuery({
    variables: { locationId: formValues.user.locationId as number },
  })

  // TODO: change to error handling component
  if (!data) return <p>Error: {error && error.message}</p>

  if (loading || !data.location.company.requiredUserVerificationFields.length)
    return null

  const handleNext = (): void => {
    // go to next step
    setActiveStep((prevActiveStep: number): number => prevActiveStep + 1)
    // Show waiver if we're done
    if (activeStep === stepAmount - 1) setFormComplete(true)
  }

  const validate = async(
    values: object
  ): Promise<void | { [s: string]: string }> => {
    const verificationFields = map(values, (value, key): {
      key: string;
      value: string;
    } => {
      if (key === 'email')
        return { key, value: formValues.user.email as string }
      return { key, value }
    })

    const res = await verifyUser({
      variables: {
        verificationFields: verificationFields.filter((field): boolean =>
          Boolean(field.value)
        ),
        companyId: formValues.user.companyId as number,
      },
    })

    if (
      res &&
      res.data &&
      res.data.verifyUser &&
      res.data.verifyUser.verifiedUserId
    ) {
      setFormValues({
        ...formValues,
        verifiedUserId: res.data.verifyUser.verifiedUserId,
      })
      return
    }

    return {
      verifyUser: 'Verification failed. Please contact facility manager.',
    }
  }

  setupInitialValues(data.location.company.requiredUserVerificationFields)

  return (
    <Formik
      validate={ validate }
      onSubmit={ handleNext }
      initialValues={ initialValues }
      validateOnBlur={ false }
      validateOnChange={ false }
    >
      {(formikProps): JSX.Element =>
        renderForm(
          formikProps,
          verificationLoading,
          data.location.company.requiredUserVerificationFields
        )
      }
    </Formik>
  )
}

export default VerifyUserStep
