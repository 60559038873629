import Dumb from './AppointmentsDialog'
import React from 'react'
import { DIALOG_PROPS_QUERY } from 'queries/local'
import { Query } from 'react-apollo'

const AppointmentsDialog = props => (
  <Query query={ DIALOG_PROPS_QUERY }>
    {({
      data: {
        dialogProps: { event },
      },
    }) => <Dumb event={ event } { ...props } />}
  </Query>
)

export default AppointmentsDialog
