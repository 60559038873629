import React from 'react'
import PropTypes from 'prop-types'

const style = {
  input: {
    width: '25px',
    textAlign: 'center',
  },
}

const NumberPicker = props => {
  return (
    <span>
      <input
        type='button'
        value='-'
        onClick={ props.onDec }
        aria-label='Decrease Quantity'
        data-testid='number-picker-dec'
      />
      {/* text and not number so we can center the text */}
      <input
        type='text'
        value={ props.value }
        readOnly
        style={ style.input }
        data-testid='number-picker'
      />
      <input
        type='button'
        value='+'
        onClick={ props.onInc }
        aria-label='Increase Quantity'
        data-testid='number-picker-inc'
      />
    </span>
  )
}

NumberPicker.propTypes = {
  onInc: PropTypes.func.isRequired,
  onDec: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}

export default NumberPicker
