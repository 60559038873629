import Dumb from './ChangePasswordTab'
import React from 'react'
import { useChangePasswordMutation } from 'types/graphql'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { password, passwordConfirmation } from 'lib/yupHelpers'

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  password,
  passwordConfirmation,
})

const formValues = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
}

export interface ResetPasswordForm {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const ChangePasswordTab = (): JSX.Element => {
  const [changePassword, { loading }] = useChangePasswordMutation()

  const handleSubmit = async(
    values: {
      currentPassword: string;
      password: string;
      passwordConfirmation: string;
    },
    { resetForm }: FormikHelpers<ResetPasswordForm>,
  ): Promise<void> => {
    await changePassword({ variables: values })
    resetForm()
  }
  return (
    <Formik
      validationSchema={ validationSchema }
      onSubmit={ handleSubmit }
      initialValues={ formValues }
    >
      {(formikProps: FormikProps<ResetPasswordForm>): JSX.Element => (
        <Dumb formikProps={ formikProps } loading={ loading } />
      )}
    </Formik>
  )
}

export default ChangePasswordTab
