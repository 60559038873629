import React from 'react'
import Button from '@material-ui/core/Button'
import AddToCalendarHOC from 'react-add-to-calendar-hoc'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import Popover from 'material-ui/Popover'

const style = {
  button: { whiteSpace: 'nowrap' },
}

// This clearly needs more work, but this is the best I got going with this lib
class AddToCalendarButton {
  constructor() {
    this.anchorEl = null
  }

  handleClick = (e, onClick) => {
    this.anchorEl = e.currentTarget
    onClick(e)
  }

  get button() {
    return (
      { onClick, children } // eslint-disable-line react/prop-types
    ) => (
      <Button
        onClick={ e => this.handleClick(e, onClick) }
        style={ style.button }
        variant='outlined'
      >
        {children}
      </Button>
    )
  }

  get menu() {
    // HACK: onRequestClose doesn't provide the event so we fake it
    return (
      { isOpen, onRequestClose, children } // eslint-disable-line react/prop-types
    ) => (
      <Popover
        open={ isOpen }
        onRequestClose={ () => onRequestClose({ preventDefault: () => {} }) }
        anchorEl={ this.anchorEl }
      >
        <Menu>
          {children.map(item => (
            <MenuItem
              key={ item.key }
              primaryText={ item.key }
              onClick={ item.props.onClick }
              href={ item.props.href }
            />
          ))}
        </Menu>
      </Popover>
    )
  }
}

const addToCalendarButton = new AddToCalendarButton()

export default AddToCalendarHOC(
  addToCalendarButton.button,
  addToCalendarButton.menu
)
