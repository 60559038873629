import React from 'react'
import DialogLink from 'components/DialogLink'
import { Link } from '@material-ui/core'
import useStyles from './styles'

export interface Props {
  label?: string;
}

const ForgotPasswordLink = ({
  label = 'Forgot Password?',
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <DialogLink
      trigger={
        <Link
          variant='body1'
          component='button'
          type='button'
          className={ classes.resetPassword }
        >
          {label}
        </Link>
      }
      dialogComponent='ResetPasswordDialog'
    />
  )
}

export default ForgotPasswordLink
