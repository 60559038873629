import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'

const styles = {
  button: {
    marginTop: 8,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}
const UnableToClaimSpot = ({ classes, title, message, classDetails }) => (
  <div>
    <Typography variant='h4'>{title}</Typography>
    {message && <Typography variant='h5'>{message}</Typography>}

    <Divider className={ classes.divider } />

    {classDetails}
    <Button
      className={ classes.button }
      color='primary'
      variant='outlined'
      component={ Link }
      to='/'
    >
      Go Home
    </Button>
  </div>
)

UnableToClaimSpot.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  classDetails: PropTypes.node,
}

export default withStyles(styles)(UnableToClaimSpot)
