import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from 'material-ui/MenuItem'
import CartIcon from 'material-ui/svg-icons/action/shopping-cart'
import Badge from 'material-ui/Badge'
import Subheader from 'material-ui/Subheader'
import { keyHandler } from 'lib/a11y'

const style = {
  badge: {
    position: 'absolute',
    width: '20px',
    height: '20px',
  },
  badgeContainer: {
    position: 'absolute',
    top: '-5px',
    right: '-10px',
  },
}

const ShoppingCartMenuItem = ({ itemCount, handleClick }) => {
  return (
    <div
      role='button'
      tabIndex={ 0 }
      onKeyPress={ keyHandler(handleClick) }
      onClick={ handleClick }
    >
      <Subheader>Cart</Subheader>
      <MenuItem
        leftIcon={
          <div>
            <CartIcon />
            <Badge
              badgeContent={ itemCount }
              style={ style.badgeContainer }
              badgeStyle={ style.badge }
              secondary
            />
          </div>
        }
        primaryText='Cart'
      />
    </div>
  )
}

ShoppingCartMenuItem.propTypes = {
  itemCount: PropTypes.number,
  handleClick: PropTypes.func,
}

export default ShoppingCartMenuItem
