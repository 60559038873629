import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'

export interface Props {
  editUserLoading: boolean;
  usersCanEditAddress: boolean;
  primaryEmail: string;
}

const EditContactInfoDialog = ({
  editUserLoading,
  usersCanEditAddress,
  primaryEmail,
}: Props): JSX.Element => {
  return (
    <DialogForm submitLabel='Save' loading={ editUserLoading }>
      <h2 style={ { fontWeight: 100 } }>Edit Contact Info</h2>
      <TextFieldInput
        name='profile.contactEmail'
        helperText={ `We will send all emails to this address. Continue to use your primary email (${primaryEmail}) for logging in.` }
      />
      <TextFieldInput name='profile.city' readOnly={ !usersCanEditAddress } />
      <TextFieldInput name='profile.state' readOnly={ !usersCanEditAddress } />
      <TextFieldInput name='profile.zip' readOnly={ !usersCanEditAddress } />
      <TextFieldInput name='profile.phoneNumber' phoneNumber />
      <TextFieldInput name='profile.workPhone' phoneNumber />
    </DialogForm>
  )
}

export default EditContactInfoDialog
