import Dumb from './RegistrationStepper'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useRegisterUserMutation } from 'types/graphql-auth'

const RegistrationStepper = (props: RouteComponentProps): JSX.Element => {
  const [registerUserMutation] = useRegisterUserMutation()

  return <Dumb registerUserMutation={ registerUserMutation } { ...props } />
}

export default withRouter(RegistrationStepper)
