import Dumb, { Props } from './GFCRegisterButton'
import React from 'react'
import { useRegisterToGfcMutation } from 'types/graphql'
import useRefreshGfcTable from 'hooks/useRefreshGfcTable'

const GFCRegisterButton = (
  props: Omit<Props, 'loading' | 'registerToClass'>
): JSX.Element => {
  const onCompleted = useRefreshGfcTable()

  const [registerToClass, { loading }] = useRegisterToGfcMutation({
    onCompleted,
  })
  return <Dumb registerToClass={ registerToClass } loading={ loading } { ...props } />
}

export default GFCRegisterButton
