import Dumb from './EditEmergencyInfoDialog'
import React from 'react'
import {
  useEditUserEmergencyContactMutation,
  useEmergencyContactDetailsQuery,
} from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorDialog from 'components/ErrorDialog'
import { Form, Formik } from 'formik'
import { ExecutionResult } from 'apollo-link'

const EditEmergencyInfoDialog = () => {
  const { data, loading, error } = useEmergencyContactDetailsQuery()
  const [
    editUser,
    { loading: editUserLoading },
  ] = useEditUserEmergencyContactMutation()

  if (loading) {
    return <Spinner center />
  }

  if (!data) {
    return <ErrorDialog errorMessage={ error && error.message } />
  }

  const { profile } = data.viewer

  const initialValues = {
    emergencyFirstName: profile.emergencyFirstName || '',
    emergencyMiddleName: profile.emergencyMiddleName || '',
    emergencyLastName: profile.emergencyLastName || '',
    emergencyRelation: profile.emergencyRelationIndex || 0,
    emergencyPhone: profile.emergencyPhone || '',
    emergencyEmail: profile.emergencyEmail || '',
    emergencyAddress: profile.emergencyAddress || '',
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ (profile): Promise<ExecutionResult> =>
        editUser({ variables: { profile } })
      }
    >
      <Form>
        <Dumb editUserLoading={ editUserLoading } />
      </Form>
    </Formik>
  )
}

export default EditEmergencyInfoDialog
