import React from 'react'
import AuthScreen from 'layouts/AuthScreen'
import { FormikProps } from 'formik'
import LoadingButton from 'components/LoadingButton'
import PasswordInput from 'components/account/PasswordInput'
import useStyles from './styles'
import { ResetPasswordForm } from './index'
import ForgotPasswordLink from 'components/auth/ForgotPasswordLink'

export interface FormField {
  name: string;
  label: string;
  error?: string | boolean;
}

const fields = ({
  errors,
  touched,
}: FormikProps<ResetPasswordForm>): FormField[] => [
  {
    name: 'password',
    label: 'Password',
    error: touched.password && errors.password,
  },
  {
    name: 'passwordConfirmation',
    label: 'Password Confirmation',
    error: touched.passwordConfirmation && errors.passwordConfirmation,
  },
]

export interface Props {
  loading: boolean;
  formikProps: FormikProps<ResetPasswordForm>;
}

const ResetPassword = ({ formikProps, loading }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <AuthScreen
      header={ <span>Reset Password</span> }
      handleSubmit={ formikProps.handleSubmit }
    >
      <div className={ classes.form }>
        {fields(formikProps).map(
          (field: FormField): JSX.Element => (
            <PasswordInput key={ field.name } { ...field } />
          )
        )}
      </div>
      <LoadingButton type='submit' loading={ loading }>
        Submit
      </LoadingButton>
      <ForgotPasswordLink label='Invalid token?' />
    </AuthScreen>
  )
}

export default ResetPassword
