import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import ListIcon from '@material-ui/icons/List'
import CalendarIcon from '@material-ui/icons/Event'
import Tooltip from '@material-ui/core/Tooltip'
import useStyles from './styles'
import { keyHandler } from 'lib/a11y'

const CalendarViewSwitch = ({
  match,
  location: { pathname },
  history: { push },
}) => {
  const classes = useStyles()
  const switchView = value => () => {
    push(`${match.path}/${value}`)
  }

  return (
    <Paper className={ classes.container } elevation={ 1 }>
      <Tooltip title='View as Calendar'>
        <Radio
          checked={ pathname.split('/').pop() === 'calendar' }
          onChange={ switchView('calendar') }
          onKeyPress={ keyHandler(switchView('calendar')) }
          className={ classes.button }
          checkedIcon={ <CalendarIcon /> }
          color='primary'
          icon={ <CalendarIcon className={ classes.unchecked } /> }
          aria-label='View Classes as Calendar'
          inputProps={ {
            'aria-label': 'View Classes as Calendar',
          } }
        />
      </Tooltip>
      <Tooltip title='View as List'>
        <Radio
          checked={ pathname.split('/').pop() === 'list' }
          onChange={ switchView('list') }
          onKeyPress={ keyHandler(switchView('list')) }
          className={ classes.button }
          checkedIcon={ <ListIcon /> }
          color='primary'
          icon={
            <Tooltip title='View as List'>
              <ListIcon className={ classes.unchecked } />
            </Tooltip>
          }
          inputProps={ {
            'aria-label': 'View Classes as List',
          } }
        />
      </Tooltip>
    </Paper>
  )
}

CalendarViewSwitch.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default CalendarViewSwitch
