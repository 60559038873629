import React from 'react'
import { PICKER_DATE_FORMAT } from 'lib/formats'
import {
  KeyboardDatePicker,
  MaterialUiPickersDate,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'
import { titleCase } from 'change-case'

const useStyles = makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    picker: {
      marginRight: spacing(2),
    },
  })
)

const picker = (
  name: string,
  value: MaterialUiPickersDate,
  onChange: (date: MaterialUiPickersDate) => void,
  classes: Record<string, string>
): JSX.Element => {
  return (
    <MuiPickersUtilsProvider utils={ DateFnsUtils }>
      <KeyboardDatePicker
        className={ classes.picker }
        id={ `${name}-picker` }
        name={ name }
        label={ titleCase(name) }
        format={ PICKER_DATE_FORMAT }
        value={ value }
        onChange={ onChange }
        KeyboardButtonProps={ {
          'aria-label': 'change date',
        } }
      />
    </MuiPickersUtilsProvider>
  )
}

export interface Props {
  start: MaterialUiPickersDate;
  end: MaterialUiPickersDate;
  onStartChange: (date: MaterialUiPickersDate) => void;
  onEndChange: (date: MaterialUiPickersDate) => void;
}

const DateRangePicker = ({
  start,
  end,
  onStartChange,
  onEndChange,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <>
      {picker('startDate', start, onStartChange, classes)}
      {picker('endDate', end, onEndChange, classes)}
    </>
  )
}

export default DateRangePicker
