import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    container: {
      padding: '10px',
    },
    ul: {
      listStyle: 'square outside none',
      paddingLeft: spacing(2),
      marginBottom: 0,
    },
    divider: {
      marginTop: spacing(4),
      marginBottom: spacing(4),
    },
    li: {
      lineHeight: '48px',
      position: 'relative',
    },
    removeButton: {
      float: 'right',
    },
    tabHeader: {
      textAlign: 'center',
      marginBottom: '-10px',
      fontWeight: 100,
      textTransform: 'uppercase',
      fontSize: '1.1rem',
    },
  })
)
