import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import BewellStone from 'components/bewell/BewellStone'

const styles = {
  stonesContainer: { position: 'relative', padding: '35px' },
}

const BewellStoneDisplay = ({
  classes,
  data: {
    viewer: {
      location: {
        bewell: {
          stonesEnabled,
          totalWellnessStoneColor,
          totalWellnessStoneLabel,
          categories,
        },
      },
    },
  },
}) => {
  // This is true when a user was presented with a new stone and closed the
  // modal, until something else changes here. Used to display two dialogs
  // one after the other (when a master stone is earned)
  const [newStoneAccepted, setNewStoneAccepted] = useState(false)

  const totalWellnessStone = {
    title: totalWellnessStoneLabel,
    color: totalWellnessStoneColor,
    isStoneEarned: categories.every(category => category.isStoneEarned),
  }

  useEffect(() => {
    // Every time there's a change in the stone status, we reset the
    // newStoneAccepted flag to false, unless this is when the master stone is
    // earned
    totalWellnessStone.isStoneEarned || setNewStoneAccepted(false)
  }, [totalWellnessStone.isStoneEarned])

  if (!stonesEnabled) return null

  return (
    <div className={ classes.stonesContainer }>
      {/* The condition for showing this stone is in the child component */}
      <BewellStone
        totalWellness
        category={ totalWellnessStone }
        isTotalWellnessEarned={ totalWellnessStone.isStoneEarned }
        newStoneAccepted={ newStoneAccepted }
        setNewStoneAccepted={ setNewStoneAccepted }
      />
      {categories.map((category, i) => (
        <BewellStone
          category={ category }
          key={ category.id }
          isTotalWellnessEarned={ totalWellnessStone.isStoneEarned }
          // add 270 so the first stone is on top
          rotation={ (360 / categories.length) * i + 270 }
          setNewStoneAccepted={ setNewStoneAccepted }
        />
      ))}
    </div>
  )
}

BewellStoneDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      location: PropTypes.shape({
        bewell: PropTypes.shape({
          stonesEnabled: PropTypes.bool.isRequired,
          totalWellnessStoneColor: PropTypes.string,
          totalWellnessStoneLabel: PropTypes.string,
          categories: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              isStoneEarned: PropTypes.bool.isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default withStyles(styles)(BewellStoneDisplay)
