import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  (theme: Theme): StyleRules => ({
    container: {
      height: '100vh',
    },
    content: {
      backgroundColor: theme.palette.common.white,
      position: 'relative',
      paddingBottom: 40,
    },
    ambiance: {
      flexGrow: 7,
      color: 'white',
      fontWeight: 100,
      position: 'relative',
    },
    signInContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 20,
    },
    signInButton: {
      color: 'white',
    },
    signInButtonSmallScreen: {
      float: 'right',
      margin: 10,
    },
    termsContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      padding: '30px 100px',
    },
    logo: {
      width: 220,
      [theme.breakpoints.only('xs')]: {
        width: 180,
      },
      margin: '20px 0 10px 20px',
    },
    form: {
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.only('md')]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.only('xs')]: {
        paddingLeft: 0,
        marginLeft: -theme.spacing(),
        marginRight: -theme.spacing(),
      },
    },
    nextButtonContainer: {
      textAlign: 'right',
      marginTop: '10em',
      marginRight: 40,
    },
    gutterRight: { marginRight: '10px' },
    footer: {
      color: theme.palette.dimmed.main,
      backgroundColor: theme.palette.common.white,
      textAlign: 'center',
      paddingBottom: theme.spacing(),
    },
  })
)
