import React, { ReactNode } from 'react'
import { IsImpersonatedQuery } from 'types/graphql'
import { keyHandler } from 'lib/a11y'

const backend = process.env.REACT_APP_V2_ADDR || 'http://admin.localhost:3000'
// There's probably a less crude way to do this, but whatever amiright?
const adminBackend = backend.replace('//', '//admin.')

export interface Props {
  children: ReactNode;
  logoutUser: () => void;
  beforeLogout?: () => Promise<void>;
  data: IsImpersonatedQuery;
}

const logout = (logoutUser: Function, impersonated?: boolean): void => {
  localStorage.setItem('elevation-token', '')
  if (impersonated) {
    window.location.replace(adminBackend)
  } else {
    logoutUser()
    window.location.replace('/login')
  }
}

const LogoutButton = ({
  children,
  logoutUser,
  beforeLogout = (): Promise<void> => Promise.resolve(),
  data: {
    viewer: { impersonated },
  },
}: Props): JSX.Element => {
  const handleClick = (): void => {
    beforeLogout().then((): void => logout(logoutUser, impersonated))
  }

  return (
    <span
      role='button'
      tabIndex={ 0 }
      onKeyPress={ keyHandler(handleClick) }
      onClick={ handleClick }
      style={ { display: 'inherit' } }
    >
      {children}
    </span>
  )
}

export default LogoutButton
