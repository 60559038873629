import React from 'react'
import { Button, Typography } from '@material-ui/core'
import useStyles from '../accountStyles'
// @ts-ignore
import QRCode from 'qrcode.react'
import { useViewerIdQuery } from 'types/graphql'
import JsPdf from 'jspdf'

const QRCodeDownload = (): JSX.Element | null => {
  const classes = useStyles()
  const { data, loading } = useViewerIdQuery()

  const handleDownload = () => {
    const canvas = document.querySelector('canvas')
    if (!canvas) return
    const pdf = new JsPdf()
    pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 15, 40, 180, 180)
    pdf.save('Elevation Check In QR Code.pdf')
  }

  if (loading || !data) return null

  return (
    <div className={ classes.container }>
      <Typography variant='subtitle2' className={ classes.helpText }>
        Download your QR code below and scan it with the check-in kiosk!
      </Typography>
      <QRCode value={ data.viewer.id + '' } renderAs='canvas' />
      <div className={ classes.actionButtonContainer }>
        <Button color='primary' variant='contained' onClick={ handleDownload }>
          Download as PDF
        </Button>
      </div>
    </div>
  )
}

export default QRCodeDownload
