import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    drawerContents: {
      width: 385,
    },
    menuItem: {
      padding: spacing(2, 4, 2, 2),
    },
  })
)
