import React, { useContext, useEffect, useState } from 'react'
import { PaymentsEnum, useLocationUpdatesQuery } from 'types/graphql-auth'
import {
  CardElement,
  Elements,
  injectStripe,
  ReactStripeElements,
  StripeProvider,
} from 'react-stripe-elements'
import { RegistrationContext } from 'components/registration/helpers'
import { Context } from 'components/registration/types'
import Paper from '@material-ui/core/Paper'
import useStyles from '../styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { Button, Typography } from '@material-ui/core'
import LoadingButton from 'components/LoadingButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import PopoverButton from 'components/PopoverButton'
import { createMarkup } from 'lib/helpers'

const btnStyles = {
  padding: 2,
  minWidth: 0,
  fontSize: 'inherit',
  textTransform: 'none',
  fontWeight: 'bold',
}

export interface CardFormProps {
  setToken: Function;
  setCardError: Function;
  stripe: ReactStripeElements.StripeProps;
}

// export for testing
export const _CardForm = ({
  stripe,
  setCardError,
  setToken,
}: CardFormProps): JSX.Element => {
  const generateTokenOnComplete = ({
    complete,
    error,
  }: ReactStripeElements.ElementChangeResponse): void => {
    if (error) {
      setCardError(error.message)
    } else {
      setCardError('')
    }

    if (complete) {
      stripe
        .createToken()
        .then((res: ReactStripeElements.PatchedTokenResponse): void => {
          if (res.error) {
            setCardError(res.error.message)
            return
          }
          if (res.token) {
            setToken(res.token.id)
          }
        })
    } else {
      setToken('')
    }
  }

  return (
    <CardElement
      style={ { base: { fontSize: '18px' } } }
      onChange={ generateTokenOnComplete }
    />
  )
}

_CardForm.displayName = '_CardForm'

const CardForm = injectStripe(_CardForm)

const CreditCardStep = (): JSX.Element | null => {
  const classes = useStyles()
  const {
    activeStep,
    setActiveStep,
    setFormValues,
    formValues,
    setFormComplete,
  } = useContext(RegistrationContext) as Context
  const [cardError, setCardError] = useState('')

  // Don't really need the whole query, but it's in the cache anyway...
  const { data, loading, error } = useLocationUpdatesQuery({
    variables: { locationId: formValues.user.locationId as number },
  })

  const handleSkip = (): void => {
    setFormComplete(true)
  }

  const handleNext = (): void => {
    setFormComplete(true)
  }

  const handleBack = (): void => {
    setActiveStep((prevActiveStep: number): number => prevActiveStep - 1)
  }

  // This shouldn't really happen
  useEffect((): void => {
    if (
      data &&
      data.location &&
      data.location.payments === PaymentsEnum.Disabled
    ) {
      handleBack()
      handleSkip()
    }
  })

  if (loading) return <Skeleton height={ 51 } />
  if (!data || !data.location.paymentMethod) {
    // TODO: change to error handling component
    return <p>{error ? error.message : 'An error occurred'}</p>
  }

  const { membershipsEnabled, membershipAgreementCopy } = data.location.config

  const setToken = (stripeCardToken: string): void => {
    setFormValues({ ...formValues, stripeCardToken })
  }

  return (
    <>
      {membershipsEnabled && membershipAgreementCopy && (
        <Typography variant='caption' style={ { marginBottom: 5 } }>
          By continuing, you agree to the membership agreement and fees as
          described{' '}
          <PopoverButton
            label='here'
            variant='text'
            color='primary'
            btnStyles={ btnStyles }
          >
            <Typography
              variant='body2'
              dangerouslySetInnerHTML={ createMarkup(membershipAgreementCopy) }
            />
          </PopoverButton>
        </Typography>
      )}
      <Paper className={ classes.stripeContainer }>
        <StripeProvider apiKey={ data.location.paymentMethod.stripeApiKey }>
          <Elements>
            {/* it complains that we don't pass stripe, but it gets it from
                injectStripe
            // @ts-ignore */}
            <CardForm setToken={ setToken } setCardError={ setCardError } />
          </Elements>
        </StripeProvider>
      </Paper>
      <FormHelperText error className={ classes.stripeErrorMessage }>
        {cardError}
      </FormHelperText>
      <Button
        onClick={ handleBack }
        disabled={ !activeStep }
        className={ classes.stepButton }
      >
        Back
      </Button>
      {data.location.payments === 'OPTIONAL' && (
        <Button
          className={ classes.stepButton }
          onClick={ handleSkip }
          variant='contained'
          color='primary'
        >
          Skip
        </Button>
      )}
      <LoadingButton
        variant='contained'
        color='primary'
        onClick={ handleNext }
        loading={ false }
        disabled={ !formValues.stripeCardToken }
      >
        Next
      </LoadingButton>
    </>
  )
}

export default CreditCardStep
