import { StyleRules, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme): StyleRules => ({
  container: {
    padding: theme.spacing(5),
  },
  logo: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
})

export default styles
