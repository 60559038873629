import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  calendar: {
    height: 'calc(100vh - 165px)',
  },
  fetching: {
    opacity: 0.6,
  },
})
