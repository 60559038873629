import gql from 'graphql-tag'
import * as fragments from 'fragments'

export const LOGOUT_USER_MUTATION = gql`
  mutation logoutV2User {
    logoutV2User {
      success
    }
  }
`

export const EDIT_USER_AVATAR_MUTATION = gql`
  mutation editUserAvatar($profile: ProfileInput) {
    editUser(profile: $profile) {
      viewer {
        id
        profile {
          id
          avatar(size: 100)
        }
      }
    }
  }
`

export const EDIT_USER_CONTACT_INFO_MUTATION = gql`
  mutation editUserContactInfo($user: UserInput, $profile: ProfileInput) {
    editUser(user: $user, profile: $profile) {
      viewer {
        id
        profile {
          id
          city
          state
          zip
          phoneNumber
          workPhone
          contactEmail
        }
      }
    }
  }
`

export const TOUR_COMPLETED = gql`
  mutation tourCompleted {
    tourCompleted {
      success
    }
  }
`

export const ACCEPT_MEMBERSHIP_AGREEMENT_MUTATION = gql`
  mutation acceptMembershipAgreement {
    acceptMembershipAgreement {
      viewer {
        id
        alertAboutMembershipFee
      }
    }
  }
`

export const ADD_ITEM_TO_CART_MUTATION = gql`
  mutation addItemToCart(
    $itemId: Int!
    $itemClass: String!
    $quantity: Int
    $staffId: Int
    $userComments: String
    $userAvailability: String
    $autoRenew: Boolean
  ) {
    addItemToCart(
      itemId: $itemId
      itemClass: $itemClass
      quantity: $quantity
      staffId: $staffId
      userComments: $userComments
      userAvailability: $userAvailability
      autoRenew: $autoRenew
    ) {
      viewer {
        id
        cart {
          id
          itemCount
          items {
            id
          }
        }
      }
      message
    }
  }
`

export const CHECKOUT_MUTATION = gql`
  mutation checkout {
    checkout {
      viewer {
        id
        cart {
          id
          itemCount
          items {
            id
          }
        }
        subscriptions {
          ...subscription
        }
      }
      message
    }
  }
  ${fragments.subscription}
`

export const REDEEM_COUPON_MUTATION = gql`
  mutation redeemCoupon($code: String!) {
    redeemCoupon(code: $code) {
      cart {
        id
        total
        coupon {
          id
          couponCode
          discount
        }
      }
    }
  }
`

export const REMOVE_ITEM_FROM_CART_MUTATION = gql`
  mutation removeItemFromCart($itemId: Int!) {
    removeItemFromCart(itemId: $itemId) {
      cart {
        id
        total
        itemCount
        items {
          id
        }
      }
      message
    }
  }
`

export const UPDATE_ITEM_QUANTITY_MUTATION = gql`
  mutation updateItemQuantity($itemId: Int!, $quantity: Int!) {
    updateItemQuantity(itemId: $itemId, quantity: $quantity) {
      cart {
        id
        total
        items {
          id
          quantity
        }
      }
    }
  }
`

export const CONTACT_INSTRUCTOR_MUTATION = gql`
  mutation contactInstructor(
    $staffId: Int!
    $subject: String!
    $message: String!
  ) {
    contactInstructor(staffId: $staffId, subject: $subject, message: $message) {
      success
      message
    }
  }
`

export const REGISTER_FOR_EVENT_MUTATION = gql`
  mutation registerForEvent($eventId: Int!, $startTime: String!) {
    registerForEvent(eventId: $eventId, startTime: $startTime) {
      slot {
        id
        openSpotsDisplay
        eventRegistration {
          id
          startTime
          endTime
          title
          eventLocation
          details
          charge {
            price
          }
        }
      }
      message
    }
  }
`

export const UNREGISTER_FOR_EVENT_MUTATION = gql`
  mutation unregisterForEvent($registrationId: Int!) {
    unregisterForEvent(registrationId: $registrationId) {
      viewer {
        id
        upcomingEventRegistrations {
          ...eventRegistration
        }
        upcomingAppointmentBookings {
          ...appointmentBooking
        }
      }
      message
    }
  }
  ${fragments.eventRegistration}
  ${fragments.appointmentBooking}
`

export const REGISTER_FOR_APPOINTMENT_MUTATION = gql`
  mutation registerForAppointment(
    $apptId: Int!
    $startTime: ISO8601DateTime!
    $length: Int!
    $eventStart: ISO8601DateTime!
    $eventEnd: ISO8601DateTime!
  ) {
    registerForAppointment(
      apptId: $apptId
      startTime: $startTime
      length: $length
    ) {
      viewer {
        id
        upcomingAppointmentBookings {
          ...appointmentBooking
        }
        location {
          id
          appointmentCalendarEntries(
            after: $eventStart
            before: $eventEnd
            apptId: $apptId
          ) {
            ...eventCalendarEntry
          }
        }
      }
      message
    }
  }
  ${fragments.appointmentBooking}
  ${fragments.eventCalendarEntry}
`

export const REDEEM_REWARD_POINTS_MUTATION = gql`
  mutation redeemRewardPoints($productId: Int!, $points: Int!) {
    redeemRewardPoints(productId: $productId, points: $points) {
      viewer {
        id
        totalPoints
      }
      message
    }
  }
`

export const CHANGE_CURRENT_LOCATION_MUTATION = gql`
  mutation changeCurrentLocation($locationId: Int!) {
    changeCurrentLocation(locationId: $locationId) {
      viewer {
        id
        location {
          id
        }
      }
    }
  }
`

export const RECORD_COMPLETED_INTAKE_SURVEY_MUTATION = gql`
  mutation recordCompletedIntakeSurvey {
    recordCompletedIntakeSurvey {
      viewer {
        id
        intakeSurvey {
          show
        }
      }
    }
  }
`

export const RENT_LOCKER_MUTATION = gql`
  mutation rentLocker($lockerId: Int!) {
    rentLocker(lockerId: $lockerId) {
      location {
        id
        lockers {
          ...locker
        }
      }
      message
    }
  }
  ${fragments.locker}
`

export const AGREE_TO_WAIVER = gql`
  mutation agreeToWaiver($locationId: Int) {
    agreeToWaiver(locationId: $locationId) {
      viewer {
        id
        agreedToWaiver
      }
      message
    }
  }
`

export const AGREE_TO_HIPAA_WAIVER = gql`
  mutation agreeToHipaaWaiver {
    agreeToWaiver(waiverType: "hipaa_waiver") {
      viewer {
        id
        agreedToHipaaWaiver: agreedToWaiver(waiverType: "hipaa_waiver")
      }
      message
    }
  }
`

export const UNRENT_LOCKER_MUTATION = gql`
  mutation unrentLocker($lockerId: Int!) {
    unrentLocker(lockerId: $lockerId) {
      location {
        id
        lockers {
          ...locker
        }
      }
      message
    }
  }
  ${fragments.locker}
`

export const MARK_MESSAGE_AS_READ_MUTATION = gql`
  mutation markMessageAsRead($messageId: Int!) {
    markMessageAsRead(messageId: $messageId) {
      viewer {
        id
        unreadMessages {
          id
        }
      }
    }
  }
`
