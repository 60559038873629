import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

const VideoDialog = ({ videoUrl, closeDialog }) => (
  <Dialog open maxWidth='md' fullWidth onClose={ closeDialog }>
    <DialogContent>
      <ReactPlayer url={ videoUrl } playing controls width='100%' />
    </DialogContent>
  </Dialog>
)

VideoDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
}

export default VideoDialog
