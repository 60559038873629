import React from 'react'
import PropTypes from 'prop-types'
import Dumb from './TransactionReceipt'
import { Query } from 'react-apollo'
import { TRANSACTION_RECEIPT_QUERY } from 'queries'

const TransactionReceipt = ({ order }) => (
  <Query query={ TRANSACTION_RECEIPT_QUERY }>
    {({ data: { viewer }, loading }) => (
      <Dumb loading={ loading } viewer={ viewer } order={ order } />
    )}
  </Query>
)

TransactionReceipt.propTypes = {
  order: PropTypes.object.isRequired,
}

export default TransactionReceipt
