import React from 'react'
import PropTypes from 'prop-types'

import LoadingButton from 'components/LoadingButton'

const RemovePartnershipButton = ({ removePartnership, loading }) => {
  return (
    <LoadingButton
      loading={ loading }
      size='small'
      secondary
      onClick={ removePartnership }
    >
      Remove
    </LoadingButton>
  )
}

RemovePartnershipButton.propTypes = {
  removePartnership: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default RemovePartnershipButton
