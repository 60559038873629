import Dumb, { Props } from './UserCreditCard'
import React from 'react'
import {
  useRemoveCreditCardMutation,
  useSetPrimaryCardMutation,
} from 'types/graphql'
import confirm from 'lib/confirm'
import { ExecutionResult } from 'apollo-link'
import { noop } from 'lib/helpers'

const UserCreditCard = (props: Pick<Props, 'card' | 'name'>): JSX.Element => {
  const cardId = props.card.id

  const [
    removeCreditCard,
    { loading: removeCardLoading },
  ] = useRemoveCreditCardMutation({ variables: { cardId } })
  const [
    setPrimaryCard,
    { loading: setPrimaryLoading },
  ] = useSetPrimaryCardMutation({ variables: { cardId } })

  const removeCC = (): void => {
    confirm('Remove Credit Card?').then((): Promise<ExecutionResult> => {
      return removeCreditCard()
    }, noop)
  }

  return (
    <Dumb
      { ...props }
      removeCC={ removeCC }
      setPrimaryCard={ setPrimaryCard }
      removeCardLoading={ removeCardLoading }
      setPrimaryLoading={ setPrimaryLoading }
    />
  )
}

export default UserCreditCard
