import PropTypes from 'prop-types'
import React from 'react'
import Card from '@material-ui/core/Card'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  card: {
    height: '100%',
    position: 'relative',
  },
  widget: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginBottom: '10px',
    boxShadow: '-10px 10px 81px -27px black',
    padding: '20px 20px 0 20px',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    backgroundColor: '#2c3e50',
    opacity: '0.6',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  innerContent: {
    color: '#fff',
    position: 'relative',
    opacity: '1',
    height: '100%',
  },
  innerContentH2: {
    fontWeight: 600,
    fontSize: '1.1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  title: {
    width: '65%',
    fontSize: '0.9em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
    paddingRight: theme.spacing(),
  },
  innerContentP: {
    fontSize: '0.9em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
    paddingRight: theme.spacing(),
  },
  button: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
})

const PromoWidget = ({
  classes,
  background,
  title,
  header,
  body,
  clickThruUrl,
  onClick,
  ariaLabel,
  buttonText,
}) => (
  <Card className={ classes.card }>
    <div className={ classes.overlay } />
    <div className={ classes.widget } style={ { backgroundImage: background } }>
      <Grid item md={ 11 } className={ classes.innerContent }>
        <p className={ classes.title }>{title}</p>
        <h2 className={ classes.innerContentH2 }>{header}</h2>
        <p className={ classes.innerContentP }>{body}</p>
      </Grid>
      {(clickThruUrl || onClick) && (
        <Button
          size='small'
          variant='contained'
          href={ clickThruUrl }
          target='_blank'
          className={ classes.button }
          onClick={ onClick }
          aria-label={ ariaLabel || buttonText || `Get Started with ${title}` }
        >
          {buttonText || 'Get Started'}
        </Button>
      )}
    </div>
  </Card>
)

PromoWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  background: PropTypes.string,
  title: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  clickThruUrl: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  buttonText: PropTypes.string,
}

export default withStyles(styles)(PromoWidget)
