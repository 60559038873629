import Dumb from './TourAdvance'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { ADVANCE_TOUR } from 'mutations/local'

const TourAdvance = ({ children }) => (
  <Mutation mutation={ ADVANCE_TOUR }>
    {advanceTour => <Dumb advanceTour={ advanceTour }>{children}</Dumb>}
  </Mutation>
)

TourAdvance.propTypes = { children: PropTypes.any }

export default TourAdvance
