import Dumb from './RegistrationStep'
import React, { useContext } from 'react'
import {
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import { NewUserForm } from 'types'
import { ObjectSchema } from 'yup'
import {
  nullifyBlanksDeep,
  RegistrationContext,
} from 'components/registration/helpers'
import { Context } from 'components/registration/types'

export interface Props {
  validationSchema?: ObjectSchema;
  children: Function;
  asyncValidation?: Function;
  asyncValidationKey?: string;
  validationLoading?: boolean;
  validate?: (values: NewUserForm) => Promise<{} | void>;
}

const RegistrationStep = ({
  validationSchema,
  asyncValidation,
  asyncValidationKey,
  validate,
  ...props
}: Props): JSX.Element => {
  const {
    activeStep,
    setActiveStep,
    setFormValues,
    formValues,
    stepAmount,
    setFormComplete,
  } = useContext(RegistrationContext) as Context

  const handleNext = (values: NewUserForm): void => {
    // set step values on the registration "store"
    setFormValues(values)
    // go to next step
    setActiveStep((prevActiveStep: number): number => prevActiveStep + 1)
    // Show waiver if we're done
    if (activeStep === stepAmount - 1) setFormComplete(true)
  }

  const validateStep = async(
    values: NewUserForm,
  ): Promise<FormikErrors<{ then: unknown }> | void> => {
    // Sync validation
    if (validationSchema) {
      try {
        await validateYupSchema<NewUserForm>(values, validationSchema, true, {
          validatePhoneNumber: formValues.showPhoneNumber,
          validateBadge: formValues.showBadgeField,
        })
      } catch (e) {
        return yupToFormErrors(e)
      }
    }
    // Async validation
    if (!asyncValidation || !asyncValidationKey) return Promise.resolve()
    const res = await asyncValidation({
      variables: { user: nullifyBlanksDeep(values.user) },
    })
    const errors = res && res.data && res.data[asyncValidationKey]
    if (errors) return errors
  }

  return (
    <Formik
      validate={ validate || validateStep }
      onSubmit={ handleNext }
      initialValues={ formValues }
      validateOnBlur={ false }
      validateOnChange={ false }
    >
      <Dumb { ...props } />
    </Formik>
  )
}

export default RegistrationStep
