import Dumb, { Props } from './DependentAvatar'
import React from 'react'
import { EditDependentComponent } from 'types/graphql'

const DependentAvatar = (props: Props): JSX.Element => (
  <EditDependentComponent>
    {(uploadAvatar): JSX.Element => (
      <Dumb uploadAvatar={ uploadAvatar } { ...props } />
    )}
  </EditDependentComponent>
)

export default DependentAvatar
