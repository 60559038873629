import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'
import SelectFieldInput from 'components/dialogForms/SelectFieldInput'
import { Option } from 'components/dialogForms/SelectFieldInput/SelectFieldInput'

export interface Props {
  submitLoading: boolean;
  isExisting: boolean;
  relations: string[];
}

const GuestDialog = ({
  submitLoading,
  isExisting,
  relations,
}: Props): JSX.Element => {
  return (
    <DialogForm
      submitLabel={ isExisting ? 'Save' : 'Add' }
      loading={ submitLoading }
    >
      <h2 style={ { fontWeight: 100 } }>{isExisting ? 'Edit' : 'Add'} Guest</h2>
      <TextFieldInput name='firstName' />
      <TextFieldInput name='lastName' />
      <TextFieldInput name='emergencyFirstName' />
      <TextFieldInput name='emergencyLastName' />
      <TextFieldInput name='emergencyPhone' phoneNumber />
      <SelectFieldInput
        name='emergencyRelation'
        labelWidth={ 150 }
        options={ relations.map(
          (relation): Option => ({
            value: relation,
            label: relation,
          })
        ) }
      />
    </DialogForm>
  )
}

export default GuestDialog
