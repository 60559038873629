import React from 'react'
import { ErrorMessage, Field, FieldProps } from 'formik'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import useStyles from '../styles'
import startCase from 'lodash/startCase'

export interface Option {
  value: string | number;
  label: string;
}

export interface Props {
  name: string;
  options: Option[];
  labelWidth: number;
  emptyOptionValue?: string | number;
}

const SelectFieldInput = ({
  name,
  options,
  labelWidth,
  emptyOptionValue,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Field name={ name }>
      {({ field, meta: { touched, error } }: FieldProps): JSX.Element => (
        <FormControl
          variant='outlined'
          className={ classes.field }
          fullWidth
          error={ touched && Boolean(error) }
        >
          <InputLabel id={ `${name}-select-label` }>{startCase(name)}</InputLabel>
          <Select
            { ...field }
            labelId={ `${name}-select-label` }
            id={ `${name}-select` }
            labelWidth={ labelWidth }
            aria-describedby={ `${name}-helper-text` }
          >
            {emptyOptionValue !== undefined && (
              <MenuItem value={ emptyOptionValue }>
                <em className={ classes.emptyOption }>
                  Choose {startCase(name)}...
                </em>
              </MenuItem>
            )}
            {options.map(
              ({ value, label }): JSX.Element => (
                <MenuItem value={ value } key={ value }>
                  {label}
                </MenuItem>
              )
            )}
          </Select>
          <FormHelperText id={ `${name}-helper-text` }>
            <ErrorMessage name={ name } />
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  )
}

export default SelectFieldInput
