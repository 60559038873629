import Dumb from './ProfileMenuItems'
import React from 'react'
import ErrorMessage from 'components/ErrorMessage'
import { useProfileMenuQuery } from 'types/graphql'

// we rely on the data to already exist in the cache (queried in AppLayout)
// otherwise the menu isn't loaded correctly
const ProfileMenuItems = (): JSX.Element | null => {
  const { data } = useProfileMenuQuery()

  if (!data) return <ErrorMessage error={ new Error('Unable to load menu...') } />

  return <Dumb data={ data } />
}

export default ProfileMenuItems
