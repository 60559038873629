import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  wrapper: {
    textAlign: 'center',
    maxWidth: '500px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    padding: '30px 20px',
    border: '1px solid #e6e6e6',
  },
  title: {
    color: '#3DC480',
    fontWeight: 500,
  },
  content: {
    color: '#79838E',
    fontSize: '1.3rem',
  },
  link: {
    textDecoration: 'none',
  },
})

export interface Props {
  children: JSX.Element | JSX.Element[];
  title: string;
  icon?: JSX.Element;
}

const EmailConfirmationMessage = ({
  children,
  title,
  icon,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={ classes.wrapper }>
      {icon}
      <h1 className={ classes.title }>{title}</h1>
      <div className={ classes.content }>{children}</div>
      <Link to='/login' className={ classes.link }>
        <Button variant='contained' color='primary' fullWidth>
          Back to Login Page
        </Button>
      </Link>
    </div>
  )
}

export default EmailConfirmationMessage
