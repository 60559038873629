import React from 'react'
import MaskedInput from 'react-text-mask'

export interface Props {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const PhoneNumberInput = ({ inputRef, ...props }: Props): JSX.Element => {
  return (
    <MaskedInput
      { ...props }
      ref={ (ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      } }
      mask={ [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ] }
      placeholderChar={ '\u2000' }
    />
  )
}

export default PhoneNumberInput
