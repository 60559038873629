import React from 'react'
import { Avatar, ExpansionPanelActions, Fab, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { FitnessServiceProps } from 'components/hfs/types'
import DialogLink from 'components/DialogLink'
import useStyles from '../styles'
import AddServiceToCart from 'components/hfs/AddServiceToCart'
import { AddItemFn } from 'components/hfs/FitnessServiceActions/index'

interface Props
  extends Omit<FitnessServiceProps, 'expiryText' | 'advanceTour'> {
  addItem: AddItemFn;
}

const FitnessServiceActions = ({
  hasStaff,
  service,
  staff,
  addItemToCartLoading,
  itemClass,
  sessions,
  addItem,
}: Props): JSX.Element => {
  const classes = useStyles()

  const {
    id,
    staffSpecific,
    staffs,
    askAvailability,
    allowUserRenewal,
  } = service

  return (
    <ExpansionPanelActions
      className={ classNames(classes.actions, 'add-to-cart') }
    >
      {/* hasStaff = Services that are purchased for a staff member */}
      {hasStaff &&
        (staff ? (
          // When purchasing with a specific staff member.
          // (e.g. from StaffInfoDialog)
          <AddServiceToCart
            loading={ addItemToCartLoading }
            onClick={ (e): void => addItem(e, id, staff.id, askAvailability) }
          />
        ) : (
          // If we have multiple staff to choose from, show their avatars
          // which link to the StaffInfoDialog for each staff member.
          staffs &&
          staffs.map(
            (staff): JSX.Element => (
              <DialogLink
                dialogComponent='StaffInfoDialog'
                dialogProps={ {
                  id: staff.id,
                  service,
                  itemClass,
                  sessions,
                } }
                key={ staff.id }
                className={ classes.addToCartButton }
                stopPropagation
                trigger={
                  <Tooltip title={ `Book with ${staff.name}!` }>
                    <Fab aria-label={ `Book with ${staff.name}!` }>
                      <Avatar
                        alt={ `Book with ${staff.name}!` }
                        src={ staff.avatar }
                        className={ classes.avatar }
                      />
                    </Fab>
                  </Tooltip>
                }
              />
            )
          )
        ))}
      {hasStaff ? (
        !staffSpecific &&
        !staff && (
          // This is for a service that purchased as "unassigned" and is
          // assigned later by an admin.
          <Tooltip title='Book with one of our staff members!'>
            <AddServiceToCart
              loading={ addItemToCartLoading }
              onClick={ (e): void => addItem(e, id, undefined, askAvailability) }
            />
          </Tooltip>
        )
      ) : (
        // Item that is not purchased with a staff member
        // (e.g. GFC Credits)
        <AddServiceToCart
          loading={ addItemToCartLoading }
          onClick={ (e): void =>
            addItem(e, id, undefined, askAvailability, allowUserRenewal)
          }
        />
      )}
    </ExpansionPanelActions>
  )
}

export default FitnessServiceActions
