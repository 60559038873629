import React from 'react'
import moment from 'moment-timezone'
import { SHORT_DATE_FORMAT } from 'lib/formats'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { DialogPropsEvent } from 'types/graphql'

export interface Props {
  event: DialogPropsEvent;
}

const EventDialogTitle = ({ event }: Props): JSX.Element => {
  return (
    <DialogTitle disableTypography>
      <Typography variant='h6'>
        Schedule {event.title} -{' '}
        {moment(event.startTime).format(SHORT_DATE_FORMAT)}
      </Typography>
      <Typography variant='body2'>{event.details}</Typography>
      <Typography variant='body2'>
        {event.staff && `With ${event.staff.name} `}
        {event.eventLocation && `@ ${event.eventLocation}`}
      </Typography>
      {event.formattedPrice && (
        <Typography variant='body2'>Price: {event.formattedPrice}</Typography>
      )}
    </DialogTitle>
  )
}
export default EventDialogTitle
