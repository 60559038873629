import React, { useContext } from 'react'
import { trackEvent } from 'lib/analytics'
import confirm from 'lib/confirm'
import LoadingButton from 'components/LoadingButton'
import { EventSlotContext } from 'components/Events/EventSlot/EventSlot'
import { UnregisterForEventMutationFn } from 'types/graphql'
import { noop } from 'lib/helpers'

const style = {
  container: {
    padding: '0 8px',
    display: 'inline-block',
  },
}

export interface Props {
  price?: string | null;
  loading: boolean;
  unregisterForEvent: UnregisterForEventMutationFn;
}

const UnregisterForEventButton = ({
  price,
  loading,
  unregisterForEvent,
}: Props): JSX.Element => {
  const { description } = useContext(EventSlotContext)

  const unregister = (): void => {
    trackEvent({
      category: 'Event',
      action: 'Unregistered',
    })

    unregisterForEvent()
  }

  const getConfirmation = (): void => {
    if (price) {
      confirm(`You will be refunded ${price}.`, {
        title: 'Unregister for Event?',
      }).then((): void => unregister(), noop)
    } else {
      unregister()
    }
  }

  return (
    <div style={ style.container }>
      <LoadingButton
        loading={ loading }
        secondary
        onClick={ getConfirmation }
        ariaLabel={ description && `unregister from ${description}` }
        data-testid='event-unregister-button'
      >
        Unregister
      </LoadingButton>
    </div>
  )
}

export default UnregisterForEventButton
