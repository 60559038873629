import React, { RefObject, useEffect, useRef, useState } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Spinner from 'components/Spinner'
import { debounce } from 'lodash'
import useStyles from './styles'

export interface Props extends ButtonProps {
  loading: boolean;
  secondary?: boolean;
  ariaLabel?: string;
}

const LoadingButton = (
  {
    ariaLabel,
    loading,
    secondary,
    disabled,
    children,
    onClick,
    ...props
  }: Props,
  ref:
  | ((instace: HTMLButtonElement | null) => void)
  | RefObject<HTMLButtonElement>
  | null
): JSX.Element => {
  const classes = useStyles()

  const [debouncedLoading, setDebouncedLoading] = useState(false)

  const debounced = useRef(
    debounce((): void => {
      setDebouncedLoading(true)
    }, 300)
  )

  useEffect((): void | (() => void) => {
    if (loading === debouncedLoading) return

    const { current } = debounced

    if (loading) {
      current()
    } else {
      setDebouncedLoading(false)
      current.cancel()
    }

    return (): void => {
      current.cancel()
    }
  }, [debouncedLoading, debounced, loading])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    !loading && onClick && onClick(e)
  }

  return (
    <Button
      variant={ secondary ? 'outlined' : 'contained' }
      color={ secondary ? 'secondary' : 'primary' }
      { ...props }
      ref={ ref }
      disabled={ debouncedLoading || disabled }
      onClick={ handleClick }
      aria-label={ debouncedLoading ? 'Loading...' : ariaLabel || '' }
    >
      {children}
      {debouncedLoading ? (
        <Spinner
          size={ 15 }
          className={ classes.spinner }
          containerClass={ classes.containerStyle }
        />
      ) : (
        ''
      )}
    </Button>
  )
}

LoadingButton.displayName = 'LoadingButton'

export default React.forwardRef(LoadingButton)
