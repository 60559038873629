import Dumb from './EventSlots'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { EVENT_QUERY } from 'queries'

const EventSlots = ({ eventId, startDate, endDate }) => (
  <Query
    query={ EVENT_QUERY }
    variables={ { eventId: eventId, after: startDate, before: endDate } }
  >
    {queryProps => <Dumb { ...queryProps } />}
  </Query>
)

EventSlots.propTypes = {
  eventId: PropTypes.number.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default EventSlots
