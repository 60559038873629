import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import { createStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader, { CardHeaderProps } from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

const styles = createStyles({
  card: {
    marginBottom: '10px',
  },
  childrenContainer: {
    textAlign: 'right',
  },
})

export interface Props extends WithStyles<typeof styles> {
  children?: JSX.Element;
  headerProps: CardHeaderProps;
  noChildrenText: string;
  hasChildren: boolean;
}

const DashboardCardList = ({
  classes,
  children,
  hasChildren,
  headerProps,
  noChildrenText,
}: Props): JSX.Element => {
  return (
    <Card className={ classes.card }>
      <CardHeader
        { ...headerProps }
        titleTypographyProps={ { variant: 'subtitle1' } }
        subheaderTypographyProps={ { variant: 'caption' } }
      />
      <CardContent>
        {hasChildren ? (
          <div className={ classes.childrenContainer }>{children}</div>
        ) : (
          <Typography variant='body2'>{noChildrenText}</Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(DashboardCardList)
