import Dumb from './FacilityInfo'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const FACILITY_INFO_QUERY = gql`
  query facilityInfo {
    viewer {
      id
      location {
        id
        name
        city
        address
        state
        zip
        hoursOfOperation
        management
        managementContact
        generalInformation
        phoneNumber
      }
    }
  }
`

const FacilityInfo = graphql(FACILITY_INFO_QUERY, {
  props: ({ data: { loading, viewer } }) => ({
    loading,
    location: viewer && viewer.location,
  }),
})(Dumb)

export default FacilityInfo
