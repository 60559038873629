import React from 'react'
import { isOnlyUser } from 'lib/helpers'
import AttendingChip from 'components/GFC/AttendingChip'
import useStyles from './styles'
import { AttendeeFragment } from 'types/graphql'

export interface Props {
  attending: AttendeeFragment[];
  margins?: boolean;
}

const RegistrationStatus = ({ attending, ...props }: Props): JSX.Element => {
  const classes = useStyles()

  if (isOnlyUser(attending)) {
    const status = attending[0].status

    return (
      // If the user has no associates, it makes more sense to display the
      // status than her name.
      <div>
        <AttendingChip
          attendee={ { status, firstName: status, lastName: '' } }
          { ...props }
        />
      </div>
    )
  }

  return (
    <div className={ classes.container }>
      {attending.map(
        (attendee): JSX.Element => (
          <AttendingChip key={ attendee.id } attendee={ attendee } { ...props } />
        )
      )}
    </div>
  )
}

export default RegistrationStatus
