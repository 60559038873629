import Dumb from './Profile'
import React from 'react'
import { ProfileBasicInfoComponent, ProfileBasicInfoQuery } from 'types/graphql'
import Spinner from 'components/Spinner'

const Profile = (): JSX.Element => (
  <ProfileBasicInfoComponent>
    {({ data, loading }): JSX.Element => {
      if (loading) return <Spinner center fullHeight />

      return <Dumb data={ data as ProfileBasicInfoQuery } />
    }}
  </ProfileBasicInfoComponent>
)

export default Profile
