import { createStyles, makeStyles, StyleRules } from '@material-ui/core/styles'
import { Props } from './CardLi'

export default makeStyles(
  (): StyleRules<any, any> =>
    createStyles({
      card: {
        margin: '20px 10px',
        position: 'relative',
      },
      header: {
        paddingBottom: (props: Props): number | string =>
          props.children ? 0 : 'auto',
      },
      headerContainer: { display: 'flex' },
      headerChildrenContainer: { margin: '10px 10px 0 0' },
    })
)
