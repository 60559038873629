import React from 'react'
import { TextField } from '@material-ui/core'
import useStyles from './styles'

interface Props {
  result?: {
    availability?: string;
    comments?: string;
  };
  setResult?: (values: { availability?: string; comments?: string }) => void;
}

type ChangeFn = (event: React.ChangeEvent<HTMLInputElement>) => void

const AvailabilityDialog = ({
  setResult = (): void => {},
  result = { availability: undefined, comments: undefined },
}: Props): JSX.Element => {
  const classes = useStyles()

  const handleChange = (key: string): ChangeFn => (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setResult({ ...result, [key]: event.target.value })
  }
  return (
    <>
      <TextField
        fullWidth
        placeholder='e.g. monday evenings'
        label='Your availability'
        name='availability'
        id='availability-input'
        onChange={ handleChange('availability') }
        className={ classes.gutterBottom }
      />
      <TextField
        fullWidth
        multiline
        rows={ 4 }
        placeholder='enter comment...'
        label='Comments'
        id='comments-input'
        name='comments'
        onChange={ handleChange('comments') }
      />
    </>
  )
}

export default AvailabilityDialog
