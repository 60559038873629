import React from 'react'
import PropTypes from 'prop-types'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import steps from './steps'
import { trackEvent } from 'lib/analytics'
import { Mutation, useApolloClient } from 'react-apollo'
import { TOUR_COMPLETED } from 'mutations'
import { UPDATE_TOUR } from 'mutations/local'
import { sleep } from 'lib/helpers'
import { STAFFS_QUERY } from 'queries'
import { GFC_SCHEDULES_QUERY } from 'queries/gfc'
import { FITNESS_SERVICE_TYPES_QUERY } from 'queries/hfs'

const advanceStep = (updateTour, index) => {
  trackEvent({
    category: 'Tour',
    action: 'Advanced',
    label: index.toString(),
  })

  updateTour({ variables: { stepIndex: index } })
}

const callback = async(tour, updateTour, tourCompleted, client, history) => {
  const { type, index, action } = tour
  const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
  const nextStep = steps[nextStepIndex]

  // we need to start on the dash
  if (type === EVENTS.TOUR_START) {
    history.push('/')
    // we query to cache this ahead of time, so there's a better chance we won't
    // encounter race conditions
    client.query({ query: GFC_SCHEDULES_QUERY })
    client.query({ query: FITNESS_SERVICE_TYPES_QUERY })
    client.query({ query: STAFFS_QUERY })

    // at the end of the tour we run all the steps' cleanup functions
  } else if (type === EVENTS.TOUR_END) {
    // set to false, so we can restart if we click tour again.
    updateTour({ variables: { run: false, stepIndex: 0 } })

    // mark the tour as completed by the user
    tourCompleted()

    trackEvent({
      category: 'Tour',
      action: 'Completed',
    })

    // the 'before' hook is fired after the step is trying to find the element
    // which sometimes is not mounted yet, so we handle it in the 'after' hook
    // of the step before
  } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
    if (nextStep.action) nextStep.action()
    await sleep(300)
    advanceStep(updateTour, nextStepIndex)
  }
}

const Tour = ({ data, history, muiTheme }) => {
  const client = useApolloClient()

  const {
    tour: { run, stepIndex },
  } = data

  return (
    run && (
      <Mutation mutation={ UPDATE_TOUR }>
        {updateTour => (
          <Mutation mutation={ TOUR_COMPLETED }>
            {tourCompleted => (
              <Joyride
                steps={ steps }
                stepIndex={ stepIndex }
                run
                callback={ tour =>
                  callback(tour, updateTour, tourCompleted, client, history)
                }
                spotlightPadding={ 0 }
                showSkipButton
                continuous
                autoStart
                disableOverlayClose
                disableBeacon
                scrollToFirstStep
                scrollOffset={ 60 }
                locale={ { skip: 'Close' } }
                styles={ {
                  buttonClose: {
                    display: 'none',
                  },
                  buttonNext: {
                    cursor: 'pointer',
                  },
                  buttonSkip: {
                    cursor: 'pointer',
                  },
                  buttonBack: {
                    cursor: 'pointer',
                  },
                  options: {
                    primaryColor: muiTheme.palette.primary1Color,
                    zIndex: 1400,
                  },
                } }
              />
            )}
          </Mutation>
        )}
      </Mutation>
    )
  )
}

Tour.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  muiTheme: PropTypes.shape({
    palette: PropTypes.shape({
      primary1Color: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    tour: PropTypes.shape({
      run: PropTypes.bool.isRequired,
      stepIndex: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Tour
