import React, { Dispatch, SetStateAction } from 'react'
import moment from 'moment'
import {
  AttendeeFragment,
  GfcFiltersInput,
  GfcScheduleFragment,
  GfcTableDataMutation,
  GfcTableDataMutationFn,
} from 'types/graphql'
import { Column, Filter, QueryResult } from 'material-table'
import {
  CustomColumnRenderFn,
  DataCallbackResponseHandler,
  GetDataFn,
  Lookup,
  UpdatedTableData,
} from 'components/GFC/GFCTable/types'
import { DATE_FORMAT, DAY_FORMAT } from 'lib/formats'
import DialogLink from 'components/DialogLink'
import RegistrationStatus from '../RegistrationStatus'
import { Button } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { FilterOptions } from 'types'

export const initialFilters: GfcFiltersInput = {
  instructor: [],
  title: [],
  day: [],
  startDateTime: undefined,
}

const getFilterHash = (
  filters: Filter<GfcScheduleFragment>[]
): GfcFiltersInput => {
  const hash = { ...initialFilters }
  filters.forEach((filter): void => {
    hash[filter.column.field as keyof GfcFiltersInput] = filter.value
  })
  return hash
}

// getting unix timestamp from server
const consumeDate = (date: number, format: string): string =>
  moment(date).format(format)

const noRegisters = (attending: AttendeeFragment[] | null): boolean => {
  if (!attending || !attending.length) return true

  return attending.every(
    ({ status }): boolean => status !== 'registered' && status !== 'waitlist'
  )
}

const getDataCallBack = (
  reject: (reason?: Error[]) => void,
  resolve: (
    value?:
    | QueryResult<GfcScheduleFragment>
    | PromiseLike<QueryResult<GfcScheduleFragment>>
  ) => void,
  page: number
): DataCallbackResponseHandler => {
  return (res: { data?: GfcTableDataMutation; errors?: Error[] }): void => {
    if (!res.data || !res.data.gfcTableData) {
      reject(res.errors)
    } else {
      resolve({
        data: res.data.gfcTableData.gfcSchedules,
        page,
        totalCount: res.data.gfcTableData.totalCount,
      })
    }
  }
}

export const getData = (
  setPageSize: Dispatch<SetStateAction<number>>,
  fetchData: GfcTableDataMutationFn
): GetDataFn => {
  return ({
    page,
    pageSize,
    filters,
  }: UpdatedTableData): Promise<QueryResult<GfcScheduleFragment>> => {
    return new Promise((resolve, reject): void => {
      setPageSize(pageSize)
      fetchData({
        variables: {
          pageSize,
          page,
          filters: getFilterHash(filters),
        },
      }).then(getDataCallBack(reject, resolve, page))
    })
  }
}

export const buildLookup = (
  filterOptions: FilterOptions,
  field: keyof Omit<GfcFiltersInput, 'startDateTime'>
): Lookup => {
  let lookup: Lookup = {}

  filterOptions[field].forEach((option): void => {
    lookup[option] = option
  })

  return lookup
}

const dayColumn = (
  filterOptions: FilterOptions
): Column<GfcScheduleFragment> => {
  return {
    title: 'Day',
    field: 'day',
    customFilterAndSearch: (
      terms: string[],
      row: GfcScheduleFragment
    ): boolean => {
      if (!terms.length) return true

      return terms.some(
        (term: string): boolean =>
          term === consumeDate(row.startDateTime, DAY_FORMAT)
      )
    },
    render: (row: GfcScheduleFragment): string =>
      consumeDate(row.startDateTime, DAY_FORMAT),
    lookup: buildLookup(filterOptions, 'day'),
    filterCellStyle: { minWidth: 160 },
    filterPlaceholder: 'Days of Week',
  }
}

const dateColumn = (): Column<GfcScheduleFragment> => {
  return {
    title: 'Date',
    type: 'date',
    field: 'startDateTime',
    filterCellStyle: { maxWidth: 120 },
    filterPlaceholder: 'Date',
    render: (row: GfcScheduleFragment): string =>
      consumeDate(row.startDateTime, DATE_FORMAT),
  }
}

const timeColumn = (): Column<GfcScheduleFragment> => {
  return {
    title: 'Time',
    field: 'formattedTime',
    filtering: false,
  }
}

const customColumnRender = (
  classes: ClassNameMap,
  key: keyof GfcScheduleFragment
): CustomColumnRenderFn => {
  return (row: GfcScheduleFragment): JSX.Element => (
    <DialogLink
      dialogComponent='GFCDetailsDialog'
      dialogProps={ { id: row.id } }
      trigger={ <span className={ classes.anchor }>{row[key]}</span> }
    />
  )
}

type StringColumn = (
  classes: ClassNameMap,
  filterOptions: FilterOptions
) => Column<GfcScheduleFragment>

const stringColumn = (
  field: 'title' | 'instructor',
  title: string
): StringColumn => (
  classes: ClassNameMap,
  filterOptions: FilterOptions
): Column<GfcScheduleFragment> => {
  return {
    title,
    field,
    render: customColumnRender(classes, field),
    lookup: buildLookup(filterOptions, field),
    filterCellStyle: { minWidth: 150 },
    filterPlaceholder: `${title} Filter`,
  }
}

const titleColumn = stringColumn('title', 'Class')

const instructorColumn = stringColumn('instructor', 'Instructor')

const statusColumn = (classes: ClassNameMap): Column<GfcScheduleFragment> => {
  return {
    title: 'Status',
    render: (row): JSX.Element => (
      <div className={ classes.statusContainer }>
        {noRegisters(row.attending) ? (
          <span>{row.spotsAvailable ? 'open' : 'full'}</span>
        ) : (
          <RegistrationStatus attending={ row.attending } />
        )}
      </div>
    ),
  }
}

const actionColumn = (): Column<GfcScheduleFragment> => {
  return {
    title: 'Action',
    render: (row): JSX.Element => (
      <div
        style={ {
          textAlign: 'center',
          display: 'block',
        } }
      >
        {
          <DialogLink
            dialogComponent='GFCDetailsDialog'
            dialogProps={ { id: row.id } }
            trigger={
              <Button color='primary' variant='contained'>
                More Info
              </Button>
            }
          />
        }
      </div>
    ),
  }
}

export const getColumns = (
  filterOptions: FilterOptions,
  classes: ClassNameMap
): Column<GfcScheduleFragment>[] => {
  return [
    dayColumn(filterOptions),
    dateColumn(),
    timeColumn(),
    titleColumn(classes, filterOptions),
    instructorColumn(classes, filterOptions),
    statusColumn(classes),
    actionColumn(),
  ]
}
