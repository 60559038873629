import React from 'react'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import GFCClaimSpotButton from 'components/GFC/GFCClaimSpotButton'
import currencyFormatter from 'currency-formatter'
import GFCRegisterButton from 'components/GFC/GFCRegisterButton'
import GFCCancelButton from 'components/GFC/GFCCancelButton'
import sanitizeHtml from 'sanitize-html'
import { padStart } from 'lib/polyfills'
import moment from 'moment'
import {
  AddToCalendarEvent,
  MappedClassRegistrationStatus,
  MapStatusToActionsProps,
} from 'types'
import { TIME_FORMAT, TIME_FORMAT_WITH_TZ } from 'lib/formats'
import BigCalendar, { DateLocalizer } from 'react-big-calendar'
import { Moment } from 'moment-timezone'
import { isObject } from 'lodash'
import { ClassRegistrationStatusEnum } from 'types/graphql'

export const asPrice = (price: number): string =>
  currencyFormatter.format(price, { code: 'USD' })

export const getPathLabel = (path: string): string => {
  path = path.split('/')[1]
  switch (path) {
  case '':
    return 'Dashboard'
  case 'health-fitness-services':
    return 'Health/Fitness Services'
  case 'check-in-information':
    return 'Check-In Information'
  default:
    return startCase(path)
  }
}

// Return the value of a query string parameter
// https://stackoverflow.com/a/47074143/3525808
export const getUrlParameter = (name: string): string => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  let results = regex.exec(window.location.search)
  return results === null ?
    '' :
    decodeURIComponent(results[1].replace(/\+/g, ' '))
}

// TODO: can probably refactor into one function
export const getStatusForMap = (
  status: ClassRegistrationStatusEnum,
  spotsAvailable: boolean
): MappedClassRegistrationStatus => {
  // registered
  if (status === 'registered') return 'REGISTERED'
  // not registered yet
  if (status === 'register') return spotsAvailable ? 'OPEN' : 'FULL'
  // on the wait list
  return spotsAvailable ? 'OPEN_SPOT' : 'NO_OPEN_SPOTS'
}

// This ensures an object with keys that are in MappedClassRegistrationStatus,
// and values that are ReactElements
type RegisterButtonMap = {
  [key in MappedClassRegistrationStatus]: React.ReactElement
}

export const mapStatusToActions = (
  props: MapStatusToActionsProps
): RegisterButtonMap => ({
  OPEN: (
    <GFCRegisterButton { ...props } color='primary' variant='contained'>
      Register
    </GFCRegisterButton>
  ),
  FULL: (
    <GFCRegisterButton { ...props } color='primary' variant='contained'>
      Get on List
    </GFCRegisterButton>
  ),
  REGISTERED: <GFCCancelButton { ...props }>Unregister</GFCCancelButton>,
  NO_OPEN_SPOTS: <GFCCancelButton { ...props }>Leave Waitlist</GFCCancelButton>,
  OPEN_SPOT: <GFCClaimSpotButton { ...props }>Claim Spot</GFCClaimSpotButton>,
})

export const getGfcStatus = (status: string): string => {
  status = lowerCase(status)
  if (/spot/.test(status)) {
    status = 'on wait list - ' + status
  }
  return status
}

export const createMarkup = (
  html?: string | null,
  opts = {}
): { __html: string } => {
  if (!html) return { __html: '' }
  return { __html: sanitizeHtml(html, opts) }
}

// sleep for a bit to allow for UI changes
export const sleep = (timeout: number): Promise<null> => {
  return new Promise((resolve: Function): void => {
    setTimeout((): void => {
      resolve()
    }, timeout)
  })
}

export const eventsLink = (label: string): string =>
  label.toLowerCase().replace(/ /g, '-')

export const calendarEvent = (
  startTime: string,
  endTime: string,
  title: string,
  description?: string | null,
  classLocation?: string | null
): AddToCalendarEvent => {
  const startDatetime = moment(startTime).utc()
  const endDatetime = moment(endTime).utc()
  return {
    description,
    startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
    endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
    location: classLocation,
    title,
    duration: padStart('' + endDatetime.diff(startDatetime, 'minutes'), 4, '0'),
  }
}

// Determine from the attending list if a user has relevant associates
export const isOnlyUser = (attending: { attendeeType: string }[]): boolean =>
  attending.length === 1 && attending[0].attendeeType === 'User'

export const noop = (): void => {}

export const timeRangeWithTimeZone = (start: string, end: string): string =>
  `${moment(start).format(TIME_FORMAT)} to ${moment(end).format(
    TIME_FORMAT_WITH_TZ
  )}`

export const eventTitle = (
  title: string,
  eventLocation?: string | null
): string => {
  if (!eventLocation) return title
  return `${title} at ${eventLocation}`
}

export const eventSubtitle = (
  details?: string | null,
  price?: string | null
): string => {
  details = details || ''
  if (price) details += ` Price: ${price}`
  return details
}

export const calendarLocalizer = (timezone: string): DateLocalizer => {
  // https://github.com/intljusticemission/react-big-calendar/issues/118#issuecomment-290253011
  const m = (args: object): Moment => moment.tz(args, timezone)
  m.localeData = moment.localeData

  return BigCalendar.momentLocalizer(m)
}

export const login = (token: string): void => {
  localStorage.setItem('elevation-token', `bearer ${token}`)
}

interface GenericObj {
  [key: string]: string | GenericObj;
}

// from an object like { user: { location: { id: 1, name: 'Gilon' } } }
// to { 'user.location.id': 1, 'user.location.name': 'Gilon' }
export const stringifyObjKeys = (
  obj: GenericObj,
  newObj: GenericObj = {},
  path = ''
): GenericObj => {
  Object.keys(obj).forEach((key): void => {
    if (isObject(obj[key])) {
      stringifyObjKeys(obj[key] as GenericObj, newObj, path + `${key}.`)
    } else {
      newObj[path + `${key}`] = obj[key]
    }
  })
  return newObj
}
