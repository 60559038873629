import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  (theme: Theme): StyleRules => ({
    time: {
      color: theme.palette.secondary.main,
      fontSize: '.9rem',
    },
  })
)
