import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogLink from 'components/DialogLink'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import CardActions from '@material-ui/core/CardActions'
import confirm from 'lib/confirm'
import LoadingButton from 'components/LoadingButton'

const styles = {
  container: {
    padding: 10,
  },
  card: {
    marginBottom: '10px',
    textAlign: 'center',
    width: '100%',
  },
  tabHeader: {
    textAlign: 'center',
    fontWeight: '100',
    textTransform: 'uppercase',
    fontSize: '1.1rem',
  },
  helpText: {
    marginBottom: 10,
  },
}

const handleRemove = (id, remove) =>
  confirm('Remove Guest Permenantly?').then(
    () => remove({ variables: { id } }),
    () => {}
  )

const GuestsTab = ({ classes, guests, removeGuest, removeGuestLoading }) => {
  return (
    <div className={ classes.container }>
      <h2 className={ classes.tabHeader }>Guests</h2>
      <Grid container justify='center' className={ classes.container }>
        {!guests.length && (
          <Typography variant='subtitle2' className={ classes.helpText }>
            You can add guests to your account here. Guests can be registered
            for group fitness classes and may accompany you to the facility. On
            their first visit, they will be prompted to sign the facility
            waiver.
          </Typography>
        )}
        {guests.map(({ id, firstName, lastName }) => (
          <Card key={ id } className={ classes.card }>
            <CardContent>
              <Typography gutterBottom variant='h5' component='h2'>
                {firstName} {lastName}
              </Typography>
            </CardContent>
            <CardActions>
              <DialogLink
                dialogComponent='GuestDialog'
                dialogProps={ { id } }
                trigger={
                  <Button size='small' color='primary'>
                    Edit
                  </Button>
                }
              />
              <LoadingButton
                loading={ removeGuestLoading }
                size='small'
                secondary
                onClick={ () => handleRemove(id, removeGuest) }
              >
                Remove
              </LoadingButton>
            </CardActions>
          </Card>
        ))}
      </Grid>
      <div style={ { textAlign: 'center' } }>
        <DialogLink
          dialogComponent='GuestDialog'
          trigger={
            <Button color='primary' variant='contained'>
              Add Guest
            </Button>
          }
        />
      </div>
    </div>
  )
}

GuestsTab.propTypes = {
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ),
  classes: PropTypes.object.isRequired,
  removeGuest: PropTypes.func.isRequired,
  removeGuestLoading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(GuestsTab)
