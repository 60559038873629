import Dumb from './DependentWaiverDialog'
import React from 'react'
import { useDependentWaiverQuery } from 'types/graphql'
import ErrorMessage from 'components/ErrorMessage'

export interface Props {
  linkText: string;
}

const DependentWaiverDialog = ({ linkText }: Props): JSX.Element | null => {
  const { data, loading } = useDependentWaiverQuery()
  if (loading) return null
  if (!data) return <ErrorMessage />

  return <Dumb data={ data } linkText={ linkText } />
}

export default DependentWaiverDialog
