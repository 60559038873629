import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import GridList from '@material-ui/core/GridList'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import classNames from 'classnames'
import dragscroll from 'dragscroll'
import BewellItem from 'components/bewell/BewellItem'

const styles = () => ({
  category: {
    marginTop: 10,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  card: {
    position: 'sticky',
    left: '0',
    zIndex: '999',
    minWidth: '150px',
    opacity: '.8',
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'black',
  },
})

const BewellBoard = ({
  classes,
  isToday,
  data: {
    viewer: {
      location: {
        bewell: { categories },
      },
    },
  },
}) => {
  useEffect(() => {
    dragscroll.reset()
  })

  return (
    <div>
      {categories.map(category => (
        <div key={ category.id } className={ classes.category }>
          <GridList className={ classNames(classes.gridList, 'dragscroll') }>
            <Card
              style={ { backgroundColor: category.color, height: 'auto' } }
              className={ classes.card }
            >
              <CardContent>
                <Typography
                  color='textSecondary'
                  gutterBottom
                  className={ classes.title }
                >
                  {category.title}
                </Typography>
              </CardContent>
            </Card>
            {category.bewellItems.map(item => (
              <BewellItem
                key={ item.id }
                item={ item }
                color={ category.color }
                isToday={ isToday }
              />
            ))}
          </GridList>
        </div>
      ))}
    </div>
  )
}

BewellBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      location: PropTypes.shape({
        bewell: PropTypes.shape({
          categories: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              title: PropTypes.string.isRequired,
              color: PropTypes.string.isRequired,
              bewellItems: PropTypes.arrayOf(
                PropTypes.shape({ id: PropTypes.number.isRequired })
              ).isRequired,
            })
          ).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  isToday: PropTypes.bool.isRequired,
}

export default withStyles(styles)(BewellBoard)
