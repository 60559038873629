import React, { useContext, useState } from 'react'
import TourAdvance from 'components/TourAdvance'
import LogoutIcon from '@material-ui/icons/PowerSettingsNew'
import ProfileIcon from '@material-ui/icons/PermIdentity'
import AccountIcon from '@material-ui/icons/AccountBalanceWallet'
import FamilyIcon from '@material-ui/icons/Home'
import Drawer from '@material-ui/core/Drawer'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import LogoutButton from 'components/auth/LogoutButton'
import Profile from 'components/profileDrawer/Profile'
import Account from 'components/account/Account'
import Family from 'components/family/Family'
import useStyles from './styles'
import { ProfileMenuQuery } from 'types/graphql'
import { MobileMenuContext } from 'components/navigation/MobileMenuButton/MobileMenuButton'
import { ProfileMenuContext } from 'components/ProfileMenu/ProfileMenu'

interface Props {
  data: ProfileMenuQuery;
}

type DrawerType = 'profile' | 'account' | 'family'

const menuItemContent = (label: string, icon: JSX.Element): JSX.Element => (
  <>
    <ListItemIcon>{icon}</ListItemIcon>
    <Typography variant='inherit'>{label}</Typography>
  </>
)

const ProfileMenuItems = ({
  data: {
    viewer: {
      impersonated,
      location: {
        config: { dependentsEnabled, guestsEnabled, partnersEnabled },
      },
    },
  },
}: Props): JSX.Element => {
  const classes = useStyles()
  const [state, setState] = useState({
    profile: false,
    account: false,
    family: false,
  })
  const { closeMobileMenu } = useContext(MobileMenuContext)
  const { closeProfileMenu } = useContext(ProfileMenuContext)

  const toggleDrawer = (
    drawer: DrawerType,
    open: boolean
  ): (() => void) => (): void => {
    if (open) {
      closeMobileMenu && closeMobileMenu()
      closeProfileMenu && closeProfileMenu()
    }

    setState({ ...state, [drawer]: open })
  }

  const drawer = (key: DrawerType, contents: JSX.Element): JSX.Element => (
    <Drawer anchor='right' open={ state[key] } onClose={ toggleDrawer(key, false) }>
      <div className={ classes.drawerContents }>{contents}</div>
    </Drawer>
  )

  const familyEnabled = dependentsEnabled || guestsEnabled || partnersEnabled
  return (
    <>
      <MenuItem
        className={ classes.menuItem }
        id='my-profile-menu-profile'
        onClick={ toggleDrawer('profile', true) }
      >
        <TourAdvance>{menuItemContent('Profile', <ProfileIcon />)}</TourAdvance>
      </MenuItem>
      <MenuItem
        className={ classes.menuItem }
        id='my-profile-menu-account'
        onClick={ toggleDrawer('account', true) }
      >
        <TourAdvance>{menuItemContent('Account', <AccountIcon />)}</TourAdvance>
      </MenuItem>

      {familyEnabled ? (
        <MenuItem
          className={ classes.menuItem }
          id='family-menu-account'
          onClick={ toggleDrawer('family', true) }
        >
          {menuItemContent('Family & Friends', <FamilyIcon color='action' />)}
        </MenuItem>
      ) : null}
      <Divider />

      <MenuItem className={ classes.menuItem } id='my-profile-menu-logout'>
        <LogoutButton>
          {menuItemContent(
            impersonated ? 'Sign back in as Admin' : 'Logout',
            <LogoutIcon />
          )}
        </LogoutButton>
      </MenuItem>

      {drawer('profile', <Profile />)}
      {drawer('account', <Account />)}
      {drawer('family', <Family />)}
    </>
  )
}

export default ProfileMenuItems
