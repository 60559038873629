import React from 'react'
import MenuNavLinks from 'components/navigation/MenuNavLinks'
import Subheader from 'material-ui/Subheader'
import { List } from 'material-ui/List'
import ProfileMenuItems from 'components/ProfileMenuItems'
import ShoppingCartMenuItem from 'components/ShoppingCartMenuItem'

const MobileMenu = (): JSX.Element => {
  return (
    <List>
      <ShoppingCartMenuItem />
      <MenuNavLinks />
      <Subheader>My Profile</Subheader>
      <ProfileMenuItems />
    </List>
  )
}

export default MobileMenu
