import Dumb from 'components/auth/Login/Login'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { password } from 'lib/yupHelpers'
import { useLoginUserMutation } from 'types/graphql-auth'
import { login as setUserToken } from 'lib/helpers'
import { useHistory } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Required'),
  password,
})

const initialValues = {
  login: '',
  password: '',
}

export interface LoginForm {
  login: string;
  password: string;
}

const Login = (): JSX.Element => {
  const [login] = useLoginUserMutation()
  const {
    push,
    location: { state },
  } = useHistory()

  const handleSubmit = async(values: LoginForm): Promise<void> => {
    const res = await login({ variables: { ...values } })
    if (res.data && res.data.loginUser && res.data.loginUser.token) {
      setUserToken(res.data.loginUser.token)
      const referrer = state && state.referrer
      push(referrer || '/')
    }
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ handleSubmit }
      validationSchema={ validationSchema }
    >
      <Dumb />
    </Formik>
  )
}

export default Login
