import React from 'react'
import useStyles from './styles'
import Spinner from 'components/Spinner'

const RedeemPointsCatalogLoading = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Spinner
      center
      containerClass={ classes.spinnerContainer }
      className={ classes.spinner }
    />
  )
}

export default RedeemPointsCatalogLoading
