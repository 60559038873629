import Dumb from './UserActivityTab'
import React from 'react'
import { UserActivityComponent, UserActivityQuery } from 'types/graphql'
import Spinner from 'components/Spinner'

const UserActivityTab = (): JSX.Element => (
  <UserActivityComponent>
    {({ data, loading }): JSX.Element => {
      if (loading) return <Spinner center fullHeight />
      return <Dumb data={ data as UserActivityQuery } />
    }}
  </UserActivityComponent>
)

export default UserActivityTab
