import gql from 'graphql-tag'
import { card } from 'fragments'

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation cancelSubscription($subscriptionId: Int!) {
    cancelSubscription(subscriptionId: $subscriptionId) {
      subscription {
        id
        cancelAtPeriodEnd
      }
      message
    }
  }
`

export const RENEW_SUBSCRIPTION_MUTATION = gql`
  mutation renewSubscription($subscriptionId: Int!) {
    renewSubscription(subscriptionId: $subscriptionId) {
      subscription {
        id
        cancelAtPeriodEnd
      }
      message
    }
  }
`

// ask for lapsedMembership back because the mutation can affect it
export const ADD_CREDIT_CARD = gql`
  mutation addCreditCard($tokenId: String!, $asPrimary: Boolean!) {
    addCreditCard(tokenId: $tokenId, asPrimary: $asPrimary) {
      viewer {
        id
        needsToAddCard
        lapsedMembership
      }
      message
    }
  }
`

export const REMOVE_CREDIT_CARD_MUTATION = gql`
  mutation removeCreditCard($cardId: Int!) {
    removeCreditCard(cardId: $cardId) {
      viewer {
        id
        cards {
          ...card
        }
      }
      message
    }
  }
  ${card}
`

export const SET_PRIMARY_CARD_MUTATION = gql`
  mutation setPrimaryCard($cardId: Int!) {
    setPrimaryCard(cardId: $cardId) {
      viewer {
        id
        cards {
          ...card
        }
      }
      message
    }
  }
  ${card}
`

export const RENAME_CREDIT_CARD_MUTATION = gql`
  mutation renameCreditCard($cardId: Int!, $label: String!) {
    renameCreditCard(cardId: $cardId, label: $label) {
      viewer {
        id
        cards {
          ...card
        }
      }
      message
    }
  }
  ${card}
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      message
    }
  }
`
