import React, { useContext } from 'react'
import { CardActions } from 'material-ui/Card'
import { trackEvent } from 'lib/analytics'
import confirm from 'lib/confirm'
import LoadingButton from 'components/LoadingButton'
import { EventSlotContext } from 'components/Events/EventSlot/EventSlot'
import { RegisterForEventMutationFn } from 'types/graphql'
import { noop } from 'lib/helpers'

export interface Props {
  price?: string | null;
  loading: boolean;
  registerForEvent: RegisterForEventMutationFn;
  disabled: boolean;
}

const RegisterForEventButton = ({
  price,
  loading,
  registerForEvent,
  disabled,
}: Props): JSX.Element => {
  const { description } = useContext(EventSlotContext)

  const register = (): void => {
    trackEvent({
      category: 'Event',
      action: 'Registered',
    })

    registerForEvent()
  }

  const getConfirmation = (): void => {
    if (price) {
      confirm(`You will be charged ${price}.`, {
        title: 'Register for Event?',
      }).then((): void => register(), noop)
    } else {
      register()
    }
  }

  return (
    <CardActions>
      <LoadingButton
        loading={ loading }
        onClick={ getConfirmation }
        disabled={ disabled }
        ariaLabel={ `register for ${description}` }
        data-testid='event-register-button'
      >
        Register
      </LoadingButton>
    </CardActions>
  )
}

export default RegisterForEventButton
