import Dumb from './InvitePartner'
import React from 'react'
import { useInvitePartnerMutation, usePartnerConfigQuery } from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'

const InvitePartner = (): JSX.Element => {
  const [invitePartner, { loading: inviteLoading }] = useInvitePartnerMutation()
  const { data, loading, error } = usePartnerConfigQuery()

  if (loading) return <Spinner center size={ 20 } />
  if (!data || error) return <ErrorMessage error={ error } />

  return (
    <Dumb
      inviteLoading={ inviteLoading }
      data={ data }
      invitePartner={ invitePartner }
    />
  )
}

export default InvitePartner
