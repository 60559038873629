import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Card } from '@material-ui/core'
import useStyles from './styles'

export interface Props {
  title: string;
  stat?: number | string | null;
  subTitle?: string;
  children?: JSX.Element;
  joyrideId: string;
}

const DashboardWidget = (props: Props): JSX.Element => {
  const classes = useStyles(props)
  const { title, stat, subTitle, children, joyrideId, ...otherProps } = props

  return (
    <Grid item xs={ 12 } md={ 6 } lg={ 3 } className={ classes.flex } { ...otherProps }>
      <Card className={ classes.widget } id={ joyrideId }>
        <div className={ classes.description }>{title}</div>
        <div className={ classes.value }>
          <span
            data-toggle='counter'
            data-end={ stat }
            className='number gfc-check-in-count'
          >
            {stat}
          </span>
        </div>
        {subTitle ? <h2 className={ classes.h2 }>{subTitle}</h2> : null}
        {children}
      </Card>
    </Grid>
  )
}

export default DashboardWidget
