import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  banner: {
    textAlign: 'center',
    backgroundColor: palette.primary.light,
    color: 'white',
    lineHeight: 3,
    width: 'calc(100% - 32px)',
    zIndex: 2,
    position: 'fixed',
    top: spacing(8),
    fontSize: 17,
  },
}))

const TopBanner = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Typography variant='subtitle1' className={ classes.banner }>
      <span role='img' aria-label='confetti'>
        🎉
      </span>{' '}
      Elevation Corporate Health Celebrates 25 Years!{' '}
      <span role='img' aria-label='confetti'>
        🎉
      </span>
    </Typography>
  )
}

export default TopBanner
