import React from 'react'
import confirm from 'lib/confirm'
import LoadingButton from 'components/LoadingButton'
import { RedeemRewardPointsMutationFn } from 'types/graphql'
import useStyles from './styles'

export interface Props {
  redeem: RedeemRewardPointsMutationFn;
  points: number;
  loading: boolean;
  disabled: boolean;
}

const RedeemButton = ({
  redeem,
  points,
  loading,
  ...props
}: Props): JSX.Element => {
  const classes = useStyles()

  const confirmRedeem = (): void => {
    confirm(`Redeem ${points} points for product?`).then(
      (): void => {
        redeem()
      },
      (): void => {}
    )
  }

  return (
    <LoadingButton
      className={ classes.button }
      onClick={ confirmRedeem }
      loading={ loading }
      { ...props }
    >
      Redeem
    </LoadingButton>
  )
}

export default RedeemButton
