import { KeyboardEvent } from 'react'
const KEY_ENTER = 13
const KEY_SPACE = 32

/* eslint-disable-next-line import/prefer-default-export */
export const keyHandler = (
  callback: () => void
): ((event: KeyboardEvent<HTMLElement>) => void) => (event): void => {
  if (event.which === KEY_ENTER || event.which === KEY_SPACE) {
    event.stopPropagation()
    return callback()
  }
}
