import Dumb from './GuestsTab'
import React from 'react'
import { Query, Mutation } from 'react-apollo'
import Spinner from 'components/Spinner'
import { GUESTS_QUERY } from 'queries/family'
import { REMOVE_GUEST_MUTATION } from 'mutations/family'

const GuestsTab = props => (
  <Query query={ GUESTS_QUERY }>
    {({ data, loading }) => {
      if (loading) return <Spinner center fullHeight />
      return (
        <Mutation mutation={ REMOVE_GUEST_MUTATION }>
          {(removeGuest, { loading: removeGuestLoading }) => (
            <Dumb
              guests={ data.viewer.guests }
              removeGuest={ removeGuest }
              removeGuestLoading={ removeGuestLoading }
              { ...props }
            />
          )}
        </Mutation>
      )
    }}
  </Query>
)

export default GuestsTab
