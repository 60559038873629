import Dumb from './RemoveCartItemButton'
import React from 'react'
import { Mutation } from 'react-apollo'
import { REMOVE_ITEM_FROM_CART_MUTATION } from 'mutations'
import { FITNESS_SERVICE_TYPES_QUERY } from 'queries/hfs'

const RemoveCartItemButton = props => (
  <Mutation
    mutation={ REMOVE_ITEM_FROM_CART_MUTATION }
    refetchQueries={ [{ query: FITNESS_SERVICE_TYPES_QUERY }] }
  >
    {removeItem => <Dumb removeItem={ removeItem } { ...props } />}
  </Mutation>
)

export default RemoveCartItemButton
