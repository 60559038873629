import React from 'react'
import PropTypes from 'prop-types'
import DialogLink from 'components/DialogLink'
// Maintain the default style and behavior of the EventWrapper,
// but wrap it in a DialogLink, so the whole thing is clickable.
import Wrapper from 'react-big-calendar/lib/EventWrapper'

const getDialogComponent = type => {
  switch (type) {
  case 'Appointment':
    return 'AppointmentsDialog'
  case 'AppointmentBooking':
    return 'BookedEventDialog'
  default:
    return 'EventSlotsDialog'
  }
}

const EventWrapper = ({ event, ...props }) => {
  const dialogEvent = { ...event, __typename: 'DialogPropsEvent' }
  return (
    <DialogLink
      dialogComponent={ getDialogComponent(event.type) }
      dialogProps={ { event: dialogEvent } }
      trigger={ <Wrapper { ...props } /> }
    />
  )
}

EventWrapper.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  }).isRequired,
}

export default EventWrapper
