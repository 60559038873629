import gql from 'graphql-tag'

export const JOIN_BEWELL = gql`
  mutation joinBewell {
    joinBewell {
      viewer {
        id
        bewell {
          optedIn
        }
      }
      message
    }
  }
`

export const TOGGLE_BEWELL_COMPLETE_ITEM = gql`
  mutation toggleBewellItemComplete(
    $id: Int!
    $isToday: Boolean!
    $complete: Boolean!
  ) {
    toggleBewellItemComplete(id: $id, isToday: $isToday, complete: $complete) {
      bewellItem {
        id
        isCompletedToday
        isCompletedYesterday
        bewellCategory {
          id
          isStoneEarned
        }
      }
      viewer {
        id
        bewell {
          points
        }
      }
      message
    }
  }
`
