import React from 'react'
import { GfcScheduleFragment } from 'types/graphql'
import SelectFilter from 'components/SelectFilter'
import moment from 'moment'
import { map, range } from 'lodash'
import useStyles from './styles'
import { Grid } from '@material-ui/core'
import { FilterOptions } from 'types'

export interface Props {
  schedules: GfcScheduleFragment[];
  setFilters: Function;
  filters: FilterOptions;
}

const filterOptions = (
  schedules: GfcScheduleFragment[]
): { filterKey: keyof FilterOptions; options: string[] }[] => [
  {
    filterKey: 'day',
    options: range(7).map((d: number): string => moment(d, 'd').format('dddd')),
  },
  {
    filterKey: 'title',
    options: map(schedules, 'title'),
  },
  {
    filterKey: 'instructor',
    options: map(schedules, 'instructor'),
  },
]

const GFCListFilters = ({
  schedules,
  setFilters,
  filters,
}: Props): JSX.Element => {
  const classes = useStyles()
  const handleChange = (
    value: string,
    filterKey: keyof FilterOptions
  ): void => {
    setFilters({ ...filters, [filterKey]: value })
  }

  return (
    <Grid container className={ classes.container }>
      <Grid item xs={ 12 }>
        {filterOptions(schedules).map(
          ({ filterKey, options }): JSX.Element => (
            <SelectFilter
              key={ filterKey }
              filterKey={ filterKey }
              options={ options }
              onChange={ handleChange }
              value={ filters[filterKey][0] }
            />
          )
        )}
      </Grid>
    </Grid>
  )
}

export default GFCListFilters
