import React from 'react'
import LoadingButton from 'components/LoadingButton'
import { ButtonProps } from '@material-ui/core/Button'
import confirm from 'lib/confirm'
import { trackEvent } from 'lib/analytics'
import { AttendeeFragment } from 'types/graphql'

export interface Props extends ButtonProps {
  style?: {};
  price?: string;
  gfcScheduleId: number;
  // in case we want to, let's say, close a dialog after
  onSuccess?: Function;
  registerToClass: Function;
  loading: boolean;
  children: JSX.Element | string;
  attendee: AttendeeFragment;
}

const GFCRegisterButton = ({
  price,
  style,
  gfcScheduleId,
  onSuccess,
  registerToClass,
  children,
  loading,
  attendee: { attendeeId, attendeeType },
  ...otherProps
}: Props): JSX.Element => {
  const register = (): Promise<void> =>
    registerToClass({
      variables: { gfcScheduleId, attendeeId, attendeeType },
    }).then((): void => {
      onSuccess && onSuccess()
    })

  const handleClick = (): Promise<void> => {
    trackEvent({
      category: 'GFC',
      action: 'Registered',
    })

    if (!price) return register()

    return confirm(`You will be charged ${price}`, {
      title: 'Register for class?',
    }).then(register, (): void => {})
  }

  return (
    <LoadingButton
      { ...otherProps }
      style={ style }
      loading={ loading }
      onClick={ handleClick }
    >
      {children}
    </LoadingButton>
  )
}

GFCRegisterButton.displayName = 'GFCRegisterButton'

export default GFCRegisterButton
