import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  sectionContainer: {
    position: 'relative',
  },
  buttonContainer: {
    textAlign: 'center',
    position: 'relative',
    top: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  cardContainer: {
    transform: 'scale(.6)',
    transformOrigin: '160px',
  },
  cardButtonContainer: {
    marginTop: '10px',
    textAlign: 'center',
  },
  cardButton: {
    margin: '0 10px',
  },
  divider: {
    position: 'relative',
    top: '20px',
  },
  cardLabel: {
    fontSize: '.8rem',
    position: 'absolute',
    left: '0',
    right: '0',
    textAlign: 'center',
    top: '163px',
  },
})
