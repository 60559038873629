import React, { Fragment } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

import ExistingInvitation from 'components/family/ExistingInvitation'
import InvitePartner from 'components/family/InvitePartner'
import RespondToPartnershipButton from 'components/family/RespondToPartnershipButton'
import RemovePartnershipButton from 'components/family/RemovePartnershipButton'
import { PartnerQuery } from 'types/graphql'
import useStyles from './styles'

export interface Props {
  data: PartnerQuery;
}

const Partner = ({
  data: {
    viewer: { partner, pendingPartner, pendingPartnerRequestFrom, invitation },
  },
}: Props): JSX.Element => {
  const classes = useStyles()
  const displayPartner = partner || pendingPartner || pendingPartnerRequestFrom

  if (invitation) {
    return <ExistingInvitation invitation={ invitation } />
  }

  if (!displayPartner) {
    return <InvitePartner />
  }

  return (
    <Card className={ classes.card }>
      <CardContent>
        <Grid item xs={ 12 }>
          <Avatar
            className={ classes.avatar }
            alt={ displayPartner.fullName || 'Partner Avatar' }
            src={ displayPartner.avatar }
          />
          <Typography variant='h5' component='h2'>
            {displayPartner.fullName}
            <br />
            {pendingPartner && (
              <Typography variant='caption'>
                (Awaiting partner's acceptance)
              </Typography>
            )}
            {pendingPartnerRequestFrom && (
              <Typography variant='caption'>
                (Invited you to become partners)
              </Typography>
            )}
          </Typography>
        </Grid>
      </CardContent>
      <CardActions>
        {pendingPartnerRequestFrom ? (
          <Fragment>
            <RespondToPartnershipButton accept />
            <RespondToPartnershipButton accept={ false } />
          </Fragment>
        ) : (
          <RemovePartnershipButton />
        )}
      </CardActions>
    </Card>
  )
}

export default Partner
