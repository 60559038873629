// TODO: implement infinite scroll
import React from 'react'
import Dumb from './SubscriptionPlansTab'
import {
  UserSubscriptionsComponent,
  UserSubscriptionsQuery,
} from 'types/graphql'
import Spinner from 'components/Spinner'

const SubscriptionPlansTab = (): JSX.Element => {
  return (
    <UserSubscriptionsComponent>
      {({ data, loading }): JSX.Element => {
        if (loading) return <Spinner center fullHeight />

        return <Dumb data={ data as UserSubscriptionsQuery } />
      }}
    </UserSubscriptionsComponent>
  )
}

export default SubscriptionPlansTab
