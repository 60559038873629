import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    field: {
      width: 'calc(100% - 48px)',
      position: 'relative',
      top: spacing(1),
    },
  })
)
