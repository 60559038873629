import React from 'react'
import { eventSubtitle, eventTitle } from 'lib/helpers'
import { eventDates } from 'lib/eventsHelpers'
import EventSlots from '../EventSlots'
import CardGroupExpansionPanel from 'components/CardGroupExpansionPanel'
import { EventsQuery } from 'types/graphql'
import useStyles from './styles'

export interface Props {
  startDate: string;
  endDate: string;
  data: EventsQuery;
}

const EventCards = ({
  startDate,
  endDate,
  data: {
    viewer: {
      location: {
        events,
        config: { eventsLabel },
      },
    },
  },
}: Props): JSX.Element => {
  const classes = useStyles()

  if (!events.length) {
    return (
      <h4 className={ classes.noEvents }>
        There are no upcoming {eventsLabel.toLowerCase()}. Check back later!
      </h4>
    )
  }

  return (
    <>
      {events.map(
        (event): JSX.Element => (
          <CardGroupExpansionPanel
            key={ event.id }
            title={ eventTitle(event.title, event.eventLocation) }
            subtitle={ eventSubtitle(event.details, event.price) }
            summaryExtraElement={ <span>{eventDates(event)}</span> }
          >
            <EventSlots
              eventId={ event.id }
              startDate={ startDate }
              endDate={ endDate }
            />
          </CardGroupExpansionPanel>
        )
      )}
    </>
  )
}

export default EventCards
