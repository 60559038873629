import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing, palette, typography }: Theme): StyleRules => ({
    container: {
      marginTop: spacing(2),
      position: 'relative',
    },
    titleText: {
      fontWeight: typography.fontWeightMedium,
      marginBottom: spacing(1),
    },
    subtitleText: {
      color: palette.dimmed.strong,
      fontSize: '0.9rem',
    },
    extraElement: {
      // takes the actions to the right in flex
      marginLeft: 'auto',
      paddingLeft: spacing(1),
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      fontSize: '0.9rem',
    },
  })
)
