import Dumb, { Props } from './RedeemPointsDialog'
import React from 'react'
import { useRedeemPointsQuery } from 'types/graphql'

const RedeemPointsDialog = (props: Props): JSX.Element | null => {
  const { data, loading, error } = useRedeemPointsQuery()

  if (loading) return <Dumb { ...props } />
  if (!data || error) {
    props.closeDialog && props.closeDialog()
    return null
  }

  return <Dumb data={ data } { ...props } />
}

export default RedeemPointsDialog
