import React, { Fragment } from 'react'
import { FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import { useValidateEmailMutation } from 'types/graphql-auth'
import { NewUserForm } from 'types'
import RegistrationStep from 'components/registration/RegistrationStep'
import { RegistrationField } from 'components/registration/types'
import FormTextField from 'components/registration/FormTextField'
import { profile } from 'lib/yupHelpers'

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    profile,
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
  }),
})

const fields = ({
  errors,
  touched,
}: FormikProps<NewUserForm>): RegistrationField[] => [
  {
    name: 'user.profile.firstName',
    label: 'First Name',
    error:
      getIn(touched, 'user.profile.firstName') &&
      getIn(errors, 'user.profile.firstName'),
  },
  {
    name: 'user.profile.lastName',
    label: 'Last Name',
    error:
      getIn(touched, 'user.profile.lastName') &&
      getIn(errors, 'user.profile.lastName'),
  },
  {
    name: 'user.email',
    label: 'Email',
    error: getIn(touched, 'user.email') && getIn(errors, 'user.email'),
  },
]

const NameAndEmailStep = (): JSX.Element => {
  const [validateEmail, { loading }] = useValidateEmailMutation()

  return (
    <RegistrationStep
      validationSchema={ validationSchema }
      asyncValidation={ validateEmail }
      asyncValidationKey='validateEmail'
      validationLoading={ loading }
    >
      {(formikProps: FormikProps<NewUserForm>): JSX.Element => (
        <Fragment>
          {fields(formikProps).map(
            (field, i): JSX.Element => (
              <FormTextField field={ field } key={ field.name } autoFocus={ !i } />
            ),
          )}
        </Fragment>
      )}
    </RegistrationStep>
  )
}

export default NameAndEmailStep
