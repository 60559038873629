import React from 'react'
import Typography from '@material-ui/core/Typography'
import { ApolloError } from 'apollo-client'

export interface Props {
  error?: ApolloError | Error;
}

const Error = ({ error }: Props): JSX.Element => {
  return <Typography>{error ? error.message : 'Failed to load...'}</Typography>
}

export default Error
