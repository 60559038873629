// IE11 doesn't support 'resize' event on window.
// Polyfill from: https://stackoverflow.com/a/41343085/3525808
export const DispatchResizeEvent = () => {
  if (
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > 0
  ) {
    var evt = document.createEvent('UIEvents')
    evt.initUIEvent('resize', true, false, window, 0)
    window.dispatchEvent(evt)
  } else {
    window.dispatchEvent(new Event('resize'))
  }
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
export const padStart = (str, targetLength, padString) => {
  targetLength = targetLength >> 0 // truncate if number, or convert non-number to 0;
  padString = String(typeof padString !== 'undefined' ? padString : ' ')
  if (str.length >= targetLength) {
    return String(str)
  } else {
    targetLength = targetLength - str.length
    if (targetLength > padString.length) {
      padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
    }
    return padString.slice(0, targetLength) + String(str)
  }
}
