import React from 'react'
import {
  AppointmentBooking,
  CloseDialogComponent,
  EventRegistration,
  UnregisterForEventComponent,
} from 'types/graphql'

import Dumb from './UnregisterForEventButton'
import { PureQueryOptions } from 'apollo-client'

interface Props {
  registration: Omit<AppointmentBooking | EventRegistration, '__typename'>;
  refetchQueries?: PureQueryOptions[];
}

const UnregisterForEventButton = ({
  registration: { id: registrationId, charge },
  refetchQueries,
}: Props): JSX.Element => {
  return (
    <CloseDialogComponent>
      {(closeDialog): JSX.Element => (
        <UnregisterForEventComponent
          variables={ { registrationId } }
          refetchQueries={ refetchQueries || [] }
          onCompleted={ (): void => {
            closeDialog()
          } }
        >
          {(unregisterForEvent, { loading }): JSX.Element => (
            <Dumb
              price={ charge && charge.price }
              loading={ loading }
              unregisterForEvent={ unregisterForEvent }
            />
          )}
        </UnregisterForEventComponent>
      )}
    </CloseDialogComponent>
  )
}

export default UnregisterForEventButton
