import React from 'react'
import { ErrorMessage, Field, FieldProps } from 'formik'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import useStyles from 'components/dialogForms/styles'
import { startCase } from 'lodash'

export interface Props {
  name: string;
  disableFuture?: boolean;
}

const DateFieldInput = ({
  name,
  disableFuture = false,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Field name={ name }>
      {({ field, form, meta: { touched, error } }: FieldProps): JSX.Element => (
        <MuiPickersUtilsProvider utils={ DateFnsUtils }>
          <KeyboardDatePicker
            { ...field }
            className={ classes.field }
            InputAdornmentProps={ { position: 'start' } }
            onChange={ (date): void => {
              date && form.setFieldValue(name, date)
            } }
            openTo='year'
            views={ ['year', 'month', 'date'] }
            autoOk
            variant='inline'
            inputVariant='outlined'
            fullWidth
            format='MM/dd/yyyy'
            margin='normal'
            id={ `${name}-input` }
            label={ startCase(name) }
            error={ touched && Boolean(error) }
            helperText={ <ErrorMessage name={ name } /> }
            disableFuture={ disableFuture }
            maxDate={ disableFuture ? new Date() : null } // Actually hides future dates
          />
        </MuiPickersUtilsProvider>
      )}
    </Field>
  )
}

export default DateFieldInput
