import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import moment from 'moment'
import ProfileTab from 'components/profileDrawer/ProfileTab'
import ProfileMedicalTab from 'components/profileDrawer/ProfileMedicalTab'
import UserActivityTab from 'components/profileDrawer/UserActivityTab'
import UserAvatar from 'components/avatars/UserAvatar'
import startCase from 'lodash/startCase'
import ContactIcon from '@material-ui/icons/ContactPhone'
import InfoIcon from '@material-ui/icons/Info'
import EmergencyIcon from '@material-ui/icons/RingVolume'
import MedicalIcon from '@material-ui/icons/Healing'
import ActivityIcon from '@material-ui/icons/List'
import AppBar from '@material-ui/core/AppBar'
import { ProfileBasicInfoFragment, ProfileBasicInfoQuery } from 'types/graphql'
import useStyles from './styles'
import TabPanel from 'components/TabPanel'

export interface Props {
  data: ProfileBasicInfoQuery;
}

export type Field = 'email' | 'name' | 'age' | keyof ProfileBasicInfoFragment
export interface InputField {
  name: string;
  value?: string | number | null;
  message?: string;
}

const Profile = ({ data: { viewer } }: Props): JSX.Element => {
  const classes = useStyles()
  const [value, setValue] = useState('basic')

  const { firstName, lastName, dateOfBirth } = viewer.profile
  const { hideDob } = viewer.location.config

  const getFields = (fields: Field[]): InputField[] => {
    return fields.map(
      (field: Field): InputField => {
        if (field === 'name') {
          return {
            name: 'Name',
            value: `${firstName} ${lastName}`,
          }
        } else if (field === 'age') {
          return {
            name: 'Age',
            value: dateOfBirth && moment().diff(dateOfBirth, 'years', false),
          }
        }
        return {
          name: startCase(field.replace('emergency', '')),
          value: field === 'email' ? viewer.email : viewer.profile[field],
        }
      }
    )
  }

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ): void => {
    setValue(newValue)
  }

  const tab = (
    value: string,
    icon: JSX.Element,
    label: string
  ): JSX.Element => (
    <Tab
      className={ classes.tab }
      id={ `tab-${label}` }
      aria-controls={ `tabpanel-${label}` }
      icon={ icon }
      value={ value }
      aria-label={ label }
    />
  )

  return (
    <div id='profile-tab' role='presentation'>
      <AppBar position='static'>
        <Tabs variant='fullWidth' value={ value } onChange={ handleChange }>
          {tab('basic', <InfoIcon />, 'Basic Info')}
          {tab('contact', <ContactIcon />, 'Contact Info')}
          {tab('emergency', <EmergencyIcon />, 'Emergency Contact')}
          {tab('medical', <MedicalIcon />, 'Medical Info')}
          {tab('user activity', <ActivityIcon />, 'User Activity')}
        </Tabs>
      </AppBar>
      <TabPanel value={ value } index='basic'>
        <h2 className={ classes.tabHeader }>Basic Info</h2>
        <div className={ classes.profileImageContainer }>
          <UserAvatar />
        </div>
        <ProfileTab
          fields={
            hideDob ?
              getFields(['name', 'gender', 'height', 'weight', 'bloodType']) :
              getFields([
                'name',
                'age',
                'gender',
                'height',
                'weight',
                'bloodType',
              ])
          }
          editDialogName='EditBasicInfoDialog'
        />
      </TabPanel>
      <TabPanel value={ value } index='contact'>
        <h2 className={ classes.tabHeader }>Contact Info</h2>
        <ProfileTab
          fields={ getFields([
            'email',
            'contactEmail',
            'city',
            'state',
            'zip',
            'phoneNumber',
            'workPhone',
          ]) }
          editDialogName='EditContactInfoDialog'
        />
      </TabPanel>
      <TabPanel value={ value } index='emergency'>
        <h2 className={ classes.tabHeader }>Emergency Contact</h2>
        <ProfileTab
          fields={ getFields([
            'emergencyFirstName',
            'emergencyMiddleName',
            'emergencyLastName',
            'emergencyRelation',
            'emergencyPhone',
            'emergencyEmail',
            'emergencyAddress',
          ]) }
          editDialogName='EditEmergencyInfoDialog'
        />
      </TabPanel>
      <TabPanel value={ value } index='medical'>
        <ProfileMedicalTab />
      </TabPanel>
      <TabPanel value={ value } index='user activity'>
        <UserActivityTab />
      </TabPanel>
    </div>
  )
}

export default Profile
