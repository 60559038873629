import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any,
  Upload: any,
};

export type ActiveRecordInterface = {
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthCompany = ActiveRecordInterface & {
   __typename?: 'AuthCompany',
  badgeRegex?: Maybe<Scalars['String']>,
  badgeRequired: Scalars['Boolean'],
  createdAt: Scalars['ISO8601DateTime'],
  emergencyContactRequired: Scalars['Boolean'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  locations: Array<AuthLocation>,
  name: Scalars['String'],
  requiredUserVerificationFields: Array<AuthRequiredUserVerificationField>,
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthCompanyPolicy = ActiveRecordInterface & {
   __typename?: 'AuthCompanyPolicy',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  title: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
  waiver?: Maybe<Scalars['String']>,
};

export type AuthCustomUserField = ActiveRecordInterface & {
   __typename?: 'AuthCustomUserField',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  options: Array<AuthCustomUserOption>,
  required: Scalars['Boolean'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthCustomUserFieldValidationError = {
   __typename?: 'AuthCustomUserFieldValidationError',
  error: Scalars['String'],
  fieldId: Scalars['Int'],
};

export type AuthCustomUserOption = ActiveRecordInterface & {
   __typename?: 'AuthCustomUserOption',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthLocation = ActiveRecordInterface & {
   __typename?: 'AuthLocation',
  city: Scalars['String'],
  company: AuthCompany,
  companyPolicy?: Maybe<AuthCompanyPolicy>,
  config: AuthLocationConfiguration,
  createdAt: Scalars['ISO8601DateTime'],
  customUserFields: Array<AuthCustomUserField>,
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  paymentMethod?: Maybe<AuthPaymentMethod>,
  payments: PaymentsEnum,
  subLocations: Array<AuthSubLocation>,
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthLocationConfiguration = ActiveRecordInterface & {
   __typename?: 'AuthLocationConfiguration',
  badgeLabel: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  membershipAgreementCopy?: Maybe<Scalars['String']>,
  membershipsEnabled: Scalars['Boolean'],
  phoneNumberRequired: Scalars['Boolean'],
  subLocationsLabel: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthPaymentMethod = ActiveRecordInterface & {
   __typename?: 'AuthPaymentMethod',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  stripeApiKey: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthProfile = ActiveRecordInterface & {
   __typename?: 'AuthProfile',
  badge: Scalars['String'],
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthQuery = {
   __typename?: 'AuthQuery',
  companiesForEmail: Array<AuthCompany>,
  company: AuthCompany,
  location: AuthLocation,
};


export type AuthQueryCompaniesForEmailArgs = {
  email: Scalars['String']
};


export type AuthQueryCompanyArgs = {
  companyId: Scalars['Int']
};


export type AuthQueryLocationArgs = {
  locationId: Scalars['Int']
};

export type AuthRequiredUserVerificationField = ActiveRecordInterface & {
   __typename?: 'AuthRequiredUserVerificationField',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  fieldType: Scalars['String'],
  id: Scalars['Int'],
  label: Scalars['String'],
  name: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthSubLocation = ActiveRecordInterface & {
   __typename?: 'AuthSubLocation',
  createdAt: Scalars['ISO8601DateTime'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  name: Scalars['String'],
  updatedAt: Scalars['ISO8601DateTime'],
};

export type AuthUser = ActiveRecordInterface & {
   __typename?: 'AuthUser',
  createdAt: Scalars['ISO8601DateTime'],
  email: Scalars['String'],
  errors: Array<Scalars['String']>,
  id: Scalars['Int'],
  profile: AuthProfile,
  updatedAt: Scalars['ISO8601DateTime'],
  username: Scalars['String'],
};

export type AuthUserInput = {
  email?: Maybe<Scalars['String']>,
  companyId?: Maybe<Scalars['Int']>,
  locationId?: Maybe<Scalars['Int']>,
  subLocationId?: Maybe<Scalars['Int']>,
  profile?: Maybe<ProfileInput>,
  password?: Maybe<Scalars['String']>,
  passwordConfirmation?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
};

export type CustomUserFieldInput = {
  id: Scalars['Int'],
  optionId: Scalars['Int'],
};


/** Autogenerated return type of LoginUser */
export type LoginUserPayload = {
   __typename?: 'LoginUserPayload',
  message?: Maybe<Scalars['String']>,
  token?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  loginUser?: Maybe<LoginUserPayload>,
  registerPointsOnlyUser?: Maybe<RegisterPointsOnlyUserPayload>,
  registerUser?: Maybe<RegisterUserPayload>,
  requestResetPassword?: Maybe<RequestResetPasswordPayload>,
  resetPassword?: Maybe<ResetPasswordPayload>,
  validateCredentials?: Maybe<ValidateCredentialsPayload>,
  validateCustomFields?: Maybe<ValidateCustomFieldsPayload>,
  validateEmail?: Maybe<ValidateEmailPayload>,
  verifyUser?: Maybe<VerifyUserPayload>,
};


export type MutationLoginUserArgs = {
  login: Scalars['String'],
  password: Scalars['String']
};


export type MutationRegisterPointsOnlyUserArgs = {
  user: AuthUserInput,
  token: Scalars['String']
};


export type MutationRegisterUserArgs = {
  user: AuthUserInput,
  stripeCardToken?: Maybe<Scalars['String']>,
  customUserFields: Array<CustomUserFieldInput>,
  verifiedUserId?: Maybe<Scalars['Int']>
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String']
};


export type MutationResetPasswordArgs = {
  token: Scalars['String'],
  password: Scalars['String'],
  passwordConfirmation: Scalars['String']
};


export type MutationValidateCredentialsArgs = {
  user: AuthUserInput
};


export type MutationValidateCustomFieldsArgs = {
  customFields: Array<CustomUserFieldInput>
};


export type MutationValidateEmailArgs = {
  user: AuthUserInput
};


export type MutationVerifyUserArgs = {
  verificationFields: Array<VerifiedUserFieldInput>,
  companyId: Scalars['Int']
};

export enum PaymentsEnum {
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
  Disabled = 'DISABLED'
}

export type ProfileInput = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']>,
  gender?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['String']>,
  weight?: Maybe<Scalars['String']>,
  bloodType?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  workPhone?: Maybe<Scalars['String']>,
  contactEmail?: Maybe<Scalars['String']>,
  emergencyFirstName?: Maybe<Scalars['String']>,
  emergencyMiddleName?: Maybe<Scalars['String']>,
  emergencyLastName?: Maybe<Scalars['String']>,
  emergencyRelation?: Maybe<Scalars['Int']>,
  emergencyPhone?: Maybe<Scalars['String']>,
  emergencyEmail?: Maybe<Scalars['String']>,
  emergencyAddress?: Maybe<Scalars['String']>,
  profilePicture?: Maybe<Scalars['Upload']>,
  fitnessRegistrationConfirmation?: Maybe<Scalars['Boolean']>,
  fitnessPurchaseConfirmation?: Maybe<Scalars['Boolean']>,
  fitnessClassSchedule?: Maybe<Scalars['Boolean']>,
  badge?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RegisterPointsOnlyUser */
export type RegisterPointsOnlyUserPayload = {
   __typename?: 'RegisterPointsOnlyUserPayload',
  accessToken: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of RegisterUser */
export type RegisterUserPayload = {
   __typename?: 'RegisterUserPayload',
  message?: Maybe<Scalars['String']>,
  success: Scalars['Boolean'],
};

/** Autogenerated return type of RequestResetPassword */
export type RequestResetPasswordPayload = {
   __typename?: 'RequestResetPasswordPayload',
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
   __typename?: 'ResetPasswordPayload',
  accessToken: Scalars['String'],
  message?: Maybe<Scalars['String']>,
};


/** Autogenerated return type of ValidateCredentials */
export type ValidateCredentialsPayload = {
   __typename?: 'ValidateCredentialsPayload',
  message?: Maybe<Scalars['String']>,
  user?: Maybe<AuthUser>,
};

/** Autogenerated return type of ValidateCustomFields */
export type ValidateCustomFieldsPayload = {
   __typename?: 'ValidateCustomFieldsPayload',
  customUserFields?: Maybe<Array<AuthCustomUserFieldValidationError>>,
  message?: Maybe<Scalars['String']>,
};

/** Autogenerated return type of ValidateEmail */
export type ValidateEmailPayload = {
   __typename?: 'ValidateEmailPayload',
  message?: Maybe<Scalars['String']>,
  user?: Maybe<AuthUser>,
};

export type VerifiedUserFieldInput = {
  key: Scalars['String'],
  value: Scalars['String'],
};

/** Autogenerated return type of VerifyUser */
export type VerifyUserPayload = {
   __typename?: 'VerifyUserPayload',
  message?: Maybe<Scalars['String']>,
  verifiedUserId?: Maybe<Scalars['Int']>,
};

export type CustomUserFieldFragment = (
  { __typename?: 'AuthCustomUserField' }
  & Pick<AuthCustomUserField, 'id' | 'name' | 'required'>
  & { options: Array<(
    { __typename?: 'AuthCustomUserOption' }
    & Pick<AuthCustomUserOption, 'id' | 'name'>
  )> }
);

export type SubLocationFragment = (
  { __typename?: 'AuthSubLocation' }
  & Pick<AuthSubLocation, 'id' | 'name'>
);

export type RegistrationCompanyFragment = (
  { __typename?: 'AuthCompany' }
  & Pick<AuthCompany, 'id' | 'name'>
);

export type RequiredUserVerificationFieldFragment = (
  { __typename?: 'AuthRequiredUserVerificationField' }
  & Pick<AuthRequiredUserVerificationField, 'id' | 'name' | 'label' | 'fieldType'>
);

export type ValidateEmailMutationVariables = {
  user: AuthUserInput
};


export type ValidateEmailMutation = (
  { __typename?: 'Mutation' }
  & { validateEmail: Maybe<(
    { __typename?: 'ValidateEmailPayload' }
    & { user: Maybe<(
      { __typename?: 'AuthUser' }
      & Pick<AuthUser, 'email'>
    )> }
  )> }
);

export type ValidateCustomFieldsMutationVariables = {
  customFields: Array<CustomUserFieldInput>
};


export type ValidateCustomFieldsMutation = (
  { __typename?: 'Mutation' }
  & { validateCustomFields: Maybe<(
    { __typename?: 'ValidateCustomFieldsPayload' }
    & { customUserFields: Maybe<Array<(
      { __typename?: 'AuthCustomUserFieldValidationError' }
      & Pick<AuthCustomUserFieldValidationError, 'fieldId' | 'error'>
    )>> }
  )> }
);

export type ValidateCredentialsMutationVariables = {
  user: AuthUserInput
};


export type ValidateCredentialsMutation = (
  { __typename?: 'Mutation' }
  & { validateCredentials: Maybe<(
    { __typename?: 'ValidateCredentialsPayload' }
    & { user: Maybe<(
      { __typename?: 'AuthUser' }
      & Pick<AuthUser, 'username'>
      & { profile: (
        { __typename?: 'AuthProfile' }
        & Pick<AuthProfile, 'badge'>
      ) }
    )> }
  )> }
);

export type VerifyUserMutationVariables = {
  verificationFields: Array<VerifiedUserFieldInput>,
  companyId: Scalars['Int']
};


export type VerifyUserMutation = (
  { __typename?: 'Mutation' }
  & { verifyUser: Maybe<(
    { __typename?: 'VerifyUserPayload' }
    & Pick<VerifyUserPayload, 'verifiedUserId'>
  )> }
);

export type RegisterUserMutationVariables = {
  user: AuthUserInput,
  stripeCardToken?: Maybe<Scalars['String']>,
  customUserFields: Array<CustomUserFieldInput>,
  verifiedUserId?: Maybe<Scalars['Int']>
};


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: Maybe<(
    { __typename?: 'RegisterUserPayload' }
    & Pick<RegisterUserPayload, 'success'>
  )> }
);

export type ResetPasswordMutationVariables = {
  token: Scalars['String'],
  password: Scalars['String'],
  passwordConfirmation: Scalars['String']
};


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'accessToken' | 'message'>
  )> }
);

export type RequestResetPasswordMutationVariables = {
  email: Scalars['String']
};


export type RequestResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { requestResetPassword: Maybe<(
    { __typename?: 'RequestResetPasswordPayload' }
    & Pick<RequestResetPasswordPayload, 'message'>
  )> }
);

export type RegisterPointsOnlyUserMutationVariables = {
  user: AuthUserInput,
  token: Scalars['String']
};


export type RegisterPointsOnlyUserMutation = (
  { __typename?: 'Mutation' }
  & { registerPointsOnlyUser: Maybe<(
    { __typename?: 'RegisterPointsOnlyUserPayload' }
    & Pick<RegisterPointsOnlyUserPayload, 'accessToken' | 'message'>
  )> }
);

export type LoginUserMutationVariables = {
  login: Scalars['String'],
  password: Scalars['String']
};


export type LoginUserMutation = (
  { __typename?: 'Mutation' }
  & { loginUser: Maybe<(
    { __typename?: 'LoginUserPayload' }
    & Pick<LoginUserPayload, 'token'>
  )> }
);

export type CompaniesForEmailQueryVariables = {
  email: Scalars['String']
};


export type CompaniesForEmailQuery = (
  { __typename?: 'AuthQuery' }
  & { companiesForEmail: Array<(
    { __typename?: 'AuthCompany' }
    & RegistrationCompanyFragment
  )> }
);

export type CompanyQueryVariables = {
  companyId: Scalars['Int']
};


export type CompanyQuery = (
  { __typename?: 'AuthQuery' }
  & { company: (
    { __typename?: 'AuthCompany' }
    & Pick<AuthCompany, 'id'>
    & { locations: Array<(
      { __typename?: 'AuthLocation' }
      & Pick<AuthLocation, 'id' | 'city'>
    )> }
  ) }
);

export type CompanyWaiverQueryVariables = {
  locationId: Scalars['Int']
};


export type CompanyWaiverQuery = (
  { __typename?: 'AuthQuery' }
  & { location: (
    { __typename?: 'AuthLocation' }
    & Pick<AuthLocation, 'id'>
    & { companyPolicy: Maybe<(
      { __typename?: 'AuthCompanyPolicy' }
      & Pick<AuthCompanyPolicy, 'id' | 'title' | 'waiver'>
    )> }
  ) }
);

export type LocationUpdatesQueryVariables = {
  locationId: Scalars['Int']
};


export type LocationUpdatesQuery = (
  { __typename?: 'AuthQuery' }
  & { location: (
    { __typename?: 'AuthLocation' }
    & Pick<AuthLocation, 'id' | 'payments'>
    & { paymentMethod: Maybe<(
      { __typename?: 'AuthPaymentMethod' }
      & Pick<AuthPaymentMethod, 'id' | 'stripeApiKey'>
    )>, config: (
      { __typename?: 'AuthLocationConfiguration' }
      & Pick<AuthLocationConfiguration, 'membershipsEnabled' | 'membershipAgreementCopy' | 'phoneNumberRequired' | 'subLocationsLabel' | 'badgeLabel'>
    ), company: (
      { __typename?: 'AuthCompany' }
      & Pick<AuthCompany, 'id' | 'badgeRequired' | 'emergencyContactRequired'>
      & { requiredUserVerificationFields: Array<(
        { __typename?: 'AuthRequiredUserVerificationField' }
        & RequiredUserVerificationFieldFragment
      )> }
    ), customUserFields: Array<(
      { __typename?: 'AuthCustomUserField' }
      & CustomUserFieldFragment
    )>, subLocations: Array<(
      { __typename?: 'AuthSubLocation' }
      & Pick<AuthSubLocation, 'id' | 'name'>
      & SubLocationFragment
    )> }
  ) }
);

export const CustomUserFieldFragmentDoc = gql`
    fragment customUserField on AuthCustomUserField {
  id
  name
  required
  options {
    id
    name
  }
}
    `;
export const SubLocationFragmentDoc = gql`
    fragment subLocation on AuthSubLocation {
  id
  name
}
    `;
export const RegistrationCompanyFragmentDoc = gql`
    fragment registrationCompany on AuthCompany {
  id
  name
}
    `;
export const RequiredUserVerificationFieldFragmentDoc = gql`
    fragment requiredUserVerificationField on AuthRequiredUserVerificationField {
  id
  name
  label
  fieldType
}
    `;
export const ValidateEmailDocument = gql`
    mutation validateEmail($user: AuthUserInput!) {
  validateEmail(user: $user) {
    user {
      email
    }
  }
}
    `;
export type ValidateEmailMutationFn = ApolloReactCommon.MutationFunction<ValidateEmailMutation, ValidateEmailMutationVariables>;
export type ValidateEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ValidateEmailMutation, ValidateEmailMutationVariables>, 'mutation'>;

    export const ValidateEmailComponent = (props: ValidateEmailComponentProps) => (
      <ApolloReactComponents.Mutation<ValidateEmailMutation, ValidateEmailMutationVariables> mutation={ValidateEmailDocument} {...props} />
    );
    

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useValidateEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateEmailMutation, ValidateEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ValidateEmailMutation, ValidateEmailMutationVariables>(ValidateEmailDocument, baseOptions);
      }
export type ValidateEmailMutationHookResult = ReturnType<typeof useValidateEmailMutation>;
export type ValidateEmailMutationResult = ApolloReactCommon.MutationResult<ValidateEmailMutation>;
export type ValidateEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateEmailMutation, ValidateEmailMutationVariables>;
export const ValidateCustomFieldsDocument = gql`
    mutation validateCustomFields($customFields: [CustomUserFieldInput!]!) {
  validateCustomFields(customFields: $customFields) {
    customUserFields {
      fieldId
      error
    }
  }
}
    `;
export type ValidateCustomFieldsMutationFn = ApolloReactCommon.MutationFunction<ValidateCustomFieldsMutation, ValidateCustomFieldsMutationVariables>;
export type ValidateCustomFieldsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ValidateCustomFieldsMutation, ValidateCustomFieldsMutationVariables>, 'mutation'>;

    export const ValidateCustomFieldsComponent = (props: ValidateCustomFieldsComponentProps) => (
      <ApolloReactComponents.Mutation<ValidateCustomFieldsMutation, ValidateCustomFieldsMutationVariables> mutation={ValidateCustomFieldsDocument} {...props} />
    );
    

/**
 * __useValidateCustomFieldsMutation__
 *
 * To run a mutation, you first call `useValidateCustomFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCustomFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCustomFieldsMutation, { data, loading, error }] = useValidateCustomFieldsMutation({
 *   variables: {
 *      customFields: // value for 'customFields'
 *   },
 * });
 */
export function useValidateCustomFieldsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateCustomFieldsMutation, ValidateCustomFieldsMutationVariables>) {
        return ApolloReactHooks.useMutation<ValidateCustomFieldsMutation, ValidateCustomFieldsMutationVariables>(ValidateCustomFieldsDocument, baseOptions);
      }
export type ValidateCustomFieldsMutationHookResult = ReturnType<typeof useValidateCustomFieldsMutation>;
export type ValidateCustomFieldsMutationResult = ApolloReactCommon.MutationResult<ValidateCustomFieldsMutation>;
export type ValidateCustomFieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateCustomFieldsMutation, ValidateCustomFieldsMutationVariables>;
export const ValidateCredentialsDocument = gql`
    mutation validateCredentials($user: AuthUserInput!) {
  validateCredentials(user: $user) {
    user {
      username
      profile {
        badge
      }
    }
  }
}
    `;
export type ValidateCredentialsMutationFn = ApolloReactCommon.MutationFunction<ValidateCredentialsMutation, ValidateCredentialsMutationVariables>;
export type ValidateCredentialsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ValidateCredentialsMutation, ValidateCredentialsMutationVariables>, 'mutation'>;

    export const ValidateCredentialsComponent = (props: ValidateCredentialsComponentProps) => (
      <ApolloReactComponents.Mutation<ValidateCredentialsMutation, ValidateCredentialsMutationVariables> mutation={ValidateCredentialsDocument} {...props} />
    );
    

/**
 * __useValidateCredentialsMutation__
 *
 * To run a mutation, you first call `useValidateCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCredentialsMutation, { data, loading, error }] = useValidateCredentialsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useValidateCredentialsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidateCredentialsMutation, ValidateCredentialsMutationVariables>) {
        return ApolloReactHooks.useMutation<ValidateCredentialsMutation, ValidateCredentialsMutationVariables>(ValidateCredentialsDocument, baseOptions);
      }
export type ValidateCredentialsMutationHookResult = ReturnType<typeof useValidateCredentialsMutation>;
export type ValidateCredentialsMutationResult = ApolloReactCommon.MutationResult<ValidateCredentialsMutation>;
export type ValidateCredentialsMutationOptions = ApolloReactCommon.BaseMutationOptions<ValidateCredentialsMutation, ValidateCredentialsMutationVariables>;
export const VerifyUserDocument = gql`
    mutation verifyUser($verificationFields: [VerifiedUserFieldInput!]!, $companyId: Int!) {
  verifyUser(verificationFields: $verificationFields, companyId: $companyId) {
    verifiedUserId
  }
}
    `;
export type VerifyUserMutationFn = ApolloReactCommon.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;
export type VerifyUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyUserMutation, VerifyUserMutationVariables>, 'mutation'>;

    export const VerifyUserComponent = (props: VerifyUserComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyUserMutation, VerifyUserMutationVariables> mutation={VerifyUserDocument} {...props} />
    );
    

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      verificationFields: // value for 'verificationFields'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, baseOptions);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = ApolloReactCommon.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyUserMutation, VerifyUserMutationVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($user: AuthUserInput!, $stripeCardToken: String, $customUserFields: [CustomUserFieldInput!]!, $verifiedUserId: Int) {
  registerUser(user: $user, stripeCardToken: $stripeCardToken, customUserFields: $customUserFields, verifiedUserId: $verifiedUserId) {
    success
  }
}
    `;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;
export type RegisterUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterUserMutation, RegisterUserMutationVariables>, 'mutation'>;

    export const RegisterUserComponent = (props: RegisterUserComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterUserMutation, RegisterUserMutationVariables> mutation={RegisterUserDocument} {...props} />
    );
    

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      stripeCardToken: // value for 'stripeCardToken'
 *      customUserFields: // value for 'customUserFields'
 *      verifiedUserId: // value for 'verifiedUserId'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
    accessToken
    message
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($email: String!) {
  requestResetPassword(email: $email) {
    message
  }
}
    `;
export type RequestResetPasswordMutationFn = ApolloReactCommon.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export type RequestResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>, 'mutation'>;

    export const RequestResetPasswordComponent = (props: RequestResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables> mutation={RequestResetPasswordDocument} {...props} />
    );
    

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, baseOptions);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = ApolloReactCommon.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const RegisterPointsOnlyUserDocument = gql`
    mutation registerPointsOnlyUser($user: AuthUserInput!, $token: String!) {
  registerPointsOnlyUser(user: $user, token: $token) {
    accessToken
    message
  }
}
    `;
export type RegisterPointsOnlyUserMutationFn = ApolloReactCommon.MutationFunction<RegisterPointsOnlyUserMutation, RegisterPointsOnlyUserMutationVariables>;
export type RegisterPointsOnlyUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterPointsOnlyUserMutation, RegisterPointsOnlyUserMutationVariables>, 'mutation'>;

    export const RegisterPointsOnlyUserComponent = (props: RegisterPointsOnlyUserComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterPointsOnlyUserMutation, RegisterPointsOnlyUserMutationVariables> mutation={RegisterPointsOnlyUserDocument} {...props} />
    );
    

/**
 * __useRegisterPointsOnlyUserMutation__
 *
 * To run a mutation, you first call `useRegisterPointsOnlyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPointsOnlyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPointsOnlyUserMutation, { data, loading, error }] = useRegisterPointsOnlyUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRegisterPointsOnlyUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterPointsOnlyUserMutation, RegisterPointsOnlyUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterPointsOnlyUserMutation, RegisterPointsOnlyUserMutationVariables>(RegisterPointsOnlyUserDocument, baseOptions);
      }
export type RegisterPointsOnlyUserMutationHookResult = ReturnType<typeof useRegisterPointsOnlyUserMutation>;
export type RegisterPointsOnlyUserMutationResult = ApolloReactCommon.MutationResult<RegisterPointsOnlyUserMutation>;
export type RegisterPointsOnlyUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterPointsOnlyUserMutation, RegisterPointsOnlyUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation loginUser($login: String!, $password: String!) {
  loginUser(login: $login, password: $password) {
    token
  }
}
    `;
export type LoginUserMutationFn = ApolloReactCommon.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;
export type LoginUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginUserMutation, LoginUserMutationVariables>, 'mutation'>;

    export const LoginUserComponent = (props: LoginUserComponentProps) => (
      <ApolloReactComponents.Mutation<LoginUserMutation, LoginUserMutationVariables> mutation={LoginUserDocument} {...props} />
    );
    

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, baseOptions);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = ApolloReactCommon.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const CompaniesForEmailDocument = gql`
    query companiesForEmail($email: String!) {
  companiesForEmail(email: $email) {
    ...registrationCompany
  }
}
    ${RegistrationCompanyFragmentDoc}`;
export type CompaniesForEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompaniesForEmailQuery, CompaniesForEmailQueryVariables>, 'query'> & ({ variables: CompaniesForEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompaniesForEmailComponent = (props: CompaniesForEmailComponentProps) => (
      <ApolloReactComponents.Query<CompaniesForEmailQuery, CompaniesForEmailQueryVariables> query={CompaniesForEmailDocument} {...props} />
    );
    

/**
 * __useCompaniesForEmailQuery__
 *
 * To run a query within a React component, call `useCompaniesForEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesForEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesForEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompaniesForEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesForEmailQuery, CompaniesForEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesForEmailQuery, CompaniesForEmailQueryVariables>(CompaniesForEmailDocument, baseOptions);
      }
export function useCompaniesForEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesForEmailQuery, CompaniesForEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesForEmailQuery, CompaniesForEmailQueryVariables>(CompaniesForEmailDocument, baseOptions);
        }
export type CompaniesForEmailQueryHookResult = ReturnType<typeof useCompaniesForEmailQuery>;
export type CompaniesForEmailLazyQueryHookResult = ReturnType<typeof useCompaniesForEmailLazyQuery>;
export type CompaniesForEmailQueryResult = ApolloReactCommon.QueryResult<CompaniesForEmailQuery, CompaniesForEmailQueryVariables>;
export const CompanyDocument = gql`
    query company($companyId: Int!) {
  company(companyId: $companyId) {
    id
    locations {
      id
      city
    }
  }
}
    `;
export type CompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyQuery, CompanyQueryVariables>, 'query'> & ({ variables: CompanyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompanyComponent = (props: CompanyComponentProps) => (
      <ApolloReactComponents.Query<CompanyQuery, CompanyQueryVariables> query={CompanyDocument} {...props} />
    );
    

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
      }
export function useCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompanyWaiverDocument = gql`
    query companyWaiver($locationId: Int!) {
  location(locationId: $locationId) {
    id
    companyPolicy {
      id
      title
      waiver
    }
  }
}
    `;
export type CompanyWaiverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyWaiverQuery, CompanyWaiverQueryVariables>, 'query'> & ({ variables: CompanyWaiverQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompanyWaiverComponent = (props: CompanyWaiverComponentProps) => (
      <ApolloReactComponents.Query<CompanyWaiverQuery, CompanyWaiverQueryVariables> query={CompanyWaiverDocument} {...props} />
    );
    

/**
 * __useCompanyWaiverQuery__
 *
 * To run a query within a React component, call `useCompanyWaiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyWaiverQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyWaiverQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCompanyWaiverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyWaiverQuery, CompanyWaiverQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyWaiverQuery, CompanyWaiverQueryVariables>(CompanyWaiverDocument, baseOptions);
      }
export function useCompanyWaiverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyWaiverQuery, CompanyWaiverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyWaiverQuery, CompanyWaiverQueryVariables>(CompanyWaiverDocument, baseOptions);
        }
export type CompanyWaiverQueryHookResult = ReturnType<typeof useCompanyWaiverQuery>;
export type CompanyWaiverLazyQueryHookResult = ReturnType<typeof useCompanyWaiverLazyQuery>;
export type CompanyWaiverQueryResult = ApolloReactCommon.QueryResult<CompanyWaiverQuery, CompanyWaiverQueryVariables>;
export const LocationUpdatesDocument = gql`
    query locationUpdates($locationId: Int!) {
  location(locationId: $locationId) {
    id
    payments
    paymentMethod {
      id
      stripeApiKey
    }
    config {
      membershipsEnabled
      membershipAgreementCopy
      phoneNumberRequired
      subLocationsLabel
      badgeLabel
    }
    company {
      id
      badgeRequired
      emergencyContactRequired
      requiredUserVerificationFields {
        ...requiredUserVerificationField
      }
    }
    customUserFields {
      ...customUserField
    }
    subLocations {
      ...subLocation
      id
      name
    }
  }
}
    ${RequiredUserVerificationFieldFragmentDoc}
${CustomUserFieldFragmentDoc}
${SubLocationFragmentDoc}`;
export type LocationUpdatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LocationUpdatesQuery, LocationUpdatesQueryVariables>, 'query'> & ({ variables: LocationUpdatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LocationUpdatesComponent = (props: LocationUpdatesComponentProps) => (
      <ApolloReactComponents.Query<LocationUpdatesQuery, LocationUpdatesQueryVariables> query={LocationUpdatesDocument} {...props} />
    );
    

/**
 * __useLocationUpdatesQuery__
 *
 * To run a query within a React component, call `useLocationUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationUpdatesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationUpdatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationUpdatesQuery, LocationUpdatesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationUpdatesQuery, LocationUpdatesQueryVariables>(LocationUpdatesDocument, baseOptions);
      }
export function useLocationUpdatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationUpdatesQuery, LocationUpdatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationUpdatesQuery, LocationUpdatesQueryVariables>(LocationUpdatesDocument, baseOptions);
        }
export type LocationUpdatesQueryHookResult = ReturnType<typeof useLocationUpdatesQuery>;
export type LocationUpdatesLazyQueryHookResult = ReturnType<typeof useLocationUpdatesLazyQuery>;
export type LocationUpdatesQueryResult = ApolloReactCommon.QueryResult<LocationUpdatesQuery, LocationUpdatesQueryVariables>;