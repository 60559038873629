import PropTypes from 'prop-types'
import React from 'react'
import Button from '@material-ui/core/Button'
import DialogLink from 'components/DialogLink'
import { InputField } from 'components/profileDrawer/Profile/Profile'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Typography } from '@material-ui/core'
import useStyles from './styles'

export interface Props {
  fields: InputField[];
  editDialogName: string;
}

const ProfileTab = ({ fields, editDialogName }: Props): JSX.Element => {
  const classes = useStyles()
  return (
    <div style={ { padding: '10px' } }>
      <Table>
        <TableBody>
          {fields.map(
            (field): JSX.Element => (
              <TableRow key={ field.name }>
                <TableCell className={ classes.narrowCell }>
                  <Typography variant='caption'>{field.name}</Typography>
                </TableCell>
                <TableCell className={ classes.wideCell }>
                  {field.value || '--'}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <div style={ { textAlign: 'center' } }>
        <DialogLink
          dialogComponent={ editDialogName }
          trigger={
            <Button variant='contained' color='primary'>
              Edit
            </Button>
          }
        />
      </div>
    </div>
  )
}

ProfileTab.propTypes = {
  fields: PropTypes.array.isRequired,
  editDialogName: PropTypes.string.isRequired,
}

export default ProfileTab
