import Dumb from './Family'
import React from 'react'
import { FamilyTabsComponent, FamilyTabsQuery } from 'types/graphql'

const Family = (): JSX.Element => (
  <FamilyTabsComponent>
    {({ data, loading }): JSX.Element | null => {
      if (loading) return null

      return <Dumb data={ data as FamilyTabsQuery } />
    }}
  </FamilyTabsComponent>
)

export default Family
