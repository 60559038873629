import React from 'react'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'

interface Props {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

const TabPanel = ({ children, value, index, ...other }: Props): JSX.Element => {
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={ value !== index }
      id={ `tabpanel-${index}` }
      aria-labelledby={ `tab-${index}` }
      { ...other }
    >
      <Box p={ 3 }>{children}</Box>
    </Typography>
  )
}

export default TabPanel
