import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    card: {
      marginBottom: '10px',
      textAlign: 'left',
    },
    chevron: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    skeleton: {
      marginLeft: spacing(2),
      marginTop: spacing(1.25),
      marginBottom: spacing(1.25),
    },
  })
)
