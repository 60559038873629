import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    toggleDayButton: {
      marginBottom: spacing(1),
    },
    rightIcon: {
      marginLeft: spacing(1),
    },
  })
)
