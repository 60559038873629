// shared queries, might decide to move all queries here at some stage if it
// makes sense. In that case, we'll probably split into files.
import gql from 'graphql-tag'
import * as fragments from 'fragments'

// get the user's completedTour so we can query it from the cache and update
// runnnigng the tour in the local state accordingly
// Also, we get impersonated and dependentsEnabled here so the profile menu
// renders correctly
export const APP_LAYOUT_QUERY = gql`
  query appLayout {
    viewer {
      id
      impersonated
      email
      intercomToken
      pendingReview
      pointsOnly
      completedTour
      agreedToWaiver
      lapsedMembership
      needsToAddCard
      alertAboutMembershipFee
      bewell {
        show
      }
      location {
        id
        timezone
        city
        name
        hasAppointments
        hasGFCs
        hasEvents
        lockers {
          id
        }
        config {
          id
          eventsLabel
          logo
          dependentsEnabled
          guestsEnabled
          partnersEnabled
          biometricDataEnabled
        }
      }
      intakeSurvey {
        show
      }
      locations {
        id
        city
        name
      }
    }
  }
`

export const COMPANY_WAIVER = gql`
  query companyWaiver($locationId: Int!) {
    location(locationId: $locationId) {
      id
      companyPolicy {
        id
        title
        waiver
      }
    }
  }
`

export const HIPAA_WAIVER = gql`
  query hipaaWaiver {
    viewer {
      id
      location {
        id
        companyPolicy {
          id
          hipaaWaiver
        }
      }
    }
  }
`

export const TIMEZONE_QUERY = gql`
  query timezone {
    viewer {
      id
      location {
        id
        timezone
      }
    }
  }
`

export const DASHBOARD_QUERY = gql`
  query dashboard {
    viewer {
      id
      location {
        id
        hasEvents
        hasAppointments
        hasGFCs
        currentChecklist {
          ...checklist
        }
        previousChecklist {
          ...checklist
        }
      }
    }
  }
  ${fragments.checklist}
`

export const TOP_NAV_QUERY = gql`
  query topNav {
    viewer {
      id
      location {
        id
        name
        config {
          id
          logo
        }
      }
      locations {
        id
        name
      }
    }
  }
`

export const INTAKE_SURVEY_QUERY = gql`
  query intakeSurvey {
    viewer {
      id
      location {
        id
        intakeSurveyUrl
        config {
          id
          logo
        }
      }
    }
  }
`

export const TRANSACTION_RECEIPT_QUERY = gql`
  query transactionReceipts {
    viewer {
      id
      fullName
      email
      location {
        id
        city
        country
        config {
          id
          logo
        }
      }
    }
  }
`

export const CART_ITEM_COUNT = gql`
  query cartItemCount {
    viewer {
      id
      cart {
        id
        itemCount
      }
    }
  }
`

export const USER_COMPLETED_TOUR = gql`
  query userCompletedTour {
    viewer {
      id
      completedTour
    }
  }
`

export const STAFF_QUERY = gql`
  query staff($staffId: Int!) {
    viewer {
      id
      location {
        id
        staff(id: $staffId) {
          id
          name
          about
          avatar
          position
        }
      }
    }
  }
`

export const STAFFS_QUERY = gql`
  query staffs {
    viewer {
      id
      location {
        id
        staffs {
          id
          avatar
          name
          position
          about
        }
      }
    }
  }
`

export const AVAILABLE_CREDITS_QUERY = gql`
  query availableCredits {
    viewer {
      id
      availableCreditsDisplay
    }
  }
`

export const CHECKIN_REPORT_QUERY = gql`
  query checkinReport($startDateUnix: Float!, $endDateUnix: Float!) {
    viewer {
      id
      facilityUses(startDateUnix: $startDateUnix, endDateUnix: $endDateUnix) {
        id
        createdAt
        facilityUseOptionLabel
      }
      gfcCheckins(startDateUnix: $startDateUnix, endDateUnix: $endDateUnix) {
        id
        createdAt
        gfcTitle
      }
    }
  }
`

export const VIEWER_ID = gql`
  query viewerId {
    viewer {
      id
    }
  }
`

export const IS_IMPERSONATED_QUERY = gql`
  query isImpersonated {
    viewer {
      id
      impersonated
    }
  }
`

export const INTERCOM_USER_QUERY = gql`
  query intercomUser {
    viewer {
      id
      email
      fullName
      company {
        id
        name
      }
      location {
        id
        city
      }
      createdAt
      intercomToken
    }
  }
`

export const NAV_LINKS_QUERY = gql`
  query navLinksQuery {
    viewer {
      id
      location {
        id
        hasEvents
        hasAppointments
        hasGFCs
        hasServices
        hasStaff
        lockers {
          id
        }
        config {
          id
          eventsLabel
          videoLibraryEnabled
          biometricDataEnabled
        }
      }
      bewell {
        show
      }
      hasActivities
    }
  }
`

export const MEDICAL = gql`
  query medical {
    viewer {
      id
      email
      profile {
        id
        allergies
        medicalConditions
      }
    }
  }
`

export const MEMBERSHIP_AGREEMENT_COPY_QUERY = gql`
  query membershipAgreementCopy {
    viewer {
      id
      location {
        id
        config {
          id
          membershipAgreementCopy
        }
      }
    }
  }
`

export const VIDEOS_QUERY = gql`
  query videos($offset: Int, $search: String) {
    videoLibrary(offset: $offset, search: $search) {
      videos {
        id
        title
        description
        duration
        imgUrl
        videoId
        videoUrl
      }
      totalCount
    }
  }
`

export const API_KEY_QUERY = gql`
  query stripeApiKey {
    viewer {
      id
      location {
        id
        stripeApiKey
      }
    }
  }
`

export const CART_ITEMS = gql`
  query cartItems {
    viewer {
      id
      cart {
        id
        total
        items {
          ...cartItem
        }
      }
    }
  }
  ${fragments.cartItem}
`

export const WIDGETS_QUERY = gql`
  query userWidgets {
    viewer {
      id
      availableCreditsDisplay
      unlimitedCreditsUntil
      guestPassCount
      totalFacilityCheckinByCurrentMonth
      totalGfcCheckinByCurrentMonth
      totalPoints
      firstBank
      company {
        id
        creditsForGfc
      }
      bewell {
        ...bewell
      }
      location {
        id
        config {
          id
          masonJarWidgetEnabled
          onlineCoachingWidgetEnabled
          gfcCheckinWidgetEnabled
          facilityCheckinWidgetEnabled
          freeGfcEnabled
          guestsEnabled
          guestPassInterval
          unlimitedGuestPasses
          rewardPointsEnabled
          pointsRedemptionEnabled
          minRedeemPointLimit
        }
      }
    }
  }
  ${fragments.bewell}
`

export const REWARD_POINTS = gql`
  query rewardPoints {
    viewer {
      id
      totalPoints
      location {
        id
        config {
          id
          rewardPointsEnabled
          pointsRedemptionEnabled
          minRedeemPointLimit
        }
      }
    }
  }
`

export const CART_COUPON = gql`
  query cartCoupon {
    viewer {
      id
      cart {
        id
        coupon {
          id
          couponCode
          discount
        }
      }
    }
  }
`

export const EVENTS_LABEL_QUERY = gql`
  query eventLabel {
    viewer {
      id
      location {
        id
        config {
          id
          eventsLabel
        }
      }
    }
  }
`

export const EVENTS_QUERY = gql`
  query events($startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
    viewer {
      id
      location {
        id
        events(startDate: $startDate, endDate: $endDate) {
          id
          title
          details
          eventLocation
          price
          startTimedate
          endTimedate
          startDate
          endDate
        }
        config {
          id
          eventsLabel
        }
      }
    }
  }
`

export const APPOINTMENTS_QUERY = gql`
  query appointments($startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
    viewer {
      id
      location {
        id
        appointments(startDate: $startDate, endDate: $endDate) {
          ...appointment
        }
      }
    }
  }
  ${fragments.appointment}
`

export const EVENTS_FOR_CALENDAR_QUERY = gql`
  query eventsCalendar($after: ISO8601DateTime!, $before: ISO8601DateTime!) {
    viewer {
      id
      location {
        id
        timezone
        eventCalendarEntries(after: $after, before: $before) {
          ...eventCalendarEntry
        }
      }
    }
  }
  ${fragments.eventCalendarEntry}
`

export const EVENT_QUERY = gql`
  query event(
    $eventId: Int!
    $after: ISO8601DateTime
    $before: ISO8601DateTime
  ) {
    viewer {
      id
      location {
        id
        event(eventId: $eventId) {
          id
          price
          type
          availableSlots(after: $after, before: $before) {
            id
            startTime
            endTime
            openSpotsDisplay
            eventId
            eventRegistration {
              id
              startTime
              endTime
              title
              details
              eventLocation
              charge {
                price
              }
            }
          }
        }
      }
    }
  }
`

export const UPCOMING_EVENT_REGISTRATIONS_QUERY = gql`
  query upcomingEventRegistrations {
    viewer {
      id
      location {
        id
        config {
          id
          eventsLabel
        }
      }
      upcomingEventRegistrations {
        id
        title
        eventLocation
        startTime
        endTime
        charge {
          price
        }
      }
    }
  }
`

export const AVAILABLE_APPT_LENGTHS = gql`
  query availableApptLengths(
    $apptId: Int!
    $startTime: ISO8601DateTime!
    $endTime: ISO8601DateTime!
  ) {
    viewer {
      id
      location {
        id
        appointment(apptId: $apptId) {
          id
          availableLengths(startTime: $startTime, endTime: $endTime)
        }
      }
    }
  }
`

export const AVAILABLE_APPT_TIMES = gql`
  query availableApptTimes(
    $apptId: Int!
    $startTime: ISO8601DateTime!
    $endTime: ISO8601DateTime!
    $length: Int!
  ) {
    viewer {
      id
      location {
        id
        appointment(apptId: $apptId) {
          id
          availableTimes(
            startTime: $startTime
            endTime: $endTime
            length: $length
          )
        }
      }
    }
  }
`

export const APPOINTMENT_CONFIRMATION_QUERY = gql`
  query appointmentConfirmation($apptId: Int!, $length: Int!) {
    viewer {
      id
      location {
        id
        appointment(apptId: $apptId) {
          id
          totalPrice(length: $length)
        }
      }
    }
  }
`

export const UPCOMING_APPOINTMENT_BOOKINGS_QUERY = gql`
  query upcomingAppointmentBookings {
    viewer {
      id
      upcomingAppointmentBookings {
        ...appointmentBooking
      }
    }
  }
  ${fragments.appointmentBooking}
`

export const APPOINTMENTS_FOR_CALENDAR_QUERY = gql`
  query appointmentCalendarEntries(
    $after: ISO8601DateTime!
    $before: ISO8601DateTime!
    $apptId: Int
  ) {
    viewer {
      id
      upcomingAppointmentBookings {
        ...appointmentBooking
      }
      location {
        id
        timezone
        appointmentCalendarEntries(
          after: $after
          before: $before
          apptId: $apptId
        ) {
          ...eventCalendarEntry
        }
      }
    }
  }
  ${fragments.appointmentBooking}
  ${fragments.eventCalendarEntry}
`

export const EVENT_SLOT_QUERY = gql`
  query eventSlot($eventId: Int!, $slotId: String!) {
    viewer {
      id
      location {
        id
        event(eventId: $eventId) {
          id
          slot(slotId: $slotId) {
            id
            eventId
            openSpotsDisplay
            eventRegistration {
              id
            }
          }
        }
      }
    }
  }
`

export const REDEEM_POINTS_QUERY = gql`
  query redeemPoints {
    viewer {
      id
      totalPoints
      location {
        id
        config {
          id
          rewardPointsRedemptionOption
        }
        customRedeemProducts {
          ...rewardRedemptionOption
        }
        tangoCards {
          ...rewardRedemptionOption
        }
      }
    }
  }
  ${fragments.rewardRedemptionOption}
`

export const USER_LOCATIONS_QUERY = gql`
  query userLocationsQuery {
    viewer {
      id
      location {
        id
        name
        city
      }
      locations {
        id
        name
        city
      }
    }
  }
`

export const PROFILE_MENU_QUERY = gql`
  query profileMenu {
    viewer {
      id
      impersonated
      location {
        id
        city
        config {
          id
          dependentsEnabled
          guestsEnabled
          partnersEnabled
        }
      }
    }
  }
`

export const CARD_LABEL_QUERY = gql`
  query cardLabel($cardId: Int!) {
    viewer {
      id
      card(cardId: $cardId) {
        id
        label
      }
    }
  }
`

export const USER_AVATAR_QUERY = gql`
  query userAvatar {
    viewer {
      id
      profile {
        id
        avatar(size: 100)
      }
      location {
        id
        config {
          id
          usersCanEditProfileImage
        }
      }
    }
  }
`

export const CONTACT_INFO_QUERY = gql`
  query contactInfo {
    viewer {
      id
      email
      profile {
        id
        city
        state
        zip
        phoneNumber
        workPhone
        contactEmail
      }
      location {
        id
        config {
          id
          usersCanEditAddress
        }
      }
    }
  }
`

export const PROFILE_BASIC_INFO_QUERY = gql`
  query profileBasicInfo {
    viewer {
      id
      email
      profile {
        id
        avatar(size: 100)
        firstName
        lastName
        ...profileBasicInfo
      }
      location {
        id
        config {
          id
          hideDob
        }
      }
    }
  }
  ${fragments.profileBasicInfo}
`

export const LOCKERS_QUERY = gql`
  query lockers {
    viewer {
      id
      location {
        id
        lockers {
          ...locker
        }
      }
    }
  }
  ${fragments.locker}
`

export const LOCKER_PRORATION_QUERY = gql`
  query lockerProration($lockerId: Int!) {
    viewer {
      id
      locker(lockerId: $lockerId) {
        id
        cancellationProration
      }
    }
  }
  ${fragments.locker}
`

export const USER_MESSAGES_QUERY = gql`
  query unreadMessages {
    viewer {
      id
      unreadMessages {
        id
        message
        title
        admin {
          id
          fullName
        }
      }
    }
  }
`
