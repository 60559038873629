import React from 'react'
import { asPrice } from 'lib/helpers'
import useStyles from './styles'
import { RedeemCouponMutation, RedeemCouponMutationFn } from 'types/graphql'
import { Typography } from '@material-ui/core'
import InlineForm from 'components/InlineForm'
import { ExecutionResult } from 'apollo-link'

export interface Props {
  coupon?: {
    couponCode: string;
    discount: number;
  } | null;
  redeemCoupon: RedeemCouponMutationFn;
}

const CouponField = ({ coupon, redeemCoupon }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={ classes.container }>
      <Typography className={ classes.subheader }>
        {coupon ? 'Coupon Applied' : 'Redeem Coupon'}
      </Typography>
      {coupon ? (
        <p>
          {coupon.couponCode}
          <span className={ classes.amount }>- {asPrice(coupon.discount)}</span>
        </p>
      ) : (
        <InlineForm
          handleSubmit={ (
            code
          ): Promise<ExecutionResult<RedeemCouponMutation>> =>
            redeemCoupon({ variables: { code: code.toUpperCase() } })
          }
          name='coupon'
          formName='Add Coupon'
          hintText=''
          inputProps={ { className: classes.input } }
        />
      )}
    </div>
  )
}

export default CouponField
