import Dumb from 'components/auth/LogoutButton/LogoutButton'
import React, { ReactNode } from 'react'
// allow access to apollo's store, so we can reset it on logout
import { IsImpersonatedComponent, LogoutV2UserComponent } from 'types/graphql'

interface Props {
  children: ReactNode;
  beforeLogout?: () => Promise<void>;
}
const LogoutButton = (props: Props): JSX.Element => (
  <IsImpersonatedComponent>
    {({ data, loading }): JSX.Element => (
      <LogoutV2UserComponent>
        {(logoutUser): JSX.Element | null => {
          if (loading || !data) return null

          return <Dumb logoutUser={ logoutUser } data={ data } { ...props } />
        }}
      </LogoutV2UserComponent>
    )}
  </IsImpersonatedComponent>
)

export default LogoutButton
