import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogLink from 'components/DialogLink'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import DependentAvatar from 'components/avatars/DependentAvatar'
import CardActions from '@material-ui/core/CardActions'
import confirm from 'lib/confirm'
import LoadingButton from 'components/LoadingButton'

const styles = {
  card: {
    marginBottom: '10px',
    textAlign: 'center',
    width: '100%',
  },
  container: { padding: '10px' },
  tabHeader: {
    textAlign: 'center',
    fontWeight: '100',
    textTransform: 'uppercase',
    fontSize: '1.1rem',
  },
  helpText: {
    marginBottom: 10,
  },
  cannotEdit: {
    textAlign: 'center',
  },
}

const ageBracketMessage = (isAdult, isOfAge) => {
  if (isAdult) return ''
  return isOfAge ? ' (Child)' : ' (Under Minimum Age)'
}

const handleRemove = (id, remove) =>
  confirm('Remove Dependent Permenantly?').then(
    () => remove({ variables: { id } }),
    () => {}
  )

const DependentsTab = ({
  classes,
  data: {
    viewer: {
      dependents,
      location: {
        config: { usersCanEditDependents },
      },
    },
  },
  removeDependent,
  removeDependentLoading,
}) => (
  <div className={ classes.container }>
    <h2 className={ classes.tabHeader }>Dependents</h2>
    <Grid container justify='center' className={ classes.container }>
      {!dependents.length && (
        <Typography
          variant='subtitle2'
          component='p'
          className={ classes.helpText }
        >
          You can add dependents to your account here. Dependents can be
          registered for group fitness classes and may accompany you to the
          facility, or on their own. Child dependents may attend group fitness
          classes aimed at children.
        </Typography>
      )}
      {dependents.map(
        ({ id, firstName, lastName, avatar, age, isAdult, isOfAge }) => (
          <Card key={ id } className={ classes.card }>
            <CardContent>
              <DependentAvatar
                id={ id }
                avatar={ avatar }
                canUpload={ usersCanEditDependents }
              />
              <Typography gutterBottom variant='h5' component='h2'>
                {firstName} {lastName}
              </Typography>
              <Typography component='p'>
                Age: {age}
                {ageBracketMessage(isAdult, isOfAge)}
              </Typography>
            </CardContent>
            {usersCanEditDependents ? (
              <CardActions>
                <DialogLink
                  dialogComponent='DependentDialog'
                  dialogProps={ { id } }
                  trigger={
                    <Button size='small' color='primary'>
                      Edit
                    </Button>
                  }
                />
                <LoadingButton
                  loading={ removeDependentLoading }
                  size='small'
                  secondary
                  onClick={ () => handleRemove(id, removeDependent) }
                >
                  Remove
                </LoadingButton>
              </CardActions>
            ) : null}
          </Card>
        )
      )}
    </Grid>
    {usersCanEditDependents ? (
      <div style={ { textAlign: 'center' } }>
        <DialogLink
          dialogComponent='DependentDialog'
          trigger={
            <Button color='primary' variant='contained'>
              Add Dependent
            </Button>
          }
        />
      </div>
    ) : (
      <Typography className={ classes.cannotEdit }>
        To add / edit dependents, please contact your facility manager.
      </Typography>
    )}
  </div>
)

DependentsTab.propTypes = {
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      dependents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          avatar: PropTypes.string.isRequired,
          age: PropTypes.number.isRequired,
          isAdult: PropTypes.bool.isRequired,
          isOfAge: PropTypes.bool.isRequired,
        }).isRequired
      ).isRequired,
      location: PropTypes.shape({
        config: PropTypes.shape({
          usersCanEditDependents: PropTypes.bool.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }),
  classes: PropTypes.object.isRequired,
  removeDependent: PropTypes.func.isRequired,
  removeDependentLoading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(DependentsTab)
