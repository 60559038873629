import Dumb from './CloseDialogButton'
import React from 'react'
import { CloseDialogComponent } from 'types/graphql'

export interface Props {
  gutterRight: boolean;
}

const CloseDialogButton = (props: Props): JSX.Element => (
  <CloseDialogComponent>
    {(closeDialog): JSX.Element => {
      return <Dumb closeDialog={ closeDialog } { ...props } />
    }}
  </CloseDialogComponent>
)

export default CloseDialogButton
