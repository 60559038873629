import gql from 'graphql-tag'
import { card, subscription, userActivity } from 'fragments'

export const ACCOUNT_TAB_QUERY = gql`
  query accountTab {
    viewer {
      id
      location {
        id
        hasPlans
        payments
        config {
          id
          qrCodeEnabled
        }
      }
    }
  }
`

export const USER_ORDERS_QUERY = gql`
  query userOrders {
    viewer {
      id
      fullName
      email
      location {
        id
        city
        country
      }
      orderHistory {
        id
        createdOnStripe
        description
        sourceLast4
        price
        quantity
        sessions
        staffName
        status
      }
    }
  }
`

export const USER_ACTIVITY_QUERY = gql`
  query userActivity {
    viewer {
      id
      userActivities {
        ...userActivity
      }
    }
  }
  ${userActivity}
`

export const USER_SUBSCRIPTIONS_QUERY = gql`
  query userSubscriptions {
    viewer {
      id
      subscriptions {
        ...subscription
      }
    }
  }
  ${subscription}
`

export const USER_CARDS_QUERY = gql`
  query userCards {
    viewer {
      id
      fullName
      cards {
        ...card
      }
    }
  }
  ${card}
`
