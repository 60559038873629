import Dumb from './CreditCardsTab'
import React from 'react'
import { UserCardsQuery, useUserCardsQuery } from 'types/graphql'

const CreditCardsTab = (): JSX.Element | null => {
  const { data, loading } = useUserCardsQuery()

  if (loading) return null

  return <Dumb data={ data as UserCardsQuery } />
}

export default CreditCardsTab
