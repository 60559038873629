
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "ActiveRecordInterface",
        "possibleTypes": [
          {
            "name": "Admin"
          },
          {
            "name": "Location"
          },
          {
            "name": "Appointment"
          },
          {
            "name": "StripeCharge"
          },
          {
            "name": "Staff"
          },
          {
            "name": "AutomatedChecklistItem"
          },
          {
            "name": "BewellCategory"
          },
          {
            "name": "BewellItem"
          },
          {
            "name": "Checklist"
          },
          {
            "name": "ChecklistItem"
          },
          {
            "name": "Company"
          },
          {
            "name": "CompanyPolicy"
          },
          {
            "name": "LocationConfiguration"
          },
          {
            "name": "RewardRedemptionOption"
          },
          {
            "name": "Event"
          },
          {
            "name": "FitnessServiceType"
          },
          {
            "name": "FitnessService"
          },
          {
            "name": "GfcCreditPlan"
          },
          {
            "name": "GfcSchedule"
          },
          {
            "name": "GroupFitnessClass"
          },
          {
            "name": "UserActivity"
          },
          {
            "name": "FacilityUseOption"
          },
          {
            "name": "GuestPassPackage"
          },
          {
            "name": "Locker"
          },
          {
            "name": "Membership"
          },
          {
            "name": "User"
          },
          {
            "name": "BiometricDatum"
          },
          {
            "name": "StripeCard"
          },
          {
            "name": "Cart"
          },
          {
            "name": "Coupon"
          },
          {
            "name": "CartItem"
          },
          {
            "name": "ClassRegistration"
          },
          {
            "name": "Dependent"
          },
          {
            "name": "Guest"
          },
          {
            "name": "Invitation"
          },
          {
            "name": "Profile"
          },
          {
            "name": "StripeSubscription"
          },
          {
            "name": "UserMessage"
          },
          {
            "name": "Video"
          },
          {
            "name": "AuthUser"
          },
          {
            "name": "AuthProfile"
          },
          {
            "name": "AuthCompany"
          },
          {
            "name": "AuthLocation"
          },
          {
            "name": "AuthCompanyPolicy"
          },
          {
            "name": "AuthLocationConfiguration"
          },
          {
            "name": "AuthCustomUserField"
          },
          {
            "name": "AuthCustomUserOption"
          },
          {
            "name": "AuthPaymentMethod"
          },
          {
            "name": "AuthSubLocation"
          },
          {
            "name": "AuthRequiredUserVerificationField"
          }
        ]
      }
    ]
  }
};
      export default result;
    