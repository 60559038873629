import Dumb from './EventCards'
import React from 'react'
import { useEventsQuery } from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'

export interface Props {
  startDate: string;
  endDate: string;
}

const EventCards = (props: Props): JSX.Element => {
  const { startDate, endDate } = props
  const { data, loading } = useEventsQuery({
    variables: { startDate, endDate },
  })
  if (loading) return <Spinner center fullHeight />
  if (!data) return <ErrorMessage />

  return <Dumb data={ data } { ...props } />
}

export default EventCards
