import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useStyles from './styles'

export interface Props {
  title: string;
  subtitle: string;
  children: JSX.Element;
  summaryExtraElement: JSX.Element;
}

const CardGroupExpansionPanel = ({
  title,
  subtitle,
  children,
  summaryExtraElement,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <ExpansionPanel className={ classes.container }>
      <ExpansionPanelSummary
        expandIcon={ <ExpandMoreIcon /> }
        data-testid='expand-event'
      >
        <div>
          <Typography className={ classes.titleText }>{title}</Typography>
          <Typography variant='body2' className={ classes.subtitleText }>
            {subtitle}
          </Typography>
        </div>
        <div className={ classes.extraElement }>{summaryExtraElement}</div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default CardGroupExpansionPanel
