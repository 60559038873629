import React from 'react'
import AccessDenied from 'layouts/AccessDenied'

const EmailConfirmationMessage = (): JSX.Element => (
  <AccessDenied title='Account is Under Review'>
    <p>
      It can take up to 24 hours for your account to be activated and your
      online account to be approved.
    </p>
    <p>
      Be on the lookout for a welcome email from{' '}
      <strong>Elevation Corporate Health</strong> when the process is complete.
    </p>
  </AccessDenied>
)

export default EmailConfirmationMessage
