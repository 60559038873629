import React, { useState } from 'react'
import PartnerIcon from '@material-ui/icons/GroupAdd'
import DependentsIcon from '@material-ui/icons/ChildCare'
import GuestsIcon from '@material-ui/icons/SupervisorAccount'

import DependentsTab from 'components/family/DependentsTab'
import GuestsTab from 'components/family/GuestsTab'
import PartnerWrapper from 'components/family/PartnerWrapper'
import { FamilyTabsQuery } from 'types/graphql'
import { TabObject } from 'types'
import TabPanel from 'components/TabPanel'
import TabBar from 'components/TabBar'

export interface Props {
  data: FamilyTabsQuery;
}

const Family = ({
  data: {
    viewer: {
      location: {
        config: { dependentsEnabled, guestsEnabled, partnersEnabled },
      },
    },
  },
}: Props): JSX.Element => {
  const tabs: TabObject[] = [
    {
      icon: <DependentsIcon />,
      value: 'dependents',
      component: <DependentsTab />,
      show: dependentsEnabled,
    },
    {
      icon: <PartnerIcon />,
      value: 'partner',
      component: <PartnerWrapper />,
      show: partnersEnabled,
    },
    {
      icon: <GuestsIcon />,
      value: 'guests',
      component: <GuestsTab />,
      show: guestsEnabled,
    },
  ].filter((tab): boolean => tab.show)

  const [value, setValue] = useState(tabs[0].value)

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ): void => {
    setValue(newValue)
  }

  const tabPanels = tabs.map(
    (tab): JSX.Element => (
      <TabPanel key={ tab.value } index={ tab.value } value={ value }>
        {tab.component}
      </TabPanel>
    )
  )

  return (
    <>
      <TabBar tabs={ tabs } value={ value } onChange={ handleChange } />
      {tabPanels}
    </>
  )
}

export default Family
