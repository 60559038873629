import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react'
import { RegistrationField } from 'components/registration/types'
import { useField } from 'formik'
import { TextField } from '@material-ui/core'
import useStyles from '../styles'
import PhoneNumberInput from 'components/PhoneNumberInput'
import { useFocusOnError } from 'components/registration/helpers'

interface RefObj {
  focus: () => void;
}

export interface Props {
  field: RegistrationField;
  autoFocus?: boolean;
}

const FormTextField = (
  { field: { name, label, error, type = 'text' }, autoFocus }: Props,
  ref: Ref<RefObj>,
): JSX.Element => {
  const classes = useStyles()
  const fieldRef = useRef<HTMLInputElement>(null)
  const [field] = useField(name)
  useFocusOnError({ fieldRef, name })
  useImperativeHandle(
    ref,
    (): RefObj => ({
      focus: (): void => {
        fieldRef.current && fieldRef.current.focus()
      },
    }),
  )

  let inputProps = { required: false }
  if (type === 'phoneNumber') {
    Object.assign(inputProps, { inputComponent: PhoneNumberInput })
    type = 'text'
  }

  return (
    <TextField
      { ...field }
      id={ name }
      key={ name }
      fullWidth
      required
      autoFocus={ autoFocus }
      inputProps={ { ref: fieldRef } }
      InputProps={ inputProps }
      variant='outlined'
      error={ Boolean(error) }
      helperText={ error }
      className={ classes.field }
      label={ label }
      type={ type }
    />
  )
}

export default forwardRef(FormTextField)
