import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppointmentStepper from 'components/appointments/AppointmentStepper'
import EventDialogTitle from 'components/Events/EventDialogTitle'

const AppointmentsDialog = ({ event, closeDialog }) => {
  event = {
    ...event,
    startTime: new Date(event.startTime),
    endTime: new Date(event.endTime),
  }
  return (
    <Dialog fullWidth maxWidth={ 'lg' } open onClose={ closeDialog }>
      <EventDialogTitle event={ event } />
      <DialogContent>
        <AppointmentStepper event={ event } closeDialog={ closeDialog } />
      </DialogContent>
    </Dialog>
  )
}

AppointmentsDialog.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    staff: PropTypes.shape({ name: PropTypes.string.isRequired }),
  }),
  closeDialog: PropTypes.func,
}

export default AppointmentsDialog
