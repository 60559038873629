import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar/AppBar'
import { TabObject } from 'types'
import useStyles from './styles'

interface Props {
  tabs: TabObject[];
  value: number | string;
  onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const TabBar = ({ tabs, value, onChange }: Props): JSX.Element => {
  const classes = useStyles()

  const tabComponents = tabs.map(
    (tab): JSX.Element => (
      <Tab
        className={ classes.tab }
        icon={ tab.icon }
        value={ tab.value }
        key={ tab.value }
        aria-label={ tab.value }
        data-testid={ `${tab.value}-tab` }
      />
    )
  )

  return (
    <AppBar position='static'>
      <Tabs variant='fullWidth' value={ value } onChange={ onChange }>
        {tabComponents}
      </Tabs>
    </AppBar>
  )
}

export default TabBar
