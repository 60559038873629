import Dumb from '../shared/WaiverDialog'
import React from 'react'
import { useCompanyWaiverQuery as useAuthQuery } from 'types/graphql-auth'
import { useAgreeToWaiverMutation, useCompanyWaiverQuery } from 'types/graphql'
import { ExecutionResult } from 'apollo-link'

interface Props {
  open: boolean;
  skipLogout?: boolean;
  handleDisagreed?: () => Promise<void>;
  handleAgreed?: () => void;
  locationId: number;
  // By default, use the standard query hook. But allow overriding the query hook
  // for the case of this dialog being used with the `auth` endpoint during
  // registration.
  waiverQueryHook?: typeof useCompanyWaiverQuery | typeof useAuthQuery;
}

const ConditionsDialog = ({
  locationId,
  handleDisagreed = (): Promise<void> => Promise.resolve(),
  handleAgreed,
  waiverQueryHook = useCompanyWaiverQuery,
  ...props
}: Props): JSX.Element | null => {
  const [agreeToWaiver, { loading: agreedLoading }] = useAgreeToWaiverMutation()
  const { data, loading, error } = waiverQueryHook({
    variables: { locationId },
  })

  if (loading) {
    return null
  }
  if (!data) {
    return <p>Error: {error && error.message}</p>
  }

  if (!data.location.companyPolicy) return null

  // If `handleAgreed` is provided, use that. Otherwise, use the `agreeToWaiver`
  // mutation.
  handleAgreed =
    handleAgreed ||
    ((): Promise<ExecutionResult> => {
      return agreeToWaiver({ variables: { locationId } })
    })

  return (
    <Dumb
      waiver={ data.location.companyPolicy.waiver }
      title={ data.location.companyPolicy.title }
      handleDisagreed={ handleDisagreed }
      handleAgreed={ handleAgreed }
      agreedLoading={ agreedLoading }
      disagreedLoading={ false }
      { ...props }
    />
  )
}

export default ConditionsDialog
