// https://github.com/apollographql/eslint-plugin-graphql/issues/99
import gql from 'graphql-tag'

export const DIALOG_COMPONENT_QUERY = gql`
  query dialogComponent {
    dialogComponent @client
  }
`

export const DIALOG_PROPS_QUERY = gql`
  query dialogProps {
    dialogProps @client {
      id
      itemClass
      sessions
      videoUrl
      service {
        id
        title
        description
        price
        validFor
        askAvailability
      }
      event {
        id
        eventId
        title
        eventLocation
        details
        formattedPrice
        startTime
        endTime
        staff {
          id
          name
        }
      }
    }
  }
`

export const TOUR_QUERY = gql`
  query tour {
    tour @client {
      run
      stepIndex
    }
  }
`

export const PROFILE_MENU_OPEN_QUERY = gql`
  query profileMenuOpen {
    profileMenuOpen @client
  }
`

export const VIDEO_SEARCH_QUERY = gql`
  query videoSearch {
    videoSearch @client
  }
`

export const REFRESH_GFC_TABLE = gql`
  query refreshGfcTable {
    refreshGfcTable @client
  }
`
