// this file is totally optional, supposed to help with debugging with devtools
export default `
  directive @client on FIELD

  type Tour {
    run: Boolean
    stepIndex: Int
  }

  type Staff {
    id: Int!,
    name: String!
  }

  type DialogPropsFitnessService {
    id: Int,
    title: String,
    description: String,
    price: Float,
    validFor: String,
    askAvailability: Boolean,
  }

  type DialogPropsEvent {
    id: String,
    eventId: Int,
    title: String,
    details: String,
    eventLocation: String,
    formattedPrice: String,
    startTime: String,
    endTime: String,
    staff: Staff,
  }

  type DialogProps {
    cardId: Int,
    gfcScheduleId: Int,
    staffId: Int,
    sessions: Int,
    itemClass: String,
    videoUrl: String,
    service: DialogPropsFitnessService,
    event: DialogPropsEvent,
  }

  type Query {
    dialogComponent: String
    dialogProps: DialogProps
    tour: Tour
    videoSearch: String
  }

  type Mutation {
    UpdateTour(run: Boolean, stepIndex: Int): null
    advanceTour: null
    updateTourstatus: null
    updateVideoSearch: null
  }
`
