import {
  createStyles,
  makeStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import { Props } from './'

// TODO: fix type
export default makeStyles(
  ({ breakpoints, spacing }: Theme): StyleRules<any, Props> =>
    createStyles({
      container: {
        height: ({ bannerShowing }: Props): number =>
          bannerShowing ? 118 : 70,
      },
      toolbar: {
        backgroundColor:
          process.env.NODE_ENV === 'development' ? 'navajowhite' : '#FFF',
        position: 'fixed',
        top: 0,
        left: spacing(2),
        right: spacing(2),
        height: 60,
        zIndex: 2,
        paddingLeft: 0,
      },
      logo: {
        marginLeft: 8,
        marginRight: 20,
        maxHeight: 60,
        objectFit: 'contain', // Maintains aspect ratio except on IE11.
        // lg/xl and md widths match DashboardNav width + above left margin (8px)
        width: 'calc(16.67% - 28px)',
        [breakpoints.down('md')]: {
          width: 'calc(25% - 28px)',
        },
        [breakpoints.down('sm')]: {
          width: 160,
          marginRight: 12,
        },
        [breakpoints.down('xs')]: {
          width: 100,
        },
      },
      headline: {
        [breakpoints.down('xs')]: {
          fontSize: 18,
        },
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 7,
      },
      flexGrow: {
        flexGrow: 1,
      },
    }),
)
