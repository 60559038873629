export const DATE_FORMAT = 'MMM Do, YYYY'
export const SHORT_DATE_FORMAT = 'MMM Do'
export const DATE_FORMAT_OFFICIAL = 'dddd, MMM D, YYYY'
export const DAY_FORMAT = 'dddd'
export const TIME_FORMAT = 'h:mma'
export const TIME_FORMAT_WITH_TZ = 'h:mma z'
export const DATE_TIME_FORMAT = "MMM Do 'YY, h:mma"
export const MONTH_FORMAT = "MMM 'YY"
export const DAY_AND_DATE_FORMAT = 'dddd, MMMM Do'
export const PICKER_DATE_FORMAT = 'MM/dd/yyyy'
