// Return a duplicate of the passed array with the addition of any elements from
// the new array whose IDs are not present in the baseArray
export const addMissingIds = (baseArray, newArray) => {
  // Duplicate baseArray to not modify it
  const dupArray = [...baseArray]

  // Loop over newArray and add any element with an ID not already in baseArray
  // to the duplicated array.
  newArray.forEach(newEl => {
    const hasId = dupArray.some(el => {
      return el.id === newEl.id
    })

    if (!hasId) dupArray.push(newEl)
  })

  return dupArray
}

// Return a duplicate of the passed array, but with the element with the
// matching ID removed
export const removeWithId = (array, id) => {
  // Duplicate array to not modify it
  const dupArray = [...array]
  // Find index of array where element has provided ID.
  const index = dupArray.findIndex(el => el.id === id)

  // Remove that element
  if (index > -1) dupArray.splice(index, 1)

  return dupArray
}

// Get the variables used for a previous query based on the query key
export const getPreviousQueryVars = (cache, key) =>
  Array.from(cache.watches).find(watch => {
    return watch.query.definitions.some(
      def => def.name && def.name.value === key
    )
  }).variables
