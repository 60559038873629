import React from 'react'
import PropTypes from 'prop-types'
import IconButton from 'material-ui/IconButton'
import RemoveIcon from 'material-ui/svg-icons/action/delete-forever'

const RemoveCartItemButton = ({
  removeItem,
  itemId,
  buttonStyle,
  ...props
}) => (
  <IconButton
    style={ buttonStyle }
    onClick={ () => removeItem({ variables: { itemId } }) }
    { ...props }
    data-testid='remove-cart-item-btn'
  >
    <RemoveIcon />
  </IconButton>
)

RemoveCartItemButton.propTypes = {
  removeItem: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  buttonStyle: PropTypes.object,
}

export default RemoveCartItemButton
