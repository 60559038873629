import React from 'react'
import Dumb from './TopNav'
import { TopNavComponent, TopNavQuery } from 'types/graphql'

export interface Props {
  bannerShowing: boolean;
}

const TopNav = (props: Props): JSX.Element => (
  <TopNavComponent>
    {({ data, loading }): JSX.Element | null => {
      if (loading) return null

      return <Dumb data={ data as TopNavQuery } { ...props } />
    }}
  </TopNavComponent>
)

export default TopNav
