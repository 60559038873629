import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    form: {
      marginBottom: spacing(4),
    },
    mistakeCopy: {
      fontSize: '0.9rem',
      textAlign: 'center',
      marginTop: spacing(2),
    },
  })
)
