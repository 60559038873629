import React, { SyntheticEvent, useState } from 'react'
import classNames from 'classnames'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import NumberPicker from 'components/NumberPicker/index'
import { FitnessServiceProps } from 'components/hfs/types'
import { asPrice, createMarkup } from 'lib/helpers'
import useStyles from 'components/hfs/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FitnessServiceActions from 'components/hfs/FitnessServiceActions'

const FitnessService = ({
  sessions: initialSessions,
  addItemToCartMutation,
  itemClass,
  service,
  expiryText,
  hasQuantity,
  hasStaff,
  advanceTour,
  addItemToCartLoading,
  staff,
}: FitnessServiceProps): JSX.Element => {
  const classes = useStyles()
  const [sessions, setSessions] = useState(initialSessions || 1)

  const dec = (e: SyntheticEvent): void => {
    e.stopPropagation()
    if (sessions <= 1) return
    setSessions(sessions - 1)
  }

  const inc = (e: SyntheticEvent): void => {
    e.stopPropagation()
    setSessions(sessions + 1)
  }

  const { title, description, price } = service

  const subtitle = `${
    price ? asPrice(price * sessions) : 'Free'
  } / ${expiryText}`

  return (
    <ExpansionPanel
      className={ classNames('fitness-service', classes.card) }
      onChange={ (): void => {
        advanceTour()
      } }
    >
      <ExpansionPanelSummary
        expandIcon={ <ExpandMoreIcon /> }
        data-testid='expand-service'
      >
        <div>
          <Typography className={ classes.titleText }>{title}</Typography>
          <Typography className={ classes.subtitleText }>
            {hasQuantity ? (
              <>
                <span>Purchase </span>
                <NumberPicker value={ sessions } onInc={ inc } onDec={ dec } />
                <span> for {subtitle}</span>
              </>
            ) : (
              subtitle
            )}
          </Typography>
        </div>
        <FitnessServiceActions
          sessions={ sessions }
          service={ service }
          addItemToCartMutation={ addItemToCartMutation }
          itemClass={ itemClass }
          hasStaff={ hasStaff }
          addItemToCartLoading={ addItemToCartLoading }
          staff={ staff }
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div
          className={ classes.description }
          dangerouslySetInnerHTML={ createMarkup(description) }
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default FitnessService
