// TODO: implement infinte scroll
import Dumb from './OrderHistoryTab'
import { graphql } from 'react-apollo'
import { USER_ORDERS_QUERY } from 'queries/account'

const OrderHistoryTab = graphql(USER_ORDERS_QUERY, {
  props: ({ data: { loading, viewer, errors } }) => ({
    errors,
    loading,
    orders: viewer && viewer.orderHistory,
  }),
  // don't stop the request on error, to allow for stripe fetch errors.
  options: {
    errorPolicy: 'all',
  },
})(Dumb)

export default OrderHistoryTab
