export default {
  dialogComponent: null,
  dialogProps: {
    id: null,
    gfcScheduleId: null,
    sessions: 1,
    staffId: null,
    itemClass: null,
    videoUrl: null,
    service: {
      id: null,
      title: null,
      description: null,
      price: null,
      validFor: null,
      askAvailability: null,
      __typename: 'DialogPropsFitnessService',
    },
    event: {
      id: null,
      eventId: null,
      title: null,
      eventLocation: null,
      details: null,
      formattedPrice: null,
      startTime: null,
      endTime: null,
      staff: null,
      __typename: 'DialogPropsEvent',
    },
    __typename: 'DialogProps',
  },
  tour: {
    run: false,
    stepIndex: 0,
    __typename: 'Tour',
  },
  profileMenuOpen: false,
  videoSearch: '',
  refreshGfcTable: false,
}
