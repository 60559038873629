import React from 'react'
import Button from '@material-ui/core/Button'
import TimeIcon from '@material-ui/icons/AccessTime'
import useStyles from './styles'

interface Props {
  isToday: boolean;
  toggleDay: () => void;
}

const ToggleDayButton = ({ isToday, toggleDay }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Button
      onClick={ toggleDay }
      variant='outlined'
      className={ classes.toggleDayButton }
      color='primary'
    >
      Edit {isToday ? 'Yesterday' : 'Today'}
      <TimeIcon className={ classes.rightIcon } />
    </Button>
  )
}

export default ToggleDayButton
