import React from 'react'
import Paper from '@material-ui/core/Paper'
import withStyles, {
  WithStyles,
  StyleRules,
} from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import MenuNavLinks from 'components/navigation/MenuNavLinks'

const styles = (theme: Theme): StyleRules => ({
  container: {
    backgroundColor: '#F5F5F5',
    marginBottom: '10px',
    position: 'fixed',
    height: 'calc(100vh - 80px)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(25% - 20px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(16.67% - 20px)',
    },
  },
  paper: {
    padding: '10px',
    height: '100%',
    fontSize: '14px',
    overflowY: 'scroll',
  },
})

const DashboardNav = ({ classes }: WithStyles<typeof styles>): JSX.Element => {
  return (
    <div className={ classes.container }>
      <Paper className={ classes.paper } elevation={ 1 } square>
        <MenuNavLinks />
      </Paper>
    </div>
  )
}

export default withStyles(styles)(DashboardNav)
