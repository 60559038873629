import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import EventsCalendar from 'components/Events/EventsCalendar'
import EventsList from 'components/Events/EventsList'
import { Redirect, Route, Switch } from 'react-router-dom'
import CalendarViewSwitch from 'components/CalendarViewSwitch'
import { EVENTS_FOR_CALENDAR_QUERY } from 'queries'
import CalendarTZWarning from 'components/CalendarTZWarning'
import Hidden from '@material-ui/core/Hidden'

const Events = ({ match: { path } }) => {
  return (
    <Grid container direction='column'>
      <Grid container spacing={ 1 }>
        <Hidden smDown>
          <Grid item>
            <CalendarViewSwitch />
          </Grid>
        </Hidden>
        <Grid item>
          <CalendarTZWarning />
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={ 12 }>
            <Switch>
              <Route
                exact
                path={ `${path}/calendar` }
                render={ () => (
                  <EventsCalendar
                    query={ EVENTS_FOR_CALENDAR_QUERY }
                    eventKey={ 'eventCalendarEntries' }
                  />
                ) }
              />
              <Route exact path={ `${path}/list` } component={ EventsList } />
              <Redirect to={ `${path}/calendar` } />
            </Switch>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container>
          <Grid item xs={ 12 }>
            <EventsList />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
}

Events.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
}

export default Events
