import { makeStyles, StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  (): StyleRules => ({
    anchor: {
      color: '#1a0dab',
      textDecoration: 'underline',
    },
    statusContainer: {
      textAlign: 'center',
      display: 'block',
      whiteSpace: 'normal',
    },
  })
)
