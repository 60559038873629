import React from 'react'
import TourIcon from 'material-ui/svg-icons/action/info'
import FlatButton from 'material-ui/FlatButton'
import { trackEvent } from 'lib/analytics'

export default updateTour => (
  <FlatButton
    aria-hidden
    tabIndex={ -1 }
    icon={ <TourIcon /> }
    label='Tour'
    onClick={ () => {
      trackEvent({
        category: 'Tour',
        action: 'Start via Button',
      })

      updateTour({ variables: { run: true } })
    } }
  />
)
