import React from 'react'
import UnregisterForEventButton from 'components/Events/UnregisterForEventButton'
import AddToCalendarButton from 'components/AddToCalendarButton'
import { calendarEvent } from 'lib/helpers'
import { AppointmentBooking, EventRegistration } from 'types/graphql'
import useStyles from './styles'
import { PureQueryOptions } from 'apollo-client'

export interface Props {
  refetchQueries?: PureQueryOptions[];
  // Omit typename because we pass DialogPropsEvent as substitute
  registration: Omit<AppointmentBooking | EventRegistration, '__typename'>;
}

const RegisteredActions = ({
  registration,
  refetchQueries,
}: Props): JSX.Element => {
  const classes = useStyles()
  const { startTime, endTime, details, title, eventLocation } = registration

  return (
    <div className={ classes.registeredContainer }>
      <UnregisterForEventButton
        registration={ registration }
        refetchQueries={ refetchQueries }
      />
      <div className={ classes.addToCalendarContainer }>
        <AddToCalendarButton
          event={ calendarEvent(
            startTime,
            endTime,
            title,
            details,
            eventLocation,
          ) }
        />
      </div>
    </div>
  )
}

export default RegisteredActions
