import Dumb from './DependentDialog'
import React from 'react'
import {
  DependentInput,
  DialogPropsQuery,
  useAddDependentMutation,
  useDependentQuery,
  useDialogPropsQuery,
  useEditDependentMutation,
} from 'types/graphql'
import ErrorDialog from 'components/ErrorDialog'
import { Form, Formik } from 'formik'
import { ExecutionResult } from 'apollo-link'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dateOfBirth: Yup.mixed().required('Required'),
  emergencyFirstName: Yup.string().required('Required'),
  emergencyLastName: Yup.string().required('Required'),
  emergencyPhone: Yup.string().required('Required'),
})

const DependentDialog = (): JSX.Element | null => {
  const { data: dialogData } = useDialogPropsQuery()
  const {
    dialogProps: { id: dependentId },
  } = dialogData as DialogPropsQuery
  const { data, loading, error } = useDependentQuery({
    variables: { dependentId },
  })
  const [editDependent, { loading: editLoading }] = useEditDependentMutation()
  const [addDependent, { loading: addLoading }] = useAddDependentMutation()

  if (loading) return null

  if (!data) return <ErrorDialog errorMessage={ error && error.message } />

  const { dependent } = data.viewer

  const initialValues = {
    firstName: (dependent && dependent.firstName) || '',
    lastName: (dependent && dependent.lastName) || '',
    dateOfBirth: dependent && dependent.dateOfBirth,
    emergencyFirstName: (dependent && dependent.emergencyFirstName) || '',
    emergencyLastName: (dependent && dependent.emergencyLastName) || '',
    emergencyPhone: (dependent && dependent.emergencyPhone) || '',
  }

  const handleSubmit = (values: DependentInput): Promise<ExecutionResult> => {
    return dependentId ?
      editDependent({
        variables: { dependentParams: values, id: dependentId },
      }) :
      addDependent({ variables: { dependentParams: values } })
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ handleSubmit }
      validationSchema={ validationSchema }
    >
      <Form>
        <Dumb
          isExisting={ Boolean(dependentId) }
          submitLoading={ editLoading || addLoading }
        />
      </Form>
    </Formik>
  )
}

export default DependentDialog
