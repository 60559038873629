import Dumb from './ShoppingCartLink'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { CART_ITEM_COUNT } from 'queries'

const ShoppingCartLink = ({ children }) => (
  <Query query={ CART_ITEM_COUNT }>
    {({ loading, data }) => {
      const itemCount =
        !loading && data.viewer.cart ? data.viewer.cart.itemCount : 0
      return (
        <Dumb itemCount={ itemCount } loading={ loading }>
          {children}
        </Dumb>
      )
    }}
  </Query>
)

ShoppingCartLink.propTypes = { children: PropTypes.element.isRequired }

export default ShoppingCartLink
