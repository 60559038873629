// just another styling component
import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import useStyles from './styles'

export interface Props {
  title: string;
  avatar?: string;
  subtitle: string | JSX.Element;
  children?: JSX.Element;
  headerChildren?: JSX.Element;
}

const CardLi = ({
  title,
  avatar,
  subtitle,
  headerChildren,
  children,
}: Props) => {
  const classes = useStyles()

  return (
    <Card className={ classes.card }>
      <div className={ classes.headerContainer }>
        <CardHeader
          title={ title }
          subheader={ subtitle }
          avatar={ avatar && <Avatar alt='Avatar' src={ avatar } /> }
          titleTypographyProps={ { variant: 'subtitle2' } }
          subheaderTypographyProps={ { variant: 'body2' } }
          className={ classes.header }
        />
        {headerChildren && (
          <div className={ classes.headerChildrenContainer }>
            {headerChildren}
          </div>
        )}
      </div>
      {children && (
        <CardContent className={ classes.content }>{children}</CardContent>
      )}
    </Card>
  )
}
export default CardLi
