import Dumb from './SelectLocationStep'
import React, { useContext } from 'react'
import { CompanyComponent, CompanyQuery } from 'types/graphql-auth'
import { Context } from 'components/registration/types'
import RegistrationStep from 'components/registration/RegistrationStep'
import { FormikProps } from 'formik'
import { NewUserForm } from 'types'
import * as Yup from 'yup'
import { RegistrationContext } from 'components/registration/helpers'

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    locationId: Yup.number()
      .positive('Required')
      .required('Required'),
  }),
})

const SelectLocationStep = (): JSX.Element | null => {
  const { formValues } = useContext(RegistrationContext) as Context

  if (!formValues.user.companyId) return null

  return (
    <CompanyComponent variables={ { companyId: formValues.user.companyId } }>
      {({ data, loading }): JSX.Element | null => {
        if (loading) return null

        const dataAsQuery = data as CompanyQuery

        return (
          <RegistrationStep validationSchema={ validationSchema }>
            {(formikProps: FormikProps<NewUserForm>): JSX.Element => (
              <Dumb data={ dataAsQuery } formikProps={ formikProps } />
            )}
          </RegistrationStep>
        )
      }}
    </CompanyComponent>
  )
}

export default SelectLocationStep
