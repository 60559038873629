import React from 'react'
import AvatarWithUpload from 'components/avatars/AvatarWithUpload'
import { FetchResult } from 'apollo-link'
import { EditUserAvatarMutationFn, UserAvatarQuery } from 'types/graphql'

export interface Props {
  uploadAvatar: EditUserAvatarMutationFn;
  data: UserAvatarQuery;
}

const UserAvatar = ({
  uploadAvatar,
  data: {
    viewer: {
      profile: { avatar },
      location: {
        config: { usersCanEditProfileImage },
      },
    },
  },
}: Props): JSX.Element => (
  <AvatarWithUpload
    avatar={ avatar }
    uploadAvatar={ (profilePicture: string): Promise<void | FetchResult> =>
      uploadAvatar({ variables: { profile: { profilePicture } } })
    }
    canUpload={ usersCanEditProfileImage }
  />
)

export default UserAvatar
