import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import CardLi from 'components/CardLi'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { UserActivityQuery } from 'types/graphql'

const styles = {
  text: {
    paddingTop: 0,
    paddingBottom: '5px',
  },
  subText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
    paddingBottom: '2px',
    paddingTop: '2px',
  },
}

export interface Props extends WithStyles<typeof styles> {
  data: UserActivityQuery;
}

const dateFormat = 'MM/DD/YYYY, h:mm a'

const UserAcvitityTab = ({
  data: {
    viewer: { userActivities },
  },
  classes,
}: Props): JSX.Element => {
  return (
    <div>
      {userActivities.map(
        (activity): JSX.Element => (
          <CardLi
            key={ activity.id }
            title={ activity.createdByName }
            subtitle={ activity.name }
            avatar={ activity.createdByAvatar }
          >
            <div>
              {activity.description && (
                <Typography className={ classes.text }>
                  {activity.description}
                </Typography>
              )}
              <Typography className={ classes.subText }>
                {moment(activity.createdAt)
                  .local()
                  .format(dateFormat)}
              </Typography>
            </div>
          </CardLi>
        )
      )}
    </div>
  )
}

export default withStyles(styles)(UserAcvitityTab)
