import React, { Component } from 'react'
import DateRangePicker from 'components/DateRangePicker'
import CheckinReport from 'components/CheckinReport'
import CheckinReportExport from 'components/CheckinReportExport'
import moment from 'moment'
import Paper from 'material-ui/Paper'

const styles = {
  controlsContainer: {
    padding: '25px',
  },
  reportContainer: {
    padding: '15px',
    marginTop: '10px',
  },
  controls: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}

class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: moment()
        .subtract(1, 'year')
        .toDate(),
      endDate: moment().toDate(),
    }
  }

  render() {
    return (
      <div>
        <Paper style={ styles.controlsContainer }>
          <div style={ styles.controls }>
            <DateRangePicker
              start={ this.state.startDate }
              end={ this.state.endDate }
              onStartChange={ date => this.setState({ startDate: date }) }
              onEndChange={ date => this.setState({ endDate: date }) }
            />
            <CheckinReportExport
              startDateUnix={ this.state.startDate.getTime() }
              endDateUnix={ this.state.endDate.getTime() }
            />
          </div>
        </Paper>
        <Paper style={ styles.reportContainer }>
          <CheckinReport
            startDateUnix={ this.state.startDate.getTime() }
            endDateUnix={ this.state.endDate.getTime() }
          />
        </Paper>
      </div>
    )
  }
}

export default Report
