import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import ErrorMessage from 'components/ErrorMessage'
import { useLocationUpdatesQuery } from 'types/graphql-auth'
import { RegistrationContext } from 'components/registration/helpers'
import { Context } from 'components/registration/types'
import FormSelectField from 'components/registration/FormSelectField'
import { NewUserForm } from 'types'

const AdditionalInfoStep = (): JSX.Element | null => {
  const { formValues, setFormValues } = useContext(
    RegistrationContext
  ) as Context
  const { setFieldValue, values } = useFormikContext<NewUserForm>()

  // Don't really need the whole query, but it's in the cache anyway...
  const { data, loading, error } = useLocationUpdatesQuery({
    variables: { locationId: formValues.user.locationId as number },
    onCompleted: (data): void => {
      const customUserFields = data.location.customUserFields.map(
        ({ id }): object => ({ id, optionId: 0 })
      )
      setFormValues({
        ...formValues,
        customUserFields,
      })
      setFieldValue('customUserFields', customUserFields)
    },
  })

  // this happens before the component updates from the query in index
  if (loading || !values.customUserFields.length) return null
  if (!data) {
    return <ErrorMessage error={ error } />
  }

  return (
    <>
      {data.location.customUserFields.map(
        ({ id, name, options }, index): JSX.Element | null => {
          return (
            <FormSelectField
              autoFocus={ !index }
              key={ id }
              name={ `customUserFields.${index}.optionId` }
              options={ options }
              label={ name }
              labelWidth={ 0 }
            />
          )
        }
      )}
    </>
  )
}

export default AdditionalInfoStep
