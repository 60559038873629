import Dumb from './ResetPassword'
import React from 'react'
import * as Yup from 'yup'
import { Formik, FormikProps } from 'formik'
import { useResetPasswordMutation } from 'types/graphql-auth'
import { useHistory, useParams } from 'react-router-dom'
import { login } from 'lib/helpers'
import { password, passwordConfirmation } from 'lib/yupHelpers'

const validationSchema = Yup.object().shape({
  password,
  passwordConfirmation,
})

const formValues = {
  password: '',
  passwordConfirmation: '',
}

export interface ResetPasswordForm {
  password: string;
  passwordConfirmation: string;
}

const ResetPassword = (): JSX.Element => {
  const [setPassword, { loading }] = useResetPasswordMutation()
  const { token = '' } = useParams()
  const { push } = useHistory()

  const handleSubmit = async(values: {
    password: string;
    passwordConfirmation: string;
  }): Promise<void> => {
    const res = await setPassword({ variables: { ...values, token } })
    if (!res || !res.data || !res.data.resetPassword) return
    login(res.data.resetPassword.accessToken)
    push('/')
  }

  return (
    <Formik
      validationSchema={ validationSchema }
      onSubmit={ handleSubmit }
      initialValues={ formValues }
    >
      {(formikProps: FormikProps<ResetPasswordForm>): JSX.Element => (
        <Dumb formikProps={ formikProps } loading={ loading } />
      )}
    </Formik>
  )
}

export default ResetPassword
