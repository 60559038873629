import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import FlatButton from 'material-ui/FlatButton'
import CartIcon from 'material-ui/svg-icons/action/shopping-cart'
import Badge from 'material-ui/Badge'

const style = {
  button: {
    overflow: 'initial',
  },
  badge: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    animationDuration: '1s',
    animationIterationCount: 1,
  },
  badgeContainer: {
    position: 'absolute',
    top: '-2px',
    right: '55px',
  },
  iconBadge: {
    top: '-33px',
    left: '12px',
  },
}

const ShoppingCartButton = ({ itemCount, handleClick }) => {
  const [animate, setAnimate] = useState(false)
  const [savedItemCount, setSavedItemCount] = useState(itemCount)

  useEffect(() => {
    if (savedItemCount !== itemCount) {
      setSavedItemCount(itemCount)
      setAnimate(true)
      setTimeout(() => {
        setAnimate(false)
      }, 1000)
    }
  }, [itemCount, animate, setAnimate, savedItemCount])

  return (
    <FlatButton
      style={ style.button }
      labelStyle={ style.labelStyle }
      icon={ <CartIcon /> }
      label='Cart'
      onClick={ handleClick }
    >
      <Badge
        badgeContent={ itemCount }
        style={ style.badgeContainer }
        badgeStyle={ {
          ...style.badge,
          ...(animate && { animationName: 'pulse' }),
        } }
        secondary
      />
    </FlatButton>
  )
}

ShoppingCartButton.propTypes = {
  itemCount: PropTypes.number,
  handleClick: PropTypes.func,
}

export default ShoppingCartButton
