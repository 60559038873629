// https://github.com/apollographql/eslint-plugin-graphql/issues/99
import gql from 'graphql-tag'

export const UPDATE_TOUR = gql`
  mutation UpdateTour($run: Boolean, $stepIndex: Int) {
    updateTour(run: $run, stepIndex: $stepIndex) @client
  }
`

export const ADVANCE_TOUR = gql`
  mutation AdvanceTour {
    advanceTour @client
  }
`

export const UPDATE_TOUR_STATUS = gql`
  mutation UpdateTourStatus {
    updateTourStatus @client
  }
`

export const OPEN_DIALOG = gql`
  mutation OpenDialog(
    $dialogComponent: String!
    $dialogProps: DialogPropsInput
  ) {
    openDialog(dialogComponent: $dialogComponent, dialogProps: $dialogProps)
      @client
  }
`

export const CLOSE_DIALOG = gql`
  mutation CloseDialog {
    closeDialog @client
  }
`

export const UPDATE_VIDEO_SEARCH = gql`
  mutation updateVideoSearch($videoSearch: String!) {
    updateVideoSearch(videoSearch: $videoSearch) @client
  }
`

export const SET_REFRESH_GFC_TABLE = gql`
  mutation setRefreshGfcTable($refreshGfcTable: Boolean!) {
    setRefreshGfcTable(refreshGfcTable: $refreshGfcTable) @client
  }
`
