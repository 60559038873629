import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    flexGrow: 1,
  },
  widget: {
    minWidth: 280,
  },
})
