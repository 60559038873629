import gql from 'graphql-tag'

export const appointmentBooking = gql`
  fragment appointmentBooking on AppointmentBooking {
    id
    title
    eventLocation
    startTime
    endTime
    staff {
      id
      name
    }
    charge {
      price
    }
  }
`

export const appointment = gql`
  fragment appointment on Appointment {
    id
    title
    details
    eventLocation
    price
    formattedPrice
    startTimedate
    endTimedate
    startDate
    endDate
  }
`

export const eventCalendarEntry = gql`
  fragment eventCalendarEntry on EventCalendarEntry {
    id
    eventId
    title
    startTime
    endTime
    type
    eventLocation
    details
    formattedPrice
    staff {
      id
      name
    }
  }
`

export const dependent = gql`
  fragment dependent on Dependent {
    id
    firstName
    lastName
    dateOfBirth
    emergencyFirstName
    emergencyLastName
    emergencyPhone
    avatar
    age
    isAdult
    isOfAge
  }
`

export const guest = gql`
  fragment guest on Guest {
    id
    firstName
    lastName
    emergencyFirstName
    emergencyLastName
    emergencyPhone
    emergencyRelation
  }
`

export const eventRegistration = gql`
  fragment eventRegistration on EventRegistration {
    id
    title
    eventLocation
    startTime
    endTime
    charge {
      price
    }
  }
`

export const attendingFragment = gql`
  fragment attendee on Attendee {
    id
    registrationId
    attendeeId
    attendeeType
    firstName
    lastName
    avatar
    status
  }
`

export const gfcSchedule = gql`
  fragment gfcSchedule on GfcSchedule {
    id
    title
    eventDate
    startDateTime
    endDateTime
    formattedTime
    instructor
    staffId
    classLocation
    spotsAvailable
    attending {
      ...attendee
    }
    isForChildDependents
    costForUser
    freeForUser
    gfc {
      id
      description
      price
    }
  }
  ${attendingFragment}
`

export const gfcMutationUpdateFragment = gql`
  fragment gfcMutationUpdate on User {
    id
    unregisteredGfcSchedules {
      ...gfcSchedule
    }
    registeredClasses {
      id
      status
      gfcSchedule {
        ...gfcSchedule
      }
    }
    availableCreditsDisplay
  }
  ${gfcSchedule}
`

export const invitation = gql`
  fragment invitation on Invitation {
    id
    email
  }
`

export const partner = gql`
  fragment partner on User {
    id
    fullName
    avatar
  }
`

export const partners = gql`
  fragment partners on User {
    partner {
      ...partner
    }
    pendingPartner {
      ...partner
    }
    pendingPartnerRequestFrom {
      ...partner
    }
    invitation {
      ...invitation
    }
  }
  ${partner}
  ${invitation}
`

export const bewell = gql`
  fragment bewell on Bewell {
    daysUntilStart
    showWidget
    startDate
    endDate
    backgroundUrl
    hasStarted
    hasEnded
    optedIn
    points
  }
`
export const bewellItem = gql`
  fragment bewellItem on BewellItem {
    id
    title
    description
    points
    perIntervalDisplay
    subtitle
    url
    urlLabel
    isCompletedToday
    isCompletedYesterday
  }
`

export const subscription = gql`
  fragment subscription on StripeSubscription {
    id
    price
    name
    currentPeriodEnd
    cancelAtPeriodEnd
    allowUserRenewal
    payFrequency
  }
`

export const card = gql`
  fragment card on StripeCard {
    id
    last4
    brand
    label
    isPrimary
  }
`

export const cartItem = gql`
  fragment cartItem on CartItem {
    id
    title
    price
    quantity
    isMembership
    staff {
      id
      name
    }
  }
`

export const userActivity = gql`
  fragment userActivity on UserActivity {
    id
    name
    createdAt
    description
    createdByName
    createdByAvatar(size: 30)
  }
`

export const profileBasicInfo = gql`
  fragment profileBasicInfo on Profile {
    gender
    dateOfBirth
    height
    weight
    bloodType
    city
    state
    zip
    phoneNumber
    workPhone
    contactEmail
    emergencyFirstName
    emergencyMiddleName
    emergencyLastName
    emergencyRelation
    emergencyPhone
    emergencyEmail
    emergencyAddress
  }
`

export const locker = gql`
  fragment locker on Locker {
    id
    name
    size
    status
    price
    frequency
  }
`

export const checklistItem = gql`
  fragment checklistItem on ChecklistItem {
    id
    label
    isCompleted
  }
`

export const checklist = gql`
  fragment checklist on Checklist {
    id
    label
    items {
      ...checklistItem
    }
  }
  ${checklistItem}
`

export const rewardRedemptionOption = gql`
  fragment rewardRedemptionOption on RewardRedemptionOption {
    id
    name
    description
    image
    pointsPerProduct
  }
`
