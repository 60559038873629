import Dumb from './BewellWidget'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { JOIN_BEWELL } from 'mutations/bewell'

const BewellWidget = props => (
  <Mutation mutation={ JOIN_BEWELL }>
    {joinBewell => <Dumb { ...props } join={ joinBewell } />}
  </Mutation>
)

export default withRouter(BewellWidget)
