import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  (theme: Theme): StyleRules => ({
    container: {
      paddingLeft: '16px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
    },
    title: {
      color: theme.palette.grey[600],
      fontSize: '1rem',
      margin: '0',
    },
    subtitle: {
      fontSize: '.75rem',
      marginTop: '8px',
      marginBottom: '8px',
    },
    body: {
      color: theme.palette.secondary.main,
      fontSize: '.75rem',
      marginTop: '8px',
      marginBottom: '8px',
    },
  })
)
