import { createStyles, makeStyles } from '@material-ui/core/styles'

export default makeStyles(() =>
  createStyles({
    card: {
      marginBottom: '15px',
      // borderTop: (appointment: AppointmentFragment) => `2px solid ${appointment.color}`,
    },
    membershipsIcon: {
      width: '49px',
      height: '49px',
    },
    header: {
      display: 'flex',
    },
    headerText: {
      paddingRight: '10px',
    },
    dates: {
      // takes the actions to the right in flex
      marginLeft: 'auto',
      padding: 0,
      // make room for the expander button
      marginRight: '30px',
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      fontSize: '.9rem',
      opacity: 0.7,
    },
    noEvents: {
      textAlign: 'center',
    },
  })
)
