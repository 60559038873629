import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    resetPassword: {
      fontSize: '12px',
      color: '#9b9b9b',
      marginTop: spacing(2),
      display: 'block',
    },
  })
)
