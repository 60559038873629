import React from 'react'
import Cards from 'react-credit-cards'
import Divider from 'material-ui/Divider'
import LoadingButton from 'components/LoadingButton'
import { CardFragment, SetPrimaryCardMutationFn } from 'types/graphql'
import useStyles from './styles'
import DialogLink from 'components/DialogLink'
import { Button } from '@material-ui/core'

export interface Props {
  card: CardFragment;
  name: string;
  removeCC: () => void;
  setPrimaryCard: SetPrimaryCardMutationFn;
  removeCardLoading: boolean;
  setPrimaryLoading: boolean;
}

const UserCreditCard = ({
  card,
  name,
  removeCC,
  setPrimaryCard,
  removeCardLoading,
  setPrimaryLoading,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div key={ card.id } className={ classes.sectionContainer }>
      <div className={ classes.cardContainer }>
        {/* we store cvc and expiry, but we don't show it here */}
        <Cards
          name={ name }
          number={ `●●●●●●●●●●●●${card.last4}` }
          issuer={ card.brand.toLowerCase() }
          cvc='no need'
          expiry='no need'
        />
      </div>
      <span className={ classes.cardLabel }>{card.label}</span>
      <div className={ classes.cardButtonContainer }>
        <DialogLink
          dialogComponent='RenameCreditCardDialog'
          dialogProps={ { id: card.id } }
          style={ { display: 'inline-block' } }
          trigger={
            <Button variant='outlined' className={ classes.cardButton }>
              Rename
            </Button>
          }
        />
        <LoadingButton
          loading={ removeCardLoading }
          secondary
          className={ classes.cardButton }
          onClick={ (): void => {
            removeCC()
          } }
          data-testid='remove-card'
        >
          Remove
        </LoadingButton>
      </div>
      {!card.isPrimary && (
        <div className={ classes.cardButtonContainer }>
          <LoadingButton
            loading={ setPrimaryLoading }
            onClick={ (): void => {
              setPrimaryCard()
            } }
            data-testid='set-primary-card'
          >
            Set as Default
          </LoadingButton>
        </div>
      )}
      <Divider className={ classes.divider } />
    </div>
  )
}

export default UserCreditCard
