import React from 'react'
import PropTypes from 'prop-types'
import LoadingButton from 'components/LoadingButton'

const ToggleItemCompleteButton = ({ completeItem, complete, loading }) => (
  <LoadingButton loading={ loading } secondary={ !complete } onClick={ completeItem }>
    {complete ? 'Complete' : 'Undo'}
  </LoadingButton>
)

ToggleItemCompleteButton.propTypes = {
  completeItem: PropTypes.func.isRequired,
  complete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default ToggleItemCompleteButton
