import Dumb from './ToggleItemCompleteButton'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { TOGGLE_BEWELL_COMPLETE_ITEM } from 'mutations/bewell'

const ToggleItemCompleteButton = ({ id, isToday, complete }) => (
  <Mutation
    mutation={ TOGGLE_BEWELL_COMPLETE_ITEM }
    variables={ { id, isToday, complete } }
  >
    {(completeItem, { loading }) => {
      return (
        <Dumb
          completeItem={ completeItem }
          complete={ complete }
          loading={ loading }
        />
      )
    }}
  </Mutation>
)

ToggleItemCompleteButton.propTypes = {
  id: PropTypes.number.isRequired,
  isToday: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
}

export default ToggleItemCompleteButton
