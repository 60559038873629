import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import Spinner from 'components/Spinner'
import CardLi from 'components/CardLi'
import TransactionReceipt from 'components/account/TransactionReceipt'

const style = {
  subText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
    paddingBottom: '2px',
    paddingTop: '2px',
  },
  errorMessage: {
    padding: '10px',
    textAlign: 'center',
  },
}

const dateFormat = 'MM/DD/YYYY, h:mm a'

const OrderHistoryTab = ({ loading, orders }) => {
  if (loading) return <Spinner center fullHeight />
  // orders can be null if there's an error from stripe
  if (!orders) {
    return <p style={ style.errorMessage }>An error occurred fetching orders</p>
  }
  if (!orders.length) {
    return <p style={ style.errorMessage }>Your order history is empty</p>
  }

  return (
    <div>
      {orders
        .filter(order => !!order)
        .map(order => (
          <CardLi
            key={ order.id }
            title={ order.description || 'No Description Available' }
            headerChildren={ <TransactionReceipt order={ order } /> }
          >
            <div>
              {order.staffName && (
                <Typography>Trainer/Coach - {order.staffName}</Typography>
              )}
              <Typography>Price - {order.price}</Typography>
              {order.quantity && (
                <Typography>Quantity - {order.quantity}</Typography>
              )}
              {order.sessions && (
                <Typography>Sessions - {order.sessions}</Typography>
              )}
              <Typography>{order.status}</Typography>

              <Typography style={ style.subText }>
                {moment(order.createdOnStripe).format(dateFormat)}
              </Typography>
              <Typography style={ style.subText }>
                {`Order #: ${order.id}`}
              </Typography>
              {order.sourceLast4 && (
                <Typography style={ style.subText }>
                  {`Card Used: ...${order.sourceLast4}`}
                </Typography>
              )}
            </div>
          </CardLi>
        ))}
    </div>
  )
}

OrderHistoryTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string,
      sourceLast4: PropTypes.string,
      createdOnStripe: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      sessions: PropTypes.number,
      staffName: PropTypes.string,
      status: PropTypes.string.isRequired,
    })
  ),
}

export default OrderHistoryTab
