import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import GFCDigestItem from 'components/GFC/GFCDigestItem'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import DashboardCardList from 'components/DashboardCardList'

const styles = {
  noClass: {
    color: '#818a91',
    padding: '8px 16px',
    display: 'block',
  },
  link: {
    textDecoration: 'none',
  },
}

export const GFCDigest = ({
  classes,
  data: {
    viewer: { unregisteredGfcSchedules },
  },
}) => {
  return (
    <DashboardCardList
      headerProps={ { title: 'Upcoming Group Fitness Classes' } }
      noChildrenText='No classes offered.'
      hasChildren={ Boolean(unregisteredGfcSchedules.length) }
    >
      <Fragment>
        {unregisteredGfcSchedules.map(gfcSchedule => (
          <GFCDigestItem key={ gfcSchedule.id } gfcSchedule={ gfcSchedule } />
        ))}
        <Button>
          <Link to='/group-fitness-classes' className={ classes.link }>
            See More...
          </Link>
        </Button>
      </Fragment>
    </DashboardCardList>
  )
}

GFCDigest.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      // unregisteredGfcSchedulesCount: PropTypes.number.isRequired
      unregisteredGfcSchedules: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        })
      ),
    }),
  }),
}

export default withStyles(styles)(GFCDigest)
