import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepContent from '@material-ui/core/StepContent'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import LengthStep from './LengthStep'
import TimeStep from './TimeStep'
import ConfirmationStep from './ConfirmationStep'

const defaultState = {
  activeStep: 0,
  selectedLength: null,
  selectedTime: null,
}

const steps = [
  {
    label: 'Select Appointment Length',
    content: LengthStep,
    stateKeys: [],
  },
  {
    label: 'Select a Time',
    content: TimeStep,
    stateKeys: ['selectedLength'],
  },
  {
    label: 'Confirm Appointment',
    content: ConfirmationStep,
    stateKeys: ['selectedLength', 'selectedTime'],
  },
]

class AppointmentStepper extends Component {
  state = defaultState

  onComplete = (key, value) => {
    if (this.state.activeStep === steps.length - 1) {
      this.close()
    } else {
      this.setState({
        [key]: value,
        activeStep: this.state.activeStep + 1,
      })
    }
  }

  back = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    })
  }

  close = () => {
    this.setState(defaultState)
    this.props.closeDialog()
  }

  getStateKeys = keys => {
    let stateKeys = {}
    keys.forEach(key => {
      stateKeys[key] = this.state[key]
    })

    return stateKeys
  }

  renderStep = step => {
    const { event } = this.props

    return (
      <Step key={ step.label }>
        <StepLabel>{step.label}</StepLabel>
        <StepContent>
          {React.createElement(step.content, {
            event,
            onComplete: (...args) => {
              this.onComplete(...args)
            },
            ...this.getStateKeys(step.stateKeys),
            back: () => this.back(),
          })}
        </StepContent>
      </Step>
    )
  }

  render() {
    const { activeStep } = this.state

    return (
      <div>
        <Stepper activeStep={ activeStep } orientation='vertical'>
          {steps.map(this.renderStep)}
        </Stepper>
        <Button onClick={ () => this.close() }>Cancel</Button>
      </div>
    )
  }
}

AppointmentStepper.propTypes = {
  event: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
}

export default AppointmentStepper
