import Dumb from './TermsDialog'
import React, { useState } from 'react'

const TermsDialog = (): JSX.Element => {
  const [numPages, setNumPages] = useState<number | undefined>(undefined)
  const [pageIndex, setPageIndex] = useState(1)
  const [open, setOpen] = useState(false)

  const changePage = (change: number): void => {
    const newPage = pageIndex + change
    if (!numPages || newPage > numPages || newPage === 0) return

    setPageIndex(newPage)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleOpen = (): void => {
    setOpen(true)
  }

  const props = {
    setNumPages,
    pageIndex,
    open,
    changePage,
    handleClose,
    handleOpen,
  }

  return <Dumb { ...props } />
}

export default TermsDialog
