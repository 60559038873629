import React, { Fragment } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Link from '@material-ui/core/Link'

import ErrorMessage from 'components/ErrorMessage'
import { DependentWaiverQuery } from 'types/graphql'
import { createMarkup } from 'lib/helpers'

export interface Props {
  data: DependentWaiverQuery;
  linkText: string;
}

const DependentWaiverDialog = ({
  data: {
    viewer: {
      location: {
        companyPolicy: { dependentWaiver },
      },
    },
  },
  linkText,
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <Fragment>
      <Link color='primary' variant='inherit' onClick={ handleClickOpen }>
        {linkText}
      </Link>
      <Dialog
        open={ open }
        onClose={ handleClose }
        scroll='paper'
        aria-labelledby='dependent-dialog-title'
      >
        <DialogTitle id='dependent-dialog-title'>Dependent Waiver</DialogTitle>
        <DialogContent dividers>
          {dependentWaiver ? (
            <DialogContentText
              dangerouslySetInnerHTML={ createMarkup(dependentWaiver) }
            />
          ) : (
            <ErrorMessage
              error={
                new Error(
                  'No waiver has been configured. Please contact facility management to continue.'
                )
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleClose } color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default DependentWaiverDialog
