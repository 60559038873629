import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import LoadingButton from 'components/LoadingButton'

const styles = {
  button: {
    marginRight: 8,
  },
}

const RespondToPartnershipButton = ({
  classes,
  loading,
  accept,
  respondToPartnership,
}) => {
  return (
    <LoadingButton
      className={ classes.button }
      loading={ loading }
      secondary={ !accept }
      onClick={ () => respondToPartnership(accept) }
    >
      {accept ? 'Accept' : 'Reject'}
    </LoadingButton>
  )
}

RespondToPartnershipButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  accept: PropTypes.bool.isRequired,
  respondToPartnership: PropTypes.func.isRequired,
}

export default withStyles(styles)(RespondToPartnershipButton)
