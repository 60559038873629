import React from 'react'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { indigo400 } from 'material-ui/styles/colors'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import {
  createMuiTheme,
  MuiThemeProvider as NewMuiThemeProvider,
} from '@material-ui/core/styles'

import indigo from '@material-ui/core/colors/indigo'
import grey from '@material-ui/core/colors/grey'
import pink from '@material-ui/core/colors/pink'

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: indigo400,
  },
})

// for this transition period, we just match the little tweak we had. We can
// mess with it more later
// Typings are modified in `theme.d.ts`
export const newTheme = createMuiTheme({
  palette: {
    primary: { main: indigo[500] },
    secondary: { main: pink[700] },
    dimmed: {
      main: grey['500'],
      strong: grey['700'],
    },
    registration: {
      contentBackground: grey['50'],
      imageOverlay: 'rgba(44, 62, 80, 0.8)',
    },
  },
})

export interface Props {
  children: JSX.Element;
}

const ThemeProvider = ({ children }: Props): JSX.Element => (
  <NewMuiThemeProvider theme={ newTheme }>
    <MuiThemeProvider muiTheme={ muiTheme }>{children}</MuiThemeProvider>
  </NewMuiThemeProvider>
)

export default ThemeProvider
