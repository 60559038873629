import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import BewellStoneSVG from 'components/bewell/BewellStoneSVG'
import NewStoneDialog from 'components/bewell/NewStoneDialog'

const styles = {
  stoneContainer: {
    borderRadius: '25%',
    width: 50,
    height: 50,
    textAlign: 'center',
    display: 'inline-block',
    marginLeft: 10,
    marginRigth: 10,
  },
  totalWellnessStoneContainer: {
    width: 70,
    height: 70,
    display: 'block',
    margin: 'auto',
  },
  smallerStoneContainer: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 'calc(50% - 15px)',
    left: 'calc(50% - 15px)',
    marginLeft: 0,
  },
  stone: {
    width: 30,
    height: 30,
    marginTop: 10,
  },
  totalWellnessStone: {
    width: 50,
    height: 50,
  },
  smallerStone: {
    width: 20,
    height: 20,
    marginTop: 5,
  },
}

const BewellStone = ({
  classes,
  category: { color, isStoneEarned, title },
  totalWellness,
  isTotalWellnessEarned,
  rotation,
  newStoneAccepted,
  setNewStoneAccepted,
}) => {
  const [openNewStoneDialog, setOpenNewStoneDialog] = useState(false)

  const [earned, setEarned] = useState(isStoneEarned)
  useEffect(() => {
    if (isStoneEarned !== earned) {
      setEarned(isStoneEarned)
      isStoneEarned && setOpenNewStoneDialog(true)
    }
  }, [earned, isStoneEarned])

  const handleClose = () => {
    setOpenNewStoneDialog(false)
    setNewStoneAccepted(true)
  }

  const show = !totalWellness || isTotalWellnessEarned

  const conditionalStyle = isTotalWellnessEarned ?
    {
      style: {
        transform: `rotate(${rotation}deg) translate(50px) rotate(-${rotation}deg)`,
      },
    } :
    {}

  const openDialog =
    openNewStoneDialog && (totalWellness ? newStoneAccepted : true)

  return (
    <Fragment>
      {show && (
        <Tooltip title={ `${title} Stone` }>
          <Paper
            className={ classNames(
              classes.stoneContainer,
              totalWellness && classes.totalWellnessStoneContainer,
              !totalWellness &&
                isTotalWellnessEarned &&
                classes.smallerStoneContainer
            ) }
            { ...conditionalStyle }
          >
            <SvgIcon
              alt='Stone'
              className={ classNames(
                classes.stone,
                totalWellness && classes.totalWellnessStone,
                !totalWellness && isTotalWellnessEarned && classes.smallerStone
              ) }
            >
              <BewellStoneSVG color={ color } earned={ earned } />
            </SvgIcon>
          </Paper>
        </Tooltip>
      )}

      {openDialog && (
        <NewStoneDialog
          handleClose={ handleClose }
          color={ color }
          title={ title }
          totalWellness={ totalWellness }
        />
      )}
    </Fragment>
  )
}

BewellStone.propTypes = {
  classes: PropTypes.object.isRequired,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isStoneEarned: PropTypes.bool.isRequired,
  }).isRequired,
  totalWellness: PropTypes.bool,
  isTotalWellnessEarned: PropTypes.bool,
  rotation: PropTypes.number,
  newStoneAccepted: PropTypes.bool,
  setNewStoneAccepted: PropTypes.func.isRequired,
}

export default withStyles(styles)(BewellStone)
