import React, { useState } from 'react'
import EventCards from 'components/Events/EventCards'
import DateRangePicker from 'components/DateRangePicker'
import moment from 'moment-timezone'
import { MaterialUiPickersDate } from '@material-ui/pickers'

export const dateString = (date: MaterialUiPickersDate): string =>
  moment(date as Date)
    .utc()
    .startOf('day')
    .toISOString()

const EventsList = (): JSX.Element => {
  const [startDate, setStartDate] = useState(
    moment().toDate() as MaterialUiPickersDate
  )
  const [endDate, setEndDate] = useState(moment()
    .add(1, 'year')
    .toDate() as MaterialUiPickersDate)

  return (
    <div>
      <DateRangePicker
        start={ startDate }
        end={ endDate }
        onStartChange={ (date: MaterialUiPickersDate): void =>
          setStartDate(date)
        }
        onEndChange={ (date: MaterialUiPickersDate): void => setEndDate(date) }
      />
      <EventCards
        startDate={ dateString(startDate) }
        endDate={ dateString(endDate) }
      />
    </div>
  )
}

export default EventsList
