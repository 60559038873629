import { makeStyles, Theme, StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    root: {
      padding: spacing(2),
    },
    textField: {
      width: '100%',
      marginBottom: spacing(2),
      marginTop: spacing(),
    },
    alignLeft: {
      textAlign: 'left',
    },
  })
)
