import gql from 'graphql-tag'
import { dependent, guest, invitation, partner, partners } from 'fragments'

export const ADD_DEPENDENT_MUTATION = gql`
  mutation addDependent(
    $dependentParams: DependentInput!
    $agreeToTerms: Boolean
  ) {
    addDependent(
      dependentParams: $dependentParams
      agreeToTerms: $agreeToTerms
    ) {
      viewer {
        id
        dependents {
          ...dependent
        }
      }
      message
    }
  }
  ${dependent}
`

export const EDIT_DEPENDENT_MUTATION = gql`
  mutation editDependent($dependentParams: DependentInput!, $id: Int!) {
    editDependent(dependentParams: $dependentParams, id: $id) {
      dependent {
        ...dependent
      }
      message
    }
  }
  ${dependent}
`

export const REMOVE_DEPENDENT_MUTATION = gql`
  mutation removeDependent($id: Int!) {
    removeDependent(id: $id) {
      viewer {
        id
        dependents {
          ...dependent
        }
      }
      message
    }
  }
  ${dependent}
`

export const INVITE_PARTNER_MUTATION = gql`
  mutation invitePartner($email: String!, $external: Boolean!) {
    invitePartner(email: $email, external: $external) {
      viewer {
        id
        pendingPartner {
          ...partner
        }
        partner {
          ...partner
        }
        invitation {
          ...invitation
        }
      }
      message
    }
  }
  ${partner}
  ${invitation}
`

export const REMOVE_PARTNERSHIP = gql`
  mutation removePartnership {
    removePartnership {
      viewer {
        id
        ...partners
      }
      message
    }
  }
  ${partners}
`

export const RESPOND_TO_PARTNERSHIP_MUTATION = gql`
  mutation respondToPartnership($accept: Boolean!) {
    respondToPartnership(accept: $accept) {
      viewer {
        id
        ...partners
      }
      message
    }
  }
  ${partners}
`

export const ADD_GUEST_MUTATION = gql`
  mutation addGuest($guestParams: GuestInput!) {
    addGuest(guestParams: $guestParams) {
      viewer {
        id
        guests {
          ...guest
        }
      }
      message
    }
  }
  ${guest}
`

export const EDIT_GUEST_MUTATION = gql`
  mutation editGuest($guestParams: GuestInput!, $id: Int!) {
    editGuest(guestParams: $guestParams, id: $id) {
      guest {
        ...guest
      }
      message
    }
  }
  ${guest}
`

export const REMOVE_GUEST_MUTATION = gql`
  mutation removeGuest($id: Int!) {
    removeGuest(id: $id) {
      viewer {
        id
        guests {
          ...guest
        }
      }
      message
    }
  }
  ${guest}
`
