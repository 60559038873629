import Dumb from './UserAvatar'
import React from 'react'
import {
  UserAvatarComponent,
  EditUserAvatarComponent,
  UserAvatarQuery,
} from 'types/graphql'

const UserAvatar = (): JSX.Element => (
  <UserAvatarComponent>
    {({ data, loading }): JSX.Element | null => {
      if (loading) return null
      return (
        <EditUserAvatarComponent>
          {(uploadAvatar): JSX.Element => {
            return (
              <Dumb
                uploadAvatar={ uploadAvatar }
                data={ data as UserAvatarQuery }
              />
            )
          }}
        </EditUserAvatarComponent>
      )
    }}
  </UserAvatarComponent>
)

export default UserAvatar
