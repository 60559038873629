// this components seems useless, but it's use is passing down closeDialog
// to the dialog component
import React from 'react'
import PropTypes from 'prop-types'
import AddCreditCardDialog from 'components/AddCreditCardDialog'
import RenameCreditCardDialog from 'components/RenameCreditCardDialog'
import GFCDetailsDialog from 'components/GFC/GFCDetailsDialog'
import StaffInfoDialog from 'components/StaffInfoDialog'
import ContactStaffDialog from 'components/ContactStaffDialog'
import EditContactInfoDialog from 'components/profileDrawer/EditContactInfoDialog'
import EditBasicInfoDialog from 'components/profileDrawer/EditBasicInfoDialog'
import EditEmergencyInfoDialog from 'components/profileDrawer/EditEmergencyInfoDialog'
import EventSlotsDialog from 'components/Events/EventSlotsDialog'
import AppointmentsDialog from 'components/appointments/AppointmentsDialog'
import BookedEventDialog from 'components/BookedEventDialog'
import VideoDialog from 'components/VideoDialog'
import ResetPasswordDialog from 'components/ResetPasswordDialog'
import RedeemPointsDialog from 'components/RewardPoints/RedeemPointsDialog'
import DependentDialog from 'components/family/DependentDialog'
import GuestDialog from 'components/family/GuestDialog'

const getComponent = componentName =>
  ({
    AddCreditCardDialog,
    RenameCreditCardDialog,
    GFCDetailsDialog,
    StaffInfoDialog,
    ContactStaffDialog,
    EditContactInfoDialog,
    EditBasicInfoDialog,
    EditEmergencyInfoDialog,
    EventSlotsDialog,
    AppointmentsDialog,
    VideoDialog,
    ResetPasswordDialog,
    RedeemPointsDialog,
    DependentDialog,
    GuestDialog,
    BookedEventDialog,
  }[componentName])

const ElevationDialog = props => {
  const { dialogComponent, ...otherProps } = props
  const Component = getComponent(dialogComponent)
  return <Component { ...otherProps } />
}

ElevationDialog.propTypes = {
  dialogComponent: PropTypes.string.isRequired,
}

export default ElevationDialog
