// all this component does is update the local tour state based on whether or
// not a user already completed the tour
import PropTypes from 'prop-types'
import { Component } from 'react'

class UpdateTourStatus extends Component {
  componentDidMount() {
    this.props.updateTourStatus()
  }

  render() {
    return null
  }
}

UpdateTourStatus.propTypes = {
  updateTourStatus: PropTypes.func.isRequired,
  children: PropTypes.any,
}

export default UpdateTourStatus
