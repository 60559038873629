import React from 'react'
import ConditionsDialog from 'components/ConditionsDialog'
import { useCompanyWaiverQuery } from 'types/graphql-auth'

interface Props {
  open: boolean;
  handleDisagreed: () => Promise<void>;
  handleAgreed: () => Promise<void>;
  locationId: number;
}

export default (props: Props): JSX.Element => {
  return (
    <ConditionsDialog
      waiverQueryHook={ useCompanyWaiverQuery }
      skipLogout
      { ...props }
    />
  )
}
