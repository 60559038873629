import Dumb from './MenuNavLink'
import React from 'react'
import { Mutation } from 'react-apollo'
import { ADVANCE_TOUR } from 'mutations/local'
import muiThemeable from 'material-ui/styles/muiThemeable'

const withMutations = props => (
  <Mutation mutation={ ADVANCE_TOUR }>
    {advanceTour => <Dumb advanceTour={ advanceTour } { ...props } />}
  </Mutation>
)

const MenuNavLink = muiThemeable()(withMutations)

export default MenuNavLink
