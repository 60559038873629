// "forked" from https://github.com/TeamWertarbyte/material-ui-search-bar
// lots of functionality we don't use here, might expand on it in the future
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from 'material-ui/IconButton'
import TextField from 'material-ui/TextField'
import ClearIcon from 'material-ui/svg-icons/content/clear'
import SearchIcon from 'material-ui/svg-icons/action/search'

const styles = {
  container: {
    position: 'relative',
    width: '200px',
  },
  iconButton: {
    opacity: 0.54,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    position: 'absolute',
    right: 0,
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0,
    },
  },
}

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/guidelines/patterns/search.html)
 */
class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      active: false,
      timeout: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ ...this.state, value: nextProps.value })
    }
  }

  handleBlur() {
    if (this.state.value.trim().length === 0) {
      this.setState({ value: '' })
    }
    // if (this.props.onBlur) this.props.onBlur(e)
  }

  handleInput(e) {
    const value = e.target.value
    if (this.state.timeout) clearTimeout(this.state.timeout)
    const timeout = setTimeout(() => {
      if (this.props.onChange) this.props.onChange(value)
    }, this.props.timeout || 600)
    this.setState({ value, timeout })
  }

  handleCancel() {
    this.setState({ active: false, value: '' })
    if (this.props.onCancelSearch) this.props.onCancelSearch()
  }

  handleKeyUp(e) {
    if (e.charCode === 13 || e.key === 'Enter') {
      this.handleRequestSearch()
    } else if (
      this.props.cancelOnEscape &&
      (e.charCode === 27 || e.key === 'Escape')
    ) {
      this.handleCancel()
    }
    // if (this.props.onKeyUp) this.props.onKeyUp(e)
  }

  render() {
    const { value } = this.state
    const { disabled, ...inputProps } = this.props

    return (
      <div style={ styles.container }>
        <TextField
          { ...inputProps }
          value={ value }
          onChange={ e => this.handleInput(e) }
          onKeyUp={ e => this.handleKeyUp(e) }
          fullWidth
          disabled={ disabled }
          name='Search...'
          placeholder={ this.props.placeholder || 'search' }
          aria-label='Search videos'
        />
        <IconButton
          disabled={ disabled }
          style={ {
            ...styles.iconButton,
            ...(this.state.value.length && styles.iconButtonHidden),
          } }
          aria-label='Perform video search'
        >
          <SearchIcon />
        </IconButton>
        <IconButton
          onClick={ () => this.handleCancel() }
          disabled={ disabled }
          style={ {
            ...styles.iconButton,
            ...(!this.state.value.length && styles.iconButtonHidden),
          } }
          aria-label='Clear Search'
        >
          <ClearIcon />
        </IconButton>
      </div>
    )
  }
}

SearchBar.propTypes = {
  /** Whether to clear search on escape */
  cancelOnEscape: PropTypes.bool,
  /** Custom top-level class */
  className: PropTypes.string,
  /** Override the close icon. */
  closeIcon: PropTypes.node,
  /** Disables text field. */
  disabled: PropTypes.bool,
  /** Fired when the search is cancelled. */
  onCancelSearch: PropTypes.func,
  /** Fired when the text value changes. */
  onChange: PropTypes.func,
  /** Sets placeholder text for the embedded text field. */
  placeholder: PropTypes.string,
  /** Override the search icon. */
  searchIcon: PropTypes.node,
  /** Override the inline-styles of the root element. */
  style: PropTypes.object,
  /** The value of the text field. */
  value: PropTypes.string,
  // timeout for input
  timeout: PropTypes.number,
}

export default SearchBar
