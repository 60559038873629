// THIS STEP IS NOT ACCESSIBLE WITH ERROR MESSAGES

import React, { Fragment, useContext } from 'react'
import { Context } from '../types'
import { RegistrationContext } from 'components/registration/helpers'
import { Button, FormHelperText, TextField } from '@material-ui/core'
import useStyles from '../styles'
import LoadingButton from 'components/LoadingButton'
import { Field, FieldProps, FormikProps } from 'formik'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import { RequiredUserVerificationFieldFragment } from 'types/graphql-auth'

export interface Props {
  verificationLoading: boolean;
  verificationFields: RequiredUserVerificationFieldFragment[];
  formikProps: FormikProps<{ [s: string]: string }>;
}

const VerifyUserStep = ({
  verificationLoading,
  verificationFields,
  formikProps,
}: Props): JSX.Element | null => {
  const classes = useStyles()
  const { setActiveStep, activeStep } = useContext(
    RegistrationContext
  ) as Context

  const handleBack = (): void => {
    setActiveStep((prevActiveStep: number): number => prevActiveStep - 1)
  }

  return (
    <Fragment>
      {verificationFields.map(
        ({ id, name, label, fieldType }, i): JSX.Element | null => {
          return (
            <Field key={ id } name={ name }>
              {({ field }: FieldProps<Date | string>): JSX.Element => {
                if (fieldType === 'date') {
                  return (
                    <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                      <KeyboardDatePicker
                        { ...field }
                        autoFocus={ !i }
                        InputAdornmentProps={ { position: 'start' } }
                        onChange={ (date): void => {
                          date &&
                            formikProps.setFieldValue(
                              name,
                              moment(date).format('MM/DD/YYYY')
                            )
                        } }
                        autoOk
                        variant='inline'
                        inputVariant='outlined'
                        fullWidth
                        format='MM/dd/yyyy'
                        margin='normal'
                        id={ name }
                        label={ label }
                        className={ classes.field }
                      />
                    </MuiPickersUtilsProvider>
                  )
                }
                return (
                  <TextField
                    { ...field }
                    autoFocus={ !i }
                    id={ name }
                    fullWidth
                    required
                    inputProps={ { required: false } }
                    variant='outlined'
                    className={ classes.field }
                    label={ label }
                  />
                )
              }}
            </Field>
          )
        }
      )}
      {formikProps.errors.verifyUser && (
        <FormHelperText error className={ classes.verifyUserErrorMessage }>
          {formikProps.errors.verifyUser}
        </FormHelperText>
      )}
      <Button
        onClick={ handleBack }
        disabled={ !activeStep }
        className={ classes.stepButton }
      >
        Back
      </Button>
      <LoadingButton
        loading={ verificationLoading }
        variant='contained'
        color='primary'
        type='submit'
      >
        Next
      </LoadingButton>
    </Fragment>
  )
}

export default VerifyUserStep
