import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import RegisteredIcon from '@material-ui/icons/Done'
import WaitlistIcon from '@material-ui/icons/PlaylistAddCheck'

const styles = theme => ({
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
})

const AttendingChip = ({
  classes,
  margins,
  attendee: { status, firstName, lastName },
}) => {
  if (status !== 'registered' && status !== 'waitlist') return null

  return (
    <Chip
      color='primary'
      label={ `${firstName} ${lastName}` }
      className={ classNames(margins && classes.chip) }
      variant={ status === 'waitlist' ? 'outlined' : 'default' }
      icon={ status === 'registered' ? <RegisteredIcon /> : <WaitlistIcon /> }
    />
  )
}

AttendingChip.propTypes = {
  classes: PropTypes.object.isRequired,
  margins: PropTypes.bool,
  attendee: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(AttendingChip)
