import React from 'react'
import createApolloClient from 'lib/apolloClient'
import { ApolloProvider } from '@apollo/react-common'

const backend = process.env.REACT_APP_BACKEND_ADDR
const uri = backend ? `${backend}/api/v3/auth` : '/api/v3/auth'
const client = createApolloClient(uri)

export interface Props {
  children: Element;
}

const AuthRoute = ({ children }: Props): JSX.Element => {
  return <ApolloProvider client={ client }>{children}</ApolloProvider>
}

export default AuthRoute
