import Dumb from './ContactStaffDialog'
import React from 'react'
import {
  DialogPropsQuery,
  useContactInstructorMutation,
  useDialogPropsQuery,
  useStaffQuery,
} from 'types/graphql'
import ErrorDialog from 'components/ErrorDialog'
import { Form, Formik } from 'formik'
import { ExecutionResult } from 'apollo-link'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  subject: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
})

const ContactStaffDialog = (): JSX.Element | null => {
  const { data: dialogData } = useDialogPropsQuery()
  const {
    dialogProps: { id: staffId },
  } = dialogData as DialogPropsQuery
  const { data, loading, error } = useStaffQuery({
    variables: { staffId },
  })
  const [contact, { loading: contactLoading }] = useContactInstructorMutation()

  if (loading) return null

  if (!data) return <ErrorDialog errorMessage={ error && error.message } />

  const initialValues = {
    subject: '',
    message: '',
  }

  const handleSubmit = ({
    subject,
    message,
  }: {
    subject: string;
    message: string;
  }): Promise<ExecutionResult> => {
    return contact({ variables: { subject, message, staffId } })
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ handleSubmit }
      validationSchema={ validationSchema }
    >
      <Form>
        <Dumb
          submitLoading={ contactLoading }
          staffName={ data.viewer.location.staff.name }
        />
      </Form>
    </Formik>
  )
}

export default ContactStaffDialog
