import Dumb from './CheckoutButton'
import React from 'react'
import { Mutation } from 'react-apollo'
import { CHECKOUT_MUTATION } from 'mutations'

import { USER_ORDERS_QUERY } from 'queries/account'
import { WIDGETS_QUERY } from 'queries'
import { FITNESS_SERVICE_TYPES_QUERY } from 'queries/hfs'

// refetching anything a purchase might affect
const CheckoutButton = props => (
  <Mutation
    mutation={ CHECKOUT_MUTATION }
    refetchQueries={ [
      { query: USER_ORDERS_QUERY },
      { query: FITNESS_SERVICE_TYPES_QUERY },
      { query: WIDGETS_QUERY },
    ] }
  >
    {(checkout, { loading, error }) => (
      <Dumb checkout={ checkout } loading={ loading } error={ error } { ...props } />
    )}
  </Mutation>
)

export default CheckoutButton
