import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import {
  calendarEvent,
  getStatusForMap,
  isOnlyUser,
  mapStatusToActions,
} from 'lib/helpers'
import GFCDetails from 'components/GFC/GFCDetails'
import DialogLink from 'components/DialogLink'
import AddToCalendarButton from 'components/AddToCalendarButton'
import useStyles from './styles'
import { CloseDialogMutationFn, GfcScheduleFragment } from 'types/graphql'

export interface Props {
  gfcSchedule: GfcScheduleFragment;
  closeDialog: CloseDialogMutationFn;
  fullScreen: boolean;
}

const GFCDetailsDialog = ({
  gfcSchedule,
  closeDialog,
  fullScreen,
}: Props): JSX.Element => {
  const classes = useStyles()
  const {
    id,
    attending,
    spotsAvailable,
    staffId,
    startDateTime,
    endDateTime,
    title,
    classLocation,
    costForUser,
    freeForUser,
    gfc: { description },
  } = gfcSchedule

  const closeButton = (
    <Button
      onClick={ (): void => {
        closeDialog()
      } }
      className={ classes.button }
      variant='contained'
      key='closeButton'
    >
      Close
    </Button>
  )

  const actionButton =
    isOnlyUser(attending) &&
    mapStatusToActions({
      price: freeForUser ? undefined : costForUser,
      gfcScheduleId: id,
      onSuccess: closeDialog,
      className: classes.button,
      attendee: attending[0],
      key: 'actionButton',
    })[getStatusForMap(attending[0].status, spotsAvailable)]

  const contactInstructor = (
    <DialogLink
      key='contactInstructorButton'
      dialogComponent='ContactStaffDialog'
      dialogProps={ { id: staffId } }
      className={ classes.button }
      trigger={
        <Button color='primary' variant='outlined'>
          Contact Instructor
        </Button>
      }
    />
  )

  const isAnyRegistered = attending.some(
    (attendee): boolean => attendee.status === 'registered'
  )

  const addToCalendar = isAnyRegistered ? (
    <AddToCalendarButton
      key='addToCalendarButton'
      className={ classes.button }
      event={ calendarEvent(
        startDateTime,
        endDateTime,
        title,
        description,
        classLocation
      ) }
    />
  ) : null

  return (
    <Dialog
      open
      onClose={ closeDialog }
      fullWidth
      maxWidth='md'
      fullScreen={ fullScreen }
    >
      <GFCDetails gfcSchedule={ gfcSchedule } />
      <DialogActions>
        {[closeButton, contactInstructor, addToCalendar, actionButton]}
      </DialogActions>
    </Dialog>
  )
}

export default GFCDetailsDialog
