import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import { TimezoneQuery } from 'types/graphql'
import moment from 'moment-timezone'

export interface Props {
  data: TimezoneQuery;
}

const CalendarTZWarning = ({
  data: {
    viewer: {
      location: { timezone },
    },
  },
}: Props): JSX.Element | null => {
  const classes = useStyles()

  const localOffset = moment()
    .tz(moment.tz.guess())
    .utcOffset()
  const locationOffset = moment()
    .tz(timezone)
    .utcOffset()

  if (localOffset === locationOffset) return null

  return (
    <Paper elevation={ 1 } className={ classes.container }>
      <Typography className={ classes.type }>
        All times are displayed in your location's local time zone (
        {moment().format('z')})
      </Typography>
    </Paper>
  )
}

export default CalendarTZWarning
