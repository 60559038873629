import Dumb, { Props } from './Cart'
import React from 'react'
import { useCartItemsQuery } from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'

const Cart = ({ closeCart }: Pick<Props, 'closeCart'>): JSX.Element => {
  const { loading, data, error } = useCartItemsQuery()

  if (loading) return <Spinner center fullHeight />
  if (!data || error) return <ErrorMessage />

  return <Dumb data={ data } closeCart={ closeCart } />
}

export default Cart
