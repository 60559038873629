import React, { useState, createContext } from 'react'
import TourAdvance from 'components/TourAdvance'
import Face from '@material-ui/icons/Face'
import ProfileMenuItems from 'components/ProfileMenuItems'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    marginRight: spacing(1),
  },
}))

// Seems silly to default empty function, but required for typescript to consume
// this. Can remove once this is TS.
export const ProfileMenuContext = createContext({
  closeProfileMenu: () => {},
})

const AdapterMenuItems = React.forwardRef((props, ref) => (
  <ProfileMenuItems innerRef={ ref } { ...props } />
))

const ProfileMenu = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [opened, setOpened] = useState(false)

  const handleClick = event => {
    setOpened(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <TourAdvance>
      <Button id='my-profile-menu' aria-haspopup='true' onClick={ handleClick }>
        <Face className={ classes.icon } />
        My Profile
      </Button>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        keepMounted={ opened }
        onClose={ handleClose }
        anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
        getContentAnchorEl={ null }
      >
        <ProfileMenuContext.Provider
          value={ {
            closeProfileMenu: handleClose,
          } }
        >
          <AdapterMenuItems />
        </ProfileMenuContext.Provider>
      </Menu>
    </TourAdvance>
  )
}

export default ProfileMenu
