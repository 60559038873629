import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'

const styles = {
  tooltip: {
    top: '-15px !important',
  },
}

export interface Props extends WithStyles<typeof styles> {
  show: boolean;
  children: JSX.Element;
}

const NOT_ALLOWED_MESSAGE = 'You are not allowed to edit this field.'

const ReadOnlyFieldTooltip = ({
  children,
  show,
  classes,
}: Props): JSX.Element => {
  return (
    <Tooltip
      title={ show ? NOT_ALLOWED_MESSAGE : '' }
      classes={ { popper: classes.tooltip } }
    >
      {children}
    </Tooltip>
  )
}

export default withStyles(styles)(ReadOnlyFieldTooltip)
