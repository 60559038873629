import React, { Fragment, useContext } from 'react'
import { FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import { useLocationUpdatesQuery } from 'types/graphql-auth'
import { NewUserForm } from 'types'
import RegistrationStep from 'components/registration/RegistrationStep'
import { Context, RegistrationField } from 'components/registration/types'
import { RegistrationContext } from 'components/registration/helpers'

import FormTextField from 'components/registration/FormTextField'
import FormSelectField from 'components/registration/FormSelectField'
import { EmergencyRelationEnum } from 'types/graphql'

const EmergencyContactStep = (): JSX.Element | null => {
  const { formValues } = useContext(RegistrationContext) as Context

  const { data } = useLocationUpdatesQuery({
    variables: { locationId: formValues.user.locationId as number },
  })

  if (!data) return null

  const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
      profile: Yup.object().shape({
        emergencyFirstName: Yup.string()
          .min(2, 'Name too short')
          .max(50, 'Name too long')
          .required('Required'),
        emergencyLastName: Yup.string()
          .min(2, 'Name too short')
          .max(50, 'Name too long')
          .required('Required'),
        emergencyPhone: Yup.string().required('Required'),
        emergencyRelation: Yup.number().positive('Required'),
      }),
    }),
  })

  const fields = ({
    errors,
    touched,
  }: FormikProps<NewUserForm>): RegistrationField[] => [
    {
      name: 'user.profile.emergencyFirstName',
      label: 'Emergency Contact First Name',
      error:
        getIn(touched, 'user.profile.emergencyFirstName') &&
        getIn(errors, 'user.profile.emergencyFirstName'),
    },
    {
      name: 'user.profile.emergencyLastName',
      label: 'Emergency Contact Last Name',
      error:
        getIn(touched, 'user.profile.emergencyLastName') &&
        getIn(errors, 'user.profile.emergencyLastName'),
    },
    {
      name: 'user.profile.emergencyPhone',
      label: 'Emergency Contact Phone #',
      error:
        getIn(touched, 'user.profile.emergencyPhone') &&
        getIn(errors, 'user.profile.emergencyPhone'),
      type: 'phoneNumber',
    },
  ]

  const relationOptions = Object.keys(EmergencyRelationEnum).map(
    (relation, i) => ({ id: i + 1, name: relation }),
  )

  return (
    <RegistrationStep validationSchema={ validationSchema }>
      {(formikProps: FormikProps<NewUserForm>): JSX.Element => (
        <Fragment>
          {fields(formikProps).map(
            (field, i): JSX.Element => (
              <FormTextField field={ field } key={ field.name } autoFocus={ !i } />
            ),
          )}
          <FormSelectField
            labelWidth={ 330 }
            label='Emergency Contact Relation To You'
            options={ relationOptions }
            name='user.profile.emergencyRelation'
          />
        </Fragment>
      )}
    </RegistrationStep>
  )
}

export default EmergencyContactStep
