import Dumb from './Tour'
import React from 'react'
import muiThemeable from 'material-ui/styles/muiThemeable'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import { TOUR_QUERY } from 'queries/local'

const Tour = props => (
  <Query query={ TOUR_QUERY }>
    {({ data, loading }) => {
      if (loading || !data.tour) return null

      return <Dumb data={ data } { ...props } />
    }}
  </Query>
)

export default muiThemeable()(withRouter(Tour))
