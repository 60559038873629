import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Cart from 'components/Cart'
import PropTypes from 'prop-types'

const ShoppingCartLink = props => {
  const [open, setOpen] = useState(false)

  if (!props.itemCount) {
    if (open) setOpen(false)
    return ''
  }

  const toggleDrawer = open => () => {
    setOpen(open)
  }

  return (
    <>
      {React.cloneElement(props.children, {
        ...props,
        handleClick: toggleDrawer(true),
      })}
      <Drawer anchor='right' open={ open } onClose={ toggleDrawer(false) }>
        <Cart closeCart={ toggleDrawer(false) } />
      </Drawer>
    </>
  )
}

ShoppingCartLink.propTypes = {
  itemCount: PropTypes.number,
  children: PropTypes.element.isRequired,
}

export default ShoppingCartLink
