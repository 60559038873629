import React, { Fragment, useContext } from 'react'
import { FormikProps, getIn } from 'formik'
import * as Yup from 'yup'
import {
  useLocationUpdatesQuery,
  useValidateCredentialsMutation,
} from 'types/graphql-auth'
import { NewUserForm } from 'types'
import RegistrationStep from 'components/registration/RegistrationStep'
import { Context, RegistrationField } from 'components/registration/types'
import { RegistrationContext } from 'components/registration/helpers'

import FormTextField from 'components/registration/FormTextField'
import { password, passwordConfirmation, username } from 'lib/yupHelpers'

const CredentialsStep = (): JSX.Element | null => {
  const [validateCredentials, { loading }] = useValidateCredentialsMutation()
  const { formValues } = useContext(RegistrationContext) as Context

  const { data, loading: queryLoading } = useLocationUpdatesQuery({
    variables: { locationId: formValues.user.locationId as number },
  })

  if (queryLoading || !data) return null

  const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
      username,
      password,
      passwordConfirmation,
      profile: Yup.object().shape({
        badge: Yup.string().when('$validateBadge', {
          is: true,
          then: Yup.string().required('Required'),
        }),
        phoneNumber: Yup.string().when('$validatePhoneNumber', {
          is: true,
          then: Yup.string().required('Required'),
        }),
      }),
    }),
  })

  const fields = ({
    errors,
    touched,
  }: FormikProps<NewUserForm>): RegistrationField[] =>
    [
      {
        name: 'user.username',
        label: 'Username',
        error:
          getIn(touched, 'user.username') && getIn(errors, 'user.username'),
        type: 'text',
      },
      {
        name: 'user.password',
        label: 'Password',
        error:
          getIn(touched, 'user.password') && getIn(errors, 'user.password'),
        type: 'password',
      },
      {
        name: 'user.passwordConfirmation',
        label: 'Password Confirmation',
        error:
          getIn(touched, 'user.passwordConfirmation') &&
          getIn(errors, 'user.passwordConfirmation'),
        type: 'password',
      },
      {
        name: 'user.profile.badge',
        label: data.location.config.badgeLabel,
        error:
          getIn(touched, 'user.profile.badge') &&
          getIn(errors, 'user.profile.badge'),
        type: 'text',
        hide: !formValues.showBadgeField,
      },
      {
        name: 'user.profile.phoneNumber',
        label: 'Phone #',
        error:
          getIn(touched, 'user.profile.phoneNumber') &&
          getIn(errors, 'user.profile.phoneNumber'),
        type: 'phoneNumber',
        hide: !formValues.showPhoneNumber,
      },
    ].filter((field): boolean => !field.hide)

  return (
    <RegistrationStep
      validationSchema={ validationSchema }
      asyncValidation={ validateCredentials }
      asyncValidationKey='validateCredentials'
      validationLoading={ loading }
    >
      {(formikProps: FormikProps<NewUserForm>): JSX.Element => (
        <Fragment>
          {fields(formikProps).map(
            (field, i): JSX.Element => (
              <FormTextField field={ field } key={ field.name } autoFocus={ !i } />
            ),
          )}
        </Fragment>
      )}
    </RegistrationStep>
  )
}

export default CredentialsStep
