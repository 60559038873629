import Dumb from './BookedEventDialog'
import React, { SyntheticEvent } from 'react'
import { DialogPropsComponent, DialogPropsQuery } from 'types/graphql'

interface Props {
  closeDialog(event: SyntheticEvent<{}>): void;
}

const BookedEventDialog = (props: Props): JSX.Element => (
  <DialogPropsComponent>
    {({ data }): JSX.Element => {
      const {
        dialogProps: { event },
      } = data as DialogPropsQuery
      return <Dumb event={ event } { ...props } />
    }}
  </DialogPropsComponent>
)

export default BookedEventDialog
