import Dumb from './MenuNavLinks'
import React from 'react'
import { useNavLinksQueryQuery } from 'types/graphql'
import ErrorMessage from 'components/ErrorMessage'

const MenuNavLinks = (): JSX.Element | null => {
  const { data, loading } = useNavLinksQueryQuery()
  if (loading) return null
  if (!data) return <ErrorMessage />

  return <Dumb data={ data } />
}

export default MenuNavLinks
