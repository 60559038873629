import React, { createContext, useState } from 'react'
import MobileMenuIcon from 'material-ui/svg-icons/navigation/menu'
import IconButton from 'material-ui/IconButton'
import Drawer from '@material-ui/core/Drawer'
import MobileMenu from 'components/navigation/MobileMenu'
import { keyHandler } from 'lib/a11y'

export const MobileMenuContext = createContext({
  closeMobileMenu: (): void => {},
})

const MobileMenuButton = (): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)

  const toggleDrawer = (open: boolean): (() => void) => (): void => {
    if (open) setOpened(true)
    setOpen(open)
  }

  return (
    <MobileMenuContext.Provider
      value={ {
        closeMobileMenu: toggleDrawer(false),
      } }
    >
      <IconButton
        onClick={ toggleDrawer(true) }
        onKeyDown={ keyHandler(toggleDrawer(true)) }
        aria-label='Mobile menu'
      >
        <MobileMenuIcon />
      </IconButton>
      <Drawer
        anchor='right'
        open={ open }
        onClose={ toggleDrawer(false) }
        keepMounted={ opened }
      >
        <MobileMenu />
      </Drawer>
    </MobileMenuContext.Provider>
  )
}

export default MobileMenuButton
