import React from 'react'
import AvatarWithUpload from 'components/avatars/AvatarWithUpload'
import { FetchResult } from 'apollo-link'
import { EditDependentMutationFn } from 'types/graphql'

export interface Props {
  id: number;
  uploadAvatar: EditDependentMutationFn;
  avatar: string;
  canUpload: boolean;
}

const DependentAvatar = (props: Props): JSX.Element => (
  <AvatarWithUpload
    { ...props }
    uploadAvatar={ (profileImage: string): Promise<void | FetchResult> =>
      props.uploadAvatar({
        variables: { id: props.id, dependentParams: { profileImage } },
      })
    }
  />
)

export default DependentAvatar
