// https://github.com/apollographql/eslint-plugin-graphql/issues/99
import gql from 'graphql-tag'
import { sleep } from 'lib/helpers'
import defaults from './defaults'

export default {
  Mutation: {
    updateTour: (obj, { run, stepIndex }, { cache }) => {
      const query = gql`
        query {
          tour @client {
            run
            stepIndex
          }
        }
      `

      const data = cache.readQuery({ query })
      const newRun = typeof run === 'boolean' ? run : data.tour.run
      const newStepIndex =
        typeof stepIndex === 'number' ? stepIndex : data.tour.stepIndex

      cache.writeData({
        data: {
          tour: {
            run: newRun,
            stepIndex: newStepIndex,
            __typename: 'Tour',
          },
        },
      })
      return null
    },

    advanceTour: async(obj, _, { cache }) => {
      const query = gql`
        query {
          tour @client {
            run
            stepIndex
          }
        }
      `
      const {
        tour: { run, stepIndex },
      } = cache.readQuery({ query })
      if (!run) return null

      await sleep(300)
      cache.writeData({
        data: {
          tour: {
            stepIndex: stepIndex + 1,
            __typename: 'Tour',
          },
        },
      })
      return null
    },

    updateTourStatus: (obj, _, { cache }) => {
      const query = gql`
        query {
          viewer {
            id
            completedTour
          }
        }
      `
      const {
        viewer: { completedTour },
      } = cache.readQuery({ query })
      if (!completedTour) {
        cache.writeData({
          data: {
            tour: {
              run: true,
              __typename: 'Tour',
            },
          },
        })
      }
      return null
    },

    openDialog: (obj, { dialogComponent, dialogProps = {} }, { cache }) => {
      cache.writeData({
        data: {
          dialogComponent,
          dialogProps: {
            ...defaults.dialogProps,
            ...dialogProps,
            service: {
              ...defaults.dialogProps.service,
              ...dialogProps.service,
            },
            event: {
              ...defaults.dialogProps.event,
              ...dialogProps.event,
            },
          },
        },
      })
      return null
    },

    closeDialog: (obj, _, { cache }) => {
      cache.writeData({
        data: {
          dialogComponent: null,
          dialogProps: defaults.dialogProps,
        },
      })
      return null
    },

    updateVideoSearch: (obj, { videoSearch }, { cache }) => {
      cache.writeData({
        data: { videoSearch },
      })
      return null
    },

    setRefreshGfcTable: (obj, { refreshGfcTable }, { cache }) => {
      cache.writeData({
        data: { refreshGfcTable },
      })
      return null
    },
  },
}
