import React from 'react'
import useStyles from './styles'
import classNames from 'classnames'
import { CircularProgress } from '@material-ui/core'

export interface Props {
  size?: number;
  fullHeight?: boolean;
  center?: boolean;
  className?: string;
  containerClass?: string;
}

const Spinner = ({
  size = 50,
  center,
  fullHeight,
  containerClass,
  className,
}: Props): JSX.Element => {
  const classes = useStyles(size)

  return (
    <span
      data-testid='active-spinner'
      className={ classNames(
        center ? classes.container : classes.block,
        fullHeight && classes.fullHeight,
        containerClass
      ) }
    >
      <CircularProgress
        className={ classNames(center && classes.center, className) }
        size={ size || 50 }
      />
    </span>
  )
}

export default Spinner
