// TODO: use components/Calendar here
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Calendar from 'react-big-calendar'
import { Card, CardText } from 'material-ui/Card'
import moment from 'moment-timezone'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withStyles from '@material-ui/core/styles/withStyles'
import EventWrapper from 'components/Events/EventWrapper'
import { withTheme } from '@material-ui/core/styles'
import { calendarLocalizer } from 'lib/helpers'

const styles = {
  calendar: {
    height: 'calc(100vh - 165px)',
  },
  fetching: {
    opacity: 0.6,
  },
}

// react-big-calendar sometimes provides an object w/ start and end dates,
// and sometimes an array of dates.
const dateRange = dates => {
  let range
  if (dates.start) {
    range = { after: dates.start, before: dates.end }
  } else {
    range = { after: dates[0], before: dates[dates.length - 1] }
  }

  // Always display complete days
  range.after = moment(range.after)
    .startOf('day')
    .toDate()
  range.before = moment(range.before)
    .endOf('day')
    .toDate()

  return range
}

class EventsCalendar extends Component {
  state = {
    fetching: false,
  }

  updateQuery = (prev, { fetchMoreResult }) => {
    this.setState({ fetching: false })
    return fetchMoreResult
  }

  updateEvents = async dates => {
    this.setState({ fetching: true })

    return this.props.fetchMore({
      variables: dateRange(dates),
      updateQuery: this.updateQuery,
    })
  }

  render() {
    const { events, classes, timezone } = this.props

    return (
      <Card>
        <CardText>
          <Calendar
            defaultDate={ new Date() }
            localizer={ calendarLocalizer(timezone) }
            defaultView='week'
            eventPropGetter={ event => {
              return {
                style: {
                  borderColor: 'gray',
                  backgroundColor:
                    event.type === 'AppointmentBooking' ?
                      this.props.theme.palette.secondary.dark :
                      this.props.theme.palette.primary.main,
                },
              }
            } }
            events={ events }
            startAccessor={ event => moment(event.startTime).toDate() }
            endAccessor={ event => moment(event.endTime).toDate() }
            views={ ['day', 'week', 'month'] }
            className={ classNames(
              classes.calendar,
              this.state.fetching && classes.fetching
            ) }
            components={ {
              eventWrapper: EventWrapper,
            } }
            onRangeChange={ dates => this.updateEvents(dates) }
            scrollToTime={ moment()
              .startOf('day')
              .set('hour', 6)
              .toDate() } // Scroll to 6 am instead of 12 am
          />
        </CardText>
      </Card>
    )
  }
}

EventsCalendar.propTypes = {
  timezone: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  fetchMore: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string,
      }).isRequired,
      secondary: PropTypes.shape({
        dark: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
export default withStyles(styles)(withTheme(EventsCalendar))
