import gql from 'graphql-tag'
import { bewellItem } from 'fragments'

export const BEWELL_LANDING_QUERY = gql`
  query bewellLanding($isToday: Boolean!) {
    currentServerDate
    viewer {
      id
      bewell {
        points
        dayDisplay(isToday: $isToday)
        dayNumber(isToday: $isToday)
        cutoff
      }
      location {
        id
        timezone
      }
    }
  }
`

export const BEWELL_BOARD_QUERY = gql`
  query bewellBoard {
    viewer {
      id
      location {
        id
        bewell {
          categories {
            id
            title
            color
            bewellItems {
              ...bewellItem
            }
          }
        }
      }
    }
  }
  ${bewellItem}
`

export const BEWELL_STONES_QUERY = gql`
  query bewellStones {
    viewer {
      id
      location {
        id
        bewell {
          categories {
            id
            title
            color
            isStoneEarned
          }
          stonesEnabled
          totalWellnessStoneLabel
          totalWellnessStoneColor
        }
      }
    }
  }
`
