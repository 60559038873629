import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles(({ spacing }: Theme) =>
  createStyles({
    formControl: {
      marginRight: spacing(1),
      minWidth: 200,
      float: 'left',
    },
    chipContainer: {
      height: spacing(6),
      display: 'flex',
      float: 'left',
      alignItems: 'flex-end',
      marginRight: spacing(2),
    },
  })
)
