import Dumb from './ProfileMedicalTab'
import {
  useAddAllergyMutation,
  useAddMedicalConditionMutation,
  useMedicalQuery,
  useRemoveAllergyMutation,
  useRemoveMedicalConditionMutation,
} from 'types/graphql'
import Spinner from 'components/Spinner'
import React from 'react'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'

const ProfileMedicalTab = () => {
  const [addAllergy] = useAddAllergyMutation()
  const [removeAllergy] = useRemoveAllergyMutation()
  const [addMedicalCondition] = useAddMedicalConditionMutation()
  const [removeMedicalCondition] = useRemoveMedicalConditionMutation()
  const { data, loading, error } = useMedicalQuery()

  if (loading) return <Spinner center />
  if (!data || error) return <ErrorMessage error={ error } />

  return (
    <Dumb
      addAllergy={ addAllergy }
      removeAllergy={ removeAllergy }
      addMedicalCondition={ addMedicalCondition }
      removeMedicalCondition={ removeMedicalCondition }
      data={ data }
    />
  )
}

export default ProfileMedicalTab
