import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import AddCreditCardForm from 'components/AddCreditCardForm'

import { StripeApiKeyQuery } from 'types/graphql'

interface Props {
  data: StripeApiKeyQuery;
}

const AddCreditCardDialog = (props: Props): JSX.Element | null => {
  if (!props.data.viewer.location.stripeApiKey) return null

  return (
    <div>
      <StripeProvider apiKey={ props.data.viewer.location.stripeApiKey }>
        <Elements>
          <AddCreditCardForm { ...props } />
        </Elements>
      </StripeProvider>
    </div>
  )
}

export default AddCreditCardDialog
