import moment from 'moment'
import { SHORT_DATE_FORMAT, TIME_FORMAT } from 'lib/formats'
import { Dispatch, SetStateAction } from 'react'

const formatDate = (date: string): string =>
  moment(date).format(SHORT_DATE_FORMAT)

const formatTime = (time: string): string => moment(time).format(TIME_FORMAT)

export interface EventDatesProps {
  startDate: string;
  endDate: string;
  startTimedate: string;
  endTimedate: string;
}

export const eventDates = (event: EventDatesProps): string => {
  const startDate = formatDate(event.startDate)
  const endDate = formatDate(event.endDate)
  const startTime = formatTime(event.startTimedate)
  const endTime = formatTime(event.endTimedate)
  const tz = moment().format('z')
  return `${startDate} - ${endDate} / ${startTime} - ${endTime} ${tz}`
}

export interface Tstruct {
  viewer: {
    id: number;
    location: {
      id: number;
      timezone: string;
      limitedGfcSchedules: [];
      appointmentCalendarEntries: [];
    };
    upcomingAppointmentBookings: [];
  };
}

export function updateQuery<T extends Tstruct>(
  setHasMore: Dispatch<SetStateAction<boolean>>,
  key: 'limitedGfcSchedules' | 'appointmentCalendarEntries'
): (prev: T, { fetchMoreResult }: { fetchMoreResult?: T }) => T {
  return function(prev: T, { fetchMoreResult }: { fetchMoreResult?: T }): T {
    if (!fetchMoreResult || !fetchMoreResult.viewer.location[key]) return prev
    if (!fetchMoreResult.viewer.location[key].length) {
      setHasMore(false)
    }
    return Object.assign({}, prev, {
      viewer: {
        ...prev.viewer,
        location: {
          ...prev.viewer.location,
          [key]: [
            ...prev.viewer.location[key],
            ...fetchMoreResult.viewer.location[key],
          ],
        },
      },
    })
  }
}
