import Dumb from './StaffInfoDialog'
import React from 'react'
import {
  DialogPropsQuery,
  StaffQuery,
  useDialogPropsQuery,
  useStaffQuery,
} from 'types/graphql'

export interface Props {
  closeDialog: () => void;
}

const StaffInfoDialog = (props: Props): JSX.Element | null => {
  const { data: dialogData } = useDialogPropsQuery()

  const {
    dialogProps: { id: staffId, ...dialogProps },
  } = dialogData as DialogPropsQuery
  const { loading, error, data } = useStaffQuery({ variables: { staffId } })

  if (loading || error) return null

  const {
    viewer: {
      location: { staff },
    },
  } = data as StaffQuery
  return <Dumb staff={ staff } { ...props } { ...dialogProps } />
}

export default StaffInfoDialog
