import Dumb from './RegisterForEventButton'
import React from 'react'
import {
  UpcomingEventRegistrationsDocument,
  useRegisterForEventMutation,
} from 'types/graphql'

export interface Props {
  event: {
    id: number;
    price?: string | null;
  };
  slot: {
    startTime: string;
    openSpotsDisplay: string;
  };
}

const RegisterForEventButton = ({
  event: { id: eventId, price },
  slot: { startTime, openSpotsDisplay },
}: Props) => {
  const [registerForEvent, { loading }] = useRegisterForEventMutation({
    variables: { eventId, startTime },
    refetchQueries: [
      {
        query: UpcomingEventRegistrationsDocument,
      },
    ],
  })
  // disabled is a bit hacky, but it's a nice little enhancment
  return (
    <Dumb
      price={ price }
      loading={ loading }
      registerForEvent={ registerForEvent }
      disabled={ openSpotsDisplay.substr(0, 1) === '0' }
    />
  )
}

export default RegisterForEventButton
