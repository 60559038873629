import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { DAY_AND_DATE_FORMAT, TIME_FORMAT } from 'lib/formats'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import LoadingButton from 'components/LoadingButton'

const styles = () => ({
  buttonContainer: {
    marginTop: 10,
  },
  bookButton: {
    marginLeft: 10,
  },
})

const ConfirmationStep = props => {
  const {
    registerForAppt,
    back,
    selectedLength,
    selectedTime,
    price,
    classes,
    loading,
  } = props
  return (
    <div>
      <Typography variant='h5'>Book Appointment?</Typography>
      <Typography variant='subtitle1'>
        <strong>Date</strong>:{' '}
        {moment(selectedTime).format(DAY_AND_DATE_FORMAT)}
        <br />
        <strong>Time</strong>: {moment(selectedTime).format(TIME_FORMAT)} -
        {' ' +
          moment(selectedTime)
            .add(selectedLength, 'minutes')
            .format(TIME_FORMAT)}
        <br />
        {price && (
          <span>
            <strong>Price</strong>: {price}
          </span>
        )}
      </Typography>
      <Grid container className={ classes.buttonContainer }>
        <Button onClick={ back }>Back</Button>
        <LoadingButton
          loading={ loading }
          onClick={ registerForAppt }
          className={ classes.bookButton }
        >
          Book
        </LoadingButton>
      </Grid>
    </div>
  )
}

ConfirmationStep.propTypes = {
  classes: PropTypes.object.isRequired,
  registerForAppt: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  selectedLength: PropTypes.number,
  selectedTime: PropTypes.string,
  price: PropTypes.string,
  loading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(ConfirmationStep)
