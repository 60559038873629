import React from 'react'
import useStyles from './styles'

export interface Props {
  title: string;
  subtitle?: string;
  body: string;
}

const DashboardCardItem = ({ title, subtitle, body }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={ classes.container }>
      <div>
        <p className={ classes.title }>{title}</p>
        <p className={ classes.subtitle }>{subtitle}</p>
        <p className={ classes.body }>{body}</p>
      </div>
    </div>
  )
}

export default DashboardCardItem
