import React from 'react'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import useStyles from './styles'
import { CloseDialogMutationFn } from 'types/graphql'

export interface Props {
  closeDialog: CloseDialogMutationFn;
  gutterRight?: boolean;
}

const CloseDialogButton = ({
  closeDialog,
  gutterRight = false,
}: Props): JSX.Element => {
  const handleClick = (): void => {
    closeDialog()
  }
  const classes = useStyles()

  return (
    <Button
      onClick={ handleClick }
      variant='contained'
      className={ classNames(gutterRight && classes.gutterRight) }
    >
      Close
    </Button>
  )
}

export default CloseDialogButton
