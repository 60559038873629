import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  buttonContainer: {
    textAlign: 'center',
    position: 'relative',
    top: '30px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
})
