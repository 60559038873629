import React, { useState } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import SelectCompanyStep from 'components/registration/SelectCompanyStep'
import NameAndEmailStep from '../NameAndEmailStep'
import StepContent from '@material-ui/core/StepContent'
import SelectLocationStep from 'components/registration/SelectLocationStep'
import CredentialsStep from 'components/registration/CredentialsStep'
import CreditCardStep from 'components/registration/CreditCardStep'
import ConditionsDialog from 'components/registration/ConditionsDialog'
import AdditionalInfoStep from 'components/registration/AdditionalInfoStep'
import SubLocationStep from 'components/registration/SubLocationStep'
import { useApolloClient } from '@apollo/react-hooks'
import { ExecutionResult } from 'apollo-link'
import { RouteComponentProps } from 'react-router'

import { PaymentsEnum } from 'types/graphql'
import { RegisterUserMutationFn } from 'types/graphql-auth'
import {
  nullifyBlanksDeep,
  RegistrationContext,
} from 'components/registration/helpers'
import EmergencyContactStep from 'components/registration/EmergencyContactStep'
import VerifyUserStep from 'components/registration/VerifyUserStep'

let values = {
  user: {
    profile: {
      firstName: '',
      lastName: '',
      badge: '',
      phoneNumber: '',
      emergencyFirstName: '',
      emergencyLastName: '',
      emergencyPhone: '',
      emergencyRelation: 0,
    },
    email: '',
    companyId: 0,
    locationId: 0,
    subLocationId: 0,
    username: '',
    password: '',
    passwordConfirmation: '',
  },
  stripeCardToken: '',
  customUserFields: [],
  verifiedUserId: 0,

  locationPayments: null,
  showBadgeField: false,
  showPhoneNumber: false,
  showAdditionalInfo: false,
  showSubLocations: false,
  subLocationsLabel: '',
  emergencyContactRequired: false,
  showVerification: false,
}

export interface Step {
  label: string;
  Component: React.ElementType;
}

export interface Props extends RouteComponentProps {
  registerUserMutation: RegisterUserMutationFn;
}

const RegistrationStepper = ({
  registerUserMutation,
  history: { push },
}: Props): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0)
  const [formValues, setFormValues] = useState(values)
  const [formComplete, setFormComplete] = useState(false)
  const { resetStore } = useApolloClient()

  const steps = [
    {
      label: 'Name and Email',
      Component: NameAndEmailStep,
    },
    {
      label: 'Company',
      Component: SelectCompanyStep,
    },
    {
      label: 'Facility Location',
      Component: SelectLocationStep,
    },
    {
      label: formValues.subLocationsLabel,
      Component: SubLocationStep,
      hide: !formValues.showSubLocations,
    },
    {
      label: 'Credentials',
      Component: CredentialsStep,
    },
    {
      label: 'User Verification',
      Component: VerifyUserStep,
      hide: !formValues.showVerification,
    },
    {
      label: 'Additional Info',
      Component: AdditionalInfoStep,
      hide: !formValues.showAdditionalInfo,
    },
    {
      label: 'Emergency Contact Info',
      Component: EmergencyContactStep,
      hide: !formValues.emergencyContactRequired,
    },
    {
      label: 'Payment Method',
      Component: CreditCardStep,
      hide: formValues.locationPayments === PaymentsEnum.Disabled,
    },
  ]

  const visibleSteps = steps.filter((step): boolean => !step.hide)

  const renderStep = ({ label, Component }: Step): JSX.Element => (
    <Step key={ label }>
      <StepLabel>{label}</StepLabel>
      <StepContent>
        <Component />
      </StepContent>
    </Step>
  )

  const registerUser = (): Promise<void> => {
    return registerUserMutation({
      variables: {
        user: nullifyBlanksDeep(formValues.user),
        stripeCardToken: formValues.stripeCardToken,
        customUserFields: formValues.customUserFields,
        verifiedUserId: formValues.verifiedUserId,
      },
    }).then((res: ExecutionResult): void => {
      if (
        res &&
        res.data &&
        res.data.registerUser &&
        res.data.registerUser.success
      ) {
        // refresh apollo
        resetStore().then((): void => {
          push('/email_verification_message')
        })
      }
    })
  }

  const onConditionsDialogClose = (): Promise<void> => {
    setActiveStep((): number => visibleSteps.length - 1)
    setFormComplete(false)
    return Promise.resolve()
  }
  return (
    <RegistrationContext.Provider
      value={ {
        activeStep,
        setActiveStep,
        formValues,
        setFormValues,
        setFormComplete,
        stepAmount: visibleSteps.length,
      } }
    >
      <Stepper activeStep={ activeStep } orientation='vertical'>
        {visibleSteps.map(renderStep)}
      </Stepper>
      {formValues.user.locationId ? (
        <ConditionsDialog
          open={ formComplete }
          locationId={ formValues.user.locationId }
          handleAgreed={ registerUser }
          handleDisagreed={ onConditionsDialogClose }
        />
      ) : (
        ''
      )}
    </RegistrationContext.Provider>
  )
}

export default RegistrationStepper
