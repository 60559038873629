import React from 'react'
import { FormControlLabel } from '@material-ui/core'
import LogoutButton from 'components/auth/LogoutButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import LoadingButton from 'components/LoadingButton'
import Checkbox from '@material-ui/core/Checkbox'
import { CloseDialogMutationFn } from 'types/graphql'
import { CardElement } from 'react-stripe-elements'

export interface Props {
  closeDialog: CloseDialogMutationFn;
  isForced?: boolean;
  message?: string;
  addCreditCardLoading: boolean;
  updateAsPrimary: () => void;
  asPrimary: boolean;
  handleSubmit: () => Promise<void>;
  showPrimaryCheckbox: boolean;
}

const AddCreditCardForm = ({
  closeDialog,
  addCreditCardLoading,
  isForced = false,
  message,
  updateAsPrimary,
  asPrimary,
  handleSubmit,
  showPrimaryCheckbox,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Dialog open onClose={ closeDialog } maxWidth='sm' fullWidth>
      <DialogContent>
        <h2 className={ classes.header }>Add Credit Card</h2>
        {message && <p>{message}</p>}
        <div className={ classes.elementContainer }>
          <CardElement className={ classes.element } />
        </div>
        {showPrimaryCheckbox && (
          <FormControlLabel
            control={
              <Checkbox
                checked={ asPrimary }
                onChange={ updateAsPrimary }
                color='primary'
              />
            }
            label='Add as Primary'
          />
        )}
      </DialogContent>
      <DialogActions>
        {isForced ? (
          <LogoutButton>
            <Button color='secondary'>Not now (logout)</Button>
          </LogoutButton>
        ) : (
          <Button
            color='secondary'
            onClick={ (): void => {
              closeDialog()
            } }
          >
            Cancel
          </Button>
        )}
        <LoadingButton onClick={ handleSubmit } loading={ addCreditCardLoading }>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddCreditCardForm
