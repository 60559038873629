import React from 'react'
import PropTypes from 'prop-types'
import { keyHandler } from 'lib/a11y'

const TourAdvance = ({ advanceTour, children }) => (
  <span
    role='button'
    tabIndex={ -1 }
    onKeyPress={ keyHandler(advanceTour) }
    onClick={ advanceTour }
    style={ { display: 'inherit' } }
  >
    {children}
  </span>
)

TourAdvance.propTypes = {
  children: PropTypes.any,
  advanceTour: PropTypes.func.isRequired,
}

export default TourAdvance
