import Dumb from './VideoDialog'
import React from 'react'
import { Query } from 'react-apollo'
import { DIALOG_PROPS_QUERY } from 'queries/local'

const VideoDialog = props => (
  <Query query={ DIALOG_PROPS_QUERY }>
    {({
      data: {
        dialogProps: { videoUrl },
      },
    }) => <Dumb videoUrl={ videoUrl } { ...props } />}
  </Query>
)

export default VideoDialog
