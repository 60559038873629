import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import FlatButton from 'material-ui/FlatButton'
import { DATE_FORMAT, TIME_FORMAT } from 'lib/formats'
import moment from 'moment'

// const csvData = [
//   ['firstname', 'lastname', 'email'],
//   ['Ahmed', 'Tomi', 'ah@smthing.co.com'],
//   ['Raed', 'Labes', 'rl@smthing.co.com'],
//   ['Yezzi', 'Min l3b', 'ymin@cocococo.com'],
// ]

const CheckinReportExport = ({ loading, data }) => {
  if (loading) return null
  const { facilityUses, gfcCheckins } = data.viewer
  const exportData = facilityUses.concat(gfcCheckins).map(record => {
    return {
      Date: moment(record.createdAt).format(DATE_FORMAT),
      Time: moment(record.createdAt).format(TIME_FORMAT),
      Type: record.gfcTitle ? 'Group Fitness Class' : 'Facility Use',
      'Facility Use Option': record.facilityUseOptionLabel,
      Class: record.gfcTitle,
    }
  })

  return (
    <CSVLink filename='Check-in Report.csv' data={ exportData }>
      <FlatButton primary>Export</FlatButton>
    </CSVLink>
  )
}

CheckinReportExport.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      facilityUses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
          facilityUseOptionLabel: PropTypes.string.isRequired,
        })
      ).isRequired,
      gfcCheckins: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
          gfcTitle: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }),
}

export default CheckinReportExport
