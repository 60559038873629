import React, { Fragment } from 'react'
import RegistrationItem from 'components/Events/RegistrationItem'
import DetailsLinkCard from 'components/DetailsLinkCard'
import { AppointmentBooking } from 'types/graphql'

export interface Props {
  registrations: [AppointmentBooking];
}

type Typename = 'DialogPropsEvent' | undefined

const Registrations = ({ registrations }: Props): JSX.Element => {
  return (
    <Fragment>
      {registrations.map(
        (registration: AppointmentBooking): JSX.Element => {
          const typename = ('DialogProps' + registration.__typename) as Typename

          return (
            <div key={ registration.id }>
              <DetailsLinkCard
                dialogComponent='BookedEventDialog'
                dialogProps={ {
                  id: registration.id,
                  event: {
                    ...registration,
                    __typename: typename,
                  },
                } }
              >
                <RegistrationItem registration={ registration } />
              </DetailsLinkCard>
            </div>
          )
        }
      )}
    </Fragment>
  )
}

export default Registrations
