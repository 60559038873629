import Dumb, { Props } from './GFCClaimSpotButton'
import React from 'react'
import { useClaimGfcSpotMutation } from 'types/graphql'
import useRefreshGfcTable from 'hooks/useRefreshGfcTable'

const GFCClaimSpotButton = (
  props: Omit<Props, 'loading' | 'claimGfcSpot'>
): JSX.Element => {
  const registrationId = props.attendee.registrationId as number

  const onCompleted = useRefreshGfcTable()
  const [claimGfcSpot, { loading }] = useClaimGfcSpotMutation({
    variables: { registrationId },
    onCompleted,
  })
  return <Dumb claimGfcSpot={ claimGfcSpot } loading={ loading } { ...props } />
}

export default GFCClaimSpotButton
