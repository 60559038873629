import { makeStyles, Theme } from '@material-ui/core/styles'

// Style the child elements with active color
export default makeStyles(({ spacing }: Theme) => ({
  container: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginBottom: spacing(),
    display: 'inline-block',
  },
  type: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
}))
