import gql from 'graphql-tag'

export const fitnessService = gql`
  fragment fitnessService on FitnessService {
    id
    title
    description
    price
    staffSpecific
    validFor
    askAvailability
    staffs {
      id
      avatar
      name
    }
  }
`

export const membership = gql`
  fragment membership on Membership {
    id
    title
    description
    price
    billingCycle
    allowUserRenewal
    autoRenew
  }
`
