import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Registration from 'layouts/Registration'
import EmailConfirmationMessage from 'layouts/EmailConfirmationMessage'
import ResetPassword from 'components/ResetPassword'
import AppLayout from 'layouts/AppLayout'
import UserUnderReview from 'layouts/UserUnderReview'
import UserUnapproved from 'layouts/UserUnapproved'
import GetRedirectedCreds from 'components/GetRedirectedCreds'
import Tour from 'components/Tour'
import Theme from 'lib/muiTheme'
import ElevationDialog from 'components/ElevationDialog'
import AuthRoute from 'layouts/AuthRoute'
import PageTitle from 'components/PageTitle'
import Login from 'components/auth/Login'

const AppRouter = () => (
  <Theme>
    <div>
      <Switch>
        <Route path='/v3-redirect/:token' component={ GetRedirectedCreds } />
        <Route
          path='/login'
          render={ () => (
            <>
              <AuthRoute>
                <PageTitle>Login</PageTitle>
                <Login />
                {/* render here too because of seperate apollo provider */}
                <ElevationDialog />
              </AuthRoute>
            </>
          ) }
        />
        <Route
          path='/under-review'
          render={ () => (
            <>
              <PageTitle>Account Under Review</PageTitle>
              <UserUnderReview />
            </>
          ) }
        />
        <Route
          path='/unapproved'
          render={ () => (
            <>
              <PageTitle>Account Unapproved</PageTitle>
              <UserUnapproved />
            </>
          ) }
        />
        <Route
          path='/register'
          render={ () => (
            <AuthRoute>
              <PageTitle>Register</PageTitle>
              <Registration />
            </AuthRoute>
          ) }
        />
        <Route
          path='/invitation/accept/:invitationToken'
          component={ () => (
            <AuthRoute>
              <PageTitle>Accept Invitation</PageTitle>
              <Registration pointsOnly />
            </AuthRoute>
          ) }
        />
        <Route
          path='/email_verification_message'
          render={ () => (
            <>
              <PageTitle>Verify Email</PageTitle>
              <EmailConfirmationMessage />
            </>
          ) }
        />
        <Route
          path='/reset-password/:token'
          render={ () => (
            <AuthRoute>
              <PageTitle>Reset Password</PageTitle>
              <ResetPassword />
              {/* render here too because of seperate apollo provider */}
              <ElevationDialog />
            </AuthRoute>
          ) }
        />
        <Route path='/' component={ AppLayout } />
      </Switch>
      <Tour />
      {/*
          here because it can't be in the profile menu - it would go away
          when the menu closes
        */}
      <ElevationDialog />
    </div>
  </Theme>
)

export default AppRouter
