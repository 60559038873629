import React from 'react'
import InlineForm from 'components/InlineForm'
import RemoveIcon from 'material-ui/svg-icons/action/delete-forever'
import IconButton from 'material-ui/IconButton'
import {
  AddAllergyMutationFn,
  AddMedicalConditionMutationFn,
  MedicalQuery,
  RemoveAllergyMutationFn,
  RemoveMedicalConditionMutationFn,
} from 'types/graphql'

import useStyles from './styles'
import { MutationFetchResult } from '@apollo/react-common'
import { Divider } from '@material-ui/core'

export interface Props {
  data: MedicalQuery;
  addAllergy: AddAllergyMutationFn;
  removeAllergy: RemoveAllergyMutationFn;
  addMedicalCondition: AddMedicalConditionMutationFn;
  removeMedicalCondition: RemoveMedicalConditionMutationFn;
}

const createListItems = (
  classes: ReturnType<typeof useStyles>
): ((item: string, onClick: () => void) => JSX.Element) => (
  item,
  onClick
): JSX.Element => (
  <li key={ item } className={ classes.li }>
    {item}
    <IconButton
      className={ classes.removeButton }
      onClick={ onClick }
      aria-label={ `Remove ${item}` }
    >
      <RemoveIcon />
    </IconButton>
  </li>
)

const ProfileMedicalTab = ({
  data: {
    viewer: {
      profile: { allergies, medicalConditions },
    },
  },
  addAllergy,
  removeAllergy,
  addMedicalCondition,
  removeMedicalCondition,
}: Props): JSX.Element => {
  const classes = useStyles()

  const listItem = createListItems(classes)

  return (
    <div className={ classes.container }>
      <h2 className={ classes.tabHeader }>Allergies</h2>
      <ul className={ classes.ul }>
        {allergies &&
          allergies.map(
            (allergy): JSX.Element =>
              listItem(allergy, (): void => {
                removeAllergy({ variables: { allergy } })
              })
          )}
      </ul>
      <InlineForm
        name='allergy'
        hintText='Add Allergy'
        formName='addAllergies'
        handleSubmit={ (allergy: string): Promise<MutationFetchResult> =>
          addAllergy({ variables: { allergy } })
        }
        shouldResetForm
      />
      <Divider className={ classes.divider } />

      <h2 className={ classes.tabHeader }>Medical Conditions</h2>
      <ul className={ classes.ul }>
        {medicalConditions &&
          medicalConditions.map(
            (medicalCondition): JSX.Element =>
              listItem(medicalCondition, (): void => {
                removeMedicalCondition({ variables: { medicalCondition } })
              })
          )}
      </ul>
      <InlineForm
        name='medicalCondition'
        hintText='Add Medical Condition'
        formName='addMedicalCondition'
        handleSubmit={ (
          medicalCondition: string
        ): Promise<MutationFetchResult> =>
          addMedicalCondition({ variables: { medicalCondition } })
        }
        shouldResetForm
      />
    </div>
  )
}

export default ProfileMedicalTab
