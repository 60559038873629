import gql from 'graphql-tag'
import { dependent, guest, partner, partners } from 'fragments'

export const FAMILY_TABS_QUERY = gql`
  query familyTabs {
    viewer {
      id
      location {
        id
        config {
          id
          dependentsEnabled
          guestsEnabled
          partnersEnabled
        }
      }
    }
  }
`

export const DEPENDENT_QUERY = gql`
  query dependent($dependentId: Int) {
    viewer {
      id
      dependent(dependentId: $dependentId) {
        ...dependent
      }
      location {
        id
        config {
          id
          dependentChildMinAge
        }
      }
    }
  }
  ${dependent}
`

export const DEPENDENT_WAIVER_QUERY = gql`
  query dependentWaiver {
    viewer {
      id
      location {
        id
        companyPolicy {
          id
          dependentWaiver
        }
      }
    }
  }
  ${dependent}
`

export const DEPENDENTS_QUERY = gql`
  query dependentsQuery {
    viewer {
      id
      dependents {
        ...dependent
      }
      location {
        id
        config {
          id
          usersCanEditDependents
        }
      }
    }
  }
  ${dependent}
`

export const PARTNER_INVITATION_QUERY = gql`
  query partnerInvitation {
    viewer {
      id
      pendingPartnerRequestFrom {
        ...partner
      }
      partner {
        ...partner
      }
    }
  }
  ${partner}
`

export const PARTNER_QUERY = gql`
  query partner {
    viewer {
      id
      ...partners
    }
  }
  ${partners}
`

export const PARTNER_CONFIG_QUERY = gql`
  query partnerConfig {
    viewer {
      id
      location {
        id
        config {
          id
          allowExternalPartners
        }
      }
    }
  }
`

export const GUESTS_QUERY = gql`
  query guestsQuery {
    viewer {
      id
      guests {
        ...guest
      }
    }
  }
  ${guest}
`

export const GUEST_QUERY = gql`
  query guest($guestId: Int) {
    guestEmergencyRelations
    viewer {
      id
      location {
        id
        config {
          id
          requireGuestEmergencyContacts
        }
      }
      guest(guestId: $guestId) {
        ...guest
      }
    }
  }
  ${guest}
`
