import Dumb from './RenameCreditCardDialog'
import React from 'react'
import {
  DialogPropsQuery,
  useCardLabelQuery,
  useDialogPropsQuery,
  useRenameCreditCardMutation,
} from 'types/graphql'
import ErrorDialog from 'components/ErrorDialog'
import { Form, Formik } from 'formik'
import { ExecutionResult } from 'apollo-link'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
})

const RenameCreditCardDialog = (): JSX.Element | null => {
  const { data: dialogData } = useDialogPropsQuery()
  const {
    dialogProps: { id: cardId },
  } = dialogData as DialogPropsQuery
  const { data, loading, error } = useCardLabelQuery({
    variables: { cardId },
  })
  const [rename, { loading: renameLoading }] = useRenameCreditCardMutation()

  if (loading) return null

  if (!data) return <ErrorDialog errorMessage={ error && error.message } />

  const initialValues = {
    label: data.viewer.card.label,
  }

  const handleSubmit = ({
    label,
  }: {
    label: string;
  }): Promise<ExecutionResult> => {
    return rename({ variables: { label, cardId } })
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ handleSubmit }
      validationSchema={ validationSchema }
    >
      <Form>
        <Dumb submitLoading={ renameLoading } />
      </Form>
    </Formik>
  )
}

export default RenameCreditCardDialog
