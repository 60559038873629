import React, { useCallback, useContext, useState } from 'react'
import { CompanyQuery, useLocationUpdatesLazyQuery } from 'types/graphql-auth'
import { FormikProps } from 'formik'
import { NewUserForm } from 'types'
import FormSelectField from 'components/registration/FormSelectField'
import { Context, SelectOption } from 'components/registration/types'
import { RegistrationContext } from 'components/registration/helpers'

export interface Props {
  data: CompanyQuery;
  formikProps: FormikProps<NewUserForm>;
}

const SelectLocationStep = ({
  data: {
    company: { locations },
  },
  formikProps,
}: Props): JSX.Element => {
  const [skipped, setSkipped] = useState(false)

  const [getLocationUpdates, { data }] = useLocationUpdatesLazyQuery()

  const { formValues, setFormValues, setActiveStep } = useContext(
    RegistrationContext,
  ) as Context

  // we don't use event type so we can call it programatically
  const handleChange = useCallback(
    (e: { target: { value: number } }): void => {
      getLocationUpdates({ variables: { locationId: e.target.value } })
    },
    [getLocationUpdates],
  )

  React.useEffect((): void => {
    // If there's only one option, select it
    if (locations.length === 1 && !formikProps.values.user.locationId) {
      formikProps.setFieldValue('user', { locationId: locations[0].id })
      handleChange({ target: { value: locations[0].id } })
      setSkipped(true)
    }

    if (
      data &&
      data.location &&
      formValues.user.locationId !== data.location.id
    ) {
      const { setFieldValue } = formikProps
      const {
        id,
        payments,
        company,
        config,
        customUserFields,
        subLocations,
      } = data.location
      const showVerification = company.requiredUserVerificationFields.filter(
        (field): boolean => field.name !== 'email',
      ).length
      setFieldValue('locationPayments', payments)
      setFieldValue('showBadgeField', company.badgeRequired)
      setFieldValue('showPhoneNumber', config.phoneNumberRequired)
      setFieldValue('showAdditionalInfo', customUserFields.length)
      setFieldValue('showSubLocations', subLocations.length)
      setFieldValue('subLocationsLabel', config.subLocationsLabel)
      setFieldValue('showVerification', showVerification)
      setFieldValue(
        'emergencyContactRequired',
        company.emergencyContactRequired,
      )
      setFormValues({
        ...formValues,
        user: { ...formValues.user, locationId: id },
        locationPayments: payments,
        showBadgeField: company.badgeRequired,
        showPhoneNumber: config.phoneNumberRequired,
        showAdditionalInfo: customUserFields.length,
        showSubLocations: subLocations.length,
        subLocationsLabel: config.subLocationsLabel,
        emergencyContactRequired: company.emergencyContactRequired,
        showVerification,
      })
      if (skipped) {
        setActiveStep((prevActiveStep: number): number => prevActiveStep + 1)
      }
    }
  }, [
    locations,
    formikProps,
    data,
    handleChange,
    setFormValues,
    formValues,
    skipped,
    setActiveStep,
  ])

  return (
    <FormSelectField
      label='Choose Location'
      // only focus on the field if there are options
      autoFocus={ locations.length > 1 }
      onChangeProp={ handleChange }
      options={ locations.map(
        ({ id, city }): SelectOption => ({ id, name: city }),
      ) }
      name='user.locationId'
    />
  )
}

export default SelectLocationStep
