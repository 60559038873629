import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { getPathLabel } from 'lib/helpers'
import { NavLink } from 'react-router-dom'
import useStyles from './styles'
import { MobileMenuContext } from 'components/navigation/MobileMenuButton/MobileMenuButton'

// Problem: https://material-ui.com/guides/composition/#caveat-with-refs
// Solution: https://material-ui.com/components/links/#third-party-routing-library
const AdapterLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ ref } { ...props } />
))

const MenuNavLink = ({ item, advanceTour }) => {
  const { closeMobileMenu } = useContext(MobileMenuContext)

  const classes = useStyles()
  const key = item.to || item.label

  const handleClick = () => {
    advanceTour()
    closeMobileMenu && closeMobileMenu()
  }

  return (
    <ListItem
      button
      id={ key.replace('/', 'link-') }
      component={ AdapterLink }
      onClick={ handleClick }
      activeClassName={ classes.active }
      to={ item.to }
      exact={ item.to === '/' }
    >
      <ListItemIcon className='nav-menu-content'>{item.icon}</ListItemIcon>
      <ListItemText
        className='nav-menu-content'
        primary={ item.label || getPathLabel(item.to) }
      />
    </ListItem>
  )
}

MenuNavLink.propTypes = {
  item: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.element,
  }).isRequired,
  advanceTour: PropTypes.func.isRequired,
}

export default MenuNavLink
