import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardMedia, CardTitle } from 'material-ui/Card'
import Grid from '@material-ui/core/Grid'
import DialogLink from 'components/DialogLink'
import IconButton from 'material-ui/IconButton'
import InfoIcon from 'material-ui/svg-icons/action/info'
import EmailIcon from 'material-ui/svg-icons/communication/email'
import { blue200, blue300, green200, green300 } from 'material-ui/styles/colors'

const styles = {
  card: {
    width: '100%',
    marginBottom: '10px',
    overflow: 'hidden',
  },
  overlayContent: {
    padding: 0,
    height: '80px',
    transition: 'height .3s',
  },
  title: {
    position: 'static',
  },
  iconButtonWrapper: {
    position: 'absolute',
    top: '7px',
    right: '10px',
    display: 'flex',
  },
  iconButton: {
    opacity: 0.8,
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  about: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
}

class StaffList extends Component {
  render() {
    return (
      <Grid container spacing={ 2 }>
        {this.props.staffs &&
          this.props.staffs.map(staffMember => (
            <Grid item xs={ 12 } sm={ 6 } lg={ 4 } key={ staffMember.id }>
              <Card style={ styles.card }>
                <CardMedia
                  overlay={
                    <CardTitle
                      title={ staffMember.name }
                      style={ styles.title }
                      subtitle={
                        <div>
                          {staffMember.position}
                          <div style={ styles.iconButtonWrapper }>
                            <DialogLink
                              dialogComponent='StaffInfoDialog'
                              dialogProps={ { id: staffMember.id } }
                              trigger={
                                <IconButton
                                  style={ styles.iconButton }
                                  tooltip='More Info'
                                  tooltipPosition='top-center'
                                  iconStyle={ styles.icon }
                                  aria-label={ `More Info for ${staffMember.name}` }
                                >
                                  <InfoIcon
                                    color={ green200 }
                                    hoverColor={ green300 }
                                  />
                                </IconButton>
                              }
                            />

                            <DialogLink
                              dialogComponent='ContactStaffDialog'
                              dialogProps={ { id: staffMember.id } }
                              trigger={
                                <IconButton
                                  style={ styles.iconButton }
                                  tooltip='Contact'
                                  tooltipPosition='top-center'
                                  iconStyle={ styles.icon }
                                  aria-label={ `Contact ${staffMember.name}` }
                                >
                                  <EmailIcon
                                    color={ blue200 }
                                    hoverColor={ blue300 }
                                  />
                                </IconButton>
                              }
                            />
                          </div>
                        </div>
                      }
                    />
                  }
                  overlayContentStyle={ {
                    ...styles.overlayContent,
                  } }
                >
                  <img src={ staffMember.avatar } alt='Staff Member' />
                </CardMedia>
              </Card>
            </Grid>
          ))}
      </Grid>
    )
  }
}

StaffList.propTypes = {
  staffs: PropTypes.array,
}

export default StaffList
