import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader } from 'material-ui/Card'
import moment from 'moment'
import { DATE_FORMAT_OFFICIAL, TIME_FORMAT } from 'lib/formats'

import EventActions from '../EventActions'

const style = {
  card: {
    marginBottom: '15px',
    position: 'relative',
  },
  header: {
    display: 'flex',
  },
  headerText: {
    paddingRight: '10px',
  },
}

export const EventSlotContext = createContext({ description: '' })

const EventSlot = props => {
  const {
    slot: { startTime, endTime, openSpotsDisplay },
  } = props

  const title = () => {
    const date = moment(startTime).format(DATE_FORMAT_OFFICIAL)
    const start = moment(startTime).format(TIME_FORMAT)
    const end = moment(endTime).format(TIME_FORMAT + ' z')
    return `${date}, ${start} - ${end}`
  }

  const description = `${title()}, ${openSpotsDisplay} spots available`

  return (
    <Card style={ style.card } containerStyle={ style.cardContainer }>
      <CardHeader
        title={ title() }
        subtitle={ `${openSpotsDisplay} spots available` }
        style={ style.header }
        textStyle={ style.headerText }
      >
        <EventSlotContext.Provider value={ { description } }>
          <EventActions
            { ...props }
            style={ style.actions }
            description={ description }
          />
        </EventSlotContext.Provider>
      </CardHeader>
    </Card>
  )
}

EventSlot.propTypes = {
  slot: PropTypes.shape({
    id: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    openSpotsDisplay: PropTypes.string.isRequired,
  }),
}

export default EventSlot
