import React, { Component, ReactNode } from 'react'
import { trackError } from 'lib/analytics'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import styles from './styles'

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorReporter extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error): void {
    trackError(error)
  }

  public render(): ReactNode {
    const { hasError } = this.state
    const { classes } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Grid container justify='center' className={ classes.container }>
          <Grid item md={ 3 } xs={ 8 }>
            <img
              className={ classes.logo }
              src='/images/elevation-logo.png'
              alt='elevation-logo'
            />
          </Grid>
          <Grid item xs={ 12 }>
            <Typography align='center'>Something went wrong :(</Typography>
            <Typography align='center'>
              Please try again momentarily. If the problem persists, please
              contact your facility manager.
            </Typography>
          </Grid>
        </Grid>
      )
    }

    return this.props.children
  }
}

export default withStyles(styles)(ErrorReporter)
