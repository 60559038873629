import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import AcceptPartnerInvitation from 'components/family/AcceptPartnerInvitation'

const useStyles = makeStyles({
  paper: {
    padding: 25,
  },
})

const Family = ({ match }) => {
  const classes = useStyles()

  return (
    <Paper className={ classes.paper }>
      <Switch>
        <Route
          exact
          path={ `${match.path}/respond-to-partnership` }
          component={ AcceptPartnerInvitation }
        />
        <Redirect to='/' />
      </Switch>
    </Paper>
  )
}

Family.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
}

export default Family
