import Dumb from './EventsCalendar'
import React from 'react'
import moment from 'moment-timezone'
import Spinner from 'components/Spinner'

import { Query } from 'react-apollo'
import PropTypes from 'prop-types'

const renameType = events => {
  return events.map(event => {
    return {
      ...event,
      type: event.__typename,
    }
  })
}

const EventsCalendar = ({ query, eventKey }) => (
  <Query
    query={ query }
    variables={ {
      after: moment(Date.now())
        .startOf('week')
        .toDate(),
      before: moment(Date.now())
        .endOf('week')
        .toDate(),
    } }
  >
    {({ data, loading, fetchMore }) => {
      if (loading) return <Spinner center fullHeight />

      let events = data.viewer.location[eventKey]
      const { timezone } = data.viewer.location

      // If present, we add booked appointments to the events
      if (eventKey === 'appointmentCalendarEntries') {
        events = events.concat(
          renameType(data.viewer.upcomingAppointmentBookings)
        )
      }
      return <Dumb events={ events } fetchMore={ fetchMore } timezone={ timezone } />
    }}
  </Query>
)

EventsCalendar.propTypes = {
  query: PropTypes.object.isRequired,
  eventKey: PropTypes.string.isRequired,
}

export default EventsCalendar
