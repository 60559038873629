import gql from 'graphql-tag'
import { gfcSchedule } from 'fragments'

export const CLASS_REGISTRATION_QUERY = gql`
  query classRegistration($classRegistrationId: Int!) {
    viewer {
      id
      classRegistration(classRegistrationId: $classRegistrationId) {
        id
        status
        attendeeId
        attendeeType
        gfcSchedule {
          id
          availableSpots
          lastRegistration {
            id
            updatedAt
          }
          ...gfcSchedule
        }
      }
    }
  }
  ${gfcSchedule}
`

export const CLASSES_REGISTERED_TO_QUERY = gql`
  query classesRegisteredTo {
    viewer {
      id
      registeredClasses {
        id
        status
        gfcSchedule {
          ...gfcSchedule
        }
      }
    }
  }
  ${gfcSchedule}
`

export const UNREGISTERED_GFC_SCHEDULES_QUERY = gql`
  query unregisteredGfcSchedules {
    viewer {
      id
      unregisteredGfcSchedules {
        ...gfcSchedule
      }
    }
  }
  ${gfcSchedule}
`

export const GFC_SCHEDULES_QUERY = gql`
  query gfcSchedules($after: ISO8601DateTime, $before: ISO8601DateTime) {
    viewer {
      id
      location {
        id
        timezone
        gfcSchedules(after: $after, before: $before) {
          ...gfcSchedule
        }
      }
    }
  }
  ${gfcSchedule}
`

export const LIMITED_GFC_SCHEDULES_QUERY = gql`
  query limitedGfcSchedules($offset: Int, $filters: GfcFiltersInput) {
    viewer {
      id
      location {
        id
        timezone
        limitedGfcSchedules(offset: $offset, filters: $filters) {
          ...gfcSchedule
        }
      }
    }
  }
  ${gfcSchedule}
`

export const GFC_SCHEDULE_QUERY = gql`
  query gfcSchedule($id: Int!) {
    gfcSchedule(id: $id) {
      ...gfcSchedule
    }
  }
  ${gfcSchedule}
`

export const GFC_FILTER_OPTIONS_QUERY = gql`
  query gfcFilterOptions {
    viewer {
      id
      location {
        id
        gfcFilterOptions {
          instructor
          title
        }
      }
    }
  }
`
