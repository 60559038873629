import Dumb from './EventSlotsDialog'
import React from 'react'
import {
  DialogPropsComponent,
  DialogPropsQuery,
  CloseDialogMutationFn,
} from 'types/graphql'

const EventSlotsDialog = ({
  closeDialog,
}: {
  closeDialog: CloseDialogMutationFn;
}): JSX.Element => (
  <DialogPropsComponent>
    {({ data }): JSX.Element => {
      const {
        dialogProps: { event },
      } = data as DialogPropsQuery
      return <Dumb event={ event } closeDialog={ closeDialog } />
    }}
  </DialogPropsComponent>
)

export default EventSlotsDialog
