import React from 'react'
import { Form, FormikProps, getIn } from 'formik'
import { RegistrationField } from 'components/registration/types'
import FormTextField from 'components/registration/FormTextField'
import LoadingButton from 'components/LoadingButton'
import { Typography } from '@material-ui/core'
import useStyles from './styles'
import { PointsOnlyUser } from './index'

const fields = ({
  errors,
  touched,
}: FormikProps<PointsOnlyUser>): RegistrationField[] => [
  {
    name: 'user.profile.firstName',
    label: 'First Name',
    error:
      getIn(touched, 'user.profile.firstName') &&
      getIn(errors, 'user.profile.firstName'),
    type: 'text',
  },
  {
    name: 'user.profile.lastName',
    label: 'Last Name',
    error:
      getIn(touched, 'user.profile.lastName') &&
      getIn(errors, 'user.profile.lastName'),
    type: 'text',
  },
  {
    name: 'user.username',
    label: 'Username',
    error: getIn(touched, 'user.username') && getIn(errors, 'user.username'),
    type: 'text',
  },
  {
    name: 'user.password',
    label: 'Password',
    error: getIn(touched, 'user.password') && getIn(errors, 'user.password'),
    type: 'password',
  },
  {
    name: 'user.passwordConfirmation',
    label: 'Password Confirmation',
    error:
      getIn(touched, 'user.passwordConfirmation') &&
      getIn(errors, 'user.passwordConfirmation'),
    type: 'password',
  },
]

export interface Props {
  formikProps: FormikProps<PointsOnlyUser>;
  loading: boolean;
}

const PointsOnlyRegistration = ({
  formikProps,
  loading,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Form>
      <Typography className={ classes.welcomeText } variant='h5'>
        Welcome to Elevation!
      </Typography>
      <Typography className={ classes.welcomeText } variant='body1'>
        Register below to gain access to your portal
      </Typography>
      {fields(formikProps).map(
        (field): JSX.Element => (
          <FormTextField field={ field } key={ field.name } />
        ),
      )}
      <LoadingButton
        loading={ loading }
        variant='contained'
        color='primary'
        type='submit'
      >
        Register
      </LoadingButton>
    </Form>
  )
}

export default PointsOnlyRegistration
