import Dumb from './EditContactInfoDialog'
import React from 'react'
import {
  useContactInfoQuery,
  useEditUserContactInfoMutation,
} from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorDialog from 'components/ErrorDialog'
import { Form, Formik } from 'formik'
import { ExecutionResult } from 'apollo-link'

const EditContactInfoDialog = (): JSX.Element => {
  const { data, loading, error } = useContactInfoQuery()
  const [
    editUser,
    { loading: editUserLoading },
  ] = useEditUserContactInfoMutation()

  if (loading) {
    return <Spinner center />
  }

  if (!data) {
    return <ErrorDialog errorMessage={ error && error.message } />
  }

  const { profile } = data.viewer

  const initialValues = {
    profile: {
      city: profile.city || '',
      state: profile.state || '',
      zip: profile.zip || '',
      phoneNumber: profile.phoneNumber || '',
      workPhone: profile.workPhone || '',
      contactEmail: profile.contactEmail || '',
    },
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ ({ profile }): Promise<ExecutionResult> =>
        editUser({ variables: { profile } })
      }
    >
      <Form>
        <Dumb
          editUserLoading={ editUserLoading }
          usersCanEditAddress={ data.viewer.location.config.usersCanEditAddress }
          primaryEmail={ data.viewer.email }
        />
      </Form>
    </Formik>
  )
}

export default EditContactInfoDialog
