// IE11 polyfill for es6 features, such as Symbols
import React from 'react'
import 'react-app-polyfill/stable'
import AppRouter from 'lib/router'
import * as ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { unregister } from './registerServiceWorker'
import { ApolloProvider } from 'react-apollo'
import createApolloClient from 'lib/apolloClient'
import history from 'lib/history'
import 'globals.css'
import 'animations.css'
import 'react-credit-cards/lib/styles-compiled.css'
import 'typeface-roboto'
import UserFeedbackProvider from 'components/UserFeedbackProvider'
import GAListener from 'components/GAListener'
import ErrorReporter from 'components/ErrorReporter'

// if (process.env.NODE_ENV !== 'production') {
//   axe(React, ReactDOM, 1000)
// }

const backend = process.env.REACT_APP_BACKEND_ADDR
const uri = backend ? `${backend}/api/v3` : '/api/v3'

ReactDOM.render(
  <ApolloProvider client={ createApolloClient(uri) }>
    <BrowserRouter>
      <GAListener trackingId={ process.env.REACT_APP_GA_TRACKING_ID }>
        <ErrorReporter>
          <UserFeedbackProvider>
            <AppRouter history={ history } />
          </UserFeedbackProvider>
        </ErrorReporter>
      </GAListener>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
)

window.portalVersion = () => '3.0.0'

// progressive web app caching
// registerServiceWorker()
// don't register until we figure out proper cache busting
unregister()
