import * as ReactGA from 'react-ga'

export const trackEvent = (args: ReactGA.EventArgs): void => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) return

  ReactGA.event(args)
}

export const trackModal = (name: string): void => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) return

  ReactGA.modalview(name)
}

export const trackError = (error: Error): void => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) return

  ReactGA.exception({
    description: error.message,
    fatal: true,
  })
}
