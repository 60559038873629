import React from 'react'
import PropTypes from 'prop-types'
import NumberPicker from 'components/NumberPicker'

const update = (fn, id, quantity) => {
  if (quantity <= 0) return
  fn({ variables: { itemId: id, quantity } })
}

const UpdateCartItemQuantity = ({ updateItemQuantity, item }) => (
  <NumberPicker
    onInc={ () => update(updateItemQuantity, item.id, item.quantity + 1) }
    onDec={ () => update(updateItemQuantity, item.id, item.quantity - 1) }
    value={ item.quantity }
  />
)

UpdateCartItemQuantity.propTypes = {
  updateItemQuantity: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
}

export default UpdateCartItemQuantity
