import Dumb from './BewellStoneDisplay'
import React from 'react'
import { Query } from 'react-apollo'
import { BEWELL_STONES_QUERY } from 'queries/bewell'

const BewellStoneDisplay = props => (
  <Query query={ BEWELL_STONES_QUERY }>
    {({ data, loading }) => {
      if (loading) return null
      return <Dumb data={ data } { ...props } />
    }}
  </Query>
)

export default BewellStoneDisplay
