import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'

export interface Props {
  submitLoading: boolean;
  staffName: string;
}

const ContactStaffDialog = ({
  submitLoading,
  staffName,
}: Props): JSX.Element => (
  <DialogForm submitLabel='Send' loading={ submitLoading }>
    <h2 style={ { fontWeight: 100 } }>Contact {staffName}</h2>
    <TextFieldInput name='subject' />
    <TextFieldInput name='message' multiline rows={ 3 } />
  </DialogForm>
)

export default ContactStaffDialog
