import React from 'react'
import { Helmet } from 'react-helmet'

export interface Props {
  children: string;
}

const PageTitle = ({ children }: Props): JSX.Element => {
  return (
    <Helmet titleTemplate='%s | Elevation Corporate Health'>
      <title>{children}</title>
    </Helmet>
  )
}

export default PageTitle
