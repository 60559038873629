import { createStyles, makeStyles } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  (): StyleRules<any, any> =>
    createStyles({
      center: {
        position: 'absolute',
        zIndex: 9999,
        top: (size: number): string => `calc(50% - ${size / 2}px)`,
        left: (size: number): string => `calc(50% - ${size / 2}px)`,
      },
      container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'inline-block',
      },
      block: {
        display: 'inline-block',
      },
      fullHeight: { height: '100vh' },
    })
)
