import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import PromoWidget from 'components/widgets/PromoWidget'
import { SHORT_DATE_FORMAT } from 'lib/formats'

const BewellWidget = ({
  bewell: {
    startDate,
    endDate,
    daysUntilStart,
    backgroundUrl,
    hasStarted,
    hasEnded,
    optedIn,
    points,
  },
  join,
  history,
}) => {
  const userHasJoined = () => Boolean(optedIn)

  const challengeIsActive = () => hasStarted && !hasEnded

  const pluralizedDay = days => `${days} ${days === 1 ? 'Day' : 'Days'}`

  const title = 'BE WELL CHALLENGE'
  const background = `url(${backgroundUrl})`
  let body
  let header
  let buttonText
  let ariaLabel
  let onClick

  if (userHasJoined()) {
    if (!hasStarted) {
      const start = moment(startDate).format(SHORT_DATE_FORMAT)
      const end = moment(endDate).format(SHORT_DATE_FORMAT)
      body = `Challenge starts on ${start} and ends on ${end}`

      header = `${pluralizedDay(daysUntilStart)} Until Challenge Begins!`
    } else if (challengeIsActive()) {
      header = `You have ${points} points!`
      buttonText = 'Log Activity'
      ariaLabel = 'Log Activity for the Be Well Challenge'
      onClick = () => {
        history.push('/be-well-challenge')
      }
    } else if (hasEnded) {
      header = `You made it! You finished with ${points} points!`
      body = 'Thanks for participating in our Be Well Challenge!'
    }
  } else {
    body = `For 30 days track physical, emotional, mental, spiritual,
        and financial activities of your choosing from the game board
        that are designed to maintain or improve your wellness.
        Each activity earns you points. Do it for yourself or do it to
        compete against other FirstBankers. Either way, you win!`
    buttonText = 'Join Now'
    onClick = join

    if (!hasStarted) {
      header = 'Want to join our upcoming wellness challenge?'
    } else if (challengeIsActive()) {
      header = `The challenge started ${pluralizedDay(
        -1 * daysUntilStart
      )} ago! It's still not too late!`
    }
  }

  return (
    <PromoWidget
      background={ background }
      title={ title }
      header={ header }
      body={ body }
      onClick={ onClick }
      buttonText={ buttonText }
      ariaLabel={ ariaLabel }
    />
  )
}

BewellWidget.propTypes = {
  bewell: PropTypes.shape({
    optedIn: PropTypes.bool.isRequired,
    points: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    daysUntilStart: PropTypes.number.isRequired,
    backgroundUrl: PropTypes.string,
    hasStarted: PropTypes.bool.isRequired,
    hasEnded: PropTypes.bool.isRequired,
  }).isRequired,
  join: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default BewellWidget
