import React from 'react'
import DialogForm from 'components/dialogForms/DialogForm'
import TextFieldInput from 'components/dialogForms/TextFieldInput'
import DateFieldInput from 'components/dialogForms/DateFieldInput'
import SelectFieldInput from 'components/dialogForms/SelectFieldInput'

export interface Props {
  editUserLoading: boolean;
  hideDob: boolean;
}

const EditBasicInfoDialog = ({
  hideDob,
  editUserLoading,
}: Props): JSX.Element => {
  return (
    <DialogForm submitLabel='Save' loading={ editUserLoading }>
      <h2 style={ { fontWeight: 100 } }>Edit Basic Info</h2>
      <TextFieldInput name='firstName' />
      <TextFieldInput name='lastName' />
      {hideDob ? <div /> : <DateFieldInput name='dateOfBirth' disableFuture />}
      <SelectFieldInput
        name='gender'
        labelWidth={ 20 }
        options={ [
          { value: 'Male', label: 'Male' },
          { value: 'Female', label: 'Female' },
          { value: 'Other', label: 'Other' },
        ] }
      />
      <TextFieldInput name='height' />
      <TextFieldInput name='weight' />
      <TextFieldInput name='bloodType' />
    </DialogForm>
  )
}

export default EditBasicInfoDialog
