import Confirmation from 'components/Confirmation/Confirmation'
import { createConfirmation } from 'react-confirm'

export interface Options {
  title?: string;
  okLabel?: string;
  cancelLabel?: string;
  showCancel?: boolean;
}

export default (
  confirmation: string | JSX.Element,
  options?: Options
): Promise<any> =>
  createConfirmation(Confirmation)({ confirmation, ...options })
