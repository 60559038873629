import Dumb from './SelectCompanyStep'
import React, { useContext } from 'react'
import { CompaniesForEmailComponent } from 'types/graphql-auth'
import { RegistrationContext } from 'components/registration/helpers'
import RegistrationStep from 'components/registration/RegistrationStep'
import { FormikProps } from 'formik'
import { NewUserForm } from 'types'
import * as Yup from 'yup'
import { Context } from 'components/registration/types'

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    companyId: Yup.number()
      .positive('Required')
      .required('Required'),
  }),
})

const SelectCompanyStep = (): JSX.Element | null => {
  const { formValues } = useContext(RegistrationContext) as Context

  if (!formValues.user.email) return null

  return (
    <CompaniesForEmailComponent variables={ { email: formValues.user.email } }>
      {({ data, loading }): JSX.Element | null => {
        if (loading) return null

        if (!data) return null

        return (
          <RegistrationStep validationSchema={ validationSchema }>
            {(formikProps: FormikProps<NewUserForm>): JSX.Element => (
              <Dumb data={ data } formikProps={ formikProps } />
            )}
          </RegistrationStep>
        )
      }}
    </CompaniesForEmailComponent>
  )
}

export default SelectCompanyStep
