import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    button: {
      marginRight: spacing(2),
      display: 'inline-block',
    },
  })
)
