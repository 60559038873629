import * as Yup from 'yup'

export default Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  emergencyFirstName: Yup.string().when('$emergencyRequired', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  emergencyLastName: Yup.string().when('$emergencyRequired', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  emergencyPhone: Yup.string().when('$emergencyRequired', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  emergencyRelation: Yup.string().when('$emergencyRequired', {
    is: true,
    then: Yup.string().required('Required'),
  }),
})
