import Dumb from './UpdateCartItemQuantity'
import React from 'react'
import { UPDATE_ITEM_QUANTITY_MUTATION } from 'mutations'
import { Mutation } from 'react-apollo'

const UpdateCartItemQuantity = props => (
  <Mutation mutation={ UPDATE_ITEM_QUANTITY_MUTATION }>
    {updateItemQuantity => (
      <Dumb updateItemQuantity={ updateItemQuantity } { ...props } />
    )}
  </Mutation>
)

export default UpdateCartItemQuantity
