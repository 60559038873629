import { NewUser } from 'types'
import { createContext, RefObject, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { stringifyObjKeys } from 'lib/helpers'

export const nullifyBlanksDeep = (user: NewUser): NewUser => {
  let clone = Object.assign({}, user)
  const propNames = Object.getOwnPropertyNames(clone)
  propNames.forEach((prop): void => {
    if (clone[prop as keyof NewUser] instanceof Object) {
      clone[prop as keyof NewUser] = nullifyBlanksDeep(
        clone[prop as keyof NewUser],
      )
    } else if (!clone[prop as keyof NewUser]) {
      delete clone[prop as keyof NewUser]
    }
  })
  return clone
}

export const RegistrationContext = createContext({})

interface FocusOnErrorParams {
  fieldRef: RefObject<{ focus: () => void }>;
  name: string;
}

// Focus on the first field with an error upon submit, good for accessibility
export const useFocusOnError = ({
  fieldRef,
  name,
}: FocusOnErrorParams): void => {
  const formik = useFormikContext()
  const prevSubmitCountRef = useRef(formik.submitCount)
  const firstErrorKey = Object.keys(stringifyObjKeys(formik.errors))[0]
  useEffect((): void => {
    if (formik.isValid) return
    if (
      prevSubmitCountRef.current !== formik.submitCount &&
      fieldRef.current &&
      // HACK: quick fix for having mismatched keys
      firstErrorKey === name.replace('optionId', 'id')
    ) {
      fieldRef.current.focus()
    }
    prevSubmitCountRef.current = formik.submitCount
  }, [
    formik.submitCount,
    formik.isValid,
    firstErrorKey,
    fieldRef,
    name,
    prevSubmitCountRef,
  ])
}
