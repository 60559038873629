import React, { useContext } from 'react'
import { CompaniesForEmailQuery } from 'types/graphql-auth'
import { FormikProps } from 'formik'
import { NewUserForm } from 'types'
import FormSelectField from '../FormSelectField'
import { Context } from 'components/registration/types'
import { RegistrationContext } from 'components/registration/helpers'

export interface Props {
  data: CompaniesForEmailQuery;
  formikProps: FormikProps<NewUserForm>;
}

// TODO: fix issue - start registering as a user in vail - silverthorne.
// after selectiing the location, go back to company. You get custom fields as
// companies and console error

const SelectCompanyStep = ({
  data: { companiesForEmail },
  formikProps,
}: Props): JSX.Element => {
  const { setActiveStep, setFormValues, formValues } = useContext(
    RegistrationContext,
  ) as Context

  // If there's only one option, select it
  React.useEffect((): void => {
    if (companiesForEmail.length === 1 && !formikProps.values.user.companyId) {
      formikProps.setFieldValue('user', { companyId: companiesForEmail[0].id })
      setFormValues({
        ...formValues,
        user: { ...formValues.user, companyId: companiesForEmail[0].id },
      })
      setActiveStep((prevActiveStep: number): number => prevActiveStep + 1)
    }
  })

  return (
    <FormSelectField
      label='Choose Company'
      options={ companiesForEmail }
      autoFocus
      name='user.companyId'
    />
  )
}

export default SelectCompanyStep
