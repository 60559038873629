import React from 'react'
import Typography from '@material-ui/core/Typography'
import DialogForm from 'components/dialogForms/DialogForm'
import DependentWaiverDialog from 'components/family/DependentWaiverDialog'
import TextFieldInput from 'components/dialogForms/TextFieldInput'
import DateFieldInput from 'components/dialogForms/DateFieldInput'

export interface Props {
  submitLoading: boolean;
  isExisting: boolean;
}

const DependentDialog = ({ submitLoading, isExisting }: Props): JSX.Element => {
  return (
    <DialogForm
      submitLabel={ isExisting ? 'Save' : 'Add' }
      loading={ submitLoading }
    >
      <h2 style={ { fontWeight: 100 } }>
        {isExisting ? 'Edit' : 'Add'} Dependent
      </h2>
      <TextFieldInput name='firstName' />
      <TextFieldInput name='lastName' />
      <DateFieldInput name='dateOfBirth' disableFuture />
      <TextFieldInput name='emergencyFirstName' />
      <TextFieldInput name='emergencyLastName' />
      <TextFieldInput name='emergencyPhone' phoneNumber />
      {isExisting ? (
        <div />
      ) : (
        <Typography variant='body2'>
          By submitting this form, you are agreeing to the waiver found{' '}
          <DependentWaiverDialog linkText='here' />.
        </Typography>
      )}
    </DialogForm>
  )
}

export default DependentDialog
