import React from 'react'

import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { InvitationFragment } from 'types/graphql'

export interface Props {
  invitation: InvitationFragment;
}

const ExistingInvitation = ({ invitation }: Props): JSX.Element => {
  return (
    <Card>
      <CardContent>
        <Grid item xs={ 12 }>
          <Typography variant='body2'>
            Your invitation to {invitation.email} is pending.
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ExistingInvitation
