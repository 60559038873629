import React, { CSSProperties, SyntheticEvent } from 'react'
import { trackModal } from 'lib/analytics'
import { OpenDialogMutationFn } from 'types/graphql'

export interface Props {
  trigger: JSX.Element;
  openDialog: OpenDialogMutationFn;
  dialogComponent: string;
  style?: CSSProperties;
  stopPropagation?: boolean;
  className?: string;
}
const DialogLink = (props: Props): JSX.Element => {
  const {
    trigger,
    openDialog,
    dialogComponent,
    stopPropagation,
    ...otherProps
  } = props

  const openDialogFunc = (e: SyntheticEvent): void => {
    openDialog()

    trackModal(dialogComponent)

    if (stopPropagation) e.stopPropagation()
  }

  const triggerProps = {
    style: {
      cursor: 'pointer',
      ...props.style,
    },
    onClick: openDialogFunc,
    ...otherProps,
  }

  return <span { ...triggerProps }>{trigger}</span>
}

export default DialogLink
