import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeWidget } from '@typeform/embed'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  container: {
    height: 'calc(100vh - 55px)',
  },
  logo: {
    width: '200px',
    margin: '10px',
  },
}

const IntakeSurvey = ({
  classes,
  complete,
  data: {
    viewer: {
      location: {
        intakeSurveyUrl,
        config: { logo },
      },
    },
  },
}) => {
  useEffect(() => {
    makeWidget(
      document.getElementById('intake-survey-container'),
      intakeSurveyUrl,
      {
        onSubmit: complete, // send results to server,
      }
    )
  })
  return (
    <div>
      <img src={ logo } alt='elevation-logo' className={ classes.logo } />
      <div id='intake-survey-container' className={ classes.container } />
    </div>
  )
}

IntakeSurvey.propTypes = {
  classes: PropTypes.object.isRequired,
  complete: PropTypes.func.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      location: PropTypes.shape({
        intakeSurveyUrl: PropTypes.string.isRequired,
        config: PropTypes.shape({
          logo: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default withStyles(styles)(IntakeSurvey)
