import { makeStyles, StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  (): StyleRules => ({
    noAccount: {
      fontSize: '0.9rem',
      textAlign: 'center',
    },
    registerLink: {
      marginLeft: '0.7rem',
    },
  })
)
