import { ApolloClient } from 'apollo-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import defaults from 'localState/defaults'
import typeDefs from 'localState/typeDefs'
import resolvers from 'localState/resolvers'
import SnackbarUtils from 'lib/SnackbarUtils'
import introspectionQueryResultData from 'types/fragmentTypes'

// headers is a function so we can test it...
export const headers = () => ({
  headers: {
    authorization: localStorage.getItem('elevation-token') || '',
  },
})

const addTokenToHeaders = setContext(() => headers())
const showFeedback = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // uncomment for debugging responses
    // console.log(response)

    // Using operationName isn't reliable in cases where the local mutation name
    // doesn't match the server's mutation name.
    // e.g. We use editUserBasicDetails locally but mutation is editUser
    // Fall back to just seeing if the first value in data has a message.
    const mutationResponse =
      response.data &&
      (response.data[operation.operationName] ||
        response.data[Object.keys(response.data)[0]])

    // if a successful mutation has a success message, display it
    if (mutationResponse && mutationResponse.message) {
      SnackbarUtils.success(mutationResponse.message)
    }

    return response
  })
})

export const handleErrors = ({ networkError = {}, graphQLErrors }) => {
  if (networkError.statusCode === 401) {
    // for some reason I can't figure out how to push location to history
    // so it refreshes here
    window.location.replace('/login')
  } else if (
    networkError.statusCode === 500 ||
    networkError.statusCode === 400
  ) {
    SnackbarUtils.error('internal server error')
  } else if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      SnackbarUtils.error(error.message)
    })
  }
}

const afterWare = onError(handleErrors)

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const cache = new InMemoryCache({
  dataIdFromObject: o => {
    // don't try to cache local types with id
    if (/DialogProps/.test(o.__typename)) {
      return null
    }
    return o.__typename + o.id
  },
  fragmentMatcher,
})

const createApolloClient = uri => {
  const httpLink = createUploadLink({ uri })
  const link = afterWare.concat(
    addTokenToHeaders.concat(showFeedback.concat(httpLink))
  )

  const client = new ApolloClient({
    link,
    cache: cache.restore(window.__APOLLO_CLIENT__),
    connectToDevTools: true,
    resolvers,
    typeDefs,
  })
  cache.writeData({ data: defaults })

  return client
}

export default createApolloClient
