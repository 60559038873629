import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import SvgIcon from '@material-ui/core/SvgIcon'
import BewellStoneSVG from 'components/bewell/BewellStoneSVG'
import BewellStoneDisplay from 'components/bewell/BewellStoneDisplay'

const styles = {
  stoneInModal: {
    display: 'block',
    width: '60px',
    height: '60px',
    margin: '10px auto',
  },
  totalWellnessStoneInModal: {
    width: '100px',
    height: '100px',
  },
  earnedStoneDialog: {
    textAlign: 'center',
  },
}

const NewStoneDialog = ({
  classes,
  handleClose,
  color,
  title,
  totalWellness,
}) => {
  const topText = totalWellness ?
    'You collected all the stones and' :
    'You just'
  const bottomText = totalWellness ?
    'You rock!' :
    'Well done! Keep up the good work!'

  return (
    <Dialog
      open
      onClose={ handleClose }
      TransitionComponent={ props => <Zoom { ...props } /> }
      className={ classes.earnedStoneDialog }
    >
      <DialogTitle disableTypography>
        <Typography variant='h4'>
          {totalWellness ? title : 'New'} Stone Earned!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {topText} earned the stone of <strong>{title}</strong>!
          {totalWellness ? (
            <BewellStoneDisplay />
          ) : (
            <SvgIcon
              alt='Stone'
              className={ classNames(
                classes.stoneInModal,
                totalWellness && classes.totalWellnessStoneInModal
              ) }
            >
              <BewellStoneSVG color={ color } earned />
            </SvgIcon>
          )}
          {bottomText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewStoneDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  totalWellness: PropTypes.bool,
}

export default withStyles(styles)(NewStoneDialog)
