import Dumb from './EditBasicInfoDialog'
import React from 'react'
import {
  useEditUserBasicDetailsMutation,
  useUserBasicDetailsQuery,
  ProfileBasicDetailsFragment,
} from 'types/graphql'
import Spinner from 'components/Spinner'
import ErrorDialog from 'components/ErrorDialog'
import { Form, Formik } from 'formik'
import { ExecutionResult } from 'apollo-link'

const initialValues = (profile: ProfileBasicDetailsFragment) => ({
  firstName: profile.firstName || '',
  lastName: profile.lastName || '',
  dateOfBirth: profile.dateOfBirth && profile.dateOfBirth,
  gender: profile.gender,
  height: profile.height || '',
  weight: profile.weight || '',
  bloodType: profile.bloodType || '',
})

const EditBasicInfoDialog = () => {
  const { data, loading, error } = useUserBasicDetailsQuery()
  const [
    editUser,
    { loading: editUserLoading },
  ] = useEditUserBasicDetailsMutation()

  if (loading) {
    return <Spinner center />
  }

  if (!data) {
    return <ErrorDialog errorMessage={ error && error.message } />
  }

  const { profile } = data.viewer

  return (
    <Formik
      initialValues={ initialValues(profile) }
      onSubmit={ (profile): Promise<ExecutionResult> =>
        editUser({ variables: { profile } })
      }
    >
      <Form>
        <Dumb
          editUserLoading={ editUserLoading }
          hideDob={ data.viewer.location.config.hideDob }
        />
      </Form>
    </Formik>
  )
}

export default EditBasicInfoDialog
