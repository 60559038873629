import React from 'react'
import AccessDenied from 'layouts/AccessDenied'

const styles = {
  icon: {
    maxWidth: '75px',
  },
  circle: {
    fill: '#3DC480',
  },
}

const icon = (): JSX.Element => (
  <svg viewBox='0 0 76 76' style={ styles.icon }>
    <circle style={ styles.circle } cx='38' cy='38' r='36' />
    <path
      fill='none'
      stroke='#FFFFFF'
      strokeWidth='5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit='10'
      d='M17.7,40.9l10.9,10.9l28.7-28.7'
    />
  </svg>
)

const EmailConfirmationMessage = (): JSX.Element => (
  <AccessDenied title='Almost Done!' icon={ icon() }>
    <p>
      Your account has been successfully created. To access your account, we
      first need to verify your email address.
    </p>
    <p>
      Please look for an email that was recently sent to you from{' '}
      <strong>Elevation Corporate Health</strong> for further instructions.
    </p>
    <p>Once your email has been verified, you can login with the link below.</p>
  </AccessDenied>
)

export default EmailConfirmationMessage
