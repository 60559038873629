import Dumb from './ShoppingCartMenuItem'
import React from 'react'
import ShoppingCartLink from 'components/ShoppingCartLink'

const ShoppingCartMenuItem = () => (
  <ShoppingCartLink>
    <Dumb />
  </ShoppingCartLink>
)

export default ShoppingCartMenuItem
