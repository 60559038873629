import Dumb from './GFCList'
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react'
import { ApolloQueryResult } from 'apollo-client'
import ErrorMessage from 'components/ErrorMessage'
import {
  LimitedGfcSchedulesQuery,
  useLimitedGfcSchedulesQuery,
} from 'types/graphql'
import ListSkeleton from 'components/ListSkeleton'
import GFCListFilters from 'components/GFC/GFCListFilters'
import { updateQuery } from 'lib/eventsHelpers'
import { initialFilters } from 'components/GFC/GFCTable/helpers'
import { FilterOptions } from 'types'

const renderLoadingSkeleton = (
  filters: FilterOptions,
  setFilters: Dispatch<SetStateAction<FilterOptions>>
): JSX.Element => (
  <Fragment>
    <GFCListFilters schedules={ [] } setFilters={ setFilters } filters={ filters } />
    <ListSkeleton count={ 10 } />
  </Fragment>
)

const GFCList = (): JSX.Element => {
  const [filters, setFilters] = useState<FilterOptions>(initialFilters)

  const [hasMore, setHasMore] = useState(true)

  const { data, loading, error, fetchMore } = useLimitedGfcSchedulesQuery({
    variables: {
      filters,
    },
  })

  if (loading) return renderLoadingSkeleton(filters, setFilters)
  if (!data) return <ErrorMessage error={ error } />

  const loadFunc = (
    offset: number
  ): Promise<ApolloQueryResult<LimitedGfcSchedulesQuery>> =>
    fetchMore({
      variables: { offset },
      updateQuery: updateQuery(setHasMore, 'limitedGfcSchedules'),
    })

  return (
    <Dumb
      data={ data }
      hasMore={ hasMore }
      loadFunc={ loadFunc }
      filters={ filters }
      setFilters={ setFilters }
    />
  )
}

export default GFCList
