import React, { SyntheticEvent } from 'react'
import LoadingButton from 'components/LoadingButton'
import AuthScreen from 'layouts/AuthScreen'
import { getUrlParameter } from 'lib/helpers'
import TextFieldInput from 'components/dialogForms/TextFieldInput'
import { useFormikContext } from 'formik'
import ForgotPasswordLink from 'components/auth/ForgotPasswordLink'
import RegisterLink from 'components/auth/RegisterLink'

type Submit = (e: SyntheticEvent) => void

const submitForm = (handleSubmit: () => void): Submit => (
  e: SyntheticEvent
): void => {
  e.preventDefault()
  handleSubmit()
}

const Login = (): JSX.Element => {
  const { isSubmitting, handleSubmit } = useFormikContext()

  return (
    <AuthScreen
      header={
        <div>
          <span>Sign in to</span>
          <span style={ { color: '#78D8B7' } }> Elevation</span>
        </div>
      }
      handleSubmit={ submitForm(handleSubmit) }
    >
      <TextFieldInput name='login' label='Username or Email' />
      <TextFieldInput name='password' type='password' />
      <LoadingButton loading={ isSubmitting } type='submit'>
        Login
      </LoadingButton>
      <ForgotPasswordLink />
      <RegisterLink hideSignUp={ getUrlParameter('no_signup') } />
    </AuthScreen>
  )
}

export default Login
