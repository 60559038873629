import React from 'react'
import { DATE_FORMAT } from 'lib/formats'
import { timeRangeWithTimeZone } from 'lib/helpers'
import moment from 'moment'
import RegistrationStatus from 'components/GFC/RegistrationStatus'
import DetailsLinkCard from 'components/DetailsLinkCard'
import DashboardCardItem from 'components/DashboardCardItem'
import { GfcScheduleFragment } from 'types/graphql'

// a more elegant solution is coming with mui4:
// https://github.com/mui-org/material-ui/pull/13503
const boxShadow = (shadow: string) => {
  return `0px 1px 3px 0px rgba(${shadow},0.2),
   0px 1px 1px 0px rgba(${shadow},0.14),
   0px 2px 1px -1px rgba(${shadow},0.12)`
}

const boxShadowStyles = (spotsAvailable: boolean) => {
  if (spotsAvailable) {
    return { boxShadow: boxShadow('92, 107, 192') }
  } else {
    return { boxShadow: boxShadow('175, 180, 43') }
  }
}

export interface Props {
  gfcSchedule: GfcScheduleFragment;
}

const GFCDigestItem = ({ gfcSchedule }: Props): JSX.Element => {
  const {
    id,
    title,
    eventDate,
    spotsAvailable,
    startDateTime,
    endDateTime,
    instructor,
    attending,
  } = gfcSchedule

  return (
    <DetailsLinkCard
      cardStyle={ boxShadowStyles(spotsAvailable) }
      dialogComponent='GFCDetailsDialog'
      dialogProps={ { id } }
    >
      <DashboardCardItem
        title={ `${title} with ${instructor}` }
        body={ `${moment(eventDate).format(DATE_FORMAT)},${' '}
        ${timeRangeWithTimeZone(startDateTime, endDateTime)} (${
      spotsAvailable ? 'open' : 'full'
    })` }
      />
      <RegistrationStatus attending={ attending } margins />
    </DetailsLinkCard>
  )
}

export default GFCDigestItem
