import Dumb from './BewellBoard'
import React from 'react'
import { Query } from 'react-apollo'
import { BEWELL_BOARD_QUERY } from 'queries/bewell'
import Spinner from 'components/Spinner'

const BewellBoard = props => (
  <Query
    query={ BEWELL_BOARD_QUERY }
    // get fresh data every 30 minutes. see ET-1273.
    pollInterval={ 30 * 60 * 1000 }
  >
    {({ data, loading }) => {
      if (loading) return <Spinner fullHeight center />
      return <Dumb data={ data } { ...props } />
    }}
  </Query>
)

export default BewellBoard
