import {
  SetRefreshGfcTableMutation,
  useSetRefreshGfcTableMutation,
} from 'types/graphql'
import { ExecutionResult } from 'apollo-link'

type SetRefreshFn = () => Promise<ExecutionResult<SetRefreshGfcTableMutation>>

const useRefreshGfcTable = (): SetRefreshFn => {
  const [setRefresh] = useSetRefreshGfcTableMutation({
    variables: { refreshGfcTable: true },
  })

  return (): Promise<ExecutionResult<SetRefreshGfcTableMutation>> =>
    setRefresh()
}

export default useRefreshGfcTable
