import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import LoadingButton from 'components/LoadingButton'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useStyles from './styles'
import { useCloseDialogMutation } from 'types/graphql'
import { ExecutionResult } from 'apollo-link'
import { useFormikContext } from 'formik'

export interface Props {
  children: JSX.Element[];
  submitLabel: string;
  loading: boolean;
}

const DialogForm = ({ loading, submitLabel, children }: Props): JSX.Element => {
  const classes = useStyles()
  const [closeDialog] = useCloseDialogMutation()
  const { submitForm, validateForm } = useFormikContext()

  const handleSave = async(): Promise<ExecutionResult | null> => {
    // submit form doesn't trigger validation if no fields are touched
    const errors = await validateForm()
    await submitForm()
    if (Object.keys(errors).length) return null
    return closeDialog()
  }

  return (
    <Dialog open onClose={ closeDialog }>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='default'
          onClick={ (): Promise<ExecutionResult> => closeDialog() }
        >
          Cancel
        </Button>
        <LoadingButton
          loading={ loading }
          onClick={ handleSave }
          className={ classes.action }
        >
          {submitLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogForm
