import React from 'react'
import { trackEvent } from 'lib/analytics'
import LoadingButton from 'components/LoadingButton'
import { CancelGfcRegistrationMutationFn } from 'types/graphql'
import { ButtonProps } from '@material-ui/core/Button'
import { MapStatusToActionsProps } from 'types'

export interface Props extends ButtonProps, MapStatusToActionsProps {
  cancelRegistration: CancelGfcRegistrationMutationFn;
  loading: boolean;
}

const GFCCancelButton = ({
  cancelRegistration,
  style,
  onSuccess,
  children,
  loading,
}: Props): JSX.Element => (
  <LoadingButton
    color='secondary'
    style={ style }
    loading={ loading }
    onClick={ (): void => {
      trackEvent({
        category: 'GFC',
        action: 'Unregistered',
      })
      cancelRegistration().then((): void => {
        onSuccess && onSuccess()
      })
    } }
    secondary
  >
    {children}
  </LoadingButton>
)

export default GFCCancelButton
