import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import moment from 'moment'
import { DATE_TIME_FORMAT } from 'lib/formats'
import GFCClaimSpotButton from 'components/GFC/GFCClaimSpotButton'
import UnableToClaimSpot from 'components/classRegistrations/UnableToClaimSpot'
import GFCDetails from 'components/GFC/GFCDetails'
import { isOnlyUser } from 'lib/helpers'

import Spinner from 'components/Spinner'

const styles = {
  buttonContainer: {
    marginTop: 8,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}

const ClaimOpenSpot = ({ classes, loading, data, onClaimed }) => {
  if (loading) return <Spinner center />

  const registration = data.viewer.classRegistration

  if (!registration) {
    return (
      <UnableToClaimSpot
        title={ "Woops! We can't find that registration." }
        message={ 'The class may have been cancelled.' }
      />
    )
  }

  const {
    id: gfcScheduleId,
    title,
    availableSpots,
    attending,
    lastRegistration,
  } = registration.gfcSchedule

  const isCancelled = () => registration.status === 'cancelled'
  const isRegistered = () => registration.status === 'registered'
  const showAttending =
    !isOnlyUser(attending) && Boolean(availableSpots) && !isCancelled()

  const classDetails = () => (
    <GFCDetails
      gfcSchedule={ registration.gfcSchedule }
      showAttending={ showAttending }
    />
  )

  if (isRegistered()) {
    return (
      <UnableToClaimSpot
        title='Good News!'
        message={ `You're already registered for ${title}.` }
        classDetails={ classDetails() }
      />
    )
  } else if (isCancelled()) {
    return (
      <UnableToClaimSpot
        title='Sorry...'
        message='It looks like this class or registration has been cancelled.'
        classDetails={ classDetails() }
      />
    )
  } else if (!availableSpots) {
    return (
      <UnableToClaimSpot
        title={ `Sorry. ${title} is currently full...` }
        message={ `Last open spot was taken on ${moment(
          lastRegistration.updatedAt
        ).format(DATE_TIME_FORMAT)}` }
        classDetails={ classDetails() }
      />
    )
  } else {
    return (
      <div>
        <Typography variant='h4'>Claim Spot for {title}?</Typography>

        <Divider className={ classes.divider } />

        {classDetails()}

        <div className={ classes.buttonContainer }>
          {!showAttending && (
            <GFCClaimSpotButton
              component={ Button }
              variant='outlined'
              gfcScheduleId={ gfcScheduleId }
              attendeeId={ attending[0].attendeeId }
              attendeeType={ attending[0].attendeeType }
              onSuccess={ onClaimed }
            >
              Claim Spot
            </GFCClaimSpotButton>
          )}
          <Button component={ Link } to='/'>
            Cancel
          </Button>
        </div>
      </div>
    )
  }
}

ClaimOpenSpot.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      classRegistration: PropTypes.shape({
        status: PropTypes.string.isRequired,
        attendeeId: PropTypes.number.isRequired,
        attendeeType: PropTypes.string.isRequired,
        gfcSchedule: PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          eventDate: PropTypes.string.isRequired,
          formattedTime: PropTypes.string.isRequired,
          instructor: PropTypes.string.isRequired,
          classLocation: PropTypes.string,
          availableSpots: PropTypes.number.isRequired,
          attending: PropTypes.arrayOf(
            PropTypes.shape({
              attendeeId: PropTypes.number.isRequired,
              attendeeType: PropTypes.string.isRequired,
            }).isRequired
          ).isRequired,
          gfc: PropTypes.shape({
            description: PropTypes.string.isRequired,
          }).isRequired,
          lastRegistration: PropTypes.shape({
            updatedAt: PropTypes.string.isRequired,
          }),
        }).isRequired,
      }),
    }),
  }),
  onClaimed: PropTypes.func.isRequired,
}

export default withStyles(styles)(ClaimOpenSpot)
