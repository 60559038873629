import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import DashboardWidget from 'components/widgets/DashboardWidget'
import Grid from '@material-ui/core/Grid'
import { Card, CardText, CardHeader } from 'material-ui/Card'

const style = {
  centerLight: {
    textAlign: 'center',
    fontWeight: '100',
    marginTop: '10px',
  },
  info: {
    borderTop: '3px solid #0abf22',
  },
  infoHeader: {
    fontSize: '18px',
    fontWeight: '300',
  },
  infoText: {
    fontSize: '1.4em',
    fontWeight: '180',
    whiteSpace: 'pre-wrap',
  },
}

const FacilityInfo = props => {
  if (props.loading) {
    return <Spinner fullWidth center />
  }
  const {
    address,
    city,
    state,
    zip,
    name,
    hoursOfOperation,
    phoneNumber,
    management,
    managementContact,
    generalInformation,
  } = props.location

  const widgets = [
    {
      title: 'Address',
      subTitle: `${address ? address + ', ' : ''}${city} ${state} ${zip || ''}`,
      show: true,
    },
    {
      title: 'Hours',
      subTitle: hoursOfOperation,
    },
    {
      title: 'Phone Number',
      subTitle: phoneNumber,
    },
    {
      title: 'Manager',
      subTitle: managementContact,
      child: <h2 style={ style.centerLight }>{management}</h2>,
    },
  ]
  return (
    <div>
      <h1 style={ style.centerLight }>{name || city}</h1>
      <Grid container spacing={ 2 }>
        {widgets.map(widget =>
          widget.show || widget.subTitle ? (
            <DashboardWidget
              title={ widget.title }
              subTitle={ widget.subTitle }
              key={ widget.title }
            >
              {widget.child}
            </DashboardWidget>
          ) : (
            ''
          )
        )}
      </Grid>
      {generalInformation && (
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Card style={ style.info }>
              <CardHeader titleStyle={ style.infoHeader } title='General Info' />
              <CardText style={ style.infoText }>{generalInformation}</CardText>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

FacilityInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object,
}

export default FacilityInfo
