import React, { ChangeEvent, Fragment } from 'react'
import { Form, Formik } from 'formik'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import LoadingButton from 'components/LoadingButton'
import { InvitePartnerMutationFn, PartnerConfigQuery } from 'types/graphql'
import useStyles from './styles'

interface Props {
  inviteLoading: boolean;
  data: PartnerConfigQuery;
  invitePartner: InvitePartnerMutationFn;
}

const InvitePartner = ({
  inviteLoading,
  data,
  invitePartner,
}: Props): JSX.Element => {
  const classes = useStyles()
  const allowExternal = data.viewer.location.config.allowExternalPartners

  return (
    <Formik
      initialValues={ {
        email: '',
        external: false,
      } }
      onSubmit={ (values): void => {
        invitePartner({
          variables: values,
        })
      } }
    >
      {({ values, setFieldValue }): JSX.Element => {
        return (
          <Form data-testid='invite-partner-form'>
            <Grid container justify='center' className={ classes.root }>
              <Typography variant='subtitle2' className={ classes.alignLeft }>
                Linking account with a domestic partner allows you to share
                dependents and/or reward points. An email will be sent to them
                for verification. Please enter your partner's email associated
                with their Elevation portal account.
              </Typography>
              <TextField
                id='partners-email-field'
                label={ "Partner's Email" }
                className={ classes.textField }
                value={ values.email }
                onChange={ (e: ChangeEvent<HTMLInputElement>): void =>
                  setFieldValue('email', e.target.value)
                }
              />
              {allowExternal ? (
                <Fragment>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='external-partner-checkbox'
                        onChange={ (e: ChangeEvent<HTMLInputElement>): void =>
                          setFieldValue('external', e.target.checked)
                        }
                        value='external'
                        color='primary'
                      />
                    }
                    label='Is External Partner'
                  />
                  <Typography
                    variant='caption'
                    className={ classes.alignLeft }
                    gutterBottom
                  >
                    External partners may be non-employees and are not required
                    to have verified credentials. They will be given limited
                    access to this portal.
                  </Typography>
                </Fragment>
              ) : null}
              <LoadingButton loading={ inviteLoading } type='submit'>
                Invite Partner
              </LoadingButton>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default InvitePartner
