import Dumb from './Partner'
import React from 'react'
import Spinner from 'components/Spinner'
import { usePartnerQuery } from 'types/graphql'
import ErrorMessage from 'components/ErrorMessage'

const Partner = (): JSX.Element => {
  const { data, loading, error } = usePartnerQuery()
  if (loading) return <Spinner center size={ 20 } />
  if (!data || error) return <ErrorMessage error={ error } />

  return <Dumb data={ data } />
}

export default Partner
