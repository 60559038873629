import React, { forwardRef, RefObject, SyntheticEvent } from 'react'
import LoadingButton from 'components/LoadingButton'
import AddIcon from '@material-ui/icons/AddShoppingCart'
import useStyles from '../styles'

interface Props {
  loading: boolean;
  onClick: (e: SyntheticEvent) => void;
}

const AddServiceToCart = (
  { loading, onClick }: Props,
  ref:
  | ((instace: HTMLButtonElement | null) => void)
  | RefObject<HTMLButtonElement>
  | null
): JSX.Element => {
  const classes = useStyles()

  return (
    <LoadingButton
      ref={ ref }
      loading={ loading }
      onClick={ onClick }
      className={ classes.addToCartButton }
    >
      <AddIcon />
      Add To Cart
    </LoadingButton>
  )
}

export default forwardRef(AddServiceToCart)
