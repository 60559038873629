import Dumb from './TimeStep'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import Spinner from 'components/Spinner'

import { AVAILABLE_APPT_TIMES } from 'queries'

const TimeStep = props => {
  const {
    event: { eventId, startTime, endTime },
    onComplete,
    back,
    selectedLength,
  } = props

  // Stepper may render this before length is available
  if (!selectedLength) return ''

  return (
    <Query
      query={ AVAILABLE_APPT_TIMES }
      variables={ {
        apptId: eventId,
        startTime,
        endTime,
        length: selectedLength,
      } }
    >
      {({ data, loading }) => {
        if (loading) return <Spinner center />

        const times = data.viewer.location.appointment.availableTimes
        return <Dumb times={ times } onComplete={ onComplete } back={ back } />
      }}
    </Query>
  )
}

TimeStep.propTypes = {
  event: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
  }),
  onComplete: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  selectedLength: PropTypes.number,
}

export default TimeStep
