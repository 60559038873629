import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Divider from 'material-ui/Divider'
import FileDownload from '@material-ui/icons/SaveAlt'
import Fab from '@material-ui/core/Fab'
import moment from 'moment/moment'
import JsPdf from 'jspdf'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'

const styles = {
  container: {
    float: 'right',
  },
  downloadBtn: {
    width: '28px',
    height: '28px',
    minWidth: 0,
  },
  header: {
    textAlign: 'center',
  },
  logo: {
    width: '125px',
    marginTop: '10px',
  },
  table: {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    color: '#8c8c8c',
    border: '1px #bbb solid',
    borderSpacing: '0',
  },
  tableCell: {
    padding: '5px',
    color: '#8c8c8c',
    border: '1px #bbb solid',
    borderSpacing: '0',
    textAlign: 'center',
  },
}

const dateFormat = 'MM/DD/YYYY, h:mm a'

class TransactionReceipt extends Component {
  constructor(props) {
    super()

    this.state = {
      open: false,
    }
    this.order = props.order
    this.viewer = props.viewer
  }

  downloadPdf() {
    const doc = new JsPdf('portrait', 'pt')

    let element = document.getElementById(`receipt-dialog-${this.order.id}`)
    doc.fromHTML(element, 15, 15, {}, () => {
      doc.save(`transaction_receipt_${this.order.id}.pdf`)
      this.handleClose()
    })
  }

  handleOpen() {
    this.setState({ open: true })
  }

  handleClose() {
    this.setState({ open: false })
  }

  render() {
    const { state, order, viewer } = this

    return (
      <div style={ styles.container }>
        <Fab onClick={ () => this.handleOpen() } size='small' color='primary'>
          <FileDownload />
        </Fab>
        <Dialog open={ state.open } onClose={ () => this.handleClose() }>
          <DialogContent>
            <div id={ `receipt-dialog-${order.id}` }>
              <h1 style={ styles.header }>Transaction Receipt</h1>
              <Divider />

              <h2>Order #: {order.id}</h2>
              <h4>{moment(order.createdOnStripe).format(dateFormat)}</h4>
              <Divider style={ { marginBottom: '10px' } } />

              <span>
                <b>{viewer.fullName}</b>
              </span>
              <br />
              <span>{viewer.email}</span>
              <br />
              <span>{viewer.location.city}</span>
              <br />
              <span>{viewer.location.country}</span>

              <table style={ styles.table }>
                <thead>
                  <tr>
                    <th style={ styles.tableCell }>Description</th>
                    <th style={ styles.tableCell }>Last 4 of Card</th>
                    <th style={ styles.tableCell }>Quantity</th>
                    <th style={ styles.tableCell }>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={ styles.tableCell }>
                      {order.description || 'No Description Available'}
                    </td>
                    <td style={ styles.tableCell }>{order.sourceLast4}</td>
                    <td style={ styles.tableCell }>{order.quantity}</td>
                    <td style={ styles.tableCell }>{order.price}</td>
                  </tr>
                </tbody>
              </table>

              <h3>THANK YOU FOR YOUR PURCHASE</h3>
              <p>
                If you feel there is an error with your order or have questions
                about your order,
                <br />
                please contact your onsite fitness manager. For technical
                support, please contact us at
                <a href='mailto:support@contactelevation.com'> support email</a>
                .
              </p>

              <Divider style={ { marginBottom: '10px' } } />

              <div style={ styles.footer }>
                <img
                  src={ viewer.location.config.logo }
                  alt='elevation-logo'
                  style={ styles.logo }
                />
                <p>Elevation Corporate Health</p>
                <p>
                  <a href='mailto:elevationcorporatehealth.com'>
                    elevationcorporatehealth.com
                  </a>
                </p>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={ { marginRight: '5px' } }
              color='secondary'
              onClick={ () => this.handleClose() }
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={ () => this.downloadPdf() }
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

TransactionReceipt.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    sourceLast4: PropTypes.string,
    createdOnStripe: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  viewer: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      config: PropTypes.shape({
        logo: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}

export default TransactionReceipt
