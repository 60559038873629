import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import Spinner from 'components/Spinner'
import { useSnackbar } from 'notistack'

const styles = {
  profileImage: {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
  },
  overlayPosition: {
    width: '100px',
    height: '100px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  overlay: {
    background: 'rgba(44, 62, 80, 0.75)',
    boxShadow: '0 0 0 4px, 2px 1px 6px 4px rgba(10, 10, 0, 0.5)',
    transition: 'opacity .25s ease',
  },
  overlayText: {
    color: '#fff',
    fontWeight: '400',
    padding: '.2em 2em',
    lineHeight: 'normal',
  },
  hovered: {
    border: '4px dashed #fff',
  },
  container: {
    width: '100%',
    textAlign: 'center',
  },
}

const overlay = ({ uploading, hovered }) =>
  (uploading || hovered) && (
    <div
      style={ {
        ...styles.overlay,
        ...styles.overlayPosition,
        ...(hovered && !uploading ? styles.hovered : {}),
      } }
    >
      {uploading ? (
        <Spinner center />
      ) : (
        <h5 style={ styles.overlayText }>Upload Profile Picture</h5>
      )}
    </div>
  )

const AvatarWithUpload = ({
  canUpload,
  containerStyle,
  avatar,
  uploadAvatar,
}) => {
  const [hovered, setHovered] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [imgError, setImgError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const uploadPicture = (accepted, rejected) => {
    if (rejected.length) {
      enqueueSnackbar('Invalid image file', { variant: 'error' })
      return
    }

    // start spinner
    setUploading(true)
    setImgError(false)

    uploadAvatar(accepted[0]).catch(() => {
      setUploading(false)
    })
  }

  return (
    <div style={ { ...styles.container, ...containerStyle } }>
      {overlay({ uploading, hovered })}
      {canUpload ? (
        <Dropzone
          className='dropzone'
          onMouseEnter={ () => setHovered(true) }
          onMouseLeave={ () => setHovered(false) }
          onDrop={ (a, r) => uploadPicture(a, r) }
          accept='image/jpeg, image/png'
          style={ styles.overlayPosition }
          aria-label='Upload Avatar'
        />
      ) : null}
      <img
        src={ imgError ? 'images/profile-picture-fallback.png' : avatar }
        alt='profile'
        style={ styles.profileImage }
        onLoad={ () => setUploading(false) }
        onError={ () => setImgError(true) }
      />
    </div>
  )
}

AvatarWithUpload.propTypes = {
  containerStyle: PropTypes.object,
  avatar: PropTypes.string,
  uploadAvatar: PropTypes.func.isRequired,
  canUpload: PropTypes.bool.isRequired,
}

export default AvatarWithUpload
