import Dumb from './GFCTable'
import { useGfcFilterOptionsQuery } from 'types/graphql'
import React from 'react'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'
import moment from 'moment'

const GFCTable = (): JSX.Element => {
  const { data, loading, error } = useGfcFilterOptionsQuery()

  if (loading) return <Spinner center fullHeight />

  if (!data) return <ErrorMessage error={ error } />

  let filterOptions = {
    ...data.viewer.location.gfcFilterOptions,
    day: moment.weekdays(),
  }

  return <Dumb filterOptions={ filterOptions } />
}

export default GFCTable
