import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  narrowCell: {
    width: '30%',
  },
  wideCell: {
    maxWidth: 0,
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '70%',
  },
})
