import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-apollo'
import { APP_LAYOUT_QUERY } from 'queries'
import Landing from 'layouts/Landing'
import { Redirect, Route, Switch } from 'react-router-dom'
import Spinner from 'components/Spinner'
import FacilityInfo from 'components/FacilityInfo'
import Dashboard from 'components/Dashboard'
import GFC from 'layouts/GFC'
import FitnessServiceTypes from 'components/hfs/FitnessServiceTypes'
import Bewell from 'components/bewell/Bewell'
import Events from 'components/Events/Events'
import Appointments from 'components/appointments/Appointments'
import Staff from 'layouts/Staff'
import Analytics from 'layouts/Analytics'
import VideoLibrary from 'layouts/VideoLibrary'
import ClassRegistrations from 'layouts/ClassRegistrations'
import Family from 'layouts/Family'
import UpdateTourStatus from 'components/UpdateTourStatus'
import IntercomWidget from 'components/IntercomWidget'
import ConditionsDialog from 'components/ConditionsDialog'
import AddCreditCardDialog from 'components/AddCreditCardDialog'
import RedeemPointsDialog from 'components/RewardPoints/RedeemPointsDialog'
import MembershipWaiverDialog from 'components/MembershipWaiverDialog'
import IntakeSurvey from 'components/IntakeSurvey'
import moment from 'moment-timezone'
import { eventsLink } from 'lib/helpers'
import Lockers from 'components/lockers/Lockers'
import UserMessages from 'components/UserMessages'
import Biometrics from './Biometrics'
import PageTitle from 'components/PageTitle'
import { titleCase } from 'change-case'

const addCreditCardDialog = () => (
  <AddCreditCardDialog
    isOpen
    isForced
    message='You are required to have an active card on file.'
  />
)

const lapsedMemberhipDialog = () => {
  const message =
    'Your monthly membership has lapsed, ' +
    'possibly due a payment method that is no longer valid. ' +
    'Please add a valid payment method. If you think this is an error, ' +
    'please contact an administrator.'
  return <AddCreditCardDialog isOpen isForced message={ message } />
}

export const AppLayout = props => {
  const data = props.data || {}
  if (data.loading) return <Spinner center fullHeight />

  if (!data.viewer) {
    return (
      <Redirect
        to={ {
          pathname: '/login',
          state: { referrer: props.location.pathname },
        } }
      />
    )
  }

  if (data.viewer.pendingReview) {
    return <Redirect to='under-review' />
  }

  const renderRoute = ({ Component, path, condition = true, exact = false }) =>
    condition && (
      <Route
        path={ `/${path}` }
        exact={ exact }
        key={ path }
        render={ routeProps => (
          <>
            <PageTitle>{titleCase(path) || 'Dashboard'}</PageTitle>
            <Component { ...routeProps } />
          </>
        ) }
      />
    )

  const {
    bewell,
    location: {
      id: locationId,
      timezone,
      hasAppointments,
      hasEvents,
      hasGFCs,
      hasServices,
      hasStaff,
      lockers,
      config: { eventsLabel },
    },
  } = data.viewer

  moment.tz.setDefault(timezone)

  const routes = [
    { Component: Dashboard, path: '', exact: true },
    {
      Component: GFC,
      path: 'group-fitness-classes',
      condition: hasGFCs,
    },
    {
      Component: FitnessServiceTypes,
      path: 'health-fitness-services',
      condition: hasServices,
    },
    {
      Component: Events,
      path: eventsLink(eventsLabel),
      condition: hasEvents,
    },
    {
      Component: Bewell,
      path: 'be-well-challenge',
      condition: Boolean(bewell) && bewell.show,
    },
    {
      Component: Appointments,
      path: 'appointments',
      condition: hasAppointments,
    },
    {
      Component: Staff,
      path: 'staff',
      condition: hasStaff,
    },
    {
      Component: Lockers,
      path: 'lockers',
      condition: Boolean(lockers.length),
    },
    {
      Component: FacilityInfo,
      path: 'facility-info',
    },
    {
      Component: Analytics,
      path: 'analytics',
    },
    {
      Component: Biometrics,
      path: 'biometrics',
    },
    {
      Component: VideoLibrary,
      path: 'video-library',
    },
    // next two routes are coming from emails
    {
      Component: ClassRegistrations,
      path: 'class-registrations',
    },
    {
      Component: Family,
      path: 'family',
    },
  ]

  const defaultBody = () => {
    return (
      <Landing>
        <div>
          <UserMessages />
          <UpdateTourStatus />
          <IntercomWidget />
          <Switch>
            {routes.map(renderRoute)}
            <Redirect to='/' />
          </Switch>
        </div>
      </Landing>
    )
  }

  const forcedDialogs = () => {
    let forcedDialog = null

    if (!data.viewer.agreedToWaiver) {
      forcedDialog = <ConditionsDialog open locationId={ locationId } />
    } else if (data.viewer.needsToAddCard) {
      forcedDialog = addCreditCardDialog()
    } else if (data.viewer.lapsedMembership) {
      forcedDialog = lapsedMemberhipDialog()
    } else if (data.viewer.alertAboutMembershipFee) {
      forcedDialog = <MembershipWaiverDialog />
    } else if (data.viewer.pointsOnly) {
      forcedDialog = <RedeemPointsDialog isForced />
    }

    return forcedDialog
  }

  const landingBody = () => {
    // REVIEW: there must be a better place to make these checks...
    if (data.viewer.intakeSurvey.show) {
      return <IntakeSurvey />
    }

    return forcedDialogs() || defaultBody()
  }

  throw new Error('This is messed up')
}

AppLayout.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    viewer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      completedTour: PropTypes.bool.isRequired,
      lapsedMembership: PropTypes.bool.isRequired,
      needsToAddCard: PropTypes.bool.isRequired,
      alertAboutMembershipFee: PropTypes.bool.isRequired,
      intakeSurvey: PropTypes.shape({
        show: PropTypes.bool.isRequired,
      }).isRequired,
      location: PropTypes.shape({
        timezone: PropTypes.string.isRequired,
        config: PropTypes.shape({
          eventsLabel: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default graphql(APP_LAYOUT_QUERY)(AppLayout)
