import React, { Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
// https://github.com/wojtekmaj/react-pdf/issues/417
// @ts-ignore
import { Document, Page, pdfjs } from 'react-pdf'
import { DispatchResizeEvent } from 'lib/polyfills'
import { keyHandler } from 'lib/a11y'
import useStyles from './styles'

// https://github.com/wojtekmaj/react-pdf#browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface Props {
  setNumPages: (numPages: number) => void;
  pageIndex: number;
  open: boolean;
  changePage: (change: number) => void;
  handleClose: () => void;
  handleOpen: () => void;
}

const TermsDialog = ({
  setNumPages,
  pageIndex,
  open,
  changePage,
  handleClose,
  handleOpen,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Fragment>
      <span
        role='button'
        tabIndex={ 0 }
        onClick={ handleOpen }
        onKeyPress={ keyHandler(handleOpen) }
        className={ classes.button }
      >
        Terms of Service
      </span>
      <Dialog open={ open } onClose={ handleClose } maxWidth={ false }>
        <DialogContent>
          <Document
            file='https://elevation-shared.s3.amazonaws.com/terms-of-service.pdf'
            onLoadSuccess={ ({ numPages }): void => setNumPages(numPages) }
          >
            <Page
              width={ 720 }
              pageNumber={ pageIndex }
              onLoadSuccess={ DispatchResizeEvent }
            />
          </Document>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={ (): void => changePage(-1) }>
            Prev
          </Button>
          <Button variant='contained' onClick={ (): void => changePage(1) }>
            Next
          </Button>
          <Button variant='contained' color='primary' onClick={ handleClose }>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default TermsDialog
