import React, { useContext, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { Form, useFormikContext } from 'formik'
import { RegistrationContext } from 'components/registration/helpers'
import LoadingButton from 'components/LoadingButton'
import useStyles from '../styles'
import { Context } from 'components/registration/types'

export interface Props {
  children: Function;
  validationLoading?: boolean;
}

const RegistrationStep = ({
  children,
  validationLoading = false,
}: Props): JSX.Element => {
  const classes = useStyles()
  const formik = useFormikContext()
  const nextRef = useRef<HTMLButtonElement>(null)

  const { activeStep, setActiveStep } = useContext(
    RegistrationContext,
  ) as Context

  const handleBack = (): void => {
    setActiveStep((prevActiveStep: number): number => prevActiveStep - 1)
  }

  return (
    <Form>
      {children(formik)}
      <Button
        onClick={ handleBack }
        disabled={ !activeStep }
        className={ classes.stepButton }
      >
        Back
      </Button>
      <LoadingButton
        ref={ nextRef }
        loading={ validationLoading }
        variant='contained'
        color='primary'
        type='submit'
      >
        Next
      </LoadingButton>
    </Form>
  )
}

export default RegistrationStep
