import React from 'react'
import { SnackbarProvider } from 'notistack'
import { SnackbarUtilsComponent } from 'lib/SnackbarUtils'

interface Props {
  children: JSX.Element;
}

const UserFeedbackProvider = ({ children }: Props): JSX.Element => {
  return (
    <SnackbarProvider
      anchorOrigin={ {
        vertical: 'bottom',
        horizontal: 'left',
      } }
    >
      {children}
      <SnackbarUtilsComponent />
    </SnackbarProvider>
  )
}

export default UserFeedbackProvider
