import React from 'react'
import { Card, CardMedia, CardText, CardTitle } from 'material-ui/Card'
import Grid from '@material-ui/core/Grid'

import RaisedButton from 'material-ui/RaisedButton'

import FitnessService from 'components/hfs/FitnessService'
import {
  FitnessServiceService,
  FitnessServiceStaff,
} from 'components/hfs/types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createMarkup } from 'lib/helpers'

interface Service extends FitnessServiceService {
  validFor?: string | null;
}

interface Staff extends FitnessServiceStaff {
  about?: string | null;
  position?: string | null;
}

interface Props {
  closeDialog: () => void;
  itemClass?: string | null;
  service?: Service | null;
  sessions?: number | null;
  staff: Staff;
}

const StaffInfoDialog = ({
  closeDialog,
  staff,
  service,
  itemClass,
  sessions,
}: Props): JSX.Element => {
  return (
    <Dialog open onClose={ closeDialog } maxWidth='md'>
      <DialogTitle>{staff.name}</DialogTitle>
      <DialogContent>
        {service && service.id && (
          <FitnessService
            service={ service }
            expiryText={ `valid for ${service.validFor}` }
            key={ service.id }
            hasQuantity
            hasStaff
            staff={ staff }
            itemClass={ itemClass as string }
            sessions={ sessions }
          />
        )}
        <Card>
          <Grid container>
            <Grid item md={ 6 }>
              <CardMedia
                overlay={
                  <CardTitle title={ staff.name } subtitle={ staff.position } />
                }
              >
                <img src={ staff.avatar } alt={ staff.name } />
              </CardMedia>
            </Grid>
            <Grid item md={ 6 }>
              {/*
                // we get spans with styling from summernote, but because
                // we have a dark background, the colors don't work.
                // this function filters that out, to allow for the span
                // add { allowTags: false } as a second argument
              */}
              <CardText>
                <div dangerouslySetInnerHTML={ createMarkup(staff.about) } />
              </CardText>
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
      <DialogActions>
        <RaisedButton label='Close' onClick={ closeDialog } />
      </DialogActions>
    </Dialog>
  )
}

export default StaffInfoDialog
