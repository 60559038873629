import Dumb, { Props as DumbProps } from './DialogLink'
import React from 'react'
import PropTypes from 'prop-types'
import { DialogProps, useOpenDialogMutation } from 'types/graphql'

interface Props extends Omit<DumbProps, 'openDialog'> {
  dialogComponent: string;
  dialogProps?: DialogProps;
}

const DialogLink = ({
  dialogComponent,
  dialogProps,
  ...props
}: Props): JSX.Element => {
  const [openDialog] = useOpenDialogMutation({
    variables: { dialogComponent, dialogProps },
  })
  return (
    <Dumb
      openDialog={ openDialog }
      dialogComponent={ dialogComponent }
      { ...props }
    />
  )
}

DialogLink.propTypes = {
  dialogComponent: PropTypes.string.isRequired,
  dialogProps: PropTypes.object,
}

export default DialogLink
