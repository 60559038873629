import React from 'react'
import PropTypes from 'prop-types'

import { EVENT_SLOT_QUERY } from 'queries'
import RegisterForEventButton from 'components/Events/RegisterForEventButton'
import RegisteredActions from 'components/Events/RegisteredActions'

const styles = {
  container: {
    marginLeft: 'auto',
  },
}

const EventActions = props => {
  const { slot } = props
  const refetchQueries = [
    {
      query: EVENT_SLOT_QUERY,
      variables: {
        eventId: slot.eventId,
        slotId: slot.id,
      },
    },
  ]

  return (
    <div style={ styles.container }>
      {slot.eventRegistration ? (
        <RegisteredActions
          registration={ slot.eventRegistration }
          refetchQueries={ refetchQueries }
        />
      ) : (
        <RegisterForEventButton { ...props } />
      )}
    </div>
  )
}
EventActions.propTypes = {
  slot: PropTypes.shape({
    id: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired,
    eventRegistration: PropTypes.shape({
      id: PropTypes.number.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      details: PropTypes.string,
      eventLocation: PropTypes.string,
    }),
  }).isRequired,
}

export default EventActions
