import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { getStatusForMap, mapStatusToActions } from 'lib/helpers'
import useStyles from './styles'
import { AttendeeFragment } from 'types/graphql'

// If the class is for kids and the user has no child dependents we show them a
// message instead of a list of attendees
const isKidClass = (attending: AttendeeFragment[]): boolean => !attending.length

export interface Props {
  price?: string;
  gfcScheduleId: number;
  attending: AttendeeFragment[];
  spotsAvailable: boolean;
}

const AttendingList = ({
  price,
  attending,
  gfcScheduleId,
  spotsAvailable,
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div>
      <Typography>
        <strong>
          {isKidClass(attending) ?
            'This class is for children only' :
            'Who is attending?'}
        </strong>
      </Typography>
      <List dense className={ classes.attendeeList }>
        {attending.map(
          (attendee): JSX.Element => (
            <ListItem key={ attendee.attendeeId } button>
              <ListItemAvatar>
                <Avatar alt={ attendee.firstName } src={ attendee.avatar } />
              </ListItemAvatar>
              <ListItemText
                primary={ `${attendee.firstName} ${attendee.lastName}` }
              />
              <ListItemSecondaryAction>
                {
                  mapStatusToActions({
                    gfcScheduleId,
                    attendee,
                    price,
                  })[getStatusForMap(attendee.status, spotsAvailable)]
                }
              </ListItemSecondaryAction>
            </ListItem>
          )
        )}
      </List>
    </div>
  )
}

export default AttendingList
