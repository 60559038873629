import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    element: {
      base: {
        fontSize: '18px',
      },
    },
    header: { fontWeight: 100 },
    elementContainer: {
      marginBottom: spacing(2.5),
    },
  })
)
