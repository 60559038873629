import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

// Style the child elements with active color
export default makeStyles(
  ({ spacing, palette }: Theme): StyleRules => ({
    avatar: {
      paddingTop: spacing(),
      paddingRight: spacing(2),
    },
    column: {
      flexDirection: 'column',
    },
    card: {
      marginBottom: spacing(2),
    },
    subtitle: {
      color: palette.grey['700'],
    },
    externalUrlBtn: {
      float: 'right',
    },
  })
)
