import Dumb from './CalendarTZWarning'
import React from 'react'
import { TimezoneComponent, TimezoneQuery } from 'types/graphql'

const CalendarTZWarning = (): JSX.Element => {
  return (
    <TimezoneComponent>
      {({ data, loading }): JSX.Element | null => {
        if (loading) return null
        return <Dumb data={ data as TimezoneQuery } />
      }}
    </TimezoneComponent>
  )
}

export default CalendarTZWarning
