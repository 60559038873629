import Dumb from './CouponField'
import React from 'react'
import { useCartCouponQuery, useRedeemCouponMutation } from 'types/graphql'

const CouponField = (): JSX.Element | null => {
  const { data, loading } = useCartCouponQuery()
  const [redeemCoupon] = useRedeemCouponMutation()

  if (loading || !data) return null

  return (
    <Dumb
      coupon={ data.viewer.cart && data.viewer.cart.coupon }
      redeemCoupon={ redeemCoupon }
    />
  )
}

export default CouponField
