import React from 'react'
import startCase from 'lodash/startCase'
import { TextField } from '@material-ui/core'
import { ErrorMessage, Field, FieldProps } from 'formik'
import useStyles from 'components/dialogForms/styles'
import PhoneNumberInput from 'components/PhoneNumberInput'
import ReadOnlyFieldTooltip from 'components/ReadOnlyFieldTooltip'
import { BaseTextFieldProps } from '@material-ui/core/TextField'

export interface Props extends BaseTextFieldProps {
  readOnly?: boolean;
  phoneNumber?: boolean;
  name: string;
}

const TextFieldInput = ({
  name,
  readOnly,
  phoneNumber,
  ...props
}: Props): JSX.Element => {
  const classes = useStyles()

  let inputProps = {}
  if (phoneNumber) {
    inputProps = { inputComponent: PhoneNumberInput }
  }

  const nameParts = name.split('.')

  return (
    <Field name={ name }>
      {({ field, meta: { touched, error } }: FieldProps): JSX.Element => {
        return (
          <>
            <ReadOnlyFieldTooltip show={ Boolean(readOnly) }>
              <TextField
                { ...field }
                fullWidth
                label={ startCase(nameParts[nameParts.length - 1]) }
                className={ classes.field }
                id={ `input-${name}` }
                InputProps={ inputProps }
                inputProps={ { readOnly } }
                error={ touched && Boolean(error) }
                helperText={ <ErrorMessage name={ name } /> }
                { ...props }
                variant='outlined'
              />
            </ReadOnlyFieldTooltip>
          </>
        )
      }}
    </Field>
  )
}

export default TextFieldInput
