import React from 'react'

const AutoRenewDialog = (): JSX.Element => {
  return (
    <>
      <p>
        Would you like this subscription to renew automatically at the end of
        the billing cycle?
      </p>
      <p>You can always change this later.</p>
    </>
  )
}

export default AutoRenewDialog
