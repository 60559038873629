import Dumb from './RenewSubscriptionButton'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { RENEW_SUBSCRIPTION_MUTATION } from 'mutations/account'

const RenewSubscriptionButton = ({ subscriptionId }) => (
  <Mutation
    mutation={ RENEW_SUBSCRIPTION_MUTATION }
    variables={ { subscriptionId } }
  >
    {(renewSubscription, { loading }) => (
      <Dumb renewSubscription={ renewSubscription } loading={ loading } />
    )}
  </Mutation>
)

RenewSubscriptionButton.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
}

export default RenewSubscriptionButton
