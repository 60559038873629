import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

import Partner from 'components/family/Partner'

const styles = {
  root: {
    textAlign: 'center',
    padding: 10,
  },
  tabHeader: {
    textAlign: 'center',
    fontWeight: '100',
    textTransform: 'uppercase',
    fontSize: '1.1rem',
    width: '100%',
  },
}

const PartnerWrapper = ({ classes }) => {
  return (
    <Grid container className={ classes.root }>
      <h2 className={ classes.tabHeader }>Partner</h2>
      <Partner />
    </Grid>
  )
}

PartnerWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PartnerWrapper)
