import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'lib/formats'
import { isOnlyUser, timeRangeWithTimeZone } from 'lib/helpers'
import AttendingList from 'components/GFC/AttendingList'
import useStyles from './styles'
import { GfcScheduleFragment } from 'types/graphql'

interface Props {
  gfcSchedule: GfcScheduleFragment;
}

const GFCDetails = ({
  gfcSchedule: {
    title,
    id: gfcScheduleId,
    eventDate,
    instructor,
    classLocation,
    costForUser,
    freeForUser,
    gfc: { description },
    startDateTime,
    endDateTime,
    attending,
    spotsAvailable,
  },
}: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText variant='h6'>{description}</DialogContentText>
        <DialogContentText variant='body1'>
          <strong>Time</strong>:{' '}
          <span className={ classes.time }>
            {timeRangeWithTimeZone(startDateTime, endDateTime)}
          </span>
          <br />
          <strong>Date</strong>: {moment(eventDate).format(DATE_FORMAT)}
          {classLocation && (
            <span>
              <br />
              <strong>Where</strong>: {classLocation}
            </span>
          )}
          <br />
          <strong>Instructor</strong>: {instructor}
          <span>
            <br />
            <strong>Price</strong>: {costForUser}
          </span>
        </DialogContentText>
        {!isOnlyUser(attending) && (
          <AttendingList
            attending={ attending }
            gfcScheduleId={ gfcScheduleId }
            spotsAvailable={ spotsAvailable }
            price={ freeForUser ? undefined : costForUser }
          />
        )}
      </DialogContent>
    </React.Fragment>
  )
}

export default GFCDetails
