import React from 'react'
import PropTypes from 'prop-types'
import { trackEvent } from 'lib/analytics'
import LoadingButton from 'components/LoadingButton'
import confirm from 'lib/confirm'

// export for testing
export const confirmText = (items, total) => (
  <>
    <p>The total amount of your purchase is {total}</p>
    <p>Items Purchasing:</p>
    <ul>
      {items.map(item => (
        <li key={ item.id }>
          {item.quantity} x {item.title}
        </li>
      ))}
    </ul>
  </>
)

const CheckoutButton = ({ label, checkout, items, total, loading }) => {
  const confirmCheckout = () => {
    confirm(confirmText(items, total), {
      title: 'Complete Purchase?',
      okLabel: 'Purchase Now',
    }).then(
      () => {
        checkout()

        trackEvent({
          category: 'Cart',
          action: 'Purchased',
        })
      },
      () => {}
    ) // this is a cancel callback, do nothing.
  }

  return (
    <LoadingButton onClick={ confirmCheckout } loading={ loading }>
      {label}
    </LoadingButton>
  )
}

CheckoutButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  checkout: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  total: PropTypes.string.isRequired,
}

export default CheckoutButton
