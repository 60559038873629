import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import Spinner from 'components/Spinner'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import randomColor from 'randomcolor'
import { DATE_TIME_FORMAT, MONTH_FORMAT } from 'lib/formats'

// add range to moment
const moment = extendMoment(Moment)

const getMonth = datetime => moment(datetime).format("MMM 'YY")

const CheckinReport = ({ loading, data, startDateUnix, endDateUnix }) => {
  if (loading) return <Spinner />

  const { facilityUses, gfcCheckins } = data.viewer

  if (!facilityUses.length && !gfcCheckins.length) {
    return <span>No data available in time range</span>
  }

  // create a full range of months between the selected dates
  // returns an array of strings: ['Jul `18', 'Aug `18'...]
  const monthsInDateRange = () => {
    const range = moment
      .range(startDateUnix, endDateUnix)
      .snapTo('month')
      .by('month')
    return Array.from(range).map(month => month.format(MONTH_FORMAT))
  }

  const groupByMonthHash = activities => {
    // we get the uses sorted by date from the server
    let facilityUsesByMonth = {}

    // add uses to months hash
    const range = monthsInDateRange()
    range.forEach(month => {
      facilityUsesByMonth[month] = []
    })

    // produce an object like: {'Jan `08': [{...facility use}]}
    activities.forEach(act => {
      facilityUsesByMonth[getMonth(act.createdAt)].push(act)
    })

    return facilityUsesByMonth
  }

  const groupByMonth = activities => {
    const range = monthsInDateRange()
    const groupedHash = groupByMonthHash(activities)

    // return an array of the format: [{month: 'Jan `08', uses: [{...facility use}]}]
    return range.map(month => {
      return { month, uses: groupedHash[month] }
    })
  }

  const groupByUseOption = facilityUses => {
    let grouped = {}
    facilityUses.forEach(use => {
      const label = use.facilityUseOptionLabel
      grouped[label] ? grouped[label].push(use) : (grouped[label] = [use])
    })
    return Object.keys(grouped).map(facilityUseOption => {
      return {
        facilityUseOption,
        usesByMonth: groupByMonth(grouped[facilityUseOption]),
      }
    })
  }

  const datasets = groupByUseOption(facilityUses)
    .map(group => {
      return {
        stack: 'facilityUses',
        label: group.facilityUseOption,
        data: group.usesByMonth.map(month => month.uses.length),
        backgroundColor: randomColor({ seed: group.facilityUseOption }),
      }
    })
    .concat([
      {
        stack: 'gfcCheckins',
        label: 'GFC Check-in',
        data: groupByMonth(gfcCheckins).map(month => month.uses.length),
        backgroundColor: randomColor({ seed: 'gfcCheckins' }),
      },
    ])

  const chartOptions = {
    title: {
      text: 'Check-In Report',
      display: true,
      fontSize: 20,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: (items, data) =>
          `${data.datasets[items[0].datasetIndex].label} - ${items[0].xLabel}`,
        // show breakdown in tooltip by dates
        label: ({ xLabel, datasetIndex }, { datasets }) => {
          const option = datasets[datasetIndex].label
          // if it's a gfc checkin
          if (option === 'GFC Check-in') {
            const checkins = groupByMonthHash(gfcCheckins)[xLabel]
            return checkins.map(checkin =>
              moment(checkin.createdAt).format(DATE_TIME_FORMAT)
            )
            // or, if it's a facility use
          } else {
            const uses = groupByMonthHash(facilityUses)[xLabel]
            const filtered = uses.filter(
              use => use.facilityUseOptionLabel === option
            )

            return filtered.map(use =>
              moment(use.createdAt).format(DATE_TIME_FORMAT)
            )
          }
        },
      },
    },
  }

  const chartData = {
    // TODO: replace with date range picker
    labels: monthsInDateRange(),
    datasets,
  }

  return <Bar data={ chartData } options={ chartOptions } />
}

CheckinReport.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    viewer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      facilityUses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
          facilityUseOptionLabel: PropTypes.string.isRequired,
        })
      ).isRequired,
      gfcCheckins: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          createdAt: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }),
  startDateUnix: PropTypes.number.isRequired,
  endDateUnix: PropTypes.number.isRequired,
}

export default CheckinReport
