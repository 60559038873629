import React from 'react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'

const BewellStoneSVG = ({ color, earned }) => {
  const tiny = () =>
    earned ?
      tinycolor(color) :
      tinycolor(color)
        .desaturate(90)
        .setAlpha(0.4)

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='-5 0 512 512.00027'>
      <g>
        <path
          d='m319.40625 2.28125-160.199219 82.207031c-5.671875 2.910157-9.683593 8.273438-10.875 14.535157l-15.027343 78.886718c-.878907 4.625-3.3125 8.808594-6.890626 11.863282l-119.15625 101.695312c-4.925781 4.203125-7.601562 10.472656-7.2226558 16.9375l10.7539058 184.113281c.667969 11.457031 10.535157 20.179688 21.988282 19.4375l355.492187-23.070312c6.863281-.445313 13.054688-4.273438 16.523438-10.207031l92.195312-157.832032c2.527344-4.328125 3.398438-9.425781 2.449219-14.34375l-35.363281-183.566406c-.789063-4.09375-.324219-8.324219 1.335937-12.148438l35.519532-81.867187c5.925781-13.664063-4.085938-28.921875-18.976563-28.921875h-153.105469c-3.28125 0-6.519531.78125-9.441406 2.28125zm0 0'
          fill={ tiny().lighten(10) }
        />
        <path
          d='m417.71875 298.390625-90.394531 103.300781c-3.394531 3.878906-8.117188 6.34375-13.242188 6.910156l-207.738281 22.992188c-9.476562 1.046875-18.433594-4.542969-21.660156-13.515625l-35.441406-98.664063c-4.1875-11.652343 2.773437-24.335937 14.847656-27.0625l224.9375-50.839843c8.296875-1.875 14.585937-8.65625 15.835937-17.070313l24.679688-166.285156c1.261719-8.476562 7.636719-15.292969 16.015625-17.109375l152.675781-33.109375c-3.710937-4.753906-9.484375-7.9375-16.28125-7.9375h-153.105469c-3.285156 0-6.519531.78125-9.441406 2.28125l-160.199219 82.207031c-5.671875 2.910157-9.683593 8.273438-10.875 14.535157l-15.027343 78.886718c-.882813 4.625-3.3125 8.808594-6.890626 11.863282l-119.15625 101.695312c-.296874.25-.582031.511719-.863281.777344-.097656.097656-.1875.195312-.285156.289062-.175781.175782-.351563.351563-.523437.53125-.113282.121094-.21875.25-.332032.371094-.140625.160156-.285156.320312-.421875.484375-.117187.140625-.230469.289063-.347656.429687-.117187.152344-.238281.304688-.355469.460938-.117187.15625-.234375.316406-.34375.476562-.105468.148438-.207031.292969-.304687.441407-.117188.175781-.230469.351562-.339844.527343-.085937.136719-.167969.273438-.25.414063-.113281.191406-.226563.386719-.332031.585937-.070313.121094-.136719.246094-.199219.375-.113281.214844-.222656.429688-.328125.648438-.046875.105469-.097656.210938-.144531.316406-.109375.242188-.21875.484375-.320313.726563-.03125.074219-.058594.148437-.085937.222656-.113281.28125-.222657.5625-.320313.847656-.003906.007813-.003906.015625-.007812.023438-.710938 2.046875-1.1015628 4.210937-1.1406252 6.414062v.003907c-.00781255.523437-.0039063 1.046874.0273437 1.574218l10.7499995 184.109375c.667969 11.457031 10.535157 20.179688 21.988282 19.4375l355.492187-23.070312c6.863281-.445313 13.054688-4.273438 16.523438-10.207031l92.195312-157.828126c1.664063-2.847656 2.59375-6.027343 2.773438-9.269531l-60.269531-19.25c-7.816407-2.496093-16.367188-.117187-21.773438 6.058594zm0 0'
          fill={ tiny() }
        />
        <path
          d='m478.445312 197.523438-14.371093-74.585938c-.363281-1.886719-.449219-3.796875-.285157-5.691406l-61.152343-19.589844c-11.925781-3.816406-24.472657 3.792969-26.589844 16.136719l-19.75 115.042969c-2.949219 17.1875 15.457031 30.039062 30.578125 21.347656zm0 0'
          fill={ tiny() }
        />
      </g>
    </svg>
  )
}

BewellStoneSVG.propTypes = {
  color: PropTypes.string.isRequired,
  earned: PropTypes.bool.isRequired,
}

export default BewellStoneSVG
