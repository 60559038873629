import React, { SyntheticEvent, Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import moment from 'moment'
import { DialogPropsEvent } from 'types/graphql'
import RegisteredActions from 'components/Events/RegisteredActions'
import CloseDialogButton from 'components/CloseDialogButton'
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'lib/formats'
import ErrorMessage from 'components/ErrorMessage'

export interface Props {
  closeDialog(event: SyntheticEvent<{}>): void;
  event: DialogPropsEvent | null;
}

const BookedEventDialog = ({ closeDialog, event }: Props): JSX.Element => {
  return (
    <Dialog fullWidth maxWidth={ 'sm' } open onClose={ closeDialog }>
      {event ? (
        <Fragment>
          <DialogTitle>
            You are booked for {event.title}{' '}
            {event.staff && ` with ${event.staff.name}`}
          </DialogTitle>
          <DialogContent>
            {moment(event.startTime).format(DATE_TIME_FORMAT)} -{' '}
            {moment(event.endTime).format(TIME_FORMAT)}
          </DialogContent>
          <DialogActions>
            <RegisteredActions registration={ event } />
            <CloseDialogButton gutterRight />
          </DialogActions>
        </Fragment>
      ) : (
        <ErrorMessage />
      )}
    </Dialog>
  )
}

export default BookedEventDialog
