import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  registeredContainer: {
    textAlign: 'right',
  },
  addToCalendarContainer: {
    marginRight: '16px',
    display: 'inline-block',
  },
})
