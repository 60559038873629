import * as Yup from 'yup'

const string = (label: string) =>
  Yup.string()
    .min(2, `${label} too short`)
    .max(50, `${label} too long`)
    .required('Required')

export const password = string('Password')

export const passwordConfirmation = Yup.string()
  .oneOf([Yup.ref('password'), ''], 'Passwords must match')
  .required('Required')

export const profile = Yup.object().shape({
  firstName: string('First name'),
  lastName: string('Last name'),
})

export const username = string('Username')
