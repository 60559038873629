import React from 'react'
import classNames from 'classnames'
import useStyles from './styles'

const images = [
  '/images/register/signup-bg-1.png',
  '/images/register/signup-bg-2.jpg',
  '/images/register/signup-bg-3.jpg',
]

const ImageRotator = (): JSX.Element => {
  const classes = useStyles()

  const renderImage = (image: string, i: number): JSX.Element => {
    return (
      <div
        key={ image }
        className={ classNames(classes.image, classes.fullAbsolute) }
        style={ {
          backgroundImage: `url(${image})`,
          animationDelay: `${i * 10}s`,
        } }
        data-testid={ image }
      />
    )
  }

  return (
    <div className={ classes.container }>
      {images.map(renderImage)}
      <div className={ classNames(classes.overlay, classes.fullAbsolute) } />
    </div>
  )
}

export default ImageRotator
