import Dumb from './LengthStep'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import Spinner from 'components/Spinner'

import { AVAILABLE_APPT_LENGTHS } from 'queries'

const LengthStep = props => {
  const {
    event: { eventId, startTime, endTime },
  } = props
  return (
    <Query
      query={ AVAILABLE_APPT_LENGTHS }
      variables={ {
        apptId: eventId,
        startTime,
        endTime,
      } }
    >
      {({ data, loading }) => {
        if (loading) return <Spinner center />

        const lengths = data.viewer.location.appointment.availableLengths
        return <Dumb lengths={ lengths } { ...props } />
      }}
    </Query>
  )
}

LengthStep.propTypes = {
  event: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
  }),
}

export default LengthStep
