import Dumb from './AdditionalInfoStep'
import { useValidateCustomFieldsMutation } from 'types/graphql-auth'
import React from 'react'
import RegistrationStep from 'components/registration/RegistrationStep'
import { NewUserForm } from 'types'
import {
  CustomFieldInValue,
  CustomFieldValidationError,
} from 'components/registration/types'

const AdditionalInfoStep = (): JSX.Element | null => {
  const [validateCustomFields] = useValidateCustomFieldsMutation()

  const validate = async(
    values: NewUserForm,
  ): Promise<{ customUserFields: CustomFieldInValue[] } | void> => {
    const res = await validateCustomFields({
      variables: {
        customFields: values.customUserFields as {
          id: number;
          optionId: number;
        }[],
      },
    })
    const errors = res && res.data && res.data.validateCustomFields

    if (!errors || !errors.customUserFields) return

    const parsed: { [index: number]: string } = errors.customUserFields.reduce(
      (
        acc: object,
        { fieldId, error }: CustomFieldValidationError,
      ): { [index: number]: string } => ({ ...acc, [fieldId]: error }),
      {},
    )

    return {
      customUserFields: values.customUserFields.map(
        (field): CustomFieldInValue => {
          if (!parsed[field.id]) return { id: 0, optionId: '' }
          return { id: field.id, optionId: parsed[field.id] }
        },
      ),
    }
  }

  return (
    <RegistrationStep validate={ validate }>
      {(): JSX.Element => <Dumb />}
    </RegistrationStep>
  )
}

export default AdditionalInfoStep
