import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

// The !important styles below are because of
// https://github.com/mui-org/material-ui/issues/16609
export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    field: {
      display: 'block !important',
      width: '100%',
      maxWidth: 500,
      marginBottom: `${spacing(1.5)}px !important`,
    },
    stripeContainer: {
      maxWidth: 500,
      padding: spacing(2),
    },
    stripeErrorMessage: {
      marginBottom: spacing(1.5),
    },
    stepButton: {
      marginRight: spacing(1),
    },
    customFieldLabel: {
      color: 'rgba(0, 0, 0, 0.6)',
      marginTop: 10,
      maxWidth: 400,
    },
    verifyUserErrorMessage: {
      marginBottom: spacing(2),
    },
  })
)
