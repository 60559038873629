import React from 'react'
import Dumb from 'components/AddCreditCardDialog/AddCreditCardDialog'
import { useStripeApiKeyQuery } from 'types/graphql'
import ErrorDialog from 'components/ErrorDialog'

const AddCreditCardDialog = (): JSX.Element | null => {
  const { data, loading, error } = useStripeApiKeyQuery()

  if (loading) return null

  if (!data) return <ErrorDialog errorMessage={ error && error.message } />

  return <Dumb data={ data } />
}

export default AddCreditCardDialog
