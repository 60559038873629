import Dumb from './ToggleDayButton'
import React from 'react'
import moment from 'moment'
import { useSnackbar } from 'notistack'

interface Props {
  isToday: boolean;
  setIsToday: (isToday: boolean) => void;
  cutoffMoment: moment.Moment;
}

const successMessage = (isToday: boolean): string =>
  `You are now viewing ${isToday ? 'yesterday' : 'today'}`

const toggleNotAllowed = (
  isToday: boolean,
  cutoffMoment: moment.Moment
): boolean => isToday && moment().isAfter(cutoffMoment)

const ToggleDayButton = ({
  isToday,
  setIsToday,
  cutoffMoment,
}: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const toggleDay = (): void => {
    if (toggleNotAllowed(isToday, cutoffMoment)) {
      enqueueSnackbar("It is too late in the day to edit yesterday's items", {
        variant: 'error',
      })
    } else {
      setIsToday(!isToday)
      // seems opposite because it's before isToday is updated
      enqueueSnackbar(successMessage(isToday))
    }
  }

  return <Dumb isToday={ isToday } toggleDay={ toggleDay } />
}

export default ToggleDayButton
