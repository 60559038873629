import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    container: {
      margin: spacing(3),
      overflow: 'hidden',
    },
    subheader: {},
    input: {
      textTransform: 'uppercase',
    },
    inputContainer: {
      position: 'relative',
    },
    button: {
      position: 'absolute',
      right: 0,
      top: '-1px',
    },
    amount: {
      float: 'right',
    },
  })
)
