import {
  createStyles,
  makeStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles'
import { Props } from './DashboardWidget'

const isStatZero = (stat?: number | string | null): boolean => {
  if (typeof stat === 'undefined' || stat === null) return true

  const statNum = typeof stat === 'string' ? parseInt(stat, 10) : stat
  return isNaN(statNum) ? false : !statNum
}

export default makeStyles(
  ({ spacing }: Theme): StyleRules<any, any> =>
    createStyles({
      flex: {
        display: 'flex',
        flex: 'auto',
        flexWrap: 'wrap',
        overflow: 'hidden',
      },
      widget: {
        color: '#404040',
        borderTop: ({ stat }: Props): string =>
          `3px solid ${isStatZero(stat) ? '#d63258' : '#0abf22'}`,
        backgroundColor: '#fff',
        borderRadius: '3px',
        padding: '20px',
        marginBottom: spacing(1),
        flex: '1',
      },
      h2: {
        fontSize: '1.4em',
        textAlign: 'center',
        fontWeight: 180,
        margin: spacing(1),
      },
      value: {
        fontSize: '2em',
        paddingTop: spacing(1),
        textAlign: 'center',
      },
      description: {
        fontSize: '18px',
        fontWeight: 300,
        textAlign: 'center',
      },
    })
)
