import Dumb from './DependentsTab'
import React from 'react'
import { Query, Mutation } from 'react-apollo'
import Spinner from 'components/Spinner'
import { DEPENDENTS_QUERY } from 'queries/family'
import { REMOVE_DEPENDENT_MUTATION } from 'mutations/family'

const DependentsTab = props => (
  <Query query={ DEPENDENTS_QUERY }>
    {({ data, loading }) => {
      if (loading) return <Spinner center fullHeight />
      return (
        <Mutation mutation={ REMOVE_DEPENDENT_MUTATION }>
          {(removeDependent, { loading: removeDependentLoading }) => (
            <Dumb
              data={ data }
              removeDependent={ removeDependent }
              removeDependentLoading={ removeDependentLoading }
              { ...props }
            />
          )}
        </Mutation>
      )
    }}
  </Query>
)

export default DependentsTab
