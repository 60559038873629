import Dumb from './ResetPasswordDialog'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useRequestResetPasswordMutation } from 'types/graphql-auth'
import { useHistory } from 'react-router'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter Email address')
    .required('Required'),
})

export const ResetPasswordDialog = (): JSX.Element => {
  const [requestReset] = useRequestResetPasswordMutation()
  const { push } = useHistory()

  const handleSubmit = async({ email }: { email: string }): Promise<void> => {
    await requestReset({ variables: { email } })
    push('/login')
  }

  return (
    <Formik
      onSubmit={ handleSubmit }
      initialValues={ { email: '' } }
      validationSchema={ validationSchema }
    >
      <Form>
        <Dumb />
      </Form>
    </Formik>
  )
}

export default ResetPasswordDialog
