import { Step } from 'react-joyride'

interface CustomStep extends Step {
  action?: () => void;
}

const steps: CustomStep[] = [
  {
    target: 'body',
    title: 'Welcome to your fitness dashboard!',
    content:
      'Take a quick tour to learn how to sign up for classes, purchase ' +
      'any health and fitness services, and manage your account.',
    placement: 'center',
    disableBeacon: true,
  },
  {
    target: '#total-class-visits',
    title: 'Total Class Visits',
    content:
      'Total Class Visits is where you can view the total amount of' +
      ' times you’ve attended a class this month.',
  },
  {
    target: '#total-facility-visits',
    title: 'Total Facility Visits',
    content:
      'Total Facility Visits is where you can view the total amount' +
      ' of times you’ve checked in to your fitness center this month.',
  },
  {
    target: '#remaining-guest-passes',
    title: 'Remaining Guest Passes',
    content:
      'Remaining Guest Passes shows the amount of guest passes you ' +
      'have left for the month.',
  },
  {
    target: '#total-points',
    title: 'Total Points',
    content:
      'Total Points shows how many points you’ve accumulated this month.',
  },
  {
    target: '#available-credits',
    title: 'Available Credits',
    content: 'Available Credits shows you how many more credits you can use.',
  },
  {
    target: '#my-profile-menu',
    title: 'My Profile',
    content: 'Access information about your profile here. Click it now.',
    action: (): void => {
      window.scrollTo(0, 0)
    },
    styles: { buttonNext: { display: 'none' } },
    spotlightClicks: true,
  },
  {
    target: '#my-profile-menu-profile',
    title: 'Profile',
    content:
      '"Profile" is where you can edit your basic info, emergency contacts ' +
      'and medical details. You can also view your recent activities here.',
    placement: 'left',
  },
  {
    target: '#my-profile-menu-account',
    title: 'Account',
    content:
      'The "Account" section is where you can view or edit any ' +
      'account details such as your password or billing information.',
    placement: 'left',
  },
  {
    target: '#family-menu-account',
    title: 'Family & Friends',
    content:
      'Use the "Family & Friends" menu to add dependents and/or guests to ' +
      'your account.',
    placement: 'left',
  },
  {
    target: '#my-profile-menu',
    title: 'My Profile',
    content: 'Click again to close the profile menu.',
    action: (): void => {
      window.scrollTo(0, 0)
    },
    styles: { buttonNext: { display: 'none' } },
    spotlightClicks: true,
  },
  {
    target: '#dash-gfc',
    title: 'Upcoming Group Fitness Classes',
    content:
      'You can find a bird’s-eye view of the upcoming group fitness ' +
      'classes here. You can learn more about the class, contact the instructor,' +
      ' and register for the class',
    spotlightPadding: 5,
    placement: 'left',
  },
  {
    target: '#dash-gfc-reClasses you aregistered',
    title: ' registered for',
    content:
      'You can view all of the classes you’re currently registered ' +
      'for here. You can also cancel your spot in class by clicking the ' +
      'Unregister button',
    spotlightPadding: 5,
    placement: 'right',
  },
  {
    target: '#link-group-fitness-classes',
    title: 'Group Fitness Classes',
    content:
      'Click Group Fitness Classes here to view the entire listing of' +
      ' classes being offered. Click it now.',
    placement: 'right',
    spotlightClicks: true,
    styles: { buttonNext: { display: 'none' } },
  },
  {
    target: '#gfc-container',
    title: 'Group Fitness Classes',
    content:
      'You can view the available Group Fitness Classes as a calendar or ' +
      'in a filterable list view.',
    disableOverlay: true,
  },
  {
    target: '#link-health-fitness-services',
    title: 'Health Fitness Services',
    content:
      'View all of the Health & Fitness Services here. This is where ' +
      'you can purchase personal training, sign up for orientations, and more.' +
      ' Click it now.',
    placement: 'right',
    spotlightClicks: true,
    styles: { buttonNext: { display: 'none' } },
  },
  {
    target: '.fitness-service-type',
    content:
      'When you click on a service type, a drop-down menu will pop up ' +
      'where you can choose which product you’d like to purchase. Click it now.',
    spotlightClicks: true,
    styles: { buttonNext: { display: 'none' } },
  },
  {
    target: '.fitness-service',
    content:
      'Click the arrow button to the right to view a full product description.',
    spotlightClicks: true,
    styles: { buttonNext: { display: 'none' } },
  },
  {
    target: '.add-to-cart',
    content:
      'When you’ve chosen a product to purchase, click the cart icon to' +
      ' add the product to your cart, or a staff icon to book a session with them',
  },
  {
    target: '#link-staff',
    title: 'Staff',
    content:
      'You can view the staff, read their bios, and contact them directly here.' +
      ' Click it now.',
    placement: 'right',
    spotlightClicks: true,
    styles: { buttonNext: { display: 'none' } },
  },
  {
    target: '#link-facility-info',
    title: 'Facility Info',
    content:
      'All of the information regarding your facility can be found ' +
      'here, including address, phone, contact, and hours. Click it now.',
    placement: 'right',
    spotlightClicks: true,
    styles: { buttonNext: { display: 'none' } },
  },
]

export default steps
