import gql from 'graphql-tag'
import { fitnessService, membership } from 'fragments/hfs'

export const FITNESS_SERVICE_TYPES_QUERY = gql`
  query locationFitnessServiceTypes {
    viewer {
      id
      location {
        id
        config {
          id
          subscriptionsTitle
          subscriptionsDescription
          subscriptionsIcon
          groupCreditsTitle
          groupCreditsDescription
          groupCreditsIcon
          guestPassIcon
        }
        fitnessServiceTypes {
          id
          title
          iconColor
          iconImage
          description
          externalUrl
          fitnessServices {
            ...fitnessService
          }
        }
        memberships {
          ...membership
        }
        gfcCreditPlans {
          id
          title
          description
          price
          validFor
        }
        guestPassPackages {
          id
          title
          description
          price
          validFor
        }
      }
    }
  }
  ${fitnessService}
  ${membership}
`
