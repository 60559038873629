import Dumb from './SubLocationStep'
import React, { useContext } from 'react'
import { RegistrationContext } from 'components/registration/helpers'
import RegistrationStep from 'components/registration/RegistrationStep'
import { Context } from 'components/registration/types'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    locationId: Yup.number()
      .positive('Required')
      .required('Required'),
  }),
})

const SubLocationStep = (): JSX.Element | null => {
  const { formValues } = useContext(RegistrationContext) as Context

  if (!formValues.user.locationId) return null

  return (
    <RegistrationStep validationSchema={ validationSchema }>
      {(): JSX.Element => <Dumb />}
    </RegistrationStep>
  )
}

export default SubLocationStep
