import React from 'react'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import RedeemButton from 'components/RewardPoints/RedeemButton'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import {
  CloseDialogMutationFn,
  RewardRedemptionOptionFragment,
} from 'types/graphql'
import useStyles from './styles'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import { chain } from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export interface Props {
  items: RewardRedemptionOptionFragment[];
  totalPoints?: null | number;
  closeDialog?: CloseDialogMutationFn;
}

type ChangeHandler = (
  event: React.ChangeEvent<{}>,
  newExpanded: boolean
) => void

const RedeemPointsCatalog = ({
  items,
  totalPoints,
  closeDialog,
}: Props): JSX.Element => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<number | false>(false)

  if (!items.length) {
    return (
      <Typography>
        No items available. Please contact your facility manager.
      </Typography>
    )
  }

  const groupedItems = chain(items)
    .sortBy('pointsPerProduct')
    .groupBy(
      (item): string =>
        `Redeemable for ${item.pointsPerProduct} reward point(s)`
    )
    .map((items: RewardRedemptionOptionFragment[], label: string): {
      label: string;
      items: RewardRedemptionOptionFragment[];
    } => ({ label, items }))
    .value()

  const handleChange = (panel: number): ChangeHandler => (
    event,
    newExpanded
  ): void => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div className={ classes.root }>
      {groupedItems.map(
        ({ label, items }, i): JSX.Element => (
          <ExpansionPanel
            key={ label }
            square
            expanded={ expanded === i }
            onChange={ handleChange(i) }
            className={ classes.expansionPanel }
          >
            <ExpansionPanelSummary
              className={ classes.expansionPanelSummary }
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls={ `${label}-content` }
              id={ `${label}-header` }
            >
              <Typography>{label}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <GridList className={ classes.itemsList }>
                {items.map(
                  ({
                    id,
                    pointsPerProduct,
                    name,
                    description,
                    image,
                  }): JSX.Element => (
                    <GridListTile key={ id }>
                      <img src={ image } alt={ name } />
                      <GridListTileBar
                        title={ `${name} (${pointsPerProduct} points)` }
                        subtitle={ <span>{description}</span> }
                        actionIcon={
                          <RedeemButton
                            productId={ id }
                            points={ pointsPerProduct }
                            disabled={
                              !totalPoints || pointsPerProduct > totalPoints
                            }
                            closeDialog={ closeDialog }
                          />
                        }
                      />
                    </GridListTile>
                  )
                )}
              </GridList>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      )}
    </div>
  )
}

export default RedeemPointsCatalog
