import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing, palette }: Theme): StyleRules => ({
    field: {
      marginBottom: spacing(2),
      marginTop: 0,
    },
    emptyOption: {
      color: palette.dimmed.main,
    },
  })
)
