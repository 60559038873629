import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import IconButton from 'material-ui/IconButton'
import DialogLink from 'components/DialogLink'
import moment from 'moment'
import PlayIcon from 'material-ui/svg-icons/av/play-circle-filled'
import InfiniteScroll from 'react-infinite-scroller'

const styles = () => ({
  imgWrapper: {
    height: '100%',
    backgroundColor: 'black',
  },
  thumbnail: {
    width: '100%',
  },
  badge: {
    position: 'absolute',
    top: '20px',
    right: '60px',
  },
  innerBadge: {
    width: '100px',
    height: '24px',
    borderRadius: '10%',
  },
})

// export for testing
export const updateQuery = (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult) return prev
  const library = Object.assign({}, prev.videoLibrary, {
    videos: [
      ...prev.videoLibrary.videos,
      ...fetchMoreResult.videoLibrary.videos,
    ],
  })
  return Object.assign({}, prev, {
    videoLibrary: { ...prev.videoLibrary, ...library },
  })
}

const loadFunc = (page, fetchMore) => {
  fetchMore({
    variables: {
      offset: page * 10,
    },
    updateQuery,
  })
}

const VideoLibraryVideos = ({ data, fetchMore, classes }) => {
  const theme = useTheme()
  const isMed = useMediaQuery(theme.breakpoints.down('md'))
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <InfiniteScroll
      pageStart={ 0 }
      loadMore={ page => loadFunc(page, fetchMore) }
      hasMore={ data.videoLibrary.videos.length < data.videoLibrary.totalCount }
      loader={
        <Typography variant='body1' key={ 0 }>
          Loading ...
        </Typography>
      }
    >
      <p>Found {data.videoLibrary.totalCount} videos</p>
      <GridList cellHeight={ 300 } cols={ isXs ? 1 : isMed ? 2 : 3 }>
        {data.videoLibrary.videos.map(video => (
          <GridListTile key={ video.id }>
            <DialogLink
              trigger={
                <div className={ classes.imgWrapper }>
                  <img
                    src={ video.imgUrl }
                    alt={ video.title }
                    className={ classes.thumbnail }
                  />
                  <GridListTileBar
                    title={ video.title }
                    subtitle={ video.description }
                    actionIcon={
                      <IconButton aria-label={ `Watch ${video.title}` }>
                        <PlayIcon color='white' />
                      </IconButton>
                    }
                  />
                  <Badge
                    classes={ { root: classes.badge, badge: classes.innerBadge } }
                    color='primary'
                    badgeContent={ moment
                      .duration(video.duration, 'seconds')
                      .humanize() }
                  >
                    {/* because every badge needs a child */}
                    <div />
                  </Badge>
                </div>
              }
              dialogComponent='VideoDialog'
              dialogProps={ { videoUrl: video.videoUrl } }
            />
          </GridListTile>
        ))}
      </GridList>
    </InfiniteScroll>
  )
}

VideoLibraryVideos.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchMore: PropTypes.func.isRequired,
  data: PropTypes.shape({
    videoLibrary: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          duration: PropTypes.number.isRequired,
          imgUrl: PropTypes.string.isRequired,
          videoUrl: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
}

export default withStyles(styles)(VideoLibraryVideos)
