import Dumb from './GuestDialog'
import React from 'react'
import {
  DialogPropsQuery,
  GuestInput,
  useAddGuestMutation,
  useDialogPropsQuery,
  useEditGuestMutation,
  useGuestQuery,
} from 'types/graphql'
import ErrorDialog from 'components/ErrorDialog'
import {
  Form,
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import { ExecutionResult } from 'apollo-link'
import validationSchema from './validationSchema'

const GuestDialog = (): JSX.Element | null => {
  const { data: dialogData } = useDialogPropsQuery()
  const {
    dialogProps: { id: guestId },
  } = dialogData as DialogPropsQuery
  const { data, loading, error } = useGuestQuery({
    variables: { guestId },
  })
  const [editGuest, { loading: editLoading }] = useEditGuestMutation()
  const [addGuest, { loading: addLoading }] = useAddGuestMutation()

  if (loading) return null

  if (!data) return <ErrorDialog errorMessage={ error && error.message } />

  const {
    guest,
    location: {
      config: { requireGuestEmergencyContacts },
    },
  } = data.viewer

  const initialValues = {
    firstName: (guest && guest.firstName) || '',
    lastName: (guest && guest.lastName) || '',
    emergencyFirstName: (guest && guest.emergencyFirstName) || '',
    emergencyLastName: (guest && guest.emergencyLastName) || '',
    emergencyPhone: (guest && guest.emergencyPhone) || '',
    emergencyRelation: (guest && guest.emergencyRelation) || '',
  }

  const handleSubmit = (guestParams: GuestInput): Promise<ExecutionResult> => {
    return guestId ?
      editGuest({ variables: { guestParams, id: guestId } }) :
      addGuest({ variables: { guestParams } })
  }

  const validate = async(
    values: GuestInput
  ): Promise<FormikErrors<{ then: unknown }> | void> => {
    try {
      await validateYupSchema<GuestInput>(values, validationSchema, true, {
        emergencyRequired: requireGuestEmergencyContacts,
      })
    } catch (e) {
      return yupToFormErrors(e)
    }
  }

  return (
    <Formik
      initialValues={ initialValues }
      onSubmit={ handleSubmit }
      validate={ validate }
    >
      <Form>
        <Dumb
          isExisting={ Boolean(guestId) }
          submitLoading={ editLoading || addLoading }
          relations={ data.guestEmergencyRelations }
        />
      </Form>
    </Formik>
  )
}

export default GuestDialog
