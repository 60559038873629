import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing }: Theme): StyleRules => ({
    tabHeader: {
      textAlign: 'center',
      marginBottom: spacing(-1),
      fontWeight: 100,
      textTransform: 'uppercase',
      fontSize: '1.1rem',
    },
    buttonContainer: {
      textAlign: 'center',
      position: 'relative',
      top: '30px',
    },
    container: {
      minWidth: spacing(38),
    },
  })
)
