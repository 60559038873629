import React, { useState } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  popover: {
    padding: theme.spacing(2),
  },
})

const PopoverButton = ({
  classes,
  children,
  label,
  variant,
  color,
  btnStyles = {},
}) => {
  const [anchor, setAnchor] = useState(null)

  const handleClick = event => setAnchor(event.currentTarget)

  const handleClose = () => setAnchor(null)

  return (
    <React.Fragment>
      <Button
        aria-haspopup='true'
        variant={ variant || 'contained' }
        color={ color || 'primary' }
        onClick={ handleClick }
        style={ { ...btnStyles } }
      >
        {label}
      </Button>
      <Popover
        anchorEl={ anchor }
        anchorOrigin={ {
          vertical: 'center',
          horizontal: 'right',
        } }
        transformOrigin={ {
          vertical: 'center',
          horizontal: 'left',
        } }
        open={ Boolean(anchor) }
        onClose={ handleClose }
      >
        <div className={ classes.popover }>{children}</div>
      </Popover>
    </React.Fragment>
  )
}

PopoverButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  btnStyles: PropTypes.object,
}

export default withStyles(styles)(PopoverButton)
