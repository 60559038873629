import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
})

const LengthStep = ({ lengths, onComplete, classes }) => (
  <Grid container spacing={ 3 } className={ classes.root }>
    {lengths.map(length => (
      <Grid item xs key={ length }>
        <Button
          variant='outlined'
          className={ classes.button }
          onClick={ () => onComplete('selectedLength', length) }
        >
          {length} minutes
        </Button>
      </Grid>
    ))}
  </Grid>
)

LengthStep.propTypes = {
  lengths: PropTypes.arrayOf(PropTypes.number).isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default withStyles(styles)(LengthStep)
