import { makeStyles, Theme } from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'

export default makeStyles(
  ({ spacing, palette, typography }: Theme): StyleRules => ({
    card: {
      marginBottom: '15px',
      lineHeight: '24px',
      position: 'relative',
    },
    titleText: {
      fontWeight: typography.fontWeightMedium,
      marginBottom: spacing(1),
    },
    subtitleText: {
      color: palette.dimmed.strong,
      fontSize: '0.9rem',
    },
    actions: {
      // takes the actions to the right in flex
      marginLeft: 'auto',
      padding: 0,
      whiteSpace: 'normal',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    addToCartButton: {
      marginBottom: '5px',
      marginTop: '5px',
    },
    avatar: {
      width: '100%',
      height: '100%',
    },
    description: {
      whiteSpace: 'pre-wrap',
    },
  })
)
